/* eslint-disable react/prop-types */
const RedButton = ({ onClick, children, type = "button", form }) => (
  <button
    type={type}
    className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200"
    {...{
      onClick,
      form,
      type
    }}
  >
    {children}
  </button>
);
export default RedButton;
