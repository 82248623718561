import { gql } from "@apollo/client";

const deleteUserOrganization = gql`
  mutation deleteUserOrganization($userId: ID!, $organizationId: ID!) {
    deleteUserOrganization(userId: $userId, organizationId: $organizationId) {
      success
    }
  }
`;
export default deleteUserOrganization;
