import { gql } from "@apollo/client";

const createCheckIn = gql`
  mutation createCheckIn($title: String!, $teamId: ID!, $type: CheckInType) {
    createCheckIn(title: $title, type: $type, teamId: $teamId) {
      id
      success
      code
    }
  }
`;

export default createCheckIn;
