import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getDefaultValueForDropdown } from "../../../../../utility/config";
import DatePicker from "../../../../layout/Datepicker";
import Loading from "../../../../layout/Loading/Loading";
import Select from "../../../../layout/Select";

export const checkInUsersQuery = gql`
  query checkInStandupResponses($checkInId: ID!) {
    checkIn(checkInId: $checkInId) {
      id
      users {
        name
        id
      }
    }
  }
`;

export const DashBoardFilters = ({ filters, setFilters, checkInId }) => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(checkInUsersQuery, {
    variables: {
      checkInId
    },
    notifyOnNetworkStatusChange: true
  });
  if (loading) return <Loading />;
  if (!data?.checkIn) return null;
  const defaultValue = getDefaultValueForDropdown(t("all"));
  const userFilterFields = [defaultValue, ...data?.checkIn?.users];

  return (
    <div className="flex ml-10">
      <div className="mr-5 w-56">
        <Select
          options={userFilterFields}
          label={t("User")}
          type="text"
          selectedOption={
            filters.userId
              ? userFilterFields.find(
                  ({ id: userId }) => userId === filters.userId
                )
              : userFilterFields[0]
          }
          onChange={(e) => setFilters({ ...filters, userId: e.id })}
        />
      </div>
      <DatePicker
        type={"range"}
        labelFrom={t("From Date")}
        labelTo={t("To Date")}
        startDate={filters.dateFrom}
        endDate={filters.dateTo}
        setStartDate={(val) => setFilters({ ...filters, dateFrom: val })}
        setEndDate={(val) => setFilters({ ...filters, dateTo: val })}
        isHorizontal={true}
      />
    </div>
  );
};
DashBoardFilters.propTypes = {
  filters: PropTypes.shape({
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    userId: PropTypes.string
  }),
  checkInId: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired
};
