/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  questionStateEnum,
  questionTypesEnum,
  setupStatusEnum,
  validationErrors
} from "../../../../../graphql/enum";
import createCheckInQuestions from "../../../../../graphql/mutation/createCheckInQuestions";
import updateCheckIn from "../../../../../graphql/mutation/updateCheckIn";
import Button from "../../../../layout/Button";
import Input from "../../../../layout/Input";

const Questions = ({ setCurrentStep, name, checkInId, questions }) => {
  const { t } = useTranslation();
  const [createCheckInQuestionsMutation] = useMutation(createCheckInQuestions);
  const [updateCheckInMutation] = useMutation(updateCheckIn);
  const isQuestions = questions.length > 0;

  const validate = (values) => {
    const errors = {};

    if (!values.questionFirst) {
      errors.questionFirst = t(`validationErrors.${validationErrors.required}`);
    }
    if (!values.questionSecond) {
      errors.questionSecond = t(
        `validationErrors.${validationErrors.required}`
      );
    }
    if (!values.questionThird) {
      errors.questionThird = t(`validationErrors.${validationErrors.required}`);
    }

    return errors;
  };

  const onSubmit = async (values) => {
    const input = [
      {
        checkInId,
        title: values.questionFirst,
        type: questionTypesEnum.tasksList,
        questionState: questionStateEnum.done
      },
      {
        checkInId,
        title: values.questionSecond,
        type: questionTypesEnum.tasksList,
        questionState: questionStateEnum.planned
      },
      {
        checkInId,
        title: values.questionThird,
        type: questionTypesEnum.textResponse,
        questionState: questionStateEnum.skipInAnalytic
      }
    ];
    if (!isQuestions) {
      await createCheckInQuestionsMutation({
        variables: {
          input
        }
      });
      await updateCheckInMutation({
        variables: {
          checkInId,
          input: {
            setupStatus: setupStatusEnum.taskTrackers
          }
        }
      });
    }
    if (isQuestions) {
      await updateCheckInMutation({
        variables: {
          checkInId,
          input: {
            questions: [
              {
                id: questions[0].id,
                title: values.questionFirst
              },
              {
                id: questions[1].id,
                title: values.questionSecond
              },
              {
                id: questions[2].id,
                title: values.questionThird
              }
            ]
          }
        }
      });
    }
    setCurrentStep(setupStatusEnum.taskTrackers);
  };

  return (
    <>
      <div className="w-6/12 mx-auto">
        <div className="text-lg leading-6 font-medium text-gray-900 text-center">
          {t("questions.renameQuestions")}
        </div>
        <div className="text-gray-500 text-center mt-5">
          {t("questions.describeAboutQuestions")}
        </div>
        <Formik
          validate={validate}
          initialValues={{
            questionFirst: questions[0]
              ? questions[0]?.title
              : "What did you do yesterday?",
            questionSecond: questions[1]
              ? questions[1]?.title
              : "What will you do today?",
            questionThird: questions[2]
              ? questions[2]?.title
              : "What (if anything) is blocking your progress?"
          }}
          onSubmit={onSubmit}
        >
          {({ errors }) => (
            <Form id={name}>
              <div className="mt-12 w-full">
                <div>
                  <Input
                    type="text"
                    name="questionFirst"
                    placeholder="Enter the title of question"
                    error={errors.questionFirst}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="questionSecond"
                    placeholder="Enter the title of question"
                    error={errors.questionSecond}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    name="questionThird"
                    placeholder="Enter the title of question"
                    error={errors.questionThird}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex justify-between">
        <div>
          <Button className="mr-5" layout="white">
            {t("Previous")}
          </Button>
        </div>
        <div>
          <Button layout="primary" form="setupCheckIn" type="submit">
            {t("Next")}
          </Button>
        </div>
      </div>
    </>
  );
};

Questions.propTypes = {
  onFormFinish: PropTypes.func,
  name: PropTypes.string,
  checkInId: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      questionState: PropTypes.string.isRequired
    })
  )
};

export default Questions;
