import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { validationErrors } from "../../../../graphql/enum";
import updateCheckIn from "../../../../graphql/mutation/updateCheckIn";
import Button from "../../../layout/Button";
import GeneralZone from "../../CheckInSingleLayout/GeneralZone/GeneralZone";

const RetrospectiveSingle = ({ checkIn, alert }) => {
  const [updateCheckInMutation] = useMutation(updateCheckIn);

  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = t(`validationErrors.${validationErrors.required}`);
    }

    return errors;
  };

  const onSubmit = async (values) => {
    await updateCheckInMutation({
      variables: {
        checkInId: checkIn.id,
        input: {
          isSetup: true,
          title: values.title
        }
      }
    });
    alert.success(
      <div>
        <p className="text-black">{t("settingsTab.successfullySaved")}</p>
      </div>
    );
  };

  return (
    <>
      <Formik
        validate={validate}
        initialValues={{
          title: checkIn.title
        }}
        onSubmit={onSubmit}
      >
        {({ errors }) => (
          <Form>
            <GeneralZone
              labelTitle={t("checkInTypes.retrospective")}
              errors={errors}
            />
            <div className="flex justify-end mt-8">
              <div>
                <Button layout="primary" type="submit">
                  {t("Save")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
RetrospectiveSingle.propTypes = {
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  alert: PropTypes.object,
  query: PropTypes.object.isRequired
};

export default withAlert()(RetrospectiveSingle);
