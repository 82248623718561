import { CheckIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { classNames } from "../../../utility";

const Option = ({ selectedOptions, person }) => {
  const isSelected = selectedOptions.some(
    (selected) => selected.id === person.id
  );

  if (!person) {
    return null;
  }
  return (
    <>
      <span
        className={classNames(
          isSelected ? "text-indigo-600" : "text-white",
          "absolute inset-y-0 left-0 top-2 items-start pl-2  my-auto"
        )}
      >
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
      </span>
      <span
        className={classNames(
          isSelected ? "font-semibold" : "font-normal",
          "text-left truncate"
        )}
      >
        {person.name}
      </span>
    </>
  );
};
Option.propTypes = {
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  person: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })
};
export default Option;
