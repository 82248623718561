import { CloudUploadIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import Button from "../Button";
import Modal from "../Modal";

const UploadOrDrag = ({
  file,
  title,
  description,
  dropzoneConfig,
  button,
  visible,
  onClose
}) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps, open, isDragActive } =
    useDropzone(dropzoneConfig);
  return (
    <Modal visible={visible} onClose={onClose} size="md">
      <div className="flex justify-center ">
        <CloudUploadIcon className="text-gray-400 w-10" />
      </div>
      <div className="flex justify-center mt-4">
        <span className="text-lg font-medium text-gray-900">
          {t("planningPokerStart.importTasks")}
        </span>
      </div>
      <div className="flex justify-center mt-5">
        <span className="text-sm w-9/12 text-center text-gray-400 mx-2">
          {t("planningPokerImportTaskModal.importTasksDescription")}
        </span>
      </div>
      <div className="mt-6">
        <span className="text-sm w-9/12 text-center text-gray-700 mx-2">
          {file ? file.name : title}
        </span>
      </div>
      <div
        {...getRootProps({ className: "dropzone" })}
        className={`relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
          isDragActive ? "bg-gray-50" : ""
        }`}
      >
        <input {...getInputProps()} />
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          viewBox="0 0 38 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 5H5C3.93913 5 2.92172 5.42143 2.17157 6.17157C1.42143 6.92172 1 7.93913 1 9V29M1 29V33C1 34.0609 1.42143 35.0783 2.17157 35.8284C2.92172 36.5786 3.93913 37 5 37H29C30.0609 37 31.0783 36.5786 31.8284 35.8284C32.5786 35.0783 33 34.0609 33 33V25M1 29L10.172 19.828C10.9221 19.0781 11.9393 18.6569 13 18.6569C14.0607 18.6569 15.0779 19.0781 15.828 19.828L21 25M33 17V25M33 25L29.828 21.828C29.0779 21.0781 28.0607 20.6569 27 20.6569C25.9393 20.6569 24.9221 21.0781 24.172 21.828L21 25M21 25L25 29M29 5H37M33 1V9M21 13H21.02"
            stroke="#9CA3AF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="mt-2">
          <span
            className="pt-2 text-sm font-medium text-indigo-600 cursor-pointer"
            onClick={open}
          >
            {t("uploadOrDrag.uploadFile")}
          </span>
          <span className="text-sm ml-1 font-medium text-gray-600">
            {t("uploadOrDrag.orDragAndDrop")}
          </span>
        </div>
        <div>
          <span className="mt-2 block text-sm font-medium text-gray-400">
            {description}
          </span>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end ">
          <div className="flex">
            <Button layout="white" className="mr-5" onClick={onClose}>
              {t("Cancel")}
            </Button>
            {button}
          </div>
        </div>
      </div>
    </Modal>
  );
};
UploadOrDrag.propTypes = {
  file: PropTypes.object,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dropzoneConfig: PropTypes.object,
  button: PropTypes.elementType,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default withAlert()(UploadOrDrag);
