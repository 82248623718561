/* eslint-disable max-statements */
import PropTypes from "prop-types";
import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { getCheckInUrl } from "../../../routes";
import { UserContext } from "../../../utility/context/User";
import Tabs from "../../layout/Tabs";
import ConnectTaskTrackerButton from "../SetupCheckIn/SetupCheckInStandup/TaskTrackers/ConnectTaskTrackerSection/ConnectTaskTrackerButton/ConnectTaskTrackerButton";
import Dashboard from "./Dashboard";
import NotificationsTab from "./NotificationsTab";
import QuestionsTab from "./QuestionsTab";
import SettingsTab from "./SettingsTab";
import StandupResponsesTab from "./StandupResponsesTab";
import StandupButtonDownloadCSV from "./StandupResponsesTab/StandupDownloadCSV/StandupDownloadCSV";

const StandupSingle = ({ checkInId, checkIn, query, refetch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useContext(UserContext);

  const parameters = new URLSearchParams(window.location.search);
  const defaultKeyId = parameters.get("defaultKey");
  const activeTab = parameters.get("activeTab");

  useEffect(() => {
    if (!activeTab && defaultKeyId) {
      const url = queryString.stringifyUrl({
        url: getCheckInUrl(checkInId),
        query: {
          activeTab: defaultKeyId
        }
      });
      history.push(url);
    }
  });
  const tabs = [
    {
      name: "dashboard",
      title: t("Dashboard"),
      render: () => <Dashboard checkInId={checkInId} />
    },
    {
      name: "responses",
      title: t("Responses"),
      render: () => (
        <StandupResponsesTab checkInId={checkInId} checkIn={checkIn} />
      ),
      rightContent: <StandupButtonDownloadCSV checkInId={checkInId} />
    },
    {
      name: "questions",
      title: t("Questions"),
      render: () => <QuestionsTab checkIn={checkIn} checkInId={checkInId} />
    },
    {
      name: "settings",
      title: t("Settings"),
      render: () => (
        <SettingsTab checkIn={checkIn} query={query} refetch={refetch} />
      )
    },
    {
      name: "notifications",
      title: t("Notifications"),
      render: () => (
        <NotificationsTab checkIn={checkIn} query={query} refetch={refetch} />
      )
    }
  ];
  const userTeam = checkIn.team.users.find(
    (userCheckInTeam) => user.id === userCheckInTeam.id
  );

  if (!checkIn.isUserCheckInSetup && userTeam) {
    return (
      <div className="flex justify-center">
        <div className="mt-12">
          <ConnectTaskTrackerButton
            type={checkIn.integrationType}
            bitrixDomain={checkIn.bitrixDomain}
            checkInId={checkInId}
          />
        </div>
      </div>
    );
  }
  return (
    <Tabs
      tabs={tabs}
      defaultKey={activeTab || "dashboard"}
      currentUrl={getCheckInUrl(checkInId)}
    />
  );
};

StandupSingle.propTypes = {
  checkInId: PropTypes.string.isRequired,
  checkIn: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  refetch: PropTypes.func
};
export default StandupSingle;
