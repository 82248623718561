import { gql } from "@apollo/client";

const CreateCheckInQuestions = gql`
  mutation deleteOrganizationHoliday($input: [deleteOrganizationHolidayInput]) {
    deleteOrganizationHoliday(input: $input) {
      success
    }
  }
`;

export default CreateCheckInQuestions;
