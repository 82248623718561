import { gql } from "@apollo/client";

const updateOrganization = gql`
  mutation updateOrganization(
    $updateOrganizationInput: UpdateOrganizationInput!
    $organizationId: ID!
  ) {
    updateOrganization(
      input: $updateOrganizationInput
      organizationId: $organizationId
    ) {
      code
      success
    }
  }
`;

export default updateOrganization;
