import { gql } from "@apollo/client";

const updateCheckIn = gql`
  mutation updateCheckIn($input: UpdateCheckInInput!, $checkInId: ID!) {
    updateCheckIn(input: $input, checkInId: $checkInId) {
      code
      success
    }
  }
`;

export default updateCheckIn;
