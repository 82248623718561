import { t } from "i18next";
import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";

import Avatars from "../../Teams/Avatars/Avatars";
import Badge from "../Badge";

const UsersTimeZone = ({ users, time, currentUser }) => {
  const usersGroupTimeZone = users.reduce((r, a) => {
    const isNewTimeZone = !!r.find((g) => g.timeZone === a.timeZone);
    if (!isNewTimeZone) return [...r, { timeZone: a.timeZone, users: [a] }];
    const newUserTimeZone = r.map((userTimeZone) => {
      if (userTimeZone.timeZone === a.timeZone)
        return { ...userTimeZone, users: [...userTimeZone.users, a] };
      return userTimeZone;
    });
    return newUserTimeZone;
  }, []);
  const currentTime = momentTimeZone.tz(
    time.id,
    "HH:mm:ss",
    currentUser.timeZone
  );
  return (
    <div>
      {" "}
      <p className="text-sm">{t("timeZone.teamTimezones")}</p>{" "}
      <div className="border-gray-200">
        <dl className="divide-y divide-gray-200">
          {usersGroupTimeZone.map((usersTimeZone, idx) => (
            <div className="py-2 grid grid-cols-12 gap-4" key={idx}>
              <dt className="text-sm  text-gray-500 col-span-3 -mr-5">
                <Badge
                  color="gray"
                  className="px-2 font-medium mt-1
"
                >
                  {currentTime
                    .clone()
                    .tz(usersTimeZone.timeZone)
                    .format("hh:mm A")}
                </Badge>
              </dt>
              <dd className="pt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-9">
                <span className="flex-grow mt-1">{`${usersTimeZone.timeZone} (
              ${momentTimeZone()
                .tz(usersTimeZone.timeZone)
                .format("Z")})`}</span>
                <span className="ml-4 flex-shrink-0">
                  <Avatars
                    users={usersTimeZone.users.slice(0, 3)}
                    isHint={true}
                  />
                </span>
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};
UsersTimeZone.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      timeZone: PropTypes.string,
      avatar: PropTypes.string
    })
  ),
  currentUser: PropTypes.shape({
    timeZone: PropTypes.string
  }),
  time: PropTypes.object
};
export default UsersTimeZone;
