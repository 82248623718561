/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { statusUserEnum } from "../../../graphql/enum";
import Input from "../../layout/Input/Input";
import Loading from "../../layout/Loading/Loading";
import Point from "../../layout/Point";
import Select from "../../layout/Select";

export const getAllTeamsOrganizationQuery = gql`
  query {
    currentUser {
      organizations {
        organization {
          teams {
            id
            title
          }
        }
      }
    }
  }
`;

const Sidebar = ({ filters, defaultValue, setFilter }) => {
  const { loading, data } = useQuery(getAllTeamsOrganizationQuery);
  const { t } = useTranslation();
  const [search, setSearch] = useState(filters.search);

  const botInstalledTypeFilter = [
    defaultValue,
    { id: "installed", name: t("teamUserTable.installed"), value: "true" },
    {
      id: "notInstalled",
      name: t("teamUserTable.notInstalled"),
      value: "false"
    }
  ];

  const statusTypesFilter = [
    defaultValue,
    {
      id: statusUserEnum.active,
      name: (
        <>
          <Point layout="green" />
          {t("statusLabel.active")}
        </>
      ),
      value: statusUserEnum.active
    },
    {
      id: statusUserEnum.pendingInvitation,
      name: (
        <>
          <Point layout="gray" />
          {t("statusLabel.pendingInvitation")}
        </>
      ),
      value: statusUserEnum.pendingInvitation
    },
    {
      id: statusUserEnum.emailNotVerified,
      name: (
        <>
          <Point layout="gray" />
          {t("statusLabel.emailNotVerified")}
        </>
      ),
      value: statusUserEnum.emailNotVerified
    },
    {
      id: statusUserEnum.deactivated,
      name: (
        <>
          <Point layout="red" />
          {t("statusLabel.deactivated")}
        </>
      ),
      value: statusUserEnum.deactivated
    }
  ];

  if (loading) {
    return <Loading />;
  }
  const teams = data.currentUser.organizations.organization.teams.map(
    (team) => ({ id: team.id, name: team.title, value: team.id })
  );
  teams.unshift(defaultValue);

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        setFilter({ search });
      }}
    >
      <Form>
        <Input
          placeholder={t("teamUserTable.searchByNameOrEmail")}
          label={t("Search")}
          type="text"
          value={search}
          name="search"
          onBlur={async (e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              await setFilter({ search });
            }
          }}
          onChange={async (e) => {
            setSearch(e.target.value);
          }}
        />
        <Select
          options={teams}
          label={t("teamUserTable.team")}
          type="select"
          selectedOption={
            filters.teamId
              ? teams.find(({ id: teamId }) => teamId === filters.teamId)
              : teams[0]
          }
          onChange={(e) => setFilter({ ...filters, teamId: e.value })}
        />
        <div className="mt-5">
          <Select
            options={statusTypesFilter}
            label={t("teamUserTable.status")}
            type="select"
            selectedOption={
              filters.status
                ? statusTypesFilter.find(
                    ({ id: status }) => status === filters.status
                  )
                : statusTypesFilter[0]
            }
            onChange={(e) => setFilter({ ...filters, status: e.value })}
          />
        </div>
        <div className="mt-5">
          <Select
            options={botInstalledTypeFilter}
            label={t("teamUserTable.bot")}
            type="select"
            selectedOption={
              filters.botInstalled
                ? botInstalledTypeFilter.find(
                    ({ value: botInstalled }) =>
                      botInstalled === filters.botInstalled
                  )
                : botInstalledTypeFilter[0]
            }
            onChange={(e) => setFilter({ ...filters, botInstalled: e.value })}
          />
        </div>
      </Form>
    </Formik>
  );
};
Sidebar.propTypes = {
  filters: PropTypes.shape({
    search: PropTypes.string,
    teamId: PropTypes.string,
    status: PropTypes.string,
    botInstalled: PropTypes.string
  }),
  defaultValue: PropTypes.object,
  setFilter: PropTypes.func
};

export default Sidebar;
