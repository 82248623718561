import { gql } from "@apollo/client";

const updateCheckInRun = gql`
  mutation updateCheckInRun($checkInRunId: ID!, $setupStatus: CheckInRunType!) {
    updateCheckInRun(checkInRunId: $checkInRunId, setupStatus: $setupStatus) {
      success
      code
    }
  }
`;

export default updateCheckInRun;
