import { gql } from "@apollo/client";

const resetPassword = gql`
  mutation resetPassword($input: resetPasswordInput!) {
    resetPassword(input: $input) {
      success
      code
      jwtToken
    }
  }
`;

export default resetPassword;
