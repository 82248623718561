import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import PlusButton from "../../layout/Button/PlusButton";
import DatePickerSingleComponent from "../../layout/Datepicker/DatePickerSingleComponent";
import Input from "../../layout/Input";
import ListHoliday from "./ListHoliday/ListHoliday";

const HolidaysSection = ({ holidays, setHolidays }) => {
  const [holidaySelect, setHolidaySelect] = useState({ date: new Date() });

  const { t } = useTranslation();
  const addHoliday = () => {
    if (holidaySelect.title) {
      setHolidays([...holidays, { id: Date.now(), ...holidaySelect }]);
      setHolidaySelect({ date: new Date(), title: "" });
    }
  };
  const removeHoliday = (id) => {
    const newHolidays = holidays.filter((holiday) => holiday.id !== id);
    setHolidays(newHolidays);
  };
  return (
    <div className="shadow rounded-lg	p-6 mt-10">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium"> {t("holidaySection.holidays")}</p>
          <p className="text-gray-500 text-sm">
            {t("holidaySection.description")}
          </p>
        </div>
        <div className="w-1/3">
          <div className="pr-5">
            <ListHoliday {...{ holidays, removeHoliday }} />
          </div>
          <div className="flex mt-5">
            <div>
              <Input
                placeholder={t("holidaySection.enterHolidayName")}
                type="text"
                value={holidaySelect.title}
                onChange={(e) =>
                  setHolidaySelect({ ...holidaySelect, title: e.target.value })
                }
                addonbefore={
                  <div className="relative -mx-3 -my-1">
                    <DatePickerSingleComponent
                      date={holidaySelect.date}
                      inputSetting={{
                        format: "D MMM",
                        borderStyle: "focus:border-none"
                      }}
                      onChange={(val) =>
                        setHolidaySelect({ ...holidaySelect, date: val })
                      }
                    />
                  </div>
                }
              />
            </div>
            <PlusButton onClick={addHoliday} className="ml-2 mt-1" />
          </div>
        </div>
      </div>
    </div>
  );
};
HolidaysSection.propTypes = {
  holidays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string
    })
  ),
  setHolidays: PropTypes.func
};
export default HolidaysSection;
