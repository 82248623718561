import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { setupStatusEnum } from "../../../../graphql/enum";
import Steps from "../../../layout/Steps";
import Questions from "./Questions";
import Review from "./Review/Review";
import { TaskLists } from "./TaskLists/TaskLists";
import TaskTrackers from "./TaskTrackers/TaskTrackers";

const SetupCheckInStandup = ({ checkIn, checkInId, refetch }) => {
  const [currentStep, setCurrentStep] = useState(checkIn.setupStatus);

  const { t } = useTranslation();

  const steps = [
    {
      id: setupStatusEnum.questions,
      name: t("setupCheckIn.questions"),
      render: (
        <Questions
          name="setupCheckIn"
          setCurrentStep={setCurrentStep}
          checkInId={checkInId}
          checkIn={checkIn}
          questions={checkIn.questions.data}
          refetch={refetch}
        />
      )
    },
    {
      id: setupStatusEnum.taskTrackers,
      name: t("setupCheckIn.taskTrackers"),
      render: (
        <TaskTrackers
          checkInId={checkInId}
          checkIn={checkIn}
          refetch={refetch}
          setCurrentStep={setCurrentStep}
        />
      )
    },
    {
      id: setupStatusEnum.taskLists,
      name: t("setupCheckIn.taskLists"),
      render: (
        <TaskLists
          checkInId={checkInId}
          setCurrentStep={setCurrentStep}
          refetch={refetch}
        />
      )
    },
    {
      id: setupStatusEnum.review,
      name: t("setupCheckIn.review"),
      render: (
        <Review
          checkIn={checkIn}
          checkInId={checkInId}
          refetch={refetch}
          setCurrentStep={setCurrentStep}
        />
      )
    }
  ];

  return (
    <>
      <Steps steps={steps} currentStepId={currentStep} />
    </>
  );
};

SetupCheckInStandup.propTypes = {
  checkIn: PropTypes.shape({
    setupStatus: PropTypes.oneOf(Object.values(setupStatusEnum)).isRequired,
    questions: PropTypes.shape({
      data: PropTypes.array
    })
  }).isRequired,
  checkInId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired
};
export default SetupCheckInStandup;
