import "react-datepicker/dist/react-datepicker.css";
import "./DatepickerStyles/Datepicker.css";

import PropTypes from "prop-types";
import { useEffect } from "react";
import DatePicker from "react-datepicker";

import { classNames } from "../../../utility";
import DatepickerCustomHeader from "./DatepickerCustomHeader/DatepickerCustomHeader";
import DatepickerCustomInput from "./DatepickerCustomInput/DatepickerCustomInput";

const DatePickerRangeComponent = ({
  labelFrom,
  labelTo,
  isHorizontal = false,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  maxDate
}) => {
  useEffect(() => {
    if (startDate > endDate) setStartDate(endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  useEffect(() => {
    if (startDate > endDate) setEndDate(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <div className={classNames("relative", isHorizontal ? "flex" : "")}>
      <div className={isHorizontal ? "flex flex-col " : null}>
        <label className="text-left block text-sm font-medium text-gray-700 mb-auto">
          {labelFrom}
        </label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          nextMonthButtonLabel=">"
          previousMonthButtonLabel="<"
          popperClassName="react-datepicker-left"
          customInput={<DatepickerCustomInput />}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <DatepickerCustomHeader
              date={date}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
        />
      </div>
      <div className={isHorizontal ? "ml-5 flex flex-col " : "mt-5"}>
        <label className="text-left block text-sm font-medium text-gray-700 mb-auto">
          {labelTo}
        </label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          maxDate={maxDate}
          nextMonthButtonLabel=">"
          previousMonthButtonLabel="<"
          popperClassName="react-datepicker-right"
          customInput={<DatepickerCustomInput />}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <DatepickerCustomHeader
              date={date}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
            />
          )}
        />
      </div>
    </div>
  );
};

DatePickerRangeComponent.propTypes = {
  labelFrom: PropTypes.string,
  labelTo: PropTypes.string,
  isHorizontal: PropTypes.bool,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  maxDate: PropTypes.object
};

export default DatePickerRangeComponent;
