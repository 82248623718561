import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import updateCheckIn from "../../../graphql/mutation/updateCheckIn";
import Button from "../../layout/Button";
import DeleteCheckInModal from "./DeleteCheckInModal/DeleteCheckInModal";

const ManageCheckInZone = ({ checkIn, query, alert }) => {
  const { t } = useTranslation();
  const [updateCheckInMutation] = useMutation(updateCheckIn);
  const [isDeleteCheckInModalVisible, setIsDeleteCheckInModalVisible] =
    useState(false);

  const onActivateOrDeactivate = async () => {
    const isActive = !checkIn.isActive;

    const { data } = await updateCheckInMutation({
      variables: {
        checkInId: checkIn.id,
        input: { isActive }
      },
      optimisticResponse: true,
      update: async (cache) => {
        const dataQuery = await cache.readQuery({
          query,
          variables: {
            checkInId: parseInt(checkIn.id, 10)
          }
        });
        const newData = {
          checkIn: {
            ...dataQuery.checkIn,
            isActive
          }
        };
        cache.writeQuery({
          data: newData,
          query,
          variables: {
            checkInId: parseInt(checkIn.id, 10)
          }
        });
      }
    });
    if (data.updateCheckIn.success) {
      alert.success(
        <div>
          <p className="text-black">{t("manageCheckInZone.successSaved")}</p>
          <p className="text-gray-500">
            {isActive
              ? t("manageCheckInZone.successActivated")
              : t("manageCheckInZone.successDeactivated")}
          </p>
        </div>
      );
    }
  };
  return (
    <>
      <div className="shadow rounded-lg	p-6 mt-8">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("manageCheckInZone.manageCheckIn")}
        </h3>
        <div className="mt-5 grid grid-cols-3 gap-4">
          <div className="mt-2 col-span-2 text-sm text-gray-500">
            <p>{t("manageCheckInZone.deactivateCheckInDescription")}</p>
          </div>
          <div className="mt-2 grid justify-items-end">
            <Button
              layout="white"
              onClick={async () => onActivateOrDeactivate()}
            >
              {checkIn.isActive
                ? t("manageCheckInZone.deactivate")
                : t("manageCheckInZone.activate")}
            </Button>
          </div>
        </div>
        <div className="mt-5 grid grid-cols-3 gap-4">
          <div className="mt-1 col-span-2 text-sm text-gray-500">
            <p>
              {t("manageCheckInZone.deleteCheckInDescription")}
              <span className="font-bold">
                {t("manageCheckInZone.deleteCheckInDescriptionStrong")}
              </span>
            </p>
          </div>
          <div className="mt-1 grid justify-items-end">
            <Button
              layout="red"
              onClick={() => setIsDeleteCheckInModalVisible(true)}
            >
              {t("manageCheckInZone.deleteCheckIn")}
            </Button>
          </div>
        </div>
        <DeleteCheckInModal
          checkIn={checkIn}
          visible={isDeleteCheckInModalVisible}
          onClose={() => setIsDeleteCheckInModalVisible(false)}
        />
      </div>
    </>
  );
};

ManageCheckInZone.propTypes = {
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired
  }),
  query: PropTypes.object.isRequired,
  alert: PropTypes.object
};

export default withAlert()(ManageCheckInZone);
