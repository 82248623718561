import React from "react";

import Profile from "../components/Profile";
import Layout from "./Layout/NewLayout/NewLayout";

const AccountView = () => (
  <Layout>
    <Profile />
  </Layout>
);

export default AccountView;
