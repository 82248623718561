/* eslint-disable max-statements */
import { DocumentDownloadIcon } from "@heroicons/react/solid";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../../../graphql/utils/date";
import { cleanObject } from "../../../../../graphql/utils/utils";
import { getExportCheckInCsvQuery } from "../../../../../routes";
import { getDefaultValueForDropdown } from "../../../../../utility/config";
import Button from "../../../../layout/Button";

const StandupButtonDownloadCSV = ({ checkInId }) => {
  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);

  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const userId = parameters.get("userId");
  const status = parameters.get("status");
  const checkInQuestionId = parameters.get("checkInQuestionId");
  const search = parameters.get("search");
  const { t } = useTranslation();
  const defaultValue = getDefaultValueForDropdown(t("all"));
  const dateFromFilter = dateFrom ? new Date(dateFrom) : defaultDateFrom;
  const dateToFilter = dateTo ? new Date(dateTo) : defaultDateTo;

  const onGeneratedCsv = () => {
    const query = queryString.stringifyUrl({
      url: getExportCheckInCsvQuery(checkInId),
      query: cleanObject({
        search: search || "",
        userId: userId === defaultValue.id ? null : userId,
        checkInQuestionId:
          checkInQuestionId === defaultValue.id ? null : checkInQuestionId,
        status: status === defaultValue.id ? null : status,
        dateFrom: moment(dateFromFilter).format(dateFormat),
        dateTo: moment(dateToFilter).format(dateFormat)
      })
    });
    window.open(query);
  };

  return (
    <Button
      layout="primaryOutline"
      className="flex items-center"
      onClick={onGeneratedCsv}
    >
      <>
        <DocumentDownloadIcon className="w-5 h-5 mr-2" />
        {t("standupResponsesTab.downloadCsv")}
      </>
    </Button>
  );
};
StandupButtonDownloadCSV.propTypes = {
  checkInId: PropTypes.string.isRequired
};

export default StandupButtonDownloadCSV;
