/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useContext } from "react";
import { Redirect } from "react-router-dom";

import { checkInRunSetupStatusEnum } from "../../../../../graphql/enum";
import { getCheckInUrl } from "../../../../../routes";
import { UserContext } from "../../../../../utility/context/User";
import Loading from "../../../../layout/Loading/Loading";
import CheckInRetrospectiveSetupNextSetupButton from "../CheckInRetrospectiveSetupNextSetupButton";
import Questions from "./SetupCheckInRetrospectiveQuestions/SetupCheckInRetrospectiveQuestions";

export const checkInRunByIdQuery = gql`
  query checkInRunById($checkInRunId: ID!, $userId: ID) {
    checkInRun(checkInRunId: $checkInRunId) {
      id
      checkIn {
        id
        teamRole
        questions {
          data {
            id
            title
            questionState
            retrospectiveItemsRooms(checkInRunId: $checkInRunId) {
              id
              title
              description
              checkInQuestionId
              retrospectiveItems(userId: $userId) {
                id
                title
                description
                user {
                  id
                  name
                  avatar
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CreateItems = ({ checkInRunId, checkInId, query }) => {
  const { user } = useContext(UserContext);

  const { loading, data, refetch } = useQuery(checkInRunByIdQuery, {
    variables: {
      checkInRunId,
      userId: user?.id
    }
  });

  if (loading) return <Loading />;
  if (!data?.checkInRun) return <Redirect to={getCheckInUrl(checkInId)} />;

  return (
    <Formik initialValues={{}}>
      {() => (
        <Form>
          <div className="-mt-8">
            <div className="grid grid-cols-2 gap-5 text-center">
              <Questions
                refetch={refetch}
                checkInRunId={checkInRunId}
                questions={data.checkInRun.checkIn?.questions?.data}
                user={user}
              />
            </div>
          </div>
          <div className="flex justify-center mt-14">
            <CheckInRetrospectiveSetupNextSetupButton
              checkInRunId={checkInRunId}
              query={query}
              userTeamRole={data.checkInRun.checkIn?.teamRole}
              checkInRunSetupStatus={checkInRunSetupStatusEnum.mergeSimilar}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

CreateItems.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  query: PropTypes.object,
  checkInId: PropTypes.string
};
export default CreateItems;
