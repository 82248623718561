/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import Button from "../../../../../../layout/Button";
import Loading from "../../../../../../layout/Loading/Loading";
import Table from "../../../../../../layout/Table/Table";

export const previewDataSourceTasks = gql`
  query previewDataSourceTasks(
    $id: ID!
    $dataSourceFilter: DataSourceFilterInput
  ) {
    previewDataSource(id: $id, dataSourceFilter: $dataSourceFilter) {
      id
      integrationId
      isValid
      tasks {
        id
        key
        title
        estimate
        status {
          id
          name
        }
        priority
        url
      }
    }
  }
`;
const PreviewTasksTableComponent = ({
  dataSourceId,
  previewFilter,
  setIsConnectTracker,
  setIsValideJQL,
  alert,
  onSave,
  isDisplayButtonSave,
  setFirstQuestionUrl
}) => {
  const { t } = useTranslation();
  const { loading, data, error } = useQuery(previewDataSourceTasks, {
    variables: {
      id: dataSourceId,
      dataSourceFilter: previewFilter
    },
    fetchPolicy: "no-cache"
  });

  if (loading) return <Loading />;

  if (error) {
    setIsConnectTracker(false);
    return null;
  }

  setIsValideJQL(data?.previewDataSource?.isValid);

  const columns = [
    {
      title: "title",
      isVisible: true
    },
    {
      title: "status",
      isVisible: true
    }
  ];

  const tasks = data?.previewDataSource?.tasks;
  setFirstQuestionUrl(
    tasks.length ? data?.previewDataSource?.tasks[0].url : null
  );
  const tableData = tasks
    ? tasks.map((task) => [
        {
          id: `name-${task.id}`,
          render: () => (
            <>
              <div className="text-black truncate">{task.title}</div>
            </>
          ),
          className: "max-w-3xl"
        },
        {
          id: `status-${task.id}`,
          render: () => (
            <>
              <div className="text-gray-500">{task.status.name}</div>
            </>
          )
        }
      ])
    : [];
  return (
    <div>
      <Table columns={columns} data={tableData} />
      {isDisplayButtonSave ? (
        <div className="flex mt-10 justify-end">
          <div className="flex">
            <Button
              layout="primary"
              className="text-xs"
              onClick={async () => {
                await onSave();
                alert.success(
                  <div>
                    <p className="text-black">
                      {t("githubSerachForm.successfullySaved")}
                    </p>
                    <p className="text-gray-500">
                      {t("githubSerachForm.allChangesWereSaved")}
                    </p>
                  </div>
                );
              }}
            >
              <div className="text-xs">{t("Save")}</div>
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

PreviewTasksTableComponent.defaultProps = {
  setIsValideJQL: () => {},
  setFirstQuestionUrl: () => {}
};

PreviewTasksTableComponent.propTypes = {
  dataSourceId: PropTypes.any.isRequired,
  previewFilter: PropTypes.object.isRequired,
  setIsConnectTracker: PropTypes.func,
  setIsValideJQL: PropTypes.func,
  alert: PropTypes.shape({
    success: PropTypes.func
  }),
  onSave: PropTypes.func,
  isDisplayButtonSave: PropTypes.bool,
  setFirstQuestionUrl: PropTypes.func
};
export const PreviewTasksTable = withAlert()(PreviewTasksTableComponent);
