import React from "react";

import SignIn from "../../components/Authorization/SignIn/SignIn";
import AuthorizationLayout from "../Layout/AuthorizationLayout/AuthorizationLayout";

const LoginView = () => (
  <AuthorizationLayout>
    <SignIn />
  </AuthorizationLayout>
);
export default LoginView;
