/* eslint-disable i18next/no-literal-string */
/* eslint-disable react/prop-types */
const DangerButton = ({ children, onClick }) => (
  <button
    type="button"
    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
    {...{
      onClick
    }}
  >
    {children}
  </button>
);
export default DangerButton;
