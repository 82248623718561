import PropTypes from "prop-types";
import { useState } from "react";

import { questionStateEnum } from "../../../../../../graphql/enum";
import Button from "../../../../../layout/Button";
import SetupCheckInRetrospectiveQuestionItem from "../SetupCheckInRetrospectiveQuestionItem/SetupCheckInRetrospectiveQuestionItems";

const SetupCheckInRetrospectiveQuestions = ({
  checkInRunId,
  user,
  questions,
  refetch
}) => {
  const [newItem, setNewItem] = useState(null);

  return questions.map(
    ({
      id: checkInQuestionId,
      title,
      retrospectiveItemsRooms,
      questionState
    }) =>
      questionState !== questionStateEnum.actionItems ? (
        <div key={checkInQuestionId}>
          <span className="text-base font-bold text-black ">{title}</span>
          <div className="grid grid-cols-1 gap-4 mt-6">
            {
              <SetupCheckInRetrospectiveQuestionItem
                refetch={refetch}
                checkInRunId={checkInRunId}
                setNewItem={setNewItem}
                rooms={[...retrospectiveItemsRooms, newItem]}
                checkInQuestionId={checkInQuestionId}
              />
            }
          </div>
          <div className="flex justify-center mt-4">
            <Button
              layout="plus"
              onClick={() => {
                setNewItem({
                  checkInQuestionId,
                  title: "",
                  users: [user]
                });
              }}
            />
          </div>
        </div>
      ) : null
  );
};

SetupCheckInRetrospectiveQuestions.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  user: PropTypes.object,
  newItem: PropTypes.object,
  questions: PropTypes.array,
  setNewItem: PropTypes.func,
  refetch: PropTypes.func
};
export default SetupCheckInRetrospectiveQuestions;
