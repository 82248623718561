/* eslint-disable i18next/no-literal-string */

import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { classNames } from "../../../utility";

const getModalSize = (size) => {
  if (size === "xs") return "sm:max-w-xs";
  if (size === "md") return "sm:max-w-md";
  if (size === "lg") return "sm:max-w-lg";
  if (size === "xl") return "sm:max-w-xl";
  if (size === "2xl") return "sm:max-w-2xl";
  if (size === "3xl") return "sm:max-w-3xl";
  if (size === "4xl") return "sm:max-w-4xl";

  return "sm:max-w-4xl";
};

const Modal = ({ visible, onClose, children, size }) => (
  <Transition.Root show={visible} as={Fragment}>
    <Dialog
      as="div"
      auto-reopen="true"
      className="fixed z-10 inset-0 overflow-y-auto"
      onClose={onClose}
    >
      <div className="flex items-end justify-center min-h-screen pt-20 px-4 pb-40 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            className={classNames(
              "min-w-max min-h-full inline-block align-bottom bg-white rounded-lg p-6 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full sm:p-6",
              getModalSize(size)
            )}
          >
            {children}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);
Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any,
  size: PropTypes.oneOf(["xs", "md", "4xl"])
};
Modal.defaultProps = {
  size: "4xl"
};
export default Modal;
