/* eslint-disable react/prop-types */
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { validationErrors } from "../../../graphql/enum";
import { validateEmail } from "../../../utility";
import Button from "../../layout/Button";
import Input from "../../layout/Input";
import Select from "../../layout/Select/Select";

const InviteTeamForm = ({
  onInvite,
  selectedUsers,
  users,
  role,
  setRole,
  roles
}) => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!validateEmail(values.email))
      errors.email = t("inviteTeamForm.emailIsNotCorrect");

    if (!values.email) {
      errors.email = t(`validationErrors.${validationErrors.required}`);
    }
    selectedUsers.map(({ user }) => {
      if (user.email === values.email)
        errors.email = t("inviteTeamForm.userAlreadyInInviteList");

      return true;
    });
    users.map((user) => {
      if (user.email === values.email)
        errors.email = t("inviteTeamForm.userAlreadyInTeam");

      return true;
    });

    return errors;
  };
  return (
    <>
      <Formik
        validate={validate}
        initialValues={{
          email: ""
        }}
        onSubmit={onInvite}
      >
        {({ errors }) => (
          <Form>
            <div className="flex justify-start mt-2">
              <div className="w-10/12">
                <div className="relative">
                  <Input
                    type="text"
                    name="email"
                    placeholder="Enter an email"
                    error={errors.email}
                    addonafter={
                      <div className="absolute top-0 right-0 flex items-center">
                        <Select
                          seletedOption={role}
                          onChange={setRole}
                          roles={roles}
                        />
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="w-1/12 flex justify-center ml-4 mt-1">
                <Button type="submit" className="w-2/12" layout="plus" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InviteTeamForm;
