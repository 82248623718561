import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "../../../layout/Input/Input";

const GeneralZone = ({ errors, labelTitle }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg	p-6">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">{t("generalZone.general")}</p>
          <p className="text-gray-500 text-sm">
            {t("generalZone.checkInNameUsedEveryWhere")}
          </p>
        </div>
        <div className="w-1/3">
          <Input
            label={t("generalZone.checkInName")}
            placeholder={t("generalZone.typeTheCheckInTitle")}
            type="text"
            name="title"
            error={errors.title}
          />
          <p className="text-gray-700 text-sm font-medium">
            {t("generalZone.checkInType")}
            <span className="font-bold">{labelTitle}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
GeneralZone.propTypes = {
  errors: PropTypes.object,
  labelTitle: PropTypes.string
};
export default GeneralZone;
