import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../../../graphql/utils/date";
import Table from "../../../../layout/Table/Table";
import Avatars from "../../../../Teams/Avatars/Avatars";

const PlanningPokerEstimatesTable = ({ questions, page, setPage }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("planningPokerEstimatesTable.story"),
      isVisible: true,
      className: "uppercase text-center w-8/12",
      colspan: 1
    },
    {
      title: t("planningPokerEstimatesTable.estimation"),
      isVisible: true,
      className: "uppercase text-center w-4/12",
      colspan: 2
    }
  ];

  const dataRender = questions.data.map((question) => [
    {
      id: `story-${question.id}`,
      render: () => (
        <div className="-mt-3 -mb-4 pb-5">
          <div className="flex flex-col items-start">
            <p className="text-gray-900 font-medium mt-4">{question.title}</p>
            <p className="text-gray-500">
              {moment(question.createdAt, dateFormat).format("ddd MMM D YYYY")}
            </p>
          </div>
        </div>
      )
    },
    {
      id: `estimation-${question.id}`,
      render: () => (
        <div className="-mt-3 -mb-4">
          <Avatars
            users={question.points.slice(0, 4).map((point) => point.user)}
          />
        </div>
      )
    },
    {
      id: `point-${question.id}`,
      render: () => (
        <div className="-mt-3 -mb-4">
          {question.finalPoint?.point ? (
            <div>
              <span className="text-blue-500">{question.finalPoint.point}</span>
              <span className="text-gray-500 ml-3">
                {t("planningPokerTab.point")}
              </span>
            </div>
          ) : (
            <span className="text-gray-500">
              {t("planningPokerTab.noPoint")}
            </span>
          )}
        </div>
      ),
      className: "text-lerft"
    }
  ]);

  return (
    <div className="sm:-mx-6 lg:-mx-8 table-tr-border-none">
      <Table
        data={dataRender}
        columns={columns}
        page={page}
        setPage={setPage}
        typeResultText={t("pagination.questions")}
        countElements={questions.count}
      />
    </div>
  );
};
PlanningPokerEstimatesTable.propTypes = {
  questions: PropTypes.shape({
    count: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired,
        title: PropTypes.string.isRequired
      })
    )
  }),
  page: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number
  }),
  setPage: PropTypes.func,
  setCurrentPageTable: PropTypes.func
};

export default PlanningPokerEstimatesTable;
