/* eslint-disable max-statements */
import PropTypes from "prop-types";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { checkInDayOffStatusEnum } from "../../../../graphql/enum";
import { cleanObject } from "../../../../graphql/utils/utils";
import { getCheckInUrl } from "../../../../routes";
import { convertStringToInteger } from "../../../../utility";
import { getDefaultValueForDropdown } from "../../../../utility/config";
import Page from "../../../layout/Page";
import DayOffTabFilter from "./DashboardFilter/DashboardFilter";
import DayOffTabTable from "./DashboardTable/DayOffTabTable";

const DashboardTab = ({ checkIn }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const defaultValue = getDefaultValueForDropdown(t("all"));
  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateTo.setMonth(defaultDateTo.getMonth() + 1);

  const usersFilterStub = [defaultValue, ...checkIn.users];
  const statusFilterStub = [
    defaultValue,
    {
      id: checkInDayOffStatusEnum.approve,
      name: t("dayOffTable.APPROVE")
    },
    {
      id: checkInDayOffStatusEnum.decline,
      name: t("dayOffTable.DECLINE")
    },
    {
      id: checkInDayOffStatusEnum.pending,
      name: t("dayOffTable.PENDING")
    }
  ];
  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const status = parameters.get("status");
  const userId = parameters.get("userId");
  const offset = parameters.get("offset");
  const limit = parameters.get("limit");

  const [filter, setFilter] = useState({
    userId,
    status,
    dateTo: dateTo ? new Date(dateTo) : defaultDateTo,
    dateFrom: dateFrom ? new Date(dateFrom) : defaultDateFrom
  });

  const [page, setPage] = useState({
    offset: !offset ? 0 : convertStringToInteger(offset),
    limit: !limit ? 10 : convertStringToInteger(limit)
  });
  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: getCheckInUrl(checkIn.id),
      query: cleanObject({
        userId: filter.userId,
        status: filter.status,
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
        offset: page.offset,
        limit: page.limit
      })
    });
    history.push(url);
  }, [filter, page, history, checkIn.id]);
  return (
    <Page
      sidebar={
        <div className="w-48">
          <DayOffTabFilter
            usersFilterStub={usersFilterStub}
            filter={filter}
            setFilter={setFilter}
            statusFilterStub={statusFilterStub}
          />
        </div>
      }
      modals={[]}
    >
      <DayOffTabTable
        checkInId={checkIn.id}
        filter={filter}
        page={page}
        setPage={setPage}
      />
    </Page>
  );
};
DashboardTab.propTypes = {
  checkIn: PropTypes.object.isRequired
};
export default DashboardTab;
