import { Field } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { classNames } from "../../../utility";

const Input = (props) => {
  const { t } = useTranslation();
  const {
    error,
    errorclassname,
    addonafter,
    addonbefore,
    label,
    id,
    fieldclassname
  } = props;
  return (
    <>
      <label
        htmlFor={id}
        className="text-left	block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="flex rounded-md shadow-sm">
        {addonbefore ? (
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {addonbefore}
          </span>
        ) : null}

        <Field
          className={classNames(
            "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm ",
            error ? "border-red-300" : "border-gray-300",
            addonbefore ? " rounded-none rounded-r-md" : "rounded-md",
            fieldclassname
          )}
          {...{ ...props }}
        />
      </div>
      {error ? (
        <p className={`text-gray-400 text-left ${errorclassname.error}`}>
          {error}
        </p>
      ) : (
        <p className={`opacity-0 ${errorclassname.notError}`}>{t("empty")}</p>
      )}
      {addonafter}
    </>
  );
};
Input.propTypes = {
  error: PropTypes.string,
  addonafter: PropTypes.object,
  addonbefore: PropTypes.any,
  errorclassname: PropTypes.shape({
    error: PropTypes.string,
    notError: PropTypes.string
  }),
  label: PropTypes.any,
  id: PropTypes.string,
  fieldclassname: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
Input.defaultProps = {
  errorclassname: {}
};
export default Input;
