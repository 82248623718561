import { useMutation } from "@apollo/client";
import { ExclamationIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import deleteCheckIn from "../../../../graphql/mutation/deleteCheckIn";
import { getTeamUrl } from "../../../../routes";
import Button from "../../../layout/Button";
import Modal from "../../../layout/Modal";

const DeleteCheckInModal = ({ visible, onClose, checkIn }) => {
  const { t } = useTranslation();
  const [deleteCheckInMutation] = useMutation(deleteCheckIn);

  const history = useHistory();

  const onDelete = async () => {
    await deleteCheckInMutation({
      variables: {
        checkInId: checkIn.id
      }
    });
    history.push(getTeamUrl(checkIn.teamId));
  };

  return (
    <Modal visible={visible} onClose={onClose} size="md">
      <div className="p-8">
        <div className="flex">
          <div>
            <div className="rounded-full p-2 bg-red-100 mr-4">
              <ExclamationIcon
                className="text-red-600 h-6 w-6"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="max-w-md">
            <h3 className="text-lg font-medium">
              {t("deleteCheckInModal.deleteCheckIn")}
            </h3>
            <p className="text-gray-500">
              {t("deleteCheckInModal.areYouSure")}
              <span className="font-semibold">{checkIn.title}</span>
              {t("deleteCheckInModal.allDataWillDelete")}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end ">
          <div className="flex">
            <Button layout="white" className="mr-5" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              form="createTeamModal"
              layout="danger"
              onClick={onDelete}
            >
              {t("Delete")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
DeleteCheckInModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};
export default DeleteCheckInModal;
