import { gql } from "@apollo/client";

const deleteCheckIn = gql`
  mutation deleteCheckIn($checkInId: ID!) {
    deleteCheckIn(checkInId: $checkInId) {
      success
      code
    }
  }
`;
export default deleteCheckIn;
