/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { botInstallUrl } from "../../../../config";
import { validationErrors } from "../../../../graphql/enum";
import updateCheckIn from "../../../../graphql/mutation/updateCheckIn";
import Button from "../../../layout/Button";
import Link from "../../../layout/Link";
import Loading from "../../../layout/Loading/Loading";
import Page from "../../../layout/Page";
import Select from "../../../layout/Select";
import SetupCheckInSuccess from "../SetupCheckInSuccess/SetupCheckInSuccess";

export const getOrganizationQuery = gql`
  query getAllOrganizations {
    currentUser {
      id
      organizations {
        organization {
          id
          rooms {
            id
            title
            spaceName
          }
        }
      }
    }
  }
`;
const SetupCheckInStandup = ({ checkInId, refetch }) => {
  const { t } = useTranslation();
  const { loading, data: roomsData } = useQuery(getOrganizationQuery);
  const [spaceSelected, setSpaceSelected] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  const [updateCheckInMutation] = useMutation(updateCheckIn);
  if (isSuccess) {
    return (
      <SetupCheckInSuccess
        onViewCheckIn={async () => {
          await refetch();
        }}
        title={t("planningPokerSetup.successfullyCreated")}
        description={t("planningPokerSetup.successfullyCreatedDescription")}
      />
    );
  }
  const validate = () => {
    const errors = {};

    if (!spaceSelected?.name)
      errors.space = t(`validationErrors.${validationErrors.required}`);

    return errors;
  };
  const onFinish = async (parent, { setErrors }) => {
    const errors = validate();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const { data } = await updateCheckInMutation({
      variables: {
        input: {
          isSetup: true,
          summarySpace: spaceSelected.spaceName
        },
        checkInId
      }
    });
    if (data.updateCheckIn.success) {
      setIsSuccess(true);
    }
  };
  if (loading) return <Loading />;
  const rooms =
    roomsData?.currentUser?.organizations?.organization?.rooms || [];
  return (
    <>
      <Page pageName={t("checkInTypes.planningPoker")}>
        <>
          <div className="w-6/12 mx-auto">
            <div className="text-lg leading-6 font-medium text-gray-900 text-center">
              {t("planningPokerSetup.setupPlanningPoker")}
            </div>
            <div className="text-gray-500 text-center mt-5 mb-10">
              {t("planningPokerSetup.setupPlanningPokerDescription")}
            </div>
          </div>
          <Formik onSubmit={onFinish} initialValues={{}}>
            {({ errors }) => (
              <Form id="select-space">
                <div className="w-3/12 mx-auto">
                  <div>
                    <div className="mt-1">
                      <Select
                        selectedOption={spaceSelected}
                        label={t("planningPokerSetup.space")}
                        onChange={setSpaceSelected}
                        options={rooms.map((element) => ({
                          id: element.id,
                          name: element.title,
                          spaceName: element.spaceName
                        }))}
                        classNameButton="flex"
                        error={errors.space}
                        placeholder={t("planningPokerSetup.selectSpace")}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <Link
                      className="flex"
                      to={{ pathname: botInstallUrl }}
                      target="_blank"
                    >
                      <PlusIcon className="w-4 mr-2" />
                      {t("planningPokerSetup.addBotToSpace")}
                    </Link>
                  </div>
                  <div className="mt-6 flex justify-center">
                    <Button
                      type="submit"
                      layout="primary"
                      className="text-center"
                    >
                      <CheckIcon className="w-5 mr-2  flex justify-center" />
                      {t("planningPokerSetup.finish")}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      </Page>
    </>
  );
};

SetupCheckInStandup.propTypes = {
  checkInId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired
};
export default SetupCheckInStandup;
