import { gql } from "@apollo/client";

const createUsersTeams = gql`
  mutation createUsersTeams($input: [CreateUsersTeamsInput]) {
    createUsersTeams(input: $input) {
      success
      code
    }
  }
`;

export default createUsersTeams;
