/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { checkInTypes } from "../../graphql/enum";
import { getCheckInUrl, getTeamUrl, teamsUrl } from "../../routes";
import Breadcrumbs from "../layout/Breadcrumbs";
import Loading from "../layout/Loading/Loading";
import NotFoundPage from "../layout/NotFoundPage/NotFoundPage";
import DayOffSingle from "./DayOffSingle/DayOffSingle";
import PlanningPokerSingle from "./PlanningPokerSingle/PlanningPokerSingle";
import RetrospectiveSingle from "./RetrospectiveSingle/RetrospectiveSingle";
import SetupCheckIn from "./SetupCheckIn";
import StandupSingle from "./StandupSingle/StandupSingle";

export const checkInByIdQuery = gql`
  query checkInById($checkInId: ID!) {
    checkIn(checkInId: $checkInId) {
      id
      title
      isActive
      isSetup
      isUserCheckInSetup
      setupStatus
      summaryType
      summarySpace
      type
      startTime
      schedule
      summaryTime
      integrationType
      bitrixDomain
      teamId
      users {
        id
        name
        avatar
      }
      notification {
        isScheduledReportsWeekly
        isScheduledReportsMonthly
        isAlertDeleteChatBot
        isAlertNoTasksConfiguration
        isUserNoResponse
        userNoResponseDays
        isReminders
        reminderTime
        numberReminders
      }
      team {
        title
        id
        role
        users {
          id
          avatar
          name
          email
          timeZone
          checkIn(checkInId: $checkInId) {
            id
          }
        }
      }
      questions {
        data {
          id
          title
          type
          questionState
        }
      }
    }
  }
`;
export const CheckInSingle = () => {
  const { checkInId } = useParams();
  const { t } = useTranslation();

  const { loading, data, refetch } = useQuery(checkInByIdQuery, {
    variables: { checkInId: parseInt(checkInId, 10) }
  });
  if (loading) return <Loading />;
  if (!data?.checkIn) return <NotFoundPage />;

  const { checkIn } = data;
  const items = [
    {
      id: "teams",
      title: t("teams.teams"),
      link: teamsUrl
    },
    {
      id: "team",
      title: checkIn?.team?.title,
      link: getTeamUrl(checkIn?.team?.id)
    },
    {
      id: "check-in",
      title: checkIn?.title,
      link: getCheckInUrl(checkIn?.id)
    }
  ];

  if (!checkIn.isSetup)
    return (
      <>
        <Breadcrumbs items={items} />
        <SetupCheckIn
          checkIn={checkIn}
          checkInId={checkInId}
          refetch={refetch}
        />
      </>
    );
  if (checkIn.type === checkInTypes.standup)
    return (
      <>
        <Breadcrumbs items={items} />
        <StandupSingle
          checkInId={checkInId}
          checkIn={checkIn}
          query={checkInByIdQuery}
          refetch={refetch}
        />
      </>
    );
  if (checkIn.type === checkInTypes.planningPoker)
    return (
      <>
        <Breadcrumbs items={items} />
        <PlanningPokerSingle
          checkIn={checkIn}
          checkInId={checkInId}
          query={checkInByIdQuery}
        />
      </>
    );
  if (checkIn.type === checkInTypes.dayOff)
    return (
      <>
        <Breadcrumbs items={items} />
        <DayOffSingle checkInId={checkInId} checkIn={checkIn} />
      </>
    );
  if (checkIn.type === checkInTypes.retrospective)
    return (
      <>
        <Breadcrumbs items={items} />
        <RetrospectiveSingle
          checkIn={checkIn}
          checkInId={checkInId}
          query={checkInByIdQuery}
        />
      </>
    );

  return <NotFoundPage />;
};
