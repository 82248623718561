import { gql } from "@apollo/client";

const updateDataSource = gql`
  mutation updateDataSource($updateDataSourceInput: UpdateDataSourceInput) {
    updateDataSource(input: $updateDataSourceInput) {
      success
      code
    }
  }
`;

export default updateDataSource;
