/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { validationErrors } from "../../graphql/enum";
import createOrganizationHoliday from "../../graphql/mutation/createOrganizationHoliday";
import deleteOrganizationHoliday from "../../graphql/mutation/deleteOrganizationHoliday";
import updateOrganization from "../../graphql/mutation/updateOrganization";
import { dateFormat } from "../../graphql/utils/date";
import { UserOrganizationAdminOrOwnerRequired } from "../../utility/hocs";
import Button from "../layout/Button";
import Loading from "../layout/Loading/Loading";
import HolidaysSection from "./HolidaysSection/HolidaysSection";
import OrganizationSection from "./OrganizationSection/OrganizationSection";

export const getCurrentUser = gql`
  query getCurrentUser {
    currentUser {
      id
      name
      avatar
      email
      organizations {
        role
        organization {
          id
          title
          ownerId
          holidays {
            id
            date
            title
          }
        }
      }
    }
  }
`;
const OrganisationView = ({ alert }) => {
  const { loading, data, refetch } = useQuery(getCurrentUser);
  const [createOrganizationHolidayMutation] = useMutation(
    createOrganizationHoliday
  );
  const [deleteOrganizationHolidayMutation] = useMutation(
    deleteOrganizationHoliday
  );
  const [updateOrganizationMutation] = useMutation(updateOrganization);

  const { t } = useTranslation();

  const [holidaysCurrent, setHolidaysCurrent] = useState([]);

  const setsetHolidaysSort = (newHolidays) => {
    if (newHolidays === null) return;

    const newHolidaysFromSort = newHolidays.map(({ id, title, date }) => ({
      id,
      title,
      date
    }));
    setHolidaysCurrent(
      newHolidaysFromSort.sort((a, b) => new Date(a.date) - new Date(b.date))
    );
  };
  const [userData, setUserData] = useState();

  useEffect(() => {
    if (!data) return;
    const { holidays = [] } = data.currentUser.organizations.organization;

    setsetHolidaysSort(holidays);
    setUserData({
      user: data.currentUser,
      organization: data.currentUser.organizations.organization
    });
  }, [data]);
  const validate = (values) => {
    const errors = {};

    if (!values.organizationTitle) {
      errors.organizationTitle = t(
        `validationErrors.${validationErrors.required}`
      );
    }

    return errors;
  };
  const onSubmit = async (values) => {
    const { holidays = [] } = data.currentUser.organizations.organization;
    const holidaysToAdd = holidaysCurrent.filter(
      (holiday) =>
        !holidays.find((holidayBase) => holiday.id === holidayBase.id)
    );

    const holidaysToDelete = holidays.filter(
      (holidayBase) =>
        !holidaysCurrent.find((holiday) => holiday.id === holidayBase.id)
    );
    const holidaysToCreateInput = holidaysToAdd.map((holiday) => ({
      title: holiday.title,
      date: moment(holiday.date).format(dateFormat)
    }));

    const holidaysToDaleteInput = holidaysToDelete.map((holiday) => ({
      id: holiday.id
    }));
    if (holidaysToDaleteInput.length > 0)
      await deleteOrganizationHolidayMutation({
        variables: {
          input: holidaysToDaleteInput
        }
      });
    if (holidaysToCreateInput.length > 0)
      await createOrganizationHolidayMutation({
        variables: {
          input: holidaysToCreateInput
        }
      });
    await updateOrganizationMutation({
      variables: {
        updateOrganizationInput: {
          title: values.organizationTitle
        },
        organizationId: userData.organization.id
      }
    });
    refetch();
    alert.success(
      <div>
        <p className="text-black">{t("settingsTab.successfullySaved")}</p>
      </div>
    );
  };

  if (loading || !userData) return <Loading />;
  return (
    <UserOrganizationAdminOrOwnerRequired>
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate  mb-6">
        {t("organisation.organisation")}
      </h2>
      <div className="mx-auto">
        <Formik
          initialValues={{ organizationTitle: userData?.organization?.title }}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ errors }) => (
            <Form id="name">
              <div className="mt-8">
                <OrganizationSection {...{ errors }} />
              </div>
              <HolidaysSection
                {...{
                  errors,
                  holidays: holidaysCurrent,
                  setHolidays: setsetHolidaysSort
                }}
              />

              <div className="flex justify-end mt-8">
                <div>
                  <Button layout="primary" type="submit">
                    {t("Save")}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </UserOrganizationAdminOrOwnerRequired>
  );
};
OrganisationView.propTypes = {
  alert: PropTypes.object
};
export default withAlert()(OrganisationView);
