/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  sendResetPasswordCode,
  validationErrors
} from "../../../../graphql/enum";
import SendResetPassword from "../../../../graphql/mutation/sendResetPassword";
import { loginUrl } from "../../../../routes";
import { validateEmail } from "../../../../utility";
import Button from "../../../layout/Button";
import Input from "../../../layout/Input";

const ResetPasswordRequestForm = () => {
  const { t } = useTranslation();
  const [SendResetPasswordMutation] = useMutation(SendResetPassword);

  const [isFaildValidateOnChange, setIsFaildValidateOnChange] = useState({
    validateOnChange: false
  });

  const validate = ({ email }) => {
    const errors = {};
    if (!validateEmail(email)) {
      errors.email = t(`validationErrors.emailIsNotCorrect`);
    }
    if (email.length === 0) {
      errors.email = t(`validationErrors.${validationErrors.required}`);
    }

    return errors;
  };
  const onResetPasswordRequest = async (value, { setErrors }) => {
    setIsFaildValidateOnChange({ validate });
    const errors = validate(value);
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;
    const { data } = await SendResetPasswordMutation({
      variables: {
        email: value.email
      }
    });

    if (data.sendResetPassword.success) {
      window.location = loginUrl;
    }
    if (
      data.sendResetPassword.code === sendResetPasswordCode.userPasswordInvalid
    ) {
      setErrors({ email: t("resetPassword.userIsNotRegistered") });
    }
  };

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      onSubmit={onResetPasswordRequest}
      {...{ ...isFaildValidateOnChange }}
    >
      {({ errors }) => (
        <Form id="reset-password-request">
          <div>
            <div className="mt-1">
              <Input
                label={t("resetPassword.emailAddress")}
                name="email"
                type="email"
                id="email"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                error={errors.email}
                errorclassname={{
                  notError: "m-0 mb-1",
                  error: "m-0 mb-3 text-sm"
                }}
              />
            </div>
          </div>
          <div className="mt-2">
            <Button
              type="submit"
              layout="primary"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium "
            >
              {t("resetPassword.sendResetPasswordLink")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordRequestForm;
