import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { validationErrors } from "../../../../graphql/enum";
import updateTeam from "../../../../graphql/mutation/updateTeam";
import Button from "../../../layout/Button";
import Input from "../../../layout/Input";

const TitleTeamEdit = ({ team, query, alert }) => {
  const { t } = useTranslation();

  const validate = ({ title }) => {
    const errors = {};
    if (!title)
      errors.title = t(`validationErrors.${validationErrors.required}`);

    return errors;
  };
  const [updateTeamMutation] = useMutation(updateTeam);

  const onSave = async ({ title }) => {
    const { data } = await updateTeamMutation({
      variables: {
        teamId: team.id,
        input: { title }
      },
      optimisticResponse: true,
      update: async (cache) => {
        const dataQuery = await cache.readQuery({
          query,
          variables: {
            teamId: team.id
          }
        });
        const newData = {
          team: {
            ...dataQuery,
            title
          }
        };
        cache.writeQuery({
          data: newData,
          query,
          variables: {
            teamId: team.id
          }
        });
      }
    });
    if (data.updateTeam.success) {
      alert.success(
        <div>
          <p className="text-black">{t("teamSettings.successSaved")}</p>
          <p className="text-gray-500">
            {t("teamSettings.successSavedDescription")}
          </p>
        </div>
      );
    }
  };
  return (
    <Formik
      initialValues={{
        title: team.title
      }}
      onSubmit={onSave}
      validate={validate}
    >
      {({ errors }) => (
        <Form>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t("teamSettings.general")}
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p> {t("teamSettings.generalDescription")}</p>
              </div>
            </div>
            <div>
              <div className="relative mt-2">
                <Input
                  type="text"
                  name="title"
                  label={t("teamSettings.teamName")}
                  error={errors.title}
                  errorclassname={{ error: "my-0", notError: "my-0" }}
                  placeholder={t("teamSettings.enterAnName")}
                />
              </div>
            </div>
            <div className="justify-self-end">
              <Button className="mt-6" type="submit">
                {t("teamSettings.save")}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
TitleTeamEdit.propTypes = {
  query: PropTypes.any,
  team: PropTypes.exact({
    id: PropTypes.string,
    title: PropTypes.string,
    isActive: PropTypes.bool
  }),
  alert: PropTypes.object
};
export default TitleTeamEdit;
