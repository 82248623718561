import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Datepicker from "../../../../layout/Datepicker";
import Input from "../../../../layout/Input/Input";

const PlanningPokerEstimatesFilter = ({ filter, setFilter }) => {
  const { t } = useTranslation();

  const [currentSearch, setCurrentSearch] = useState(filter.search);
  const date = new Date();
  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        const newFilter = {
          ...filter,
          search: currentSearch
        };
        setFilter(newFilter);
      }}
    >
      <Form>
        <Input
          label={t("Search")}
          type="text"
          value={currentSearch}
          name="search"
          onBlur={async (e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              const newFilter = {
                ...filter,
                search: currentSearch
              };
              setFilter(newFilter);
            }
          }}
          placeholder={t("planningPokerEstimatesTable.searchByStory")}
          onChange={async (e) => {
            setCurrentSearch(e.target.value);
          }}
          errorclassname={{ notError: "hidden" }}
        />
        <div className="mt-5">
          <Datepicker
            type={"range"}
            labelFrom={t("From Date")}
            labelTo={t("To Date")}
            startDate={filter.dateFrom}
            endDate={filter.dateTo}
            maxDate={date}
            setStartDate={(val) => setFilter({ ...filter, dateFrom: val })}
            setEndDate={(val) => setFilter({ ...filter, dateTo: val })}
          />
        </div>
      </Form>
    </Formik>
  );
};

PlanningPokerEstimatesFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired
};
export default PlanningPokerEstimatesFilter;
