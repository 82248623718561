/* eslint-disable no-unused-vars */
/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, message, Select } from "antd";
import queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Loading from "../components/layout/Loading/Loading";
import { integrationTypes, mutationCodes } from "../graphql/enum";
import setCheckInQuestion from "../graphql/mutation/setCheckInQuestion";
import updateJiraIntegration from "../graphql/mutation/updateJiraIntegration";
import { getCheckInUrl, teamsUrl } from "../routes";
import Layout from "./Layout/NewLayout/NewLayout";

const { Option } = Select;
export const getJiraAccessibleResources = gql`
  query jiraAccessibleResources($code: String!, $dataSourceId: ID) {
    jiraAccessibleResources(code: $code, dataSourceId: $dataSourceId) {
      integrationId
      accessibleResources {
        cloudId
        name
      }
    }
  }
`;

const JiraOauthCallback = () => {
  const history = useHistory();

  const { code, state } = queryString.parse(window.location.search);

  const { checkInId, dataSourceId } = JSON.parse(state);
  const { t } = useTranslation();

  const [updateJiraIntegrationMutation] = useMutation(updateJiraIntegration);
  const [setCheckInQuestionMutation] = useMutation(setCheckInQuestion);

  const [currentCloudId, setCurrentCloudId] = useState(false);

  const { data, loading } = useQuery(getJiraAccessibleResources, {
    variables: {
      code,
      dataSourceId
    }
  });

  useEffect(() => {
    setCurrentCloudId(
      data?.jiraAccessibleResources?.accessibleResources[0].cloudId
    );
  }, [data]);

  const setCheckIn = useCallback(
    async (integrationId) => {
      if (dataSourceId) return null;
      await setCheckInQuestionMutation({
        variables: {
          checkInId,
          integrationId,
          integrationType: integrationTypes.jira
        }
      });
      return null;
    },
    [checkInId, dataSourceId, setCheckInQuestionMutation]
  );

  useEffect(() => {
    const accessibleResources =
      data?.jiraAccessibleResources?.accessibleResources;
    if (!accessibleResources) return;
    if (accessibleResources?.length > 1) return;

    setCheckIn(data.jiraAccessibleResources.integrationId);
    history.push(getCheckInUrl(checkInId));
  }, [data, checkInId, history, setCheckIn]);

  if (loading) return <Loading />;

  const accessibleResources =
    data?.jiraAccessibleResources?.accessibleResources;

  return (
    <Layout>
      {t("jiraOauthCallback.selectYourDomain")}
      <Select
        defaultValue={accessibleResources[0].cloudId}
        style={{ marginLeft: "20px", width: "300px" }}
        onChange={(v) => setCurrentCloudId(v)}
      >
        {accessibleResources.map((resource) => (
          <Option value={resource.cloudId}>{resource.name}</Option>
        ))}
      </Select>
      <Button
        onClick={async () => {
          message.loading("loading");
          const { data: dataUpdate } = await updateJiraIntegrationMutation({
            variables: {
              integrationId: data.jiraAccessibleResources.integrationId,
              cloudId: currentCloudId
            }
          });
          message.destroy();
          if (dataUpdate.updateJiraIntegration.code !== mutationCodes.success) {
            message.error(
              `Error code: ${dataUpdate.updateJiraIntegration.code}`
            );
            return;
          }
          setCheckIn(data.jiraAccessibleResources.integrationId);
          history.push(getCheckInUrl(checkInId));
        }}
      >
        {t("Submit")}
      </Button>
    </Layout>
  );
};

export default JiraOauthCallback;
