/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { cleanObject } from "../../graphql/utils/utils";
import { peopleUrl } from "../../routes";
import { convertStringToInteger } from "../../utility";
import { getDefaultValueForDropdown } from "../../utility/config";
import { UserAdminRequired } from "../../utility/hocs";
import Loading from "../layout/Loading/Loading";
import Page from "../layout/Page/index";
import ButtonOpenInvitePeopleModal from "./ButtonOpenInvitePeopleModal/ButtonOpenInvitePeopleModal";
import InviteUsersModal from "./InvitePeopleModal/InvitePeopleModal";
import PeopleTable from "./PeopleTable/PeopleTable";
import Sidebar from "./Sidebar/Sidebar";

export const getOrganizationQuery = gql`
  query {
    currentUser {
      organizations {
        organization {
          id
          title
          ownerId
          teams {
            id
            title
          }
        }
      }
    }
  }
`;

export const People = () => {
  const { t } = useTranslation();
  const defaultValue = getDefaultValueForDropdown(t("all"));
  const history = useHistory();
  const parameters = new URLSearchParams(window.location.search);
  const botInstalled = parameters.get("botInstalled");
  const status = parameters.get("status");
  const teamId = parameters.get("teamId");
  const search = parameters.get("search");
  const offset = parameters.get("offset");
  const limit = parameters.get("limit");
  const [filter, setFilter] = useState({
    search: search || "",
    teamId,
    status,
    botInstalled
  });
  const [page, setPage] = useState({
    offset: !offset ? 0 : convertStringToInteger(offset),
    limit: !limit ? 10 : convertStringToInteger(limit)
  });

  const { loading, data, refetch } = useQuery(getOrganizationQuery);
  const [isInviteUserModalVisible, setIsInviteUserModalVisible] =
    useState(false);
  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: peopleUrl,
      query: cleanObject({
        search: filter.search,
        teamId: filter.teamId,
        status: filter.status,
        botInstalled: filter.botInstalled,
        offset: page.offset,
        limit: page.limit
      })
    });
    history.push(url);
  }, [page, filter, history]);

  if (loading) return <Loading />;
  const { organization } = data?.currentUser?.organizations;
  return (
    <UserAdminRequired>
      <Page
        key="page"
        pageName={t("oganizationPeople.people")}
        headerRight={
          <ButtonOpenInvitePeopleModal
            setIsInviteUserModalVisible={setIsInviteUserModalVisible}
          />
        }
        sidebar={
          <Sidebar
            setFilter={setFilter}
            filters={filter}
            defaultValue={defaultValue}
          />
        }
        modals={[
          <InviteUsersModal
            visible={isInviteUserModalVisible}
            onClose={() => setIsInviteUserModalVisible(false)}
            organization={organization}
            refetch={refetch}
            key="invite-user-modal"
          />
        ]}
      >
        <PeopleTable
          filters={filter}
          page={page}
          setPage={setPage}
          organizationId={organization.id}
        />
      </Page>
    </UserAdminRequired>
  );
};
