/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { organizationRoles, teamRoles } from "../../graphql/enum";
import { getTeamUrl, teamsUrl } from "../../routes";
import { UserContext } from "../../utility/context/User";
import Breadcrumbs from "../layout/Breadcrumbs";
import Loading from "../layout/Loading/Loading";
import NotFoundPage from "../layout/NotFoundPage/NotFoundPage";
import Tabs from "../layout/Tabs";
import AddCheckInButton from "./AddCheckInButton/AddCheckInButton";
import CheckInTable from "./CheckInTable/CheckInTable";
import InviteTeamButton from "./InviteTeamButton/InviteTeamButton";
import SettingsTeam from "./SettingsTeam/SettingsTeam";
import TeamUserTable from "./TeamUserTable/TeamUserTable";

export const teamDataQuery = gql`
  query standupDataQuery($teamId: ID!) {
    team(teamId: $teamId) {
      id
      title
      isActive
      users {
        id
        name
        email
        isBotAdded
        avatar
        teamStatus
        teamRole
      }
      checkIns {
        id
        type
        title
        schedule
        status
        startTime
        isSetup
        isActive
        users {
          id
        }
      }
    }
  }
`;

export const TeamSingle = () => {
  const { teamId } = useParams();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { loading, data, refetch } = useQuery(teamDataQuery, {
    variables: {
      teamId
    }
  });

  const parameters = new URLSearchParams(window.location.search);
  const defaultKeyId = parameters.get("defaultKey");
  const activeTab = parameters.get("activeTab");
  const history = useHistory();

  useEffect(() => {
    if (!activeTab && defaultKeyId) {
      const url = queryString.stringifyUrl({
        url: getTeamUrl(teamId),
        query: {
          activeTab: defaultKeyId
        }
      });
      history.push(url);
    }
  });

  if (loading) {
    return <Loading />;
  }
  if (!data?.team) return <NotFoundPage />;

  const items = [
    {
      id: 0,
      title: t("teams.teams"),
      link: teamsUrl
    },
    {
      id: data?.team?.id,
      title: data?.team?.title,
      link: getTeamUrl(data?.team?.id)
    }
  ];

  const userTeam = data?.team.users.find(({ id }) => user.id === id);

  if (
    organizationRoles.owner === user.organization.role ||
    organizationRoles.admin === user.organization.role ||
    organizationRoles.manager === user.organization.role ||
    teamRoles.manager === userTeam?.teamRole
  ) {
    const tabs = [
      {
        title: t("teamSingle.checkins"),
        name: "checkins",
        render: () => <CheckInTable checkIns={data.team.checkIns} />,
        rightContent: <AddCheckInButton teamId={teamId} refetch={refetch} />
      },
      {
        title: t("teamSingle.members"),
        name: "members",
        render: () => (
          <TeamUserTable
            users={data.team.users}
            refetch={refetch}
            teamId={teamId}
            teamTitle={data.team.title}
            query={teamDataQuery}
            checkIns={data.team.checkIns}
          />
        ),
        rightContent: (
          <InviteTeamButton
            users={data.team.users}
            teamId={teamId}
            team={data.team}
            refetch={refetch}
          />
        )
      },
      {
        title: t("teamSingle.settings"),
        name: "settings",
        render: () => <SettingsTeam query={teamDataQuery} team={data.team} />
      }
    ];

    return (
      <>
        <Breadcrumbs items={items} />
        <Tabs
          tabs={tabs}
          defaultKey={activeTab || "checkins"}
          currentUrl={getTeamUrl(teamId)}
        />
      </>
    );
  }

  const tabs = [
    {
      title: t("teamSingle.checkins"),
      name: "checkins",
      render: () => <CheckInTable checkIns={data.team.checkIns} />,
      rightContent: <AddCheckInButton teamId={teamId} refetch={refetch} />
    }
  ];

  return (
    <>
      <Breadcrumbs items={items} />
      <Tabs
        tabs={tabs}
        defaultKey={activeTab || "checkins"}
        currentUrl={getTeamUrl(teamId)}
      />
    </>
  );
};
