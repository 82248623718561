/* eslint-disable react/prop-types */
const PlusButton = ({ onClick, type = "button", form, className }) => (
  <button
    type={type}
    style={{ width: "30px", height: "30px" }}
    className={`flex justify-center align-center rounded-full border border-transparent shadow-sm bg-indigo-600 text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-lg ${className}`}
    {...{
      onClick,
      form,
      type
    }}
  >
    +
  </button>
);
export default PlusButton;
