import { DocumentDownloadIcon } from "@heroicons/react/solid";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../../../graphql/utils/date";
import { cleanObject } from "../../../../../graphql/utils/utils";
import { getExportDayOffRequestCsvQuery } from "../../../../../routes";
import Button from "../../../../layout/Button";

const ButtonDowloadCSV = ({ checkInId }) => {
  const { t } = useTranslation();
  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateTo.setMonth(defaultDateTo.getMonth() + 1);
  const onGeneratedCsv = () => {
    const parameters = new URLSearchParams(window.location.search);
    const dateToParameter = parameters.get("dateTo");
    const dateFromParameter = parameters.get("dateFrom");
    const status = parameters.get("status");
    const userId = parameters.get("userId");

    const dateTo = dateToParameter ? new Date(dateToParameter) : defaultDateTo;
    const dateFrom = dateFromParameter
      ? new Date(dateFromParameter)
      : defaultDateFrom;

    const query = queryString.stringifyUrl({
      url: getExportDayOffRequestCsvQuery(checkInId),
      query: cleanObject({
        status,
        userId,
        dateFrom: moment(dateFrom).format(dateFormat),
        dateTo: moment(dateTo).format(dateFormat)
      })
    });
    window.open(query);
  };

  return (
    <div onClick={onGeneratedCsv}>
      <Button layout="primaryOutline" className="flex items-center">
        <div className="flex">
          <DocumentDownloadIcon className="w-5 h-5 mr-2" />
          {t("dayOffDashboard.downloadCsv")}
        </div>
      </Button>
    </div>
  );
};
ButtonDowloadCSV.propTypes = {
  checkInId: PropTypes.string
};
export default ButtonDowloadCSV;
