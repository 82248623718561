import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { signInEnum, validationErrors } from "../../../../graphql/enum";
import SignIn from "../../../../graphql/mutation/signIn";
import { resetPasswordRequestUrl } from "../../../../routes";
import { validateEmail } from "../../../../utility";
import { UserContext } from "../../../../utility/context/User";
import Button from "../../../layout/Button";
import Input from "../../../layout/Input";
import Link from "../../../layout/Link";

const SignInForm = () => {
  const { t } = useTranslation();
  const { login } = useContext(UserContext);
  const [SignInMutation] = useMutation(SignIn);

  const [isFaildValidateOnChange, setIsFaildValidateOnChange] = useState({
    validateOnChange: false
  });

  const validate = ({ email, password }) => {
    const errors = {};

    if (!validateEmail(email)) {
      errors.email = t(`validationErrors.emailIsNotCorrect`);
    }
    if (email.length === 0) {
      errors.email = t(`validationErrors.${validationErrors.required}`);
    }

    if (password.length < 8) {
      errors.password = `${t(`validationErrors.passwordMustBeAtLeast`)} 8 ${t(
        `validationErrors.characters`
      )}`;
    }
    if (password.length === 0) {
      errors.password = t(`validationErrors.${validationErrors.required}`);
    }

    return errors;
  };
  const onSignIn = async (value, { setErrors }) => {
    setIsFaildValidateOnChange({ validate });
    const errors = validate(value);
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;
    const { data } = await SignInMutation({
      variables: {
        input: value
      }
    });

    if (data.signIn.success) {
      login(data.signIn.jwtToken);
    }
    if (data.signIn.code === signInEnum.userPasswordInvalid) {
      setErrors({ password: t("signIn.usernameOrPasswordIsIncorrect") });
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        isRemember: false
      }}
      onSubmit={onSignIn}
      {...{ ...isFaildValidateOnChange }}
    >
      {({ errors }) => (
        <Form id="sign-in">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("signIn.emailAddress")}
            </label>
            <div className="mt-1">
              <Input
                name="email"
                type="email"
                id="email"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                error={errors.email}
                errorclassname={{ notError: "m-0", error: "m-0 mb-1 text-sm" }}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {t("signIn.password")}
            </label>
            <div className="mt-1">
              <Input
                name="password"
                type="password"
                id="password"
                error={errors.password}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                errorclassname={{ notError: "m-0", error: "m-0 mb-1 text-sm" }}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Input
                id="isRemember"
                name="isRemember"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                errorclassname={{ notError: "hidden" }}
              />
              <label
                htmlFor="isRemember"
                className="ml-2 block text-sm text-gray-900"
              >
                {t("signIn.rememberMe")}
              </label>
            </div>
            <div>
              <Link to={resetPasswordRequestUrl} className="font-medium">
                {t("signIn.forgotYourPassword")}
              </Link>
            </div>
          </div>

          <div className="mt-6">
            <Button
              type="submit"
              layout="primary"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium "
            >
              {t("signIn.signIn")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
