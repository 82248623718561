import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Avatar from "../../../../layout/Avatar";
import Toggle from "../../../../layout/Toggle/Toggle";

const ManageCheckInUsersZone = ({ checkInData, setCheckInData }) => {
  const { t } = useTranslation();

  if (!checkInData.userCheckIns) return null;
  return (
    <div className="mt-8 shadow rounded-lg	p-6">
      <p className="text-lg font-medium">
        {t("manageCheckInUserZone.participatingUsers")}
      </p>
      <p className="text-gray-500 text-sm">
        {t("manageCheckInUserZone.participatingUsersDescription")}
      </p>
      <div className="text-left uppercase text-gray-500 font-semibold mt-10 mb-2">
        {t("manageCheckInUserZone.checkInQuestionWillBeSentTo")}
      </div>
      <ul className="divide-y divide-gray-200 border-t border-b border-gray-200">
        {checkInData.userCheckIns.map((userCheckIn) => (
          <li key={userCheckIn.userId} className="py-4 flex justify-between">
            <div className="text-sm font-medium text-gray-900 flex items-center">
              <div className="mr-3">
                <Avatar
                  src={userCheckIn.user.avatar}
                  name={userCheckIn.user.name}
                />
              </div>
              {userCheckIn.user.name}
            </div>
            <Toggle
              enabled={userCheckIn.isUserInCheckIn}
              onChange={() => {
                const newUserCheckIns = checkInData.userCheckIns.map((item) => {
                  if (item.userId === userCheckIn.userId) {
                    return {
                      ...item,
                      isUserInCheckIn: !item.isUserInCheckIn
                    };
                  }
                  return item;
                });
                setCheckInData({
                  ...checkInData,
                  userCheckIns: newUserCheckIns
                });
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
ManageCheckInUsersZone.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired
};
export default ManageCheckInUsersZone;
