import { RadioGroup } from "@headlessui/react";
import PropTypes from "prop-types";

import { classNames } from "../../../utility/index";

const ListOfDescription = ({ options, selected, onChange }) => (
  <>
    <RadioGroup value={selected} onChange={onChange} className="z-0">
      <div className="bg-white rounded-md -space-y-px">
        {options.map((option, optionIdx) => (
          <div key={optionIdx}>
            <RadioGroup.Option
              key={optionIdx}
              value={option.id}
              className={({ checked }) =>
                classNames(
                  optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  optionIdx === options.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  checked
                    ? "bg-indigo-50 border-indigo-200"
                    : "border-gray-200",
                  "relative border p-4 flex cursor-pointer outline-none"
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked
                        ? "bg-indigo-600 border-transparent"
                        : "bg-white border-gray-300",
                      active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                      "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center outline-none"
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-900" : "text-gray-900",
                        "block text-sm font-medium"
                      )}
                    >
                      {option.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "block text-sm"
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          </div>
        ))}
      </div>
    </RadioGroup>
    {options.map((option) => (
      <div key={option.id}>
        {option.id === selected ? option.hideContent : null}
      </div>
    ))}
  </>
);
ListOfDescription.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    }).isRequired
  ).isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
export default ListOfDescription;
