/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { organizationRoles } from "../../../../graphql/enum";
import Input from "../../../layout/Input";
import Select from "../../../layout/Select/Select";

const NameUserAndRoleOrganization = ({ role, setRole, selectedUser }) => {
  const { t } = useTranslation();

  const roles = [
    {
      id: organizationRoles.user,
      name: t(organizationRoles.user)
    },
    {
      id: organizationRoles.manager,
      name: t(organizationRoles.manager)
    },
    {
      id: organizationRoles.admin,
      name: t(organizationRoles.admin)
    }
  ];
  const ownerRole = {
    id: organizationRoles.owner,
    name: t(organizationRoles.owner)
  };
  const getRole = (selected) => {
    if (selected.organizationRole === organizationRoles.owner) {
      return ownerRole;
    }
    if (selected.organizationRole !== organizationRoles.owner) {
      const currentRole = roles.find(
        (element) => element.id === selected.organizationRole
      );
      return currentRole;
    }

    return {};
  };
  if (!role) {
    const selectedRole = getRole(selectedUser);
    setRole(selectedRole);
    return null;
  }
  return (
    <div className="mt-10">
      <h3 className="mb-0 text-lg leading-6">
        {t("editPeopleUserModal.access")}
      </h3>
      <p> {t("editPeopleUserModal.youCanChangeUserRoleOrganization")}</p>
      <div className="mt-6">
        <Input
          disabled={true}
          value={
            selectedUser.name === selectedUser.email
              ? selectedUser.email
              : `${selectedUser.name}, ${selectedUser.email}`
          }
          addonafter={
            role.id === organizationRoles.owner ? (
              <Select seletedOption={role} roles={[role]} disable={true} />
            ) : (
              <Select seletedOption={role} onChange={setRole} roles={roles} />
            )
          }
          containerclass="flex border-gray-300 border rounded-md"
        />
      </div>
    </div>
  );
};

NameUserAndRoleOrganization.propTypes = {
  selectedUser: PropTypes.object,
  role: PropTypes.object,
  setRole: PropTypes.func
};
export default NameUserAndRoleOrganization;
