/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { teamRoles } from "../../../graphql/enum";
import createUserCheckIns from "../../../graphql/mutation/createUserCheckIns";
import deleteUserCheckIns from "../../../graphql/mutation/deleteUserCheckIns";
import updateUserTeams from "../../../graphql/mutation/updateUserTeams";
import Button from "../../layout/Button";
import Input from "../../layout/Input";
import Modal from "../../layout/Modal";
import Select from "../../layout/Select/Select";
import DeleteTeamUserButton from "../DeleteTeamUserButton/DeleteTeamUserButton";
import EditUserCheckInSection from "../EditUserCheckInSection";

const EditTeamUserModal = ({
  visible,
  onClose,
  selectedUser,
  query,
  teamId,
  checkIns,
  refetch,
  users
}) => {
  const { t } = useTranslation();
  const [updateUserTeamsMutation] = useMutation(updateUserTeams);
  const [createUserCheckInsMutation] = useMutation(createUserCheckIns);
  const [deleteUserCheckInsMutation] = useMutation(deleteUserCheckIns);

  const roles = useMemo(
    () => [
      {
        id: teamRoles.user,
        name: t(`teamRoles.${teamRoles.user}`)
      },
      {
        id: teamRoles.manager,
        name: t(`teamRoles.${teamRoles.manager}`)
      }
    ],
    [t]
  );
  const [role, setRole] = useState(roles[0]);

  useEffect(() => {
    const defaultRole = roles.find((r) => r.id === selectedUser?.teamRole);
    if (defaultRole) setRole(defaultRole);
  }, [roles, selectedUser]);

  const [userCheckIns, setUserChekIns] = useState(null);

  useEffect(() => {
    if (!selectedUser) return;

    const defaultUserCheckIns = checkIns.map((checkIn) => {
      const isUserInCheckIn = checkIn.users.some(
        (user) => user.id === selectedUser.id
      );

      return {
        checkInId: checkIn.id,
        title: checkIn.title,
        isUserInCheckIn
      };
    });
    setUserChekIns(defaultUserCheckIns);
  }, [checkIns, selectedUser]);

  if (!selectedUser) return null;

  const onSave = async () => {
    await updateUserTeamsMutation({
      variables: {
        input: [
          {
            role: role.id,
            teamId,
            userId: selectedUser.id
          }
        ]
      },
      optimisticResponse: true,
      update: async (cache) => {
        const data = await cache.readQuery({
          query,
          variables: {
            teamId
          }
        });
        const newUsers = data.team.users.map((user) => {
          if (user.id === selectedUser.id)
            return {
              ...user,
              teamRole: role.id
            };
          return user;
        });
        const newData = {
          team: {
            ...data.team,
            users: newUsers
          }
        };
        cache.writeQuery({
          data: newData,
          query,
          variables: {
            teamId
          }
        });
      }
    });
    const userCheckInsToCreate = userCheckIns.filter(
      (userCheckIn) => userCheckIn.isUserInCheckIn
    );
    const userCheckInsToCreateInput = userCheckInsToCreate.map(
      ({ checkInId }) => ({ checkInId, userId: selectedUser.id })
    );

    const userCheckInsToDelete = userCheckIns.filter(
      (userCheckIn) => !userCheckIn.isUserInCheckIn
    );
    const userCheckInsToDeleteInput = userCheckInsToDelete.map(
      ({ checkInId }) => ({ checkInId, userId: selectedUser.id })
    );
    if (userCheckInsToCreateInput.length > 0) {
      await createUserCheckInsMutation({
        variables: {
          input: userCheckInsToCreateInput
        }
      });
    }
    if (userCheckInsToDeleteInput.length > 0) {
      await deleteUserCheckInsMutation({
        variables: {
          input: userCheckInsToDeleteInput
        }
      });
    }
    refetch();
    onClose();
  };
  const usersMenageCount = users.filter(
    (user) => user.teamRole === teamRoles.manager
  ).length;
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="p-8">
        <div className="mt-1 text-center sm:mt-1">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {t("editTeamUserModal.editTeamUser")}
          </Dialog.Title>
        </div>
        <div className="mt-10">
          <h3 className="mb-0 text-lg leading-6">
            {t("editTeamUserModal.access")}
          </h3>
          <p> {t("editTeamUserModal.youCanChangeUserRole")}</p>
        </div>
        <div className="mt-6">
          <Input
            disabled={true}
            value={
              selectedUser.name === selectedUser.email
                ? selectedUser.email
                : `${selectedUser.name}, ${selectedUser.email}`
            }
            addonafter={
              <Select seletedOption={role} onChange={setRole} roles={roles} />
            }
            containerclass="flex border-gray-300 border rounded-md"
          />
        </div>
        {userCheckIns?.length > 0 ? (
          <div className="mt-6">
            <div className="text-left uppercase text-gray-500 font-semibold mt-3">
              {t("editTeamUserModal.listOfUserCheckIns")}
            </div>
            <EditUserCheckInSection
              userCheckIns={userCheckIns}
              setUserChekIns={setUserChekIns}
            />
          </div>
        ) : null}
        {usersMenageCount > 1 ? (
          <div className="shadow-md pl-5 py-7 flex justify-between items-center mt-14">
            <p className="mb-0 text-sm text-gray-500">
              {t("editTeamUserModal.warningAboutDeletingUser")}
              <span className="font-semibold">
                {t("editTeamUserModal.itIsInreversible")}
              </span>
            </p>
            <div className="mr-1.5">
              <DeleteTeamUserButton
                userId={selectedUser.id}
                teamId={teamId}
                onDelete={() => onClose()}
                query={query}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end">
          <div className="flex">
            <Button layout="white" className="mr-5" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="submit" form="createTeamModal" onClick={onSave}>
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditTeamUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  query: PropTypes.any,
  teamId: PropTypes.string,
  checkIns: PropTypes.array,
  refetch: PropTypes.func,
  users: PropTypes.array
};
export default EditTeamUserModal;
