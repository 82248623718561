/* eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";

import { classNames } from "../../../utility";
import Pagination from "../Pagination/Pagination";

const Table = ({
  columns,
  data,
  page,
  pageSize,
  setPage,
  countElements,
  typeResultText,
  isTableRowBorder
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:pl-2 lg:pr-2">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr key="col">
                  {columns.map((col, idx) => (
                    <th
                      key={idx}
                      scope="col"
                      colSpan={`${col.colspan ?? "1"}`}
                      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${col.className}`}
                    >
                      {col.isVisible ? (
                        col.title
                      ) : (
                        <span className="sr-only"> {col.title}</span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                className={classNames(
                  "bg-white",
                  !isTableRowBorder ? "divide-y divide-gray-200" : ""
                )}
              >
                {data.length > 0 ? (
                  data.map((dataItem, idx) => (
                    <tr
                      key={idx}
                      className={
                        isTableRowBorder ? "border-b-15 border-gray-50" : ""
                      }
                    >
                      {dataItem.map(({ render, colspan, className, id }) => (
                        <td
                          colSpan={colspan || "1"}
                          className={`px-6 py-4 whitespace-nowrap text-left ${className} 0`}
                          key={id}
                        >
                          {render()}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr key="no-data-row">
                    <td
                      key="no-data-col"
                      colSpan={
                        columns.length > 1
                          ? columns.reduce((previousValue, col) => ({
                              countColumns:
                                (previousValue.countColumns ??
                                  previousValue.colspan ??
                                  1) + (col.colspan ?? 1)
                            })).countColumns
                          : 1
                      }
                      className={`px-6 py-16 whitespace-nowrap text-center text-xs font-medium text-gray-500`}
                    >
                      {t("tablesRows.noData")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              {...{
                page,
                setPage,
                pageSize,
                countElements,
                typeResultText
              }}
              displayElements={data.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Table.defaultProps = {
  isTableRowBorder: false
};
export default Table;
