/* eslint-disable react/prop-types */
const PrimaryButton = ({
  onClick,
  children,
  type = "button",
  form,
  className,
  disabled
}) => (
  <button
    type={type}
    className={`inline-flex  justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm ${className}`}
    {...{
      onClick,
      form,
      type
    }}
    disabled={disabled}
  >
    {children}
  </button>
);
export default PrimaryButton;
