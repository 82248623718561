import { gql } from "@apollo/client";

const updateUser = gql`
  mutation updateUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      code
      success
    }
  }
`;

export default updateUser;
