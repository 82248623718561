import PropTypes from "prop-types";

const PrimaryButton = ({
  onClick,
  children,
  type = "button",
  form,
  className
}) => (
  <button
    type={type}
    className={`inline-flex  justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-50 text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm ${className}`}
    {...{
      onClick,
      form,
      type
    }}
  >
    {children}
  </button>
);

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
  type: PropTypes.string,
  form: PropTypes.func,
  className: PropTypes.string
};
export default PrimaryButton;
