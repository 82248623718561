import { gql } from "@apollo/client";

const createBitrixIntegration = gql`
  mutation createBitrixIntegration(
    $code: String!
    $domain: String!
    $integrationId: ID
  ) {
    createBitrixIntegration(
      code: $code
      domain: $domain
      integrationId: $integrationId
    ) {
      success
      code
      id
    }
  }
`;

export default createBitrixIntegration;
