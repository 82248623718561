import { DotsHorizontalIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useState } from "react";

import Button from "../Button";

const SelectIcon = ({ menu }) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      {isShow ? (
        <div
          className="fixed opacity-0 z-10 w-screen	h-screen top inset-0 bg-gray-50"
          onClick={() => setIsShow(!isShow)}
        ></div>
      ) : null}
      <div className="absolute inline-block text-lefixed">
        <div>
          <button
            type="button"
            data-testid="on-menu"
            className="bg-none rounded-full flex items-center text-gray-400"
            onClick={() => setIsShow(!isShow)}
          >
            <DotsHorizontalIcon className="text-gray-400 w-6 hover:text-gray-600 active:btext-gray-800" />
          </button>
        </div>

        <div
          className={`${
            isShow
              ? "transform opacity-100 scale-100"
              : "transform hidden scale-95"
          }  origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-black focus:outline-none`}
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {menu.map(({ title, onClick }, idx) => (
              <Button
                onClick={onClick}
                layout="white"
                key={idx}
                style={{ fontWeight: "400 !important" }}
                className="justify-between font-normal shadow-none text-left border-none text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-indigo-50"
              >
                {title}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
SelectIcon.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func
    }).isRequired
  )
};
export default SelectIcon;
