import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { classNames } from "../../../utility";

const Select = ({
  onChange,
  selectedOption,
  options,
  label,
  placeholder,
  icon,
  error
}) => (
  <Listbox value={selectedOption} onChange={onChange} placeholder={placeholder}>
    {({ open }) => (
      <>
        <Listbox.Label className="text-left	block text-sm font-medium text-gray-700">
          {label}
        </Listbox.Label>
        <div className="mt-1 relative">
          <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <span className="block truncate">
              {selectedOption?.name ? (
                selectedOption.name
              ) : (
                <div className="text-gray-300">{placeholder}</div>
              )}
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              {icon || (
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {options.map((person) => (
                <Listbox.Option
                  key={person.id}
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-indigo-600" : "text-gray-900",
                      "cursor-default select-none relative py-2 pl-3 pr-9"
                    )
                  }
                  value={person}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {person.name}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-indigo-600",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
        {error ? <p className="text-gray-400 text-left">{error}</p> : null}
      </>
    )}
  </Listbox>
);
Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedOption: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.any
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.any.isRequired
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.any,
  error: PropTypes.any
};
export default Select;
