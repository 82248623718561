/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Loading from "../components/layout/Loading/Loading";
import NotFoundPage from "../components/layout/NotFoundPage/NotFoundPage";
import { approveInviteMutationCode } from "../graphql/enum";
import approveTeamInviteMutation from "../graphql/mutation/approveTeamInvite";
import { teamsUrl } from "../routes";
import { UserContext } from "../utility/context/User";
import ErrorView from "./forbidden/Error/Error";

const ApproveTeamInviteView = ({ location }) => {
  const { code } = queryString.parse(location.search);

  const [approveTeamInvite, { loading, data }] = useMutation(
    approveTeamInviteMutation
  );

  const { login } = useContext(UserContext);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    approveTeamInvite({ variables: { code } });
  }, [code, approveTeamInvite]);
  if (loading) return <Loading />;

  if (data) {
    const { success, code: responseCode } = data.approveTeamInvite;
    if (success) {
      const { jwtToken } = data.approveTeamInvite;
      login(jwtToken);
      history.push(teamsUrl);
    }
    if (responseCode === approveInviteMutationCode.alreadyApproved) {
      return <ErrorView>{t("approveInvite.tokenIsApproved")}</ErrorView>;
    }
    if (responseCode === approveInviteMutationCode.tokenExpired) {
      return <ErrorView>{t("approveInvite.tokenExpired")}</ErrorView>;
    }
  }
  return <NotFoundPage />;
};

ApproveTeamInviteView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default ApproveTeamInviteView;
