/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";

const LinearSteps = ({ steps, currentStep }) => {
  if (!currentStep) return null;
  const currentStepIndex = steps.findIndex(
    (step) => step.id === currentStep.id
  );
  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map((step, stepIndex) => (
          <li key={step.name} className="md:flex-1">
            {stepIndex < currentStepIndex ? (
              <p className="group pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                  {step.name}
                </span>
              </p>
            ) : stepIndex === currentStepIndex ? (
              <p
                className="pl-4 py-2 flex flex-col border-l-4 border-indigo-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                aria-current="step"
              >
                <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase">
                  {step.name}
                </span>
              </p>
            ) : (
              <p className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                  {step.name}
                </span>
              </p>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
LinearSteps.propTypes = {
  currentStep: PropTypes.object,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};
export default LinearSteps;
