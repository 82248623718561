import { gql } from "@apollo/client";

const sendPeopleInvites = gql`
  mutation sendPeopleInvites($organizationId: ID!, $users: [PeopleInvites]) {
    sendPeopleInvites(organizationId: $organizationId, users: $users) {
      success
      code
    }
  }
`;

export default sendPeopleInvites;
