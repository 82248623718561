/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { XIcon } from "@heroicons/react/solid";
import moment from "moment";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../../../graphql/utils/date";
import { getCheckInRunUrl } from "../../../../../routes";
import LinkView from "../../../../layout/Link";
import Loading from "../../../../layout/Loading/Loading";
import Table from "../../../../layout/Table/Table";

export const checkInByIdQuery = gql`
  query checkInById(
    $checkInId: ID!
    $filtersCheckInRun: CheckInRunsFilterInput
    $pagination: paginationInput
    $filtersQuestion: CheckInQuestionsListFilterInput
  ) {
    checkIn(checkInId: $checkInId) {
      id
      title
      checkInRuns {
        count(filters: $filtersCheckInRun)
        data(filters: $filtersCheckInRun, pagination: $pagination) {
          id
          date
          retrospectiveItemsRooms {
            id
            title
            checkInQuestionId
          }
          retrospectiveItemsRoomsActions {
            id
            title
            checkInQuestionId
          }
        }
      }
      questions {
        data(filters: $filtersQuestion) {
          id
          title
          type
          questionState
        }
      }
    }
  }
`;

const DashboardTable = ({ filters, page, setPage, checkInId }) => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(checkInByIdQuery, {
    variables: {
      checkInId: parseInt(checkInId, 10),
      filtersCheckInRun: {
        search: filters.search,
        dateFrom: moment(filters.dateFrom).format(dateFormat),
        dateTo: moment(filters.dateTo).format(dateFormat)
      },
      filtersQuestion: {
        checkInQuestionId: filters.checkInQuestion
          ? filters.checkInQuestion.id
          : null
      }
    }
  });

  const defaultDateFrom = new Date();
  defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);
  const columns = [
    {
      title: t("Date"),
      isVisible: true
    },
    {
      title: t("Question"),
      isVisible: true
    },
    {
      title: "Empty",
      isVisible: false
    },
    {
      title: t("Link"),
      isVisible: false
    }
  ];
  if (loading) return <Loading />;

  const dataRender = data.checkIn.checkInRuns.data.map((dataItem) => [
    {
      id: `dare-${dataItem.id}`,
      render: () => (
        <div className="flex -mx-6 flex-col items-center">
          <p className="text-gray-500">
            {moment(dataItem.date, dateFormat).format("ddd MMM D YYYY")}
          </p>
        </div>
      )
    },
    {
      id: `dare-${dataItem.id}`,
      render: () => (
        <ul className="divide-y divide-gray-200  -mx-6 ">
          {data.checkIn.questions.data.map((q) => {
            const rooms = dataItem?.retrospectiveItemsRooms.filter(
              (r) => r.checkInQuestionId === q.id
            );
            if (rooms.length !== 0)
              return (
                <li className="flex items-center py-8" key={q.id}>
                  <div className="w-6/12 text-gray-500">{q.title}</div>
                  <div className="w-6/12">
                    {rooms.map((roomItem, idx) => (
                      <div key={idx}>{roomItem.title}</div>
                    ))}
                  </div>
                </li>
              );
            const actions = dataItem?.retrospectiveItemsRoomsActions.filter(
              (r) => r.checkInQuestionId === q.id
            );
            if (actions.length !== 0)
              return (
                <li className="flex items-center py-8" key={q.id}>
                  <div className="w-6/12 text-gray-500">{q.title}</div>
                  <div className="w-6/12">
                    {actions.map((actionItem, idx) => (
                      <div key={idx}>{actionItem.title}</div>
                    ))}
                  </div>
                </li>
              );

            return (
              <li className="flex items-center py-8" key={q.id}>
                <div className="w-6/12 text-gray-500">{q.title}</div>
                <div className="w-6/12">
                  <XIcon width="20" height="20" className="text-gray-300" />
                </div>
              </li>
            );
          })}
        </ul>
      ),
      colspan: 2
    },
    {
      id: `dare-${dataItem.id}`,
      render: () => (
        <ul className="divide-y divide-gray-200 text-center  -mx-6 ">
          <LinkView to={getCheckInRunUrl(checkInId, dataItem.id)}>
            {t("Open")}
          </LinkView>
        </ul>
      )
    }
  ]);
  return (
    <div className="sm:-mx-6 lg:-mx-8">
      <Table
        data={dataRender}
        columns={columns}
        page={page}
        setPage={setPage}
        typeResultText={t("dayOffTable.stories")}
        countElements={data?.checkIn?.checkInRuns?.count}
        isTableRowBorder={true}
      />
    </div>
  );
};
DashboardTable.propTypes = {
  checkInId: PropTypes.string.isRequired,
  filters: PropTypes.object,
  page: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number
  }),
  setPage: PropTypes.func,
  alert: PropTypes.object
};
export default withAlert()(DashboardTable);
