import { gql } from "@apollo/client";

const sendVerificationEmail = gql`
  mutation sendVerificationEmail($email: String!) {
    sendVerificationEmail(email: $email) {
      code
      success
    }
  }
`;

export default sendVerificationEmail;
