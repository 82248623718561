import { gql } from "@apollo/client";

const resendOrganizationInvite = gql`
  mutation resendOrganizationInvite($userId: ID!, $organizationId: ID!) {
    resendOrganizationInvite(userId: $userId, organizationId: $organizationId) {
      success
    }
  }
`;
export default resendOrganizationInvite;
