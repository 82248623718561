import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import SendVerificationEmail from "../../../graphql/mutation/sendVerificationEmail";
import { UserContext } from "../../../utility/context/User";
import Button from "../../layout/Button";

const VerificationEmailButton = ({ alert }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [SendVerificationEmailMutation] = useMutation(SendVerificationEmail);

  const onSendVerificationEmail = async () => {
    const { data } = await SendVerificationEmailMutation({
      variables: {
        email: user.email
      }
    });
    if (data.sendVerificationEmail.success) {
      alert.success(
        <div>
          <p className="text-black">{t("jiraSearchForm.successfullySaved")}</p>
          <p className="text-gray-500">{t("alert.successfullySent")}</p>
        </div>
      );
    }
  };

  return (
    <div className="mt-6">
      <Button
        onClick={onSendVerificationEmail}
        layout="primary"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium "
      >
        {t("notVerificationEmail.sendVerificationEmail")}
      </Button>
    </div>
  );
};

VerificationEmailButton.propTypes = {
  alert: PropTypes.object
};

export default withAlert()(VerificationEmailButton);
