/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  manageOrganizationResources,
  resourceCategories
} from "../../../graphql/enum";
import { ResourceRequired } from "../../../utility/hocs";
import Input from "../../layout/Input";

const AccountSection = ({ errors }) => {
  const { t } = useTranslation();
  return (
    <ResourceRequired
      resource={manageOrganizationResources.renameOrganization}
      resourceCategory={resourceCategories.manageOrganization}
    >
      <div className="shadow rounded-lg	p-6">
        <div className="flex">
          <div className="w-1/3 mr-10">
            <p className="text-lg font-medium">
              {t("organizationSection.organization")}
            </p>
            <p className="text-gray-500 text-sm">
              {t("organizationSection.description")}
            </p>
          </div>
          <div className="w-1/3">
            <Input
              label={t("organizationSection.organizationName")}
              placeholder={t("organizationSection.typeOrganizationName")}
              type="text"
              name="organizationTitle"
              error={errors.organizationTitle}
            />
          </div>
        </div>
      </div>
    </ResourceRequired>
  );
};

AccountSection.propTypes = {
  errors: PropTypes.shape({
    organizationTitle: PropTypes.string
  })
};

export default AccountSection;
