import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { UserTeamManagerRequired } from "../../../../../utility/hocs";
import Input from "../../../../layout/Input/Input";
import Toggle from "../../../../layout/Toggle/Toggle";

const Alerts = ({ checkInData, setCheckInData, teamRole, errors }) => {
  const { t } = useTranslation();
  return (
    <UserTeamManagerRequired userTeamRole={teamRole}>
      <div className="shadow rounded-lg	p-6 mt-6">
        <div className="flex">
          <div className="w-1/3 mr-10">
            <p className="text-lg font-medium">
              {t("standupNotificationsTab.alerts")}
            </p>
            <p className="text-gray-500 text-sm">
              {t("standupNotificationsTab.alertsDescription")}
            </p>
          </div>
          <div className="w-2/3">
            <div className="flex">
              <div className="w-1/12">
                <Toggle
                  enabled={checkInData.isAlertDeleteChatBot}
                  onChange={() =>
                    setCheckInData({
                      ...checkInData,
                      isAlertDeleteChatBot: !checkInData.isAlertDeleteChatBot
                    })
                  }
                />
              </div>
              <div className="w-11/12 -ml-1">
                <div>
                  <span className=" text-sm  font-medium text-gray-700">
                    {t("standupNotificationsTab.userHasDeletedChatbot")}
                  </span>
                </div>{" "}
                <div>
                  <span className="text-sm font-normal text-gray-500">
                    {t(
                      "standupNotificationsTab.userHasDeletedChatbotDescription"
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex mt-6">
              <div className="w-1/12">
                <Toggle
                  enabled={checkInData.isAlertNoTasksConfiguration}
                  onChange={() =>
                    setCheckInData({
                      ...checkInData,
                      isAlertNoTasksConfiguration:
                        !checkInData.isAlertNoTasksConfiguration
                    })
                  }
                />
              </div>
              <div className="w-11/12 -ml-1">
                <div>
                  <span className=" text-sm  font-medium text-gray-700">
                    {t("standupNotificationsTab.thereIsNotConfiguredTaskList")}
                  </span>
                </div>{" "}
                <div>
                  <span className="text-sm font-normal text-gray-500">
                    {t(
                      "standupNotificationsTab.thereIsNotConfiguredTaskListDescription"
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex mt-6">
              <div className="w-1/12">
                <Toggle
                  enabled={checkInData.isUserNoResponse}
                  onChange={() =>
                    setCheckInData({
                      ...checkInData,
                      isUserNoResponse: !checkInData.isUserNoResponse
                    })
                  }
                />
              </div>
              <div className="w-11/12 -ml-1">
                <div>
                  <span className="flex text-sm text-center font-medium text-gray-700">
                    <span className="pt-2">
                      {t("standupNotificationsTab.userNoSentResponseFor")}
                    </span>
                    <span className="px-3 text-center max-w-min">
                      <Input
                        name="userNoResponseDays"
                        type="text"
                        error={errors.userNoResponseDays}
                        fieldclassname="text-center"
                        value={checkInData.userNoResponseDays}
                        onChange={({ target }) => {
                          const value =
                            target.value.length > 0
                              ? parseInt(target.value, 10)
                              : "";
                          setCheckInData({
                            ...checkInData,
                            userNoResponseDays: value
                          });
                        }}
                      />
                    </span>
                    <span className="pt-2">
                      {t("standupNotificationsTab.days")}
                    </span>
                  </span>
                </div>
                <div>
                  <span className="text-sm font-normal text-gray-500">
                    {t(
                      "standupNotificationsTab.userNoSentResponseForDescription",
                      { day: checkInData.userNoResponseDays }
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserTeamManagerRequired>
  );
};
Alerts.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  teamRole: PropTypes.string,
  errors: PropTypes.object.isRequired
};
export default Alerts;
