import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { validationErrors } from "../../../../../../graphql/enum";
import { validateUrl } from "../../../../../../utility";
import Input from "../../../../../layout/Input";

const CreateBitrixForm = ({ name, onSubmit, setBitrixDomain }) => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    if (!validateUrl(`https://${values.domain}`)) {
      errors.domain = t(`createBitrixForm.domainIsNotCorrect`);
    }

    if (!values.domain) {
      errors.domain = t(`validationErrors.${validationErrors.required}`);
    }

    setBitrixDomain(values.domain);
    return errors;
  };

  return (
    <div className="mt-10">
      <div className="text-lg leading-6 font-medium text-gray-900 text-left">
        {t("createBitrixForm.setupDomain")}
      </div>
      <div className="text-gray-500 text-left mt-2">
        {t("createBitrixForm.setupDomainDesctiption")}
      </div>
      <Formik
        validate={validate}
        initialValues={{
          domain: ""
        }}
        onSubmit={onSubmit}
      >
        {({ errors }) => (
          <Form id={name} data-testid="createBitrixForm">
            <div className="mt-12 w-full">
              <div>
                <Input
                  type="text"
                  name="domain"
                  placeholder="Enter the domain"
                  error={errors.domain}
                  addonbefore="https://"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CreateBitrixForm.propTypes = {
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  setBitrixDomain: PropTypes.func
};
export default CreateBitrixForm;
