import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Input from "../../layout/Input";

const AccountSection = ({ user, errors }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg	p-6 mt-10">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">
            {" "}
            {t("accountSection.personalData")}
          </p>
          <p className="text-gray-500 text-sm">
            {t("accountSection.description")}
          </p>
        </div>
        <div className="w-1/3">
          <p className="text-gray-700 text-sm font-medium mb-5">
            {t("accountSection.email")}
            <span className="font-bold ml-3">{user.email}</span>
          </p>
          <Input
            label={t("accountSection.fullName")}
            placeholder={t("accountSection.typeUserName")}
            type="text"
            name="userName"
            error={errors.userName}
          />
        </div>
      </div>
    </div>
  );
};
AccountSection.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }),
  errors: PropTypes.shape({
    userName: PropTypes.string
  })
};
export default AccountSection;
