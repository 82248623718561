import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { checkInTypes } from "../../../graphql/enum";
import { getCheckInUrl } from "../../../routes";
import { getIsHoliday } from "../../../utility";
import { UserContext } from "../../../utility/context/User";
import { convertTimeToTimeZone } from "../../../utility/date";
import Link from "../../layout/Link";
import Loading from "../../layout/Loading/Loading";
import Table from "../../layout/Table/Table";
import CheckInIcon from "./CheckInIcon/CheckInIcon";
import CheckInStatusLabel from "./CheckInStatusLabel/CheckInStatusLabel";

export const checkInsQuery = gql`
  query checkInsQuery {
    currentUser {
      organizations {
        organization {
          holidays {
            id
            date
          }
        }
      }
    }
  }
`;

const getNextRunDate = (startTime, schedule, holidays, timeZone) => {
  const currentDate = moment();
  const beginningTime = convertTimeToTimeZone(startTime, timeZone);
  const isHoliday = getIsHoliday(holidays, new Date(currentDate.format()));
  if (schedule.includes(currentDate.format("ddd")) && !isHoliday) {
    return beginningTime.format("D MMM H:mm");
  }
  const startDate = convertTimeToTimeZone(startTime, timeZone).add(1, "days");

  while (
    !schedule.includes(startDate.format("ddd")) &&
    getIsHoliday(holidays, new Date(currentDate.format()))
  ) {
    startDate.add(1, "days");
  }
  return startDate.format("D MMM H:mm");
};
const CheckInTable = ({ checkIns }) => {
  const { user } = useContext(UserContext);
  const { loading, data: currentUserData } = useQuery(checkInsQuery);

  const { t } = useTranslation();
  if (loading) return <Loading />;

  const columns = [
    {
      title: "Name",
      isVisible: true
    },
    {
      title: "schedule",
      isVisible: true
    },
    {
      title: "next run",
      isVisible: true
    },
    {
      title: "status",
      isVisible: true
    },
    {
      title: "status",
      isVisible: false
    }
  ];
  const data = checkIns.map((checkIn) => [
    {
      id: `title-${checkIn.id}`,
      render: () => (
        <Link to={getCheckInUrl(checkIn.id)}>
          <div className="flex items-center">
            <CheckInIcon
              type={checkIn.type}
              className="h-5 w-4 mr-1 text-gray-400"
            />
            <div className="text-sm font-medium text-gray-900 ">
              {checkIn.title}
            </div>
          </div>
        </Link>
      )
    },
    {
      id: `time-${checkIn.id}`,
      render: () => {
        if (checkIn.type === checkInTypes.planningPoker) {
          return null;
        }
        if (checkIn.type === checkInTypes.standup) {
          const startTimeLocal = convertTimeToTimeZone(
            checkIn.startTime,
            user.timeZone
          );
          return (
            <div className="text-sm text-gray-500">
              {checkIn.schedule.join(", ")} {t("at")}{" "}
              {startTimeLocal.format("HH:mm")}
            </div>
          );
        }
        return null;
      }
    },
    {
      id: `nextRun-${checkIn.id}`,
      render: () => {
        if (checkIn.type !== checkInTypes.standup) {
          return null;
        }
        if (checkIn.schedule.length === 0) return null;

        return (
          <div className="text-sm text-gray-500">
            {getNextRunDate(
              checkIn.startTime,
              checkIn.schedule,
              currentUserData.currentUser.organizations.organization.holidays,
              user.timeZone
            )}
          </div>
        );
      }
    },
    {
      id: `label-${checkIn.id}`,
      render: () => <CheckInStatusLabel checkIn={checkIn} />
    },
    {
      id: `setup-${checkIn.id}`,
      render: () => {
        if (!checkIn.isSetup)
          return <Link to={getCheckInUrl(checkIn.id)}>{t("Setup")}</Link>;

        return null;
      }
    }
  ]);

  return <Table columns={columns} data={data} />;
};

CheckInTable.propTypes = {
  checkIns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      schedule: PropTypes.array.isRequired,
      status: PropTypes.string
    }).isRequired
  ).isRequired
};
export default CheckInTable;
