import { RefreshIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { summaryRoomType } from "../../../../../../../../graphql/enum";
import Select from "../../../../../../../layout/Select";

const SummaryRoomSelect = ({
  rooms,
  type,
  checkInData,
  setCheckInData,
  errors,
  refetch
}) => {
  const { t } = useTranslation();
  const options = rooms.map((r) => ({
    id: r.id,
    spaceName: r.spaceName,
    name: r.title
  }));
  useEffect(() => {
    if (!checkInData.summarySpace) return;

    const activeRoom = options.find(
      (r) => r.spaceName === checkInData.summarySpace
    );
    if (activeRoom) {
      setCheckInData({
        ...checkInData,
        summarySpace: activeRoom
      });
    }
  }, [checkInData, setCheckInData, options]);

  if (type === summaryRoomType.space) {
    return (
      <div className="flex items-end">
        <div className="float-left w-1/2">
          <Select
            options={options}
            label={t("summaryRoomSelect.summarySpace")}
            placeholder={t("summaryRoomSelect.selectSpace")}
            selectedOption={checkInData.summarySpace}
            error={errors.summarySpace}
            onChange={(val) => {
              setCheckInData({
                ...checkInData,
                summarySpace: val
              });
            }}
          />
        </div>
        <span
          onClick={async () => {
            await refetch();
          }}
          className="ml-5 mb-2 cursor-pointer"
        >
          <RefreshIcon className="h-6 w-6 text-gray-500" />
        </span>
      </div>
    );
  }

  return null;
};
SummaryRoomSelect.defaultProps = {
  rooms: []
};
SummaryRoomSelect.propTypes = {
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      spaceName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  type: PropTypes.oneOf(Object.values(summaryRoomType)).isRequired,
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  errors: PropTypes.object,
  refetch: PropTypes.func
};
export default SummaryRoomSelect;
