import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../../../graphql/utils/date";
import { getDefaultValueForDropdown } from "../../../../../utility/config";
import Loading from "../../../../layout/Loading/Loading";
import BlockersTable from "./BlockersTable/BlockersTable";

export const checkInStandupResponsesQuery = gql`
  query checkInStandupResponses(
    $checkInId: ID!
    $filter: CheckInStandupResponsesFilter
  ) {
    checkInStandupResponses {
      data(checkInId: $checkInId, filter: $filter) {
        date
        id
        user {
          id
          name
          avatar
        }
        blocker {
          taskId
          textMessage
        }
      }
    }
  }
`;

export const Blockers = ({ checkInId, filters }) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultValueForDropdown(t("all"));

  const { loading, data } = useQuery(checkInStandupResponsesQuery, {
    variables: {
      checkInId,
      filter: {
        search: "",
        dateFrom: moment(filters.dateFrom).format(dateFormat),
        dateTo: moment(filters.dateTo).format(dateFormat),
        userId: filters.userId === defaultValue.id ? null : filters.userId
      }
    },
    notifyOnNetworkStatusChange: true
  });
  if (loading) return <Loading />;
  if (!data?.checkInStandupResponses) return null;
  return (
    <>
      <div className="mx-10">
        <div className="flex py-4 mt-2 justify-start text-2xl">
          {t("standupUserTable.blockers")}
        </div>
        <BlockersTable data={data?.checkInStandupResponses.data} />
      </div>
    </>
  );
};
Blockers.propTypes = {
  checkInId: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    userId: PropTypes.string
  })
};
