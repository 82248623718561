import { gql } from "@apollo/client";

const approveVerificationEmail = gql`
  mutation approveVerificationEmail($code: String!) {
    approveVerificationEmail(code: $code) {
      success
      code
      jwtToken
    }
  }
`;

export default approveVerificationEmail;
