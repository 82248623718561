import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../layout/Button";
import AddCheckInModal from "../AddCheckInModal/AddCheckInModal";

const AddCheckInButton = ({ teamId, refetch }) => {
  const { t } = useTranslation();
  const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] =
    useState(false);

  return (
    <>
      <Button onClick={() => setIsCreateTeamModalVisible(true)}>
        {t("Add")}
      </Button>
      <AddCheckInModal
        visible={isCreateTeamModalVisible}
        onClose={() => setIsCreateTeamModalVisible(false)}
        teamId={teamId}
        refetch={refetch}
      />
    </>
  );
};
AddCheckInButton.propTypes = {
  teamId: PropTypes.string.isRequired,
  refetch: PropTypes.func
};

export default AddCheckInButton;
