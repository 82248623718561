import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../../../../graphql/utils/date";
import Avatar from "../../../../../layout/Avatar";
import Table from "../../../../../layout/Table/Table";

const BlockersTable = ({ data }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("Date"),
      isVisible: true,
      className: "w-2/12"
    },
    {
      title: t("User"),
      isVisible: true,
      className: "w-2/12 text-center"
    },
    {
      title: t("Blocker"),
      isVisible: true,
      className: "w-8/12"
    }
  ];
  const dataTable = data.map((dataItem) =>
    dataItem.blocker === null
      ? null
      : [
          {
            id: dataItem.id,
            render: () => (
              <div className="py-5">
                <p className="text-gray-500">
                  {moment(dataItem.date, dateFormat).format("ddd MMM D YYYY")}
                </p>
              </div>
            )
          },
          {
            id: dataItem.id,
            render: () => (
              <div className="flex flex-col items-center">
                <Avatar src={dataItem.user.avatar} name={dataItem.user.name} />
                <p className="text-gray-900 font-medium mt-2">
                  {dataItem.user.name}
                </p>
              </div>
            )
          },
          {
            id: dataItem.id,
            render: () => (
              <div className="text-indigo-500 py-4">
                {dataItem.blocker.textMessage}
              </div>
            )
          }
        ]
  );
  return (
    <>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:pl-2 lg:pr-2">
          <Table
            columns={columns}
            data={dataTable.filter((blocker) => blocker !== null)}
          />
        </div>
      </div>
    </>
  );
};
BlockersTable.propTypes = {
  data: PropTypes.array.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};

export default BlockersTable;
