import React from "react";

import { Teams } from "../components/Teams/Teams";
import Layout from "./Layout/NewLayout/NewLayout";

const TeamsView = () => (
  <Layout>
    <Teams />
  </Layout>
);
export default TeamsView;
