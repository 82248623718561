import PropTypes from "prop-types";

const WhiteButton = (props) => (
  <button
    {...{
      ...props
    }}
    className={`inline-flex flex-1 justify-center rounded-md border shadow-sm px-4 py-2 bg-white-600 text-base font-medium text-gray sm:text-sm ${props.className}`}
  >
    {props.children}
  </button>
);

WhiteButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};
export default WhiteButton;
