import interact from "interactjs";
import PropTypes from "prop-types";
import { useEffect } from "react";

const setPosition = ({ element, x, y }) => {
  element.setAttribute("data-x", x);
  element.setAttribute("data-y", y);
};
const dragMoveListener = (event) => {
  const { target } = event;
  target.classList.add("bg-gray-50");
  const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
  const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
  target.style.transform = `translate(${x}px, ${y}px)`;
  target.style.transform = `translate(${x}px, ${y}px)`;

  setPosition({ element: target, x, y });
};

const DragAndDrop = ({ dragAndDropConfig, ondropSave }) => {
  useEffect(() => {
    interact(`.${dragAndDropConfig.draggableClass}`).draggable({
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          endOnly: true,
          restriction: "parent"
        })
      ],
      autoScroll: true,
      listeners: {
        move: dragMoveListener,
        end: (event) => {
          const { target } = event;
          if (!event.relatedTarget) {
            target.classList.remove("bg-gray-50");
            target.style.transform = `translate(0px, 0px)`;
            target.style.transform = `translate(0px, 0px)`;
            setPosition({ element: target, x: 0, y: 0 });
          }
        }
      }
    });
  }, [dragAndDropConfig.draggableClass]);
  useEffect(() => {
    const events = {
      ondropactivate(event) {
        const draggableElement = event.relatedTarget;
        const dropzoneElement = event.target;
        dropzoneElement.classList.remove("bg-indigo-50");
        draggableElement.classList.remove("bg-indigo-50");
      },
      ondropdeactivate() {},
      ondragenter(event) {
        const dropzoneElement = event.target;
        dropzoneElement.classList.add("bg-indigo-50");
      },
      ondragleave(event) {
        const dropzoneElement = event.target;
        dropzoneElement.classList.remove("bg-indigo-50");
      },
      ondrop(event) {
        const draggableElement = event.relatedTarget;
        const dropzoneElement = event.target;
        const zoneMovesAttributes = dropzoneElement?.attributes || {};
        const elementMovesAttributes = draggableElement?.attributes || {};
        dropzoneElement.classList.remove("bg-indigo-50");
        draggableElement.classList.remove("bg-indigo-50");

        ondropSave({ elementMovesAttributes, zoneMovesAttributes });
      }
    };
    interact(`.${dragAndDropConfig.dropzoneClass}`).dropzone({
      accept: `.${dragAndDropConfig.draggableClass}`,
      overlap: 0.75,
      ...events
    });
  }, [
    dragAndDropConfig.draggableClass,
    dragAndDropConfig.dropzoneClass,
    ondropSave
  ]);
  return null;
};

DragAndDrop.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  query: PropTypes.object,
  checkInId: PropTypes.string
};
export default DragAndDrop;
