import { useContext } from "react";

import { organizationRoles, teamRoles } from "../graphql/enum";
import { UserContext } from "./context/User";

export const UserOrganizationOwnerRequired = ({ children }) => {
  const { user } = useContext(UserContext);
  if (user.organization.role === organizationRoles.owner) return children;

  return null;
};
export const UserAdminRequired = ({ children }) => {
  const { user } = useContext(UserContext);
  if (
    user.organization.role === organizationRoles.owner ||
    user.organization.role === organizationRoles.manager ||
    user.organization.role === organizationRoles.admin
  )
    return children;

  return null;
};
export const UserOrganizationAdminOrOwnerRequired = ({ children }) => {
  const { user } = useContext(UserContext);
  if (
    user.organization.role === organizationRoles.owner ||
    user.organization.role === organizationRoles.admin
  )
    return children;

  return null;
};
export const UserTeamManagerRequired = ({ children, userTeamRole }) => {
  const { user } = useContext(UserContext);
  if (
    user.organization.role === organizationRoles.owner ||
    user.organization.role === organizationRoles.manager ||
    user.organization.role === organizationRoles.admin ||
    userTeamRole === teamRoles.manager
  )
    return children;

  return null;
};
export const UserTeamRequired = ({ children, usersTeam }) => {
  const { user } = useContext(UserContext);
  const userTeam = usersTeam.find(({ id: userId }) => userId === user.id);
  if (userTeam) return children;

  return null;
};
export const UserTeamNotManagerRequired = ({ children, userTeamRole }) => {
  const { user } = useContext(UserContext);
  if (
    user.organization.role !== organizationRoles.owner &&
    user.organization.role !== organizationRoles.manager &&
    user.organization.role !== organizationRoles.admin &&
    userTeamRole === teamRoles.user
  )
    return children;

  return null;
};
export const ResourceOrUserStandupOwnerRequired = ({
  children,
  resource,
  ownerId,
  disableResourceCheck = false,
  resourceCategory
}) => {
  const { user } = useContext(UserContext);

  if (disableResourceCheck) {
    if (ownerId === user.id) return children;

    return null;
  }

  if (
    user.resources[resourceCategory].includes(resource) ||
    ownerId === user.id
  )
    return children;
  return null;
};

export const ResourceRequired = ({ children, resource, resourceCategory }) => {
  const { user } = useContext(UserContext);
  if (user.resources[resourceCategory].includes(resource)) return children;

  return null;
};
