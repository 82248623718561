/* eslint-disable max-statements */
import Papa from "papaparse";
import PropTypes from "prop-types";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import Button from "../../../../layout/Button";
import UploadOrDrag from "../../../../layout/UploadOrDrag/UploadOrDrag";

const ImportTaskModal = ({
  visible,
  onClose,
  lastId,
  setLastId,
  setFieldValue,
  questions,
  setQuestions,
  alert
}) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const maxSizeByte = 10485760;
  const dropzoneConfig = {
    accept: "text/csv",
    maxSize: maxSizeByte,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles[0]) return;
      setFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0])
        })
      );
    }
  };
  const uploadFile = () => {
    const reader = new FileReader();

    reader.onerror = () => {
      alert.error(
        <div>
          <p className="text-black">{t("alert.error")}</p>
          <p className="text-gray-500">{t("alert.errorOccurred")}</p>
        </div>
      );
    };
    reader.onload = () => {
      const binaryStr = reader.result;
      const { data } = Papa.parse(binaryStr, {
        delimiter: ",",
        newline: ""
      });
      const lastQuestionId = lastId < 2 ? 2 : lastId;
      const newQuestions = data.filter((question) => question.length === 2);
      const addQuestion = newQuestions.map((prev, index) => ({
        id: lastQuestionId + index
      }));
      setQuestions([...questions, ...addQuestion]);
      setLastId(lastId + addQuestion.length);
      addQuestion.map(async (question, index) => {
        setFieldValue(`checkInLink[${question.id}]`, newQuestions[index][0]);
        setFieldValue(`checkInTitle[${question.id}]`, newQuestions[index][1]);
      });

      if (newQuestions.length < 1) {
        alert.error(
          <div>
            <p className="text-black">{t("alert.error")}</p>
            <p className="text-gray-500">{t("alert.incorrectDataFormat")}</p>
          </div>
        );
        return null;
      }

      alert.success(
        <div>
          <p className="text-black">{t("alert.successfully")}</p>
          <p className="text-gray-500">{t("alert.successfullyUpload")}</p>
        </div>
      );
      onClose();
      setFile();
      return null;
    };
    reader.readAsText(file);
  };
  const buttonImport = () => (
    <Button layout="primary" disabled={!file} onClick={() => uploadFile()}>
      {t("planningPokerImportTaskModal.import")}
    </Button>
  );
  return (
    <UploadOrDrag
      maxSizeByte={maxSizeByte}
      file={file}
      title={t("planningPokerImportTaskModal.tasksFile")}
      description={t("planningPokerImportTaskModal.formatsUpMB")}
      dropzoneConfig={dropzoneConfig}
      button={buttonImport}
      visible={visible}
      onClose={onClose}
    />
  );
};
ImportTaskModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lastId: PropTypes.number.isRequired,
  setLastId: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    success: PropTypes.func.isRequired,
    error: PropTypes.func.isRequired
  })
};
export default withAlert()(ImportTaskModal);
