import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import {
  bitrixAuthorizeUrl,
  GITHUB_AUTHORIZE_URL,
  JIRA_AUTHORIZE_URL
} from "./config";
import {
  approveEmailVerification,
  approveInviteOrganization,
  approveInvitePeople,
  approveInviteTeam,
  approveOrganizationInviteLink,
  approveTeamInviteLink,
  botIsNotIstalledLink,
  checkInsUrl,
  emailNotVerificationUrl,
  getCheckInUrl,
  getTeamUrl,
  gitHubAuthorizeUrl,
  gitHubOauthCallbackUrl,
  homeUrl,
  jiraAuthorizeUrl,
  jiraOauthCallbackUrl,
  loginUrl,
  organisationUrl,
  peopleUrl,
  privacyPolicyUrl,
  profileUrl,
  resetPasswordRequestUrl,
  resetPasswordUrl,
  signupUrl,
  teamsUrl,
  termsOfServiceUrl
} from "./routes";
import {
  ApproveInviteRoute,
  AuthorizedAppRoute,
  NotAuthorizedAppRoute,
  NotVerificationEmailAppRoute,
  PublicAppRoute
} from "./routes/AppRoutes/AppRoutes";
import views from "./views";

const {
  ApproveOrganizationInviteLink,
  ApproveOrganizationInvite,
  BotNotInstalled,
  CreateOrganizationAccount,
  GitHubOauthCallback,
  Home,
  JiraOauthCallback,
  Login,
  People,
  PrivacyPolicy,
  Profile,
  Teams,
  TeamSingleView,
  TermsOfService,
  ApproveTeamInvite,
  ApprovePeopleInvite,
  ApproveTeamInviteLink,
  CheckInSingle,
  VerificationEmail,
  ApproveEmailVerification,
  ResetPasswordRequest,
  ResetPassword,
  CheckIns,
  Organisation
} = views;

const AppRouter = () => (
  <Router>
    <Switch>
      <>
        {/* AUTHORIZED USER ROUTES */}
        <AuthorizedAppRoute exact path={homeUrl} component={Home} />
        <AuthorizedAppRoute
          exact
          path={getCheckInUrl(":checkInId")}
          component={CheckInSingle}
        />
        <AuthorizedAppRoute
          exact
          path={getTeamUrl(":teamId")}
          component={TeamSingleView}
        />
        <AuthorizedAppRoute
          exact
          path={jiraAuthorizeUrl}
          component={() => {
            window.location = JIRA_AUTHORIZE_URL;
          }}
        />
        <AuthorizedAppRoute
          exact
          path={gitHubAuthorizeUrl}
          component={() => {
            window.location = GITHUB_AUTHORIZE_URL;
          }}
        />
        <AuthorizedAppRoute exact path={teamsUrl} component={Teams} />
        <AuthorizedAppRoute exact path={checkInsUrl} component={CheckIns} />
        <AuthorizedAppRoute
          exact
          path={bitrixAuthorizeUrl}
          component={() => {
            window.location = bitrixAuthorizeUrl;
          }}
        />
        <AuthorizedAppRoute
          exact
          path={jiraOauthCallbackUrl}
          component={JiraOauthCallback}
        />
        <AuthorizedAppRoute
          exact
          path={gitHubOauthCallbackUrl}
          component={GitHubOauthCallback}
        />
        <AuthorizedAppRoute exact path={profileUrl} component={Profile} />
        <AuthorizedAppRoute exact path={peopleUrl} component={People} />
        <AuthorizedAppRoute
          exact
          path={organisationUrl}
          component={Organisation}
        />
        <NotVerificationEmailAppRoute
          exact
          path={emailNotVerificationUrl}
          component={VerificationEmail}
        />
        <ApproveInviteRoute
          exact
          path={approveInviteTeam}
          component={ApproveTeamInvite}
        />
        <ApproveInviteRoute
          exact
          path={approveInvitePeople}
          component={ApprovePeopleInvite}
        />
        <PublicAppRoute
          exact
          path={approveEmailVerification}
          component={ApproveEmailVerification}
        />
        <PublicAppRoute
          exact
          path={approveInviteOrganization}
          component={ApproveOrganizationInvite}
        />
        <PublicAppRoute
          exact
          path={botIsNotIstalledLink}
          component={BotNotInstalled}
        />

        {/* NOT AUTHORIZED USER ROUTES */}
        <NotAuthorizedAppRoute exact path={loginUrl} component={Login} />

        <NotAuthorizedAppRoute
          exact
          path={signupUrl}
          component={CreateOrganizationAccount}
        />
        <NotAuthorizedAppRoute
          exact
          path={resetPasswordUrl}
          component={ResetPassword}
        />
        <NotAuthorizedAppRoute
          exact
          path={resetPasswordRequestUrl}
          component={ResetPasswordRequest}
        />
        <AuthorizedAppRoute
          exact
          path={approveTeamInviteLink}
          component={ApproveTeamInviteLink}
        />
        <AuthorizedAppRoute
          exact
          path={approveOrganizationInviteLink}
          component={ApproveOrganizationInviteLink}
        />
        <NotAuthorizedAppRoute
          exact
          path={termsOfServiceUrl}
          component={TermsOfService}
        />
        <NotAuthorizedAppRoute
          exact
          path={privacyPolicyUrl}
          component={PrivacyPolicy}
        />
      </>
    </Switch>
  </Router>
);

export default AppRouter;
