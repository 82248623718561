import PropTypes from "prop-types";

import { questionTypesEnum } from "../../../../../graphql/enum";
import RadioGroup from "../../../../layout/RadioGroup";

const QuestionsRadioGroup = ({
  questions,
  currentQuestion,
  setCurrentQuestion
}) => {
  const taskListQuestions = questions.filter(
    (q) => questionTypesEnum.tasksList === q.type
  );
  const options = taskListQuestions.map(({ id, title }) => ({
    id,
    name: title
  }));

  return (
    <RadioGroup
      layout="list"
      options={options}
      selected={currentQuestion.id}
      onChange={(id) => {
        const newQuestion = questions.find((q) => q.id === id);
        setCurrentQuestion(newQuestion);
      }}
    />
  );
};
QuestionsRadioGroup.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  currentQuestion: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
  }),
  setCurrentQuestion: PropTypes.func.isRequired
};
export default QuestionsRadioGroup;
