import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import updateTeam from "../../../../graphql/mutation/updateTeam";
import Button from "../../../layout/Button";
import DeleteTeamModal from "./DeleteTeamModal/DeleteTeamModal";

const ActionTeam = ({ team, query, alert }) => {
  const { t } = useTranslation();
  const [updateTeamMutation] = useMutation(updateTeam);
  const [isDeleteTeamModalVisible, setIsDeleteTeamModalVisible] =
    useState(false);
  const onActivateOrDeactivate = async (teamIsActive) => {
    const isActive = !teamIsActive;
    const { data } = await updateTeamMutation({
      variables: {
        teamId: team.id,
        input: { isActive }
      },
      optimisticResponse: true,
      update: async (cache) => {
        const dataQuery = await cache.readQuery({
          query,
          variables: {
            teamId: team.id
          }
        });
        const newData = {
          team: {
            ...dataQuery,
            isActive
          }
        };
        cache.writeQuery({
          data: newData,
          query,
          variables: {
            teamId: team.id
          }
        });
      }
    });
    if (data.updateTeam.success) {
      alert.success(
        <div>
          <p className="text-black">{t("teamSettings.successSaved")}</p>
          <p className="text-gray-500">
            {isActive
              ? t("teamSettings.successActivated")
              : t("teamSettings.successDeactivated")}
          </p>
        </div>
      );
    }
  };
  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {t("teamSettings.menageTeam")}
      </h3>
      <div className="mt-5 grid grid-cols-3 gap-4">
        <div className="mt-2 col-span-2 text-sm text-gray-500">
          <p>{t("teamSettings.deactivateTeamDescription")}</p>
        </div>
        <div className="mt-2 grid justify-items-end">
          <Button
            layout="white"
            onClick={async () => onActivateOrDeactivate(team.isActive)}
          >
            {team.isActive
              ? t("teamSettings.deactivate")
              : t("teamSettings.activate")}
          </Button>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-3 gap-4">
        <div className="mt-1 col-span-2 text-sm text-gray-500">
          <p>
            {t("teamSettings.deleteTeamDescription")}{" "}
            <span className="font-bold">
              {t("teamSettings.deleteTeamDescriptionStrong")}
            </span>
          </p>
        </div>
        <div className="mt-1 grid justify-items-end">
          <Button
            layout="red"
            onClick={() => setIsDeleteTeamModalVisible(true)}
          >
            {t("teamSettings.deleteTeam")}
          </Button>
        </div>
      </div>
      <DeleteTeamModal
        visible={isDeleteTeamModalVisible}
        onClose={() => setIsDeleteTeamModalVisible(false)}
        team={team}
      />
    </>
  );
};
ActionTeam.propTypes = {
  query: PropTypes.any,
  team: PropTypes.exact({
    id: PropTypes.string,
    title: PropTypes.string,
    isActive: PropTypes.bool
  }),
  alert: PropTypes.object
};
export default ActionTeam;
