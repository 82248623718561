import PropTypes from "prop-types";

import { classNames } from "../../../utility";
import CapitalLetterAvatar from "../CapitalLetterAvatar";

const Avatar = ({ src, name, width, height, className, alt }) =>
  src ? (
    <img
      {...{
        className: classNames(
          "relative inline-block rounded-full ring-2 ring-white",
          !width ? "w-8" : "",
          !height ? "h-8" : "",
          className
        ),
        src,
        alt,
        width,
        height
      }}
    />
  ) : (
    <CapitalLetterAvatar {...{ letter: name[0], width, height, className }} />
  );

Avatar.defaultProps = {
  alt: ""
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};

export default Avatar;
