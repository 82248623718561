import { gql } from "@apollo/client";

const approvePeopleInvite = gql`
  mutation approvePeopleInvite($code: String!) {
    approvePeopleInvite(code: $code) {
      success
      code
      jwtToken
    }
  }
`;

export default approvePeopleInvite;
