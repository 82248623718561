import React from "react";

import Organisation from "../components/Organisation";
import Layout from "./Layout/NewLayout/NewLayout";

const OrganisationView = () => (
  <Layout>
    <Organisation />
  </Layout>
);

export default OrganisationView;
