import { gql, useQuery } from "@apollo/client";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { getTeamUrl } from "../../routes";
import { groupBy } from "../../utility";
import { UserContext } from "../../utility/context/User";
import Link from "../layout/Link";
import Loading from "../layout/Loading/Loading";
import Page from "../layout/Page";
import Avatars from "../Teams/Avatars/Avatars";
import CheckInBlock from "./CheckInBlock/CheckInBlock";

export const checkInsQuery = gql`
  query checkInsQuery {
    currentUser {
      id
      checkIns {
        id
        type
        teamId
        teamRole
        activeRate
        blockerCount
        inProgressTasks
        doneTasks
        lastRun
        isWarning
        team {
          title
          isWarning
          users {
            id
            email
            name
            avatar
          }
        }
        title
        schedule
        status
        startTime
        isSetup
        isActive
        users {
          id
        }
      }
      organizations {
        organization {
          holidays {
            date
          }
        }
      }
    }
  }
`;
export const CheckIns = () => {
  const { loading, data } = useQuery(checkInsQuery);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  if (loading) return <Loading />;
  const teams = groupBy(data.currentUser.checkIns, "teamId");
  const teamsIds = Object.keys(teams);
  return (
    <Page pageName={t("checkIns.checkIns")}>
      <div className="-mx-8">
        {teamsIds.map((teamId) => (
          <div className="mb-5" key={teamId}>
            <div className="flex">
              {teams[teamId][0].team.isWarning ? (
                <div className="mr-2">
                  <ExclamationCircleIcon className="w-6 text-yellow-400" />
                </div>
              ) : null}
              <Link to={getTeamUrl(teamId)}>
                <span className="text-gray-900 font-semibold text-lg">
                  {teams[teamId][0].team.title}
                </span>
              </Link>
              <div className="ml-2 h-6">
                <Avatars
                  users={teams[teamId][0].team.users}
                  width="24px"
                  height="24px"
                  className="h-6"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 content-start mt-4">
              {teams[teamId].map((checkIn, idx) => (
                <CheckInBlock
                  {...{
                    checkIn,
                    holidays:
                      data.currentUser.organizations.organization.holidays,
                    userTimeZone: user.timeZone,
                    userTeamRole: teams[teamId][0].teamRole,
                    key: idx
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Page>
  );
};
