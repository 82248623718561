/* eslint-disable max-statements */
import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { checkInTypes } from "../../../graphql/enum";
import { getCheckInUrl } from "../../../routes";
import { getNextRunDate } from "../../../utility";
import {
  UserTeamManagerRequired,
  UserTeamNotManagerRequired
} from "../../../utility/hocs";
import CheckInBlockDetails from "./CheckInBlockDetails/CheckInBlockDetails";

const CheckInBlock = ({ checkIn, holidays, userTimeZone, userTeamRole }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    id: checkInId,
    type,
    isSetup,
    startTime,
    schedule,
    lastRun
  } = checkIn;
  const checkInUrl = getCheckInUrl(checkInId);

  const getTabs = () => {
    if (!isSetup)
      return [
        {
          title: t("Setup"),
          onClick: () => history.push(`${checkInUrl}`)
        }
      ];
    if (type === checkInTypes.standup)
      return [
        {
          title: t("Dashboard"),
          onClick: () => history.push(`${checkInUrl}?activeTab=dashboard`)
        },
        {
          title: t("Responses"),
          onClick: () => history.push(`${checkInUrl}?activeTab=responses`)
        },
        {
          title: t("Questions"),
          onClick: () => history.push(`${checkInUrl}?activeTab=questions`)
        },
        {
          title: t("Settings"),
          onClick: () => history.push(`${checkInUrl}?activeTab=settings`)
        },
        {
          title: t("Notifications"),
          onClick: () => history.push(`${checkInUrl}?activeTab=notifications`)
        }
      ];
    if (type === checkInTypes.planningPoker) {
      const tabsUser = [
        {
          title: t("Dashboard"),
          onClick: () => history.push(`${checkInUrl}?activeTab=dashboard`)
        }
      ];
      const tabsManager = [
        {
          title: t("Start"),
          onClick: () => history.push(`${checkInUrl}?activeTab=start`)
        },
        {
          title: t("Dashboard"),
          onClick: () => history.push(`${checkInUrl}?activeTab=dashboard`)
        },
        {
          title: t("Estimates"),
          onClick: () => history.push(`${checkInUrl}?activeTab=estimates`)
        },
        {
          title: t("Settings"),
          onClick: () => history.push(`${checkInUrl}?activeTab=settings`)
        }
      ];
      const tabsNotManagerRequired = UserTeamNotManagerRequired({
        children: tabsManager,
        userTeamRole
      });
      if (tabsNotManagerRequired !== null) return tabsNotManagerRequired;
      const tabsManagerRequired = UserTeamManagerRequired({
        children: tabsManager,
        userTeamRole
      });
      if (tabsManagerRequired !== null) return tabsManagerRequired;
      return tabsUser;
    }
    if (type === checkInTypes.dayOff)
      return [
        {
          title: t("Dashboard"),
          onClick: () => history.push(`${checkInUrl}?activeTab=dashboard`)
        }
      ];
    return [];
  };
  const getNextRunOn = () => {
    if (type === checkInTypes.standup)
      return `${t("checkIns.nextRunOn")} ${getNextRunDate(
        startTime,
        schedule,
        holidays,
        userTimeZone
      )}`;
    return "";
  };
  const getStarted = () => {
    if (lastRun) {
      const lastRunTimeZone = momentTimeZone(lastRun)
        .tz(userTimeZone)
        .format("ddd, hh:mm A");
      return `${t("checkIns.started")} ${lastRunTimeZone}`;
    }
    return "";
  };
  const tabs = getTabs();
  const nextRun = getNextRunOn();
  const started = getStarted();
  return (
    <CheckInBlockDetails
      {...{
        checkIn,
        nextRun,
        started,
        tabs
      }}
    />
  );
};
CheckInBlock.propTypes = {
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isSetup: PropTypes.bool.isRequired,
    startTime: PropTypes.string.isRequired,
    schedule: PropTypes.array.isRequired,
    lastRun: PropTypes.object,
    isWarning: PropTypes.bool.isRequired,
    activeRate: PropTypes.number,
    blockerCount: PropTypes.number,
    inProgressTasks: PropTypes.number,
    doneTasks: PropTypes.number,
    isActive: PropTypes.bool.isRequired
  }),
  holidays: PropTypes.array,
  userTimeZone: PropTypes.string.isRequired,
  userTeamRole: PropTypes.string.isRequired
};
export default CheckInBlock;
