import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "../../../layout/Button";
import GreenSuccess from "../../../layout/icons/GreenSuccess/GreenSuccess";

const SetupCheckInSuccess = ({ title, description, onViewCheckIn }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg p-6">
      <div className="flex flex-col justify-center items-center w-6/12 mx-auto">
        <GreenSuccess />
        <p className="text-lg font-medium text-center mt-5">{title}</p>
        <p className="text-gray-500 text-sm text-center mt-3">{description}</p>
      </div>
      <div className="mt-20 flex justify-center">
        <div className="mr-8">
          <Button layout="white" onClick={onViewCheckIn}>
            {t("setupCheckIn.viewCheckIn")}
          </Button>
        </div>
      </div>
    </div>
  );
};
SetupCheckInSuccess.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onViewCheckIn: PropTypes.func
};

export default SetupCheckInSuccess;
