/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Typography } from "antd";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Loading from "../components/layout/Loading/Loading";
import { approveInviteMutationCode } from "../graphql/enum";
import approvePeopleInviteMutation from "../graphql/mutation/approvePeopleInvite";
import { teamsUrl } from "../routes";
import { UserContext } from "../utility/context/User";
import Layout from "./Layout/NewLayout/NewLayout";

const ApprovePeopleInviteView = ({ location }) => {
  const { Title } = Typography;
  const { code } = queryString.parse(location.search);

  const [approvePeopleInvite, { loading, data }] = useMutation(
    approvePeopleInviteMutation
  );

  const { login } = useContext(UserContext);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    approvePeopleInvite({ variables: { code } });
  }, [code, approvePeopleInvite]);
  if (loading) return <Loading />;

  if (data) {
    const { success, code: responseCode } = data.approvePeopleInvite;
    if (responseCode === approveInviteMutationCode.tokenExpired) {
      return (
        <Layout>
          <Title type="danger">{t("approveInvite.tokenExpired")} </Title>
        </Layout>
      );
    }
    if (responseCode === approveInviteMutationCode.alreadyApproved) {
      return (
        <Layout>
          <Title type="danger">{t("approveInvite.tokenIsApproved")} </Title>
        </Layout>
      );
    }
    if (success) {
      const { jwtToken } = data.approvePeopleInvite;
      login(jwtToken);
      history.push(teamsUrl);
    }
  }
  return null;
};

ApprovePeopleInviteView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  })
};

export default ApprovePeopleInviteView;
