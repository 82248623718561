import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import sendTeamInvites from "../../../graphql/mutation/sendTeamInvites";
import Button from "../../layout/Button";
import Modal from "../../layout/Modal";
import InviteTeam from "../InviteTeam/InviteTeam";

const InviteTeamModal = ({
  visible,
  onClose,
  users,
  teamId,
  refetch,
  team
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sendTeamInvitesMutation] = useMutation(sendTeamInvites);

  const { t } = useTranslation();

  const onSendInvites = async () => {
    const invitedUsers = selectedUsers.map(({ user, role: userRole }) => ({
      userId: user.id,
      role: userRole.id
    }));
    await sendTeamInvitesMutation({
      variables: {
        users: invitedUsers,
        teamId
      }
    });
    await refetch();
    await onClose();
    setSelectedUsers([]);
  };

  return (
    <Modal visible={visible} onClose={onClose}>
      <InviteTeam
        {...{
          selectedUsers,
          setSelectedUsers,
          users,
          teamId,
          teamTitle: team.title
        }}
      />
      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end">
          <div className="flex">
            <Button layout="white" className="mr-5" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              form="createTeamModal"
              onClick={onSendInvites}
              disabled={selectedUsers.length === 0}
            >
              {t("inviteTeamModal.sendInvites")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
InviteTeamModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  users: PropTypes.array,
  teamId: PropTypes.string,
  refetch: PropTypes.func,
  team: PropTypes.shape({
    title: PropTypes.string
  })
};
export default InviteTeamModal;
