import { PlusIcon } from "@heroicons/react/solid";
import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { botInstallUrl } from "../../../../../../config";
import Link from "../../../../../layout/Link";
import TimePicker from "../../../../../layout/TimePicker/TimePicker";
import UsersTimeZone from "../../../../../layout/UsersTimeZone/UsersTimeZone";
import SummaryRoomRadioGroup from "./SummaryRoomRadioGroup";

const SummaryZone = ({
  checkInData,
  setCheckInData,
  errors,
  usersTeam,
  currentUser
}) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg mt-6	p-6">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">
            {t("summaryZone.summaryReport")}
          </p>
          <p className="text-gray-500 text-sm">
            {t("summaryZone.description")}
          </p>
        </div>
        <div className="w-2/3">
          <div className="flex">
            <div className="w-1/2">
              <TimePicker
                currentValue={checkInData.summaryTime}
                startValue={checkInData.startTime}
                error={errors?.summaryTime}
                onChange={(val) =>
                  setCheckInData({ ...checkInData, summaryTime: val })
                }
                label={t("summaryZone.summaryTime")}
              />
              <span className="text-gray-500">
                {`${t("scheduleZone.yourTimezone")} ${currentUser.timeZone} (
              ${momentTimeZone().tz(currentUser.timeZone).format("Z")})`}
              </span>
            </div>
            <div className="w-1/2 pl-5">
              <UsersTimeZone
                users={usersTeam}
                time={checkInData.summaryTime}
                currentUser={currentUser}
              />
            </div>
          </div>
          <div className="mt-8">
            <SummaryRoomRadioGroup
              checkInData={checkInData}
              setCheckInData={setCheckInData}
              errors={errors}
            />
            <div className="mt-3">
              <Link
                className="flex"
                to={{ pathname: botInstallUrl }}
                target="_blank"
              >
                <PlusIcon className="w-4 mr-2" />
                {t("planningPokerSetup.addBotToSpace")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
SummaryZone.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  errors: PropTypes.object,
  usersTeam: PropTypes.array,
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    timeZone: PropTypes.string,
    avatar: PropTypes.string
  })
};
export default SummaryZone;
