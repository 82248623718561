/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";

const LinkView = (props) => (
  <Link
    {...{
      ...props,
      className: `text-indigo-600 hover:text-indigo-900 ${props.className}`
    }}
  >
    {props.children}
  </Link>
);
export default LinkView;
