/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import { UserGroupIcon } from "@heroicons/react/outline";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { teamRoles } from "../../../graphql/enum";
import sendPeopleInvites from "../../../graphql/mutation/sendPeopleInvites";
import Badge from "../../layout/Badge";
import Button from "../../layout/Button";
import Hint from "../../layout/Hint";
import ListOfUsers from "../../layout/ListOfUsers/ListOfUsers";
import Modal from "../../layout/Modal";
import { getAllUserOrganizationQuery } from "../PeopleTable/PeopleTable";
import InvitePeopleButton from "./InvitePeopleButton/InvitePeopleButton";
import InviteUsersForm from "./InvitePeopleForm/InvitePeopleForm";

const InvitePeopleModal = ({ visible, onClose, organization, refetch }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sendPeopleInvitesMutation] = useMutation(sendPeopleInvites);

  const { t } = useTranslation();

  const onSendInvites = async () => {
    const invitedUsers = selectedUsers.map(({ user }) => ({
      userId: user.id,
      teams: user.teams.map((team) => ({
        teamId: team.id,
        role: teamRoles.user
      })),
      organizationRole: user.role.id
    }));

    await sendPeopleInvitesMutation({
      variables: {
        users: invitedUsers,
        organizationId: organization.id
      },
      refetchQueries: [getAllUserOrganizationQuery]
    });
    refetch();
    await onClose();
    setSelectedUsers([]);
  };
  const roleOrganization = (role) => (
    <Badge color="purple" className="ml-1">
      {t(`${role.id}`)}
    </Badge>
  );
  if (!visible) return null;

  return (
    <Modal visible={visible} onClose={onClose}>
      <div style={{ width: "910px" }}>
        <div>
          <div className="mx-auto flex items-center justify-center">
            <UserGroupIcon
              className="h-12 w-12 text-sm text-gray-400 font-thin stroke-1"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-lg w- leading-6 font-medium text-gray-900"
            >
              {t("invitePeopleModal.inviteMembers")}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500 w-5/12 mx-auto">
                {t("invitePeopleModal.inviteMembersDescribe")}
              </p>
            </div>
            <div>
              <InviteUsersForm
                {...{
                  selectedUsers,
                  setSelectedUsers,
                  teams: organization.teams
                }}
              />
            </div>
          </div>
        </div>
        {selectedUsers.length !== 0 ? (
          <div>
            <div className="text-left uppercase text-gray-500 font-semibold mt-3">{`${t(
              "invitePeopleModal.userAddedOrganization"
            )} ${organization.title}`}</div>
            <ListOfUsers
              users={selectedUsers.map((selectedUser) => ({
                ...selectedUser,
                ...{
                  user: {
                    ...selectedUser.user,
                    addonafter: roleOrganization(selectedUser.user.role)
                  }
                }
              }))}
              onClose={(id) => {
                const newSelectedUsers = selectedUsers.filter(
                  ({ user }) => user.id !== id
                );

                setSelectedUsers(newSelectedUsers);
              }}
            />
          </div>
        ) : null}
        <div className="mt-5 sm:mt-6">
          <div className="flex">
            <InvitePeopleButton organizationId={organization.id} />
            <div className="flex-grow">
              <div className="flex justify-end text-gray-400">
                <Hint
                  hint={t("invitePeopleModal.invitationLinksDescription")}
                  style={{ bottom: "35px" }}
                >
                  <div className="flex cursor-pointer">
                    <div>
                      <QuestionMarkCircleIcon
                        className="h-4 w-4 text-sm font-thin stroke-1"
                        aria-hidden="true"
                      />
                    </div>
                    <div>
                      <span className="ml-1 text-sm w-10/12 mx-auto">
                        {t("invitePeopleModal.learnMoreAboutSharing")}
                      </span>
                    </div>
                  </div>
                </Hint>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <div className="flex justify-end">
            <div className="flex">
              <Button layout="white" className="mr-5" onClick={onClose}>
                {t("Cancel")}
              </Button>
              <Button
                onClick={onSendInvites}
                disabled={selectedUsers.length === 0}
              >
                {t("invitePeopleModal.sendInvites")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
InvitePeopleModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    ownerId: PropTypes.string,
    teams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string
      })
    ),
    users: PropTypes.shape({
      count: PropTypes.object,
      data: PropTypes.array
    })
  })
};
export default InvitePeopleModal;
