import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { integrationTypes } from "../../../../../../graphql/enum";
import Button from "../../../../../layout/Button";
import ConnectTaskTrackerBlock from "./ConnectTaskTrackerBlock/ConnectTaskTrackerBlock";

const ConnectTaskTrackerSection = ({
  type,
  setPreviewTaskTracker,
  bitrixDomain,
  checkInId
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ConnectTaskTrackerBlock
        type={type}
        bitrixDomain={bitrixDomain}
        checkInId={checkInId}
      />
      <div className="flex justify-between mt-20">
        <div>
          <Button
            layout="white"
            className="mr-5"
            onClick={() => {
              setPreviewTaskTracker(null);
            }}
          >
            {t("Previous")}
          </Button>
        </div>
        <div>
          <Button layout="primary" type="submit" disabled={true}>
            {t("Next")}
          </Button>
        </div>
      </div>
    </>
  );
};
ConnectTaskTrackerSection.propTypes = {
  type: PropTypes.oneOf(Object.values(integrationTypes)),
  setPreviewTaskTracker: PropTypes.func,
  bitrixDomain: PropTypes.string,
  checkInId: PropTypes.string.isRequired
};
export default ConnectTaskTrackerSection;
