/* eslint-disable react/prop-types */
import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import FallbackSpinner from "../../components/layout/Loading/Loading";
import { setCurrentUser } from "../errors";
import { setJWT } from "../localStorage";

export const CURRENT_USER = gql`
  {
    currentUser {
      id
      email
      name
      avatar
      isBotAdded
      status
      timeZone
      resources {
        manageStandup
        manageOrganizationStandup
        manageOrganization
        manageOrganizationUsers
        manageOrganizationTeams
      }
      role {
        id
        name
      }
      organizations {
        role
        organization {
          id
          title
          ownerId
          users {
            data {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const UserContext = React.createContext({});
export const UserContextProvider = ({ children }) => {
  const { loading, error, data, refetch } = useQuery(CURRENT_USER, {
    errorPolicy: "ignore"
  });
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (!loading) {
      const currentUser =
        !error && data && data.currentUser
          ? {
              id: data.currentUser.id,
              email: data.currentUser.email,
              name: data.currentUser.name,
              status: data.currentUser.status,
              role: data.currentUser.role.name,
              avatar: data.currentUser.avatar,
              isBotAdded: data.currentUser.isBotAdded,
              resources: data.currentUser.resources,
              timeZone: data.currentUser.timeZone,
              organization: data.currentUser.organizations
                ? {
                    role: data.currentUser.organizations.role,
                    ...data.currentUser.organizations.organization
                  }
                : null
            }
          : false;
      setUser(currentUser);
    }
  }, [error, data, loading]);

  if (loading || user === null) return <FallbackSpinner />;

  setCurrentUser(user);
  const login = (jwt) => {
    setJWT(jwt);
    refetch();
  };

  const logout = () => {
    setJWT(null);
    refetch();
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
