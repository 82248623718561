import { ChevronRightIcon, HomeIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { homeUrl } from "../../../routes";

const Breadcrumbs = ({ items }) => (
  <div className="flex text-gray-400 max-w-7xl mx-auto pb-4">
    <div className=" flex items-center">
      <Link to={homeUrl}>
        <HomeIcon className="w-5 h-5" />
      </Link>
    </div>
    {items?.length > 0
      ? items.map((item) => (
          <div key={item.id} className=" flex items-center">
            <ChevronRightIcon className="w-5 h-5 mr-5 ml-5" />
            <Link to={item.link}>
              <span className="font-medium text-sm">{item.title}</span>
            </Link>
          </div>
        ))
      : null}
  </div>
);

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      title: PropTypes.string,
      link: PropTypes.string
    })
  ).isRequired
};

export default Breadcrumbs;
