import { gql } from "@apollo/client";

const updateJiraIntegration = gql`
  mutation updateJiraIntegration($integrationId: ID!, $cloudId: String) {
    updateJiraIntegration(integrationId: $integrationId, cloudId: $cloudId) {
      success
      code
    }
  }
`;

export default updateJiraIntegration;
