/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { organizationRoles, statusUserEnum } from "../../../graphql/enum";
import resendTeamInvite from "../../../graphql/mutation/resendTeamInvite";
import sendTeamInvites from "../../../graphql/mutation/sendTeamInvites";
import Avatar from "../../layout/Avatar";
import Button from "../../layout/Button";
import CheckSuccess from "../../layout/icons/CheckSuccess/CheckSuccess";
import Failed from "../../layout/icons/Failed/Failed";
import StatusLabel from "../../layout/StatusLabel/StatusLabel";
import Table from "../../layout/Table/Table";
import EditTeamUserModal from "../EditTeamUserModal";
import InviteTeam from "../InviteTeam/InviteTeam";

const TeamUserTable = ({
  users,
  refetch,
  teamId,
  teamTitle,
  query,
  checkIns
}) => {
  const { t } = useTranslation();
  const [selectedInviteUsers, setSelectedInviteUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [resendTeamInviteMutation] = useMutation(resendTeamInvite);
  const [isEditTeamUserModalVisible, setIsEditTeamUserModalVisible] =
    useState(false);
  const [sendTeamInvitesMutation] = useMutation(sendTeamInvites);

  const onSendInvites = async () => {
    const invitedUsers = selectedInviteUsers.map(
      ({ user, role: userRole }) => ({
        userId: user.id,
        role: userRole.id
      })
    );
    await sendTeamInvitesMutation({
      variables: {
        users: invitedUsers,
        teamId
      }
    });
    await refetch();
    setSelectedInviteUsers([]);
  };
  const columns = [
    {
      title: t("teamUserTable.name"),
      isVisible: true
    },
    {
      title: t("teamUserTable.status"),
      isVisible: true
    },
    {
      title: t("teamUserTable.botInstalled"),
      isVisible: true,
      className: "w-3"
    },
    {
      tilte: t("teamUserTable.edit"),
      isVisible: false
    }
  ];
  const data = users.map((user) => [
    {
      id: `${user.id}-name`,
      render: () => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <Avatar src={user.avatar} name={user.name} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {user.name}
              {user.organizationRole !== organizationRoles.user ? (
                <span className="px-2 ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                  {t(user.organizationRole)}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="text-sm text-gray-500">{user.email}</div>
          </div>
        </div>
      )
    },
    {
      id: `${user.id}-status`,
      render: () => (
        <div className="flex">
          <StatusLabel status={user.teamStatus} />
          {user.teamStatus === statusUserEnum.pendingInvitation ? (
            <Button
              className="mt-1 ml-1"
              layout="refresh"
              onClick={() =>
                resendTeamInviteMutation({
                  variables: {
                    userId: user.id,
                    teamId
                  }
                })
              }
            />
          ) : null}
        </div>
      )
    },
    {
      id: `${user.id}-bot`,
      render: () =>
        user.isBotAdded ? (
          <CheckSuccess className="mx-auto" />
        ) : (
          <Failed className="mx-auto" />
        )
    },
    {
      id: `${user.id}-edit`,
      render: () => (
        <span
          className="text-indigo-600 hover:text-indigo-900 text-right cursor-pointer"
          onClick={() => {
            setSelectedUser(user);
            setIsEditTeamUserModalVisible(true);
          }}
        >
          {t("teamUserTable.edit")}
        </span>
      ),
      className: "text-right"
    }
  ]);

  if (users.length < 2) {
    return (
      <>
        <div className="mx-auto  md:w-8/12 sm:w-full mt-8">
          <InviteTeam
            {...{
              selectedUsers: selectedInviteUsers,
              setSelectedUsers: setSelectedInviteUsers,
              users,
              teamId,
              teamTitle
            }}
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <div className="flex justify-center">
            <div className="flex">
              <Button
                type="submit"
                form="createTeamModal"
                onClick={onSendInvites}
                disabled={selectedInviteUsers.length === 0}
              >
                {t("inviteTeam.sendInvites")}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Table {...{ columns, data }} />
      <EditTeamUserModal
        onClose={() => setIsEditTeamUserModalVisible(false)}
        visible={isEditTeamUserModalVisible}
        selectedUser={selectedUser}
        refetch={refetch}
        teamId={teamId}
        query={query}
        checkIns={checkIns}
        users={users}
      />
    </>
  );
};
TeamUserTable.propTypes = {
  users: PropTypes.array,
  query: PropTypes.any,
  teamId: PropTypes.string,
  teamTitle: PropTypes.string,
  checkIns: PropTypes.array,
  refetch: PropTypes.func
};
export default TeamUserTable;
