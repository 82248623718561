import React from "react";

import { CheckInSingle } from "../components/CheckInSingle/CheckInSingle";
import Layout from "./Layout/NewLayout/NewLayout";

const CheckInSingleView = () => (
  <Layout>
    <CheckInSingle />
  </Layout>
);

export default CheckInSingleView;
