/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { dateFormat } from "../../../../../graphql/utils/date";
import { getDefaultValueForDropdown } from "../../../../../utility/config";
import Loading from "../../../../layout/Loading/Loading";
import NoChart from "../../../../layout/NoChart";
import CustomBar from "./CustomBar/CustomBar";
import CustomTooltip from "./CustomTooltip/CustomTooltip";

const getValue = (val, defaultValue = 2) => {
  if (!val.allTasks) {
    return defaultValue;
  }
  return val.allTasks;
};
export const checkInByIdAnalyticQuery = gql`
  query checkInStandupResponsesAnalytic(
    $checkInId: ID!
    $dateFrom: Date!
    $dateTo: Date!
    $userId: ID
  ) {
    checkInStandupResponsesAnalytic(
      checkInId: $checkInId
      dateFrom: $dateFrom
      dateTo: $dateTo
      userId: $userId
    ) {
      user {
        id
        name
        avatar
      }
      date
      doneTasks
      plannedTasks
    }
  }
`;

export const Barchart = ({ filters, checkInId, defaultValue }) => {
  const defaultValueDropdown = getDefaultValueForDropdown("all");
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [barGraphData, setBarGraphData] = useState({});
  const [isInRightHalf, setIsInRightHalf] = useState(false);
  const chartEl = useRef(null);
  const { loading, data } = useQuery(checkInByIdAnalyticQuery, {
    variables: {
      checkInId,
      dateFrom: moment(filters.dateFrom).format(dateFormat),
      dateTo: moment(filters.dateTo).format(dateFormat),
      userId: filters.userId === defaultValueDropdown.id ? null : filters.userId
    }
  });

  if (loading) return <Loading />;

  if (!data?.checkInStandupResponsesAnalytic) return null;
  if (data.checkInStandupResponsesAnalytic.length < 1)
    return (
      <div className="mt-2 mx-10">
        <NoChart />
      </div>
    );

  const dashoardData = data?.checkInStandupResponsesAnalytic.map((i) => ({
    date: moment(i.date).format("ddd MMM D"),
    plannedTasks: i.plannedTasks,
    doneTasks: i.doneTasks,
    allTasks: i.plannedTasks + i.doneTasks,
    name: i.user.name,
    avatar: i.user.avatar
  }));

  let activeBar = null;

  const chechIfIntersect = (e) => {
    if (e.activeTooltipIndex !== activeBar && e.activeTooltipIndex) {
      if (e.chartX > chartEl?.current?.container?.clientWidth / 2) {
        setIsInRightHalf(true);
      } else {
        setIsInRightHalf(false);
      }
    }
    activeBar = e.activeTooltipIndex;
  };
  const separatorBar = (dataResponses) => {
    const newDataResponses = [];
    dataResponses.map((dataResponse, index, array) => {
      const lastElemet = array[index - 1];
      if (index !== 0 && lastElemet?.date !== array[index].date)
        newDataResponses.push({
          date: dataResponse.date,
          allTasks: 1,
          name: ""
        });
      newDataResponses.push(dataResponse);

      return null;
    });
    return newDataResponses;
  };

  const newDashoardData = separatorBar(dashoardData);

  return (
    <ResponsiveContainer height={400}>
      <BarChart
        ref={chartEl}
        onMouseMove={(e) => chechIfIntersect(e)}
        width={500}
        height={400}
        data={newDashoardData}
        margin={{
          top: 20,
          right: 30,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="5" />
        <XAxis dataKey="date" xAxisId={0} hide={true} />
        <XAxis
          dataKey="date"
          allowDuplicatedCategory={false}
          minTickGap={8}
          xAxisId={1}
        />
        <YAxis domain={[0, "dataMax + 3"]} />
        <Tooltip
          position={{
            x:
              barGraphData?.x +
              +barGraphData?.width / 2 -
              (isInRightHalf ? 217 : 24),
            y: barGraphData?.y - 170
          }}
          cursor={{ fill: "transparent" }}
          content={
            <CustomTooltip
              isTooltipActive={isTooltipActive}
              isInRightHalf={isInRightHalf}
            />
          }
        />
        <Bar
          dataKey={(v) => getValue(v, defaultValue)}
          shape={<CustomBar avatarWidth={30} avatarHeight={30} />}
          onMouseEnter={(e) => {
            setIsTooltipActive(true);
            setBarGraphData(e);
          }}
          onMouseLeave={() => {
            setIsTooltipActive(false);
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

Barchart.propTypes = {
  defaultValue: PropTypes.number,
  checkInId: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    userId: PropTypes.string
  })
};
