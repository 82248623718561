import PropTypes from "prop-types";

import GrayPoint from "./GrayPoint";
import GreenPoint from "./GreenPoint";
import PinkPoint from "./PinkPoint";
import RedPoint from "./RedPoint";

const Button = (props) => {
  const { layout } = props;

  if (layout === "gray") return <GrayPoint />;
  if (layout === "red") return <RedPoint />;
  if (layout === "green") return <GreenPoint />;
  if (layout === "pink") return <PinkPoint />;

  return null;
};
Button.propTypes = {
  layout: PropTypes.oneOf(["gray", "red", "green", "pink"]),
  disabled: PropTypes.bool
};
Button.defaultProps = {
  layout: "gray"
};
export default Button;
