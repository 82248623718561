import { Field } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { classNames } from "../../../../../utility";
import Avatars from "../../../../Teams/Avatars/Avatars";

const SetupCheckInRetrospectiveItem = ({
  item,
  saveItem,
  addonBefore,
  classnameavatar,
  classnamefield,
  isDisplayDescription,
  addonAfter,
  classnameitem,
  fieldDisabled
}) => {
  const [title, setTitle] = useState(item?.title);
  const [isEmptyTitle, setIsEmptyTitle] = useState(false);
  const [description, setDescription] = useState(item?.description);
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        "border rounded-lg px-6 py-5  bg-white inline-flex",
        isEmptyTitle ? "border-red-300" : "border-gray-300",
        isDisplayDescription ? "inline-flex" : "flex justify-between",
        classnameitem
      )}
      roomid={item.id}
    >
      {addonBefore}
      {item.users ? (
        <div className={classNames("mt-2 pr-4", classnameavatar)}>
          <Avatars
            users={item.users}
            width="40px"
            height="40px"
            className="h-8 w-8"
          />
        </div>
      ) : null}
      <div className={classnamefield}>
        <Field
          name="title"
          className="bg-white bg-opacity-0	outline-none w-full p-0 border-0 text-gray-900 placeholder-gray-200"
          type="text"
          value={title}
          placeholder={t("checkInRetrospectiveSetup.enterTitle")}
          onChange={(e) => setTitle(e.target.value)}
          disabled={fieldDisabled}
          onBlur={async (e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              if (title) {
                setIsEmptyTitle(false);
                await saveItem({ ...item, title, description });
                return;
              }
              setIsEmptyTitle(true);
            }
          }}
        />
        {isDisplayDescription ? (
          <Field
            name="title"
            type="text"
            value={description}
            disabled={fieldDisabled}
            placeholder={t("checkInRetrospectiveSetup.enterDescription")}
            onChange={(e) => setDescription(e.target.value)}
            className="bg-white bg-opacity-0 outline-none w-full border-0 p-0 text-gray-500 placeholder-gray-200"
            onBlur={async (e) => {
              if (!e.currentTarget.contains(e.relatedTarget)) {
                await saveItem({ ...item, title, description });
              }
            }}
          />
        ) : null}
      </div>
      {addonAfter}
    </div>
  );
};

SetupCheckInRetrospectiveItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    users: PropTypes.array
  }),
  saveItem: PropTypes.func,
  addonBefore: PropTypes.any,
  addonAfter: PropTypes.any,
  classnameavatar: PropTypes.string,
  classnamefield: PropTypes.string,
  isDisplayDescription: PropTypes.bool,
  classnameitem: PropTypes.string,
  fieldDisabled: PropTypes.bool
};
SetupCheckInRetrospectiveItem.defaultProps = {
  isDisplayDescription: true,
  fieldDisabled: false
};
export default SetupCheckInRetrospectiveItem;
