/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { integrationTypes, questionTypesEnum } from "../../../../graphql/enum";
import updateDataSource from "../../../../graphql/mutation/updateDataSource";
import Loading from "../../../layout/Loading/Loading";
import Page from "../../../layout/Page";
import { PreviewTasksTable } from "../../SetupCheckIn/SetupCheckInStandup/TaskLists/PreviewQuestion/PreviewTasksTable/PreviewTasksTable";
import ConnectTaskTrackerBlock from "../../SetupCheckIn/SetupCheckInStandup/TaskTrackers/ConnectTaskTrackerSection/ConnectTaskTrackerBlock/ConnectTaskTrackerBlock";
import QuestionsRadioGroup from "./QuestionsRadioGroup/QuestionsRadioGroup";
import QuestionTabFilter from "./QuestionTabFilter/QuestionTabFilter";

export const checkInByIdQuery = gql`
  query checkInById($checkInId: ID!) {
    checkIn(checkInId: $checkInId) {
      integrationType
      bitrixDomain
      questions {
        data {
          id
          title
          type
          dataSource {
            id
            jql
            createdDaysAgo
            asigneeIds
            reporterIds
            statusIds
            projectIds
            closedDaysAgo
            organizationId
            deadlineDaysBefore
            deadlineDaysAfter
            updatedDaysAgo
            limit
            filter
          }
        }
      }
    }
  }
`;
export const QuestionsTab = ({ checkInId }) => {
  const { loading, data, refetch } = useQuery(checkInByIdQuery, {
    variables: { checkInId }
  });
  const [previewFilter, setPreviewFilter] = useState({});
  const [isValideJQL, setIsValideJQL] = useState(true);
  const [firstQuestionUrl, setFirstQuestionUrl] = useState(null);
  const [updateDataSourceMutation] = useMutation(updateDataSource);

  const [isConnectTracker, setIsConnectTracker] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState({});

  useEffect(() => {
    if (!data) return;
    const defaultCurrentQuestion = data.checkIn.questions.data.find(
      (q) => q.type === questionTypesEnum.tasksList
    );
    if (!currentQuestion?.id) setCurrentQuestion(defaultCurrentQuestion || {});

    if (currentQuestion?.dataSource) {
      const dataSource = currentQuestion?.dataSource;
      setPreviewFilter({
        statusId: dataSource.statusIds[0],
        organizationId: dataSource.organizationId,
        filter: dataSource.filter,
        limit: dataSource.limit,
        updatedDaysAgo: dataSource.updatedDaysAgo,
        jql: dataSource.jql,
        asigneeIds: dataSource.asigneeIds || [],
        reporterIds: dataSource.reporterIds || [],
        createdDaysAgo: dataSource.createdDaysAgo,
        closedDaysAgo: dataSource.closedDaysAgo,
        projectIds: dataSource.projectIds || [],
        statusIds: dataSource.statusIds || []
      });
    }
  }, [currentQuestion?.dataSource, currentQuestion?.id, data]);

  if (loading) return <Loading />;
  if (!data?.checkIn) return null;

  if (!isConnectTracker) {
    return (
      <ConnectTaskTrackerBlock
        type={data.checkIn.integrationType}
        bitrixDomain={data.checkIn.bitrixDomain}
        checkInId={checkInId}
        dataSourceId={currentQuestion?.dataSource.id}
      />
    );
  }
  const dataSource = currentQuestion?.dataSource;

  return (
    <>
      <Page
        sidebar={
          <QuestionsRadioGroup
            currentQuestion={currentQuestion}
            setCurrentQuestion={async (arg) => {
              setCurrentQuestion(arg);
              await refetch();
            }}
            questions={data.checkIn.questions.data}
          />
        }
      >
        {currentQuestion?.dataSource ? (
          <div>
            <QuestionTabFilter
              integrationType={data.checkIn.integrationType}
              dataSourceId={currentQuestion?.dataSource?.id}
              previewFilter={previewFilter}
              setPreviewFilter={setPreviewFilter}
              isValideJQL={isValideJQL}
              setIsValideJQL={setIsValideJQL}
              firstQuestionUrl={firstQuestionUrl}
            />

            <PreviewTasksTable
              setIsConnectTracker={setIsConnectTracker}
              dataSourceId={dataSource?.id}
              isDisplayButtonSave={
                data.checkIn.integrationType !== integrationTypes.bitrix
              }
              setFirstQuestionUrl={setFirstQuestionUrl}
              previewFilter={previewFilter}
              isValideJQL={isValideJQL}
              setIsValideJQL={setIsValideJQL}
              onSave={async () => {
                await updateDataSourceMutation({
                  variables: {
                    updateDataSourceInput: {
                      dataSourceId: dataSource?.id,
                      dataSourceFilter: {
                        ...previewFilter
                      }
                    }
                  }
                });
              }}
            />
          </div>
        ) : null}
      </Page>
    </>
  );
};
QuestionsTab.propTypes = {
  checkInId: PropTypes.string.isRequired
};
