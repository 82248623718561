import "react-datepicker/dist/react-datepicker.css";
import "./DatepickerStyles/Datepicker.css";

import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import DatepickerCustomHeader from "./DatepickerCustomHeader/DatepickerCustomHeader";
import DatepickerCustomInput from "./DatepickerCustomInput/DatepickerCustomInput";

const DatePickerSingleComponent = ({ label, inputSetting, date, onChange }) => (
  <div>
    <label className={`text-left block text-sm font-medium text-gray-700`}>
      {label}
    </label>
    <DatePicker
      className="border-0"
      customInput={<DatepickerCustomInput {...inputSetting} />}
      renderCustomHeader={({
        date: selectedDate,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <DatepickerCustomHeader
          date={selectedDate}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
      selected={date}
      onChange={onChange}
    />
  </div>
);

DatePickerSingleComponent.propTypes = {
  label: PropTypes.string,
  inputSetting: PropTypes.object,
  date: PropTypes.object,
  onChange: PropTypes.func
};

export default DatePickerSingleComponent;
