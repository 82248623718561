import React from "react";

import VerificationEmail from "../components/VerificationEmail/VerificationEmail";
import AuthorizationLayout from "./Layout/AuthorizationLayout/AuthorizationLayout";

const LoginView = () => (
  <AuthorizationLayout>
    <VerificationEmail />
  </AuthorizationLayout>
);
export default LoginView;
