import { useMutation } from "@apollo/client";
import { LinkIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import mergeCheckInRetrospectiveRoom from "../../../../../../graphql/mutation/mergeCheckInRetrospectiveRoom";
import separateCheckInRetrospectiveRoom from "../../../../../../graphql/mutation/separateCheckInRetrospectiveRoom";
import updateCheckInRetrospectiveRoom from "../../../../../../graphql/mutation/updateCheckInRetrospectiveRoom";
import { classNames } from "../../../../../../utility";
import DragAndDrop from "../../DragAndDrop/DragAndDrop";
import SetupCheckInRetrospectiveItem from "../../SetupCheckInRetrospectiveItem";

const MergeSimilarQuestionItem = ({ refetch, rooms, checkInQuestionId }) => {
  const [updateCheckInRetrospectiveRoomMutation] = useMutation(
    updateCheckInRetrospectiveRoom
  );
  const [mergeCheckInRetrospectiveRoomMutation] = useMutation(
    mergeCheckInRetrospectiveRoom
  );
  const [separateCheckInRetrospectiveRoomMutation] = useMutation(
    separateCheckInRetrospectiveRoom
  );
  const { t } = useTranslation();

  const updateRoom = async (room) => {
    await updateCheckInRetrospectiveRoomMutation({
      variables: {
        retrospectiveItemsRoomId: room.id,
        input: {
          title: room.title,
          description: room.description
        }
      }
    });
    refetch();
  };
  const mergeRoom = async ({ elementMovesAttributes, zoneMovesAttributes }) => {
    const roomId = elementMovesAttributes?.roomid.value;
    const parentRoomId = zoneMovesAttributes?.roomid.value;
    if (!roomId && !parentRoomId) return;
    await mergeCheckInRetrospectiveRoomMutation({
      variables: {
        retrospectiveItemsRoomId: roomId,
        retrospectiveItemsRoomParentId: parentRoomId
      }
    });
    refetch();
  };
  const dragAndDropConfig = {
    dropzoneClass: `dropzone-${checkInQuestionId}`,
    draggableClass: `draggable-${checkInQuestionId}`
  };
  return (
    <>
      {rooms.map((item) => {
        if (item?.checkInQuestionId === checkInQuestionId) {
          return (
            <SetupCheckInRetrospectiveItem
              key={item.id}
              item={{
                ...item,
                users: item.retrospectiveItems.map(({ user }) => user)
              }}
              saveItem={updateRoom}
              classnameavatar={item.retrospectiveItems.length > 1 ? "mr-9" : ""}
              classnameitem={classNames(
                dragAndDropConfig.dropzoneClass,
                item.retrospectiveItems.length > 1
                  ? ""
                  : dragAndDropConfig.draggableClass
              )}
              addonBefore={
                item.retrospectiveItems.length > 1 ? null : (
                  <div
                    className={classNames("-ml-6 mr-3")}
                    style={{ marginTop: "0.86rem" }}
                  >
                    <img
                      className=""
                      src="/assets/img/icon/drag.svg"
                      alt={t("checkInRetrospectiveSetup.drag")}
                    />
                  </div>
                )
              }
              addonAfter={
                item.retrospectiveItems.length > 1 ? (
                  <LinkIcon
                    className="w-5 cursor-pointer"
                    onClick={async () => {
                      await separateCheckInRetrospectiveRoomMutation({
                        variables: {
                          retrospectiveItemsRoomId: item.id
                        }
                      });
                      await refetch();
                    }}
                  />
                ) : null
              }
            />
          );
        }
        return null;
      })}
      <DragAndDrop
        dragAndDropConfig={dragAndDropConfig}
        ondropSave={mergeRoom}
      />
    </>
  );
};
MergeSimilarQuestionItem.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  setNewItem: PropTypes.func,
  rooms: PropTypes.array,
  checkInQuestionId: PropTypes.string,
  refetch: PropTypes.func
};
export default MergeSimilarQuestionItem;
