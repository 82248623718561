import { API_URL } from "../config";

export const loginUrl = "/login";
export const jiraAuthorizeUrl = "/jira-authorize";
export const jiraOauthCallbackUrl = "/jira-oauth-callback";
export const gitHubOauthCallbackUrl = "/github-oauth-callback";

export const gitHubAuthorizeUrl = `/github-authorize`;

export const termsOfServiceUrl = "/terms-of-service";
export const privacyPolicyUrl = "/privacy-policy";
export const bitrixAuthorizeUrl = "/bitrix-authorize";

export const homeUrl = "/";
export const approveInviteOrganization = "/inviteOrganization";
export const approveInviteTeam = "/inviteTeam";
export const approveInvitePeople = "/invitePeople";
export const approveEmailVerification = "/verification-email-approve";

export const approveTeamInviteLink = "/teamInviteLink";
export const approveOrganizationInviteLink = "/organizationInviteLink";

export const botIsNotIstalledLink = "/bot-not-installed";
export const profileUrl = "/profile";
export const organisationUrl = "/organisation";
export const peopleUrl = "/people";
export const signupUrl = "/signup";
export const emailNotVerificationUrl = "/not-verification-email";
export const resetPasswordUrl = "/reset-password";
export const resetPasswordRequestUrl = "/reset-password-request";

export const teamsUrl = "/teams";
export const checkInsUrl = "/check-ins";

export const getTeamUrl = (teamId) => `/team/${teamId}`;

export const getCheckInUrl = (checkInId) => `/check-in/${checkInId}`;
export const getCheckInRunUrl = (checkInId, checkInRun) =>
  `/check-in/${checkInId}?checkInRunId=${checkInRun}`;
export const organizationPeopleUrl = "/organization-users";

export const getExportCheckInCsvQuery = (checkInId) =>
  `${API_URL}/export/userCheckInResponses/${checkInId}/csv`;

export const getExportPlanningPokerQuestionsCsvQuery = (checkInId) =>
  `${API_URL}/export/planningPokerQuestions/${checkInId}/csv`;

export const getExportDayOffRequestCsvQuery = (checkInId) =>
  `${API_URL}/export/dayOffRequest/${checkInId}/csv`;

const routes = {
  loginUrl,
  jiraAuthorizeUrl,
  bitrixAuthorizeUrl,
  jiraOauthCallbackUrl,
  termsOfServiceUrl,
  privacyPolicyUrl,
  signupUrl,
  // User routes
  homeUrl,
  botIsNotIstalledLink,
  profileUrl,
  organisationUrl,
  peopleUrl,
  approveInvitePeople,
  teamsUrl,
  emailNotVerificationUrl,
  approveEmailVerification,
  resetPasswordRequestUrl,
  resetPasswordUrl
};

export default routes;
