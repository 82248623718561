/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { integrationTypes, setupStatusEnum } from "../../../../../graphql/enum";
import changeIntegrationTypeCheckIn from "../../../../../graphql/mutation/changeIntegrationTypeCheckIn";
import updateCheckIn from "../../../../../graphql/mutation/updateCheckIn";
import Button from "../../../../layout/Button";
import BitrixIcon from "../../../../layout/icons/BitrixIcon";
import GitHubIcon from "../../../../layout/icons/GitHubIcon";
import JiraIcon from "../../../../layout/icons/JiraIcon";
import RadioGroup from "../../../../layout/RadioGroup";
import ConnectTaskTrackerSection from "./ConnectTaskTrackerSection/ConnectTaskTrackerSection";
import CreateBitrixForm from "./CreateBitrixForm/CreateBitrixForm";

const TaskTrackers = ({ checkInId, setCurrentStep, refetch, checkIn }) => {
  const [bitrixDomain, setBitrixDomain] = useState("");
  const { t } = useTranslation();

  const options = [
    {
      id: integrationTypes.jira,
      name: t("JIRA"),
      rightContent: <JiraIcon width="40" height="40" />
    },
    {
      id: integrationTypes.bitrix,
      name: t("Bitrix"),
      rightContent: <BitrixIcon width="40" height="40" />,
      hideContent: (
        <CreateBitrixForm
          name="createBitrixForm"
          setBitrixDomain={setBitrixDomain}
          onSubmit={(values) => {
            setBitrixDomain(values.domain);
          }}
        />
      )
    },
    {
      id: integrationTypes.gitHub,
      name: t("Github"),
      rightContent: <GitHubIcon width="40" height="40" />
    }
  ];
  const [previewTaskTracker, setPreviewTaskTracker] = useState(null);
  const [currentTaskTracker, setCurrentTaskTracker] = useState(
    checkIn?.integrationType
      ? options.find(({ id }) => id === checkIn?.integrationType)
      : {}
  );
  const [updateCheckInMutation] = useMutation(updateCheckIn);
  const [changeIntegrationTypeCheckInMutation] = useMutation(
    changeIntegrationTypeCheckIn
  );

  if (previewTaskTracker)
    return (
      <ConnectTaskTrackerSection
        type={previewTaskTracker.id}
        bitrixDomain={bitrixDomain}
        setPreviewTaskTracker={setPreviewTaskTracker}
        checkInId={checkInId}
      />
    );

  return (
    <>
      <div className="w-6/12 mx-auto">
        <div className="text-lg leading-6 font-medium text-gray-900 text-center">
          {t("taskTrackers.chooseTaskTracker")}
        </div>
        <div className="text-gray-500 text-center mt-5 mb-10">
          {t("taskTrackers.chooseTaskTrackerDescription")}
        </div>
        <RadioGroup
          layout="stacked"
          options={options}
          selected={currentTaskTracker}
          onChange={(val) => {
            setCurrentTaskTracker(val);
          }}
        />
      </div>
      <div className="flex justify-between mt-20">
        <div>
          <Button
            className="mr-5"
            layout="white"
            onClick={async () => {
              if (!checkIn.integrationType) {
                await updateCheckInMutation({
                  variables: {
                    checkInId,
                    input: {
                      integrationType: currentTaskTracker.id
                    }
                  }
                });
                await refetch();
                setCurrentStep(setupStatusEnum.questions);
                return;
              }
              if (checkIn.integrationType !== currentTaskTracker.id) {
                await changeIntegrationTypeCheckInMutation({
                  variables: {
                    input: {
                      checkInId,
                      integrationType: currentTaskTracker.id
                    }
                  }
                });
                await updateCheckInMutation({
                  variables: {
                    checkInId,
                    input: {
                      isSetup: false,
                      setupStatus: setupStatusEnum.taskTrackers
                    }
                  }
                });
              }
              await refetch();
              setCurrentStep(setupStatusEnum.questions);
            }}
          >
            {t("Previous")}
          </Button>
        </div>
        <div>
          <Button
            layout="primary"
            type="submit"
            form="createBitrixForm"
            onClick={async () => {
              if (!checkIn.integrationType) {
                await updateCheckInMutation({
                  variables: {
                    checkInId,
                    input: {
                      integrationType: currentTaskTracker.id
                    }
                  }
                });
                setPreviewTaskTracker(currentTaskTracker);
                return;
              }

              if (checkIn.integrationType !== currentTaskTracker.id) {
                await changeIntegrationTypeCheckInMutation({
                  variables: {
                    input: {
                      checkInId,
                      integrationType: currentTaskTracker.id
                    }
                  }
                });
                await updateCheckInMutation({
                  variables: {
                    checkInId,
                    input: {
                      isSetup: false,
                      setupStatus: setupStatusEnum.taskTrackers
                    }
                  }
                });
                setPreviewTaskTracker(currentTaskTracker);
                return;
              }
              if (checkIn.setupStatus === setupStatusEnum.taskTrackers) {
                setPreviewTaskTracker(currentTaskTracker);
                return;
              }
              setCurrentStep(setupStatusEnum.taskLists);
            }}
          >
            {t("Next")}
          </Button>
        </div>
      </div>
    </>
  );
};
TaskTrackers.propTypes = {
  checkInId: PropTypes.string.isRequired,
  checkIn: PropTypes.shape({
    integrationType: PropTypes.string,
    setupStatus: PropTypes.string.isRequired
  }),
  setCurrentStep: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default TaskTrackers;
