import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { statusUserEnum } from "../../../graphql/enum";
import Badge from "../Badge";

const StatusLabel = ({ status }) => {
  const { t } = useTranslation();
  if (status === statusUserEnum.active)
    return <Badge color="green">{t("statusLabel.active")}</Badge>;

  if (status === statusUserEnum.pendingInvitation)
    return <Badge color="yellow">{t("statusLabel.pendingInvitation")}</Badge>;

  if (status === statusUserEnum.emailNotVerified)
    return <Badge color="yellow">{t("statusLabel.emailNotVerified")}</Badge>;

  if (status === statusUserEnum.deactivated)
    return <Badge color="red">{t("statusLabel.deactivated")}</Badge>;

  return null;
};
StatusLabel.propTypes = {
  status: PropTypes.oneOf([
    statusUserEnum.active,
    statusUserEnum.pendingInvitation,
    statusUserEnum.emailNotVerified,
    statusUserEnum.deactivated
  ]),
  disabled: PropTypes.bool
};

export default StatusLabel;
