import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { homeUrl } from "../../../routes";
import Button from "../Button";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="flex flex-col items-center mt-10">
      <img src="/assets/img/icon/404.png" alt={t("error.pageNotFound")} />
      <Button
        className="mt-11 py-4 px-11"
        onClick={() => {
          history.push(homeUrl);
        }}
      >
        {t("error.backToHomePage")}
      </Button>
    </div>
  );
};

export default NotFoundPage;
