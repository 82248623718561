import PropTypes from "prop-types";

import { checkInTypes } from "../../../graphql/enum";
import NotFoundPage from "../../layout/NotFoundPage/NotFoundPage";
import SetupCheckInPlanningPoker from "./SetupCheckInPlanningPoker/SetupCheckInPlanningPoker";
import SetupCheckInStandup from "./SetupCheckInStandup/SetupCheckInStandup";

const SetupCheckIn = ({ checkIn, checkInId, refetch }) => {
  if (checkIn.type === checkInTypes.standup) {
    return (
      <>
        <SetupCheckInStandup
          checkInId={checkInId}
          checkIn={checkIn}
          refetch={refetch}
        />
      </>
    );
  }
  if (checkIn.type === checkInTypes.planningPoker)
    return (
      <SetupCheckInPlanningPoker checkInId={checkInId} refetch={refetch} />
    );

  return <NotFoundPage />;
};

SetupCheckIn.propTypes = {
  checkIn: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(checkInTypes)).isRequired
  }).isRequired,
  checkInId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired
};
export default SetupCheckIn;
