import { gql } from "@apollo/client";

const deleteRetrospectiveItemsRoomAction = gql`
  mutation deleteRetrospectiveItemsRoomAction(
    $retrospectiveItemsRoomActionId: ID!
  ) {
    deleteRetrospectiveItemsRoomAction(
      retrospectiveItemsRoomActionId: $retrospectiveItemsRoomActionId
    ) {
      success
    }
  }
`;

export default deleteRetrospectiveItemsRoomAction;
