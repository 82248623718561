/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { organizationRoles, statusUserEnum } from "../../../graphql/enum";
import resendOrganizationInvite from "../../../graphql/mutation/resendOrganizationInvite";
import { getTeamUrl } from "../../../routes/index";
import Avatar from "../../layout/Avatar";
import Button from "../../layout/Button";
import CheckSuccess from "../../layout/icons/CheckSuccess/CheckSuccess";
import Failed from "../../layout/icons/Failed/Failed";
import Loading from "../../layout/Loading/Loading";
import StatusLabel from "../../layout/StatusLabel/StatusLabel";
import Table from "../../layout/Table/Table";
import EditPeopleUserModal from "./EditPeopleUserModal/EditPeopleUserModal";

export const getAllUserOrganizationQuery = gql`
  query usersOrganization(
    $filters: UsersOrganizationFiltersInput
    $pagination: paginationInput
  ) {
    currentUser {
      organizations {
        organization {
          id
          title
          ownerId
          teams {
            id
            title
          }
          users {
            count(filters: $filters)
            data(filters: $filters, pagination: $pagination) {
              id
              name
              email
              status
              isBotAdded
              avatar
              organizationRole
              myTeams {
                id
                title
                role
              }
            }
          }
        }
      }
    }
  }
`;

const PeopleTable = ({ filters, page, setPage, organizationId }) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState();

  const [isEditPeopleUserModalVisible, setIsEditPeopleUserModalVisible] =
    useState(false);
  const [resendOrganizationInviteMutation] = useMutation(
    resendOrganizationInvite
  );

  const stringToBoolean = (string) => {
    if (string === "true") {
      return true;
    }
    return false;
  };

  const variables = {
    filters: {
      search: filters.search,
      teamId: filters.teamId,
      status: filters.status,
      isBotInstalled: filters.botInstalled
        ? stringToBoolean(filters.botInstalled)
        : null
    },
    pagination: {
      offset: page.offset,
      limit: page.limit
    }
  };

  const { loading, data, refetch } = useQuery(getAllUserOrganizationQuery, {
    variables
  });
  useEffect(() => {
    if (selectedUser?.id) {
      const currentSelectedUser =
        data?.currentUser?.organizations?.organization.users.data.find(
          (user) => user.id === selectedUser.id
        );
      setSelectedUser(currentSelectedUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Loading />;
  const { users, teams } = data?.currentUser?.organizations?.organization;
  const columns = [
    {
      title: t("teamUserTable.name"),
      isVisible: true,
      className: "min-w-320"
    },
    {
      title: t("teamUserTable.teams"),
      isVisible: true,
      className: "min-w-180"
    },
    {
      title: t("teamUserTable.status"),
      isVisible: true,
      className: "min-w-120"
    },
    {
      title: t("teamUserTable.botInstalled"),
      isVisible: true,
      className: "min-w-120 pl-1 pr-1"
    },
    {
      tilte: t("teamUserTable.edit"),
      isVisible: false,
      className: "min-w-60"
    }
  ];
  const people = users.data;

  const dataRender = people.map((user) => [
    {
      id: `name-${user.id}`,
      render: () => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <Avatar src={user.avatar} name={user.name} className="w-10 h-10" />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {user.name}
              {user.organizationRole !== organizationRoles.user ? (
                <span className="px-2 ml-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                  {t(user.organizationRole)}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="text-sm text-gray-500">{user.email}</div>
          </div>
        </div>
      )
    },
    {
      id: `teams-${user.id}`,
      render: () =>
        user.myTeams.map((teamItem) => (
          <div key={teamItem.id} className="text-sm text-gray-900">
            <a
              href={getTeamUrl(teamItem.id)}
              className="text-indigo-600 hover:text-indigo-900"
            >
              {teamItem.title}
            </a>
          </div>
        ))
    },
    {
      id: `status-${user.id}`,
      render: () => (
        <div className="flex">
          <StatusLabel status={user.status} />
          {user.status === statusUserEnum.pendingInvitation ? (
            <Button
              className="mt-1 ml-1"
              layout="refresh"
              onClick={() =>
                resendOrganizationInviteMutation({
                  variables: {
                    userId: user.id,
                    organizationId
                  }
                })
              }
            />
          ) : null}
        </div>
      )
    },
    {
      id: `bot-${user.id}`,
      render: () => (user.isBotAdded ? <CheckSuccess /> : <Failed />),
      className: "flex justify-center text-sm"
    },
    {
      id: `edit-${user.id}`,
      render: () => (
        <span
          className="text-indigo-600 hover:text-indigo-900 text-right cursor-pointer"
          onClick={() => {
            setSelectedUser(user);
            setIsEditPeopleUserModalVisible(true);
          }}
        >
          {t("teamUserTable.edit")}
        </span>
      ),
      className: "text-right"
    }
  ]);

  return (
    <>
      <div className="sm:-mx-6 lg:-mx-8 table-tr-border-none">
        <Table
          {...{ columns }}
          data={dataRender}
          countElements={users.count}
          typeResultText={t("pagination.users")}
          page={page}
          setPage={setPage}
        />
      </div>
      <EditPeopleUserModal
        key={selectedUser ? selectedUser.id : "modal"}
        onClose={() => setIsEditPeopleUserModalVisible(false)}
        visible={isEditPeopleUserModalVisible}
        selectedUser={selectedUser}
        teams={teams}
        refetch={refetch}
        organizationId={organizationId}
      />
    </>
  );
};
PeopleTable.propTypes = {
  page: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number
  }),
  organizationId: PropTypes.string,
  filters: PropTypes.shape({
    search: PropTypes.string,
    teamId: PropTypes.string,
    status: PropTypes.string,
    botInstalled: PropTypes.bool
  }),
  setPage: PropTypes.func
};
export default PeopleTable;
