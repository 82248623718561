/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../../../layout/Input/Input";
import Loading from "../../../../../../../layout/Loading/Loading";
import Multiselect from "../../../../../../../layout/Multiselect";

export const previewUsers = gql`
  query previewUsers($id: ID!) {
    previewDataSource(id: $id) {
      id
      users {
        id
        key
        name
        lastName
        email
      }
      statuses {
        id
        name
      }
      projects {
        id
        name
      }
    }
  }
`;

export const BitrixSearchForm = ({
  dataSourceId,
  setPreviewFilter,
  previewFilter,
  formclassname
}) => {
  const { data, loading } = useQuery(previewUsers, {
    variables: {
      id: dataSourceId
    }
  });

  const { t } = useTranslation();

  const [currentData, setCurrentData] = useState({
    asigneeIds: [],
    projectIds: [],
    createdDaysAgo: "",
    closedDaysAgo: "",
    reporterIds: [],
    statusIds: []
  });

  const users = data?.previewDataSource?.users;
  const projects = data?.previewDataSource?.projects;
  const statuses = data?.previewDataSource?.statuses;

  useEffect(() => {
    if (!users) return;
    const asigneeIds = users.filter((u) =>
      previewFilter.asigneeIds.some((id) => id === u.id)
    );

    const reporterIds = users.filter((u) =>
      previewFilter.reporterIds.some((id) => id === u.id)
    );

    const projectIds = projects.filter((p) =>
      previewFilter.projectIds.some((id) => id === p.id)
    );

    const statusIds = statuses.filter((s) =>
      previewFilter.statusIds.some((id) => id === s.id)
    );

    setCurrentData({
      asigneeIds,
      projectIds,
      createdDaysAgo: previewFilter.createdDaysAgo,
      closedDaysAgo: previewFilter.closedDaysAgo,
      reporterIds,
      statusIds
    });
  }, [previewFilter, users, projects, statuses]);

  if (!dataSourceId) return null;
  if (loading) return <Loading />;
  const usersOptions = users?.map((u) => ({
    id: u.id,
    name: `${u.name} ${u.lastName}`
  }));
  const projectOptions = projects?.map((p) => ({
    id: p.id,
    name: p.name
  }));
  const statusOptions = statuses?.map((s) => ({
    id: s.id,
    name: s.name
  }));

  return (
    <>
      <Formik>
        <div className={formclassname}>
          <div>
            <Multiselect
              options={usersOptions}
              label={t("bitrixSearchForm.asignee")}
              onChange={async (val) => {
                const asigneeIds = val.map(({ id }) => id);
                const newPreviewFilter = {
                  ...previewFilter,
                  asigneeIds
                };
                await setCurrentData({
                  ...currentData,
                  asigneeIds: val
                });
                await setPreviewFilter(newPreviewFilter);
              }}
              selectedOptions={currentData.asigneeIds}
            />
          </div>
          <div>
            <Multiselect
              options={usersOptions}
              label={t("bitrixSearchForm.reporter")}
              onChange={async (val) => {
                const reporterIds = val.map(({ id }) => id);
                const newPreviewFilter = {
                  ...previewFilter,
                  reporterIds
                };
                await setCurrentData({
                  ...currentData,
                  reporterIds: val
                });
                await setPreviewFilter(newPreviewFilter);
              }}
              selectedOptions={currentData.reporterIds}
            />
          </div>
          <div>
            <Input
              label={t("bitrixSearchForm.created")}
              type="number"
              errorclassname={{ notError: "hidden" }}
              addonafter={
                <div className="absolute top-1/2 bottom-1/2 right-2 flex items-center text-gray-500">
                  {t("bitrixSearchForm.daysAgo")}
                </div>
              }
              onChange={async (e) => {
                setCurrentData({
                  ...currentData,
                  createdDaysAgo:
                    e.target.value === "" ? null : Number(e.target.value)
                });
              }}
              onBlur={async (e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  const newPreviewFilter = {
                    ...previewFilter,
                    createdDaysAgo: currentData.createdDaysAgo
                  };
                  await setPreviewFilter(newPreviewFilter);
                }
              }}
              value={currentData.createdDaysAgo}
            />
          </div>
          <div>
            <Input
              label={t("bitrixSearchForm.closed")}
              type="number"
              errorclassname={{ notError: "hidden" }}
              addonafter={
                <div className="absolute top-1/2 bottom-1/2 right-2 flex items-center text-gray-500">
                  {t("bitrixSearchForm.daysAgo")}
                </div>
              }
              onChange={async (e) => {
                setCurrentData({
                  ...currentData,
                  closedDaysAgo:
                    e.target.value === "" ? null : Number(e.target.value)
                });
              }}
              onBlur={async (e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  const newPreviewFilter = {
                    ...previewFilter,
                    closedDaysAgo: currentData.closedDaysAgo
                  };
                  await setPreviewFilter(newPreviewFilter);
                }
              }}
              value={currentData.closedDaysAgo}
            />
          </div>
          <div>
            <Multiselect
              options={projectOptions}
              label={t("bitrixSearchForm.project")}
              onChange={async (val) => {
                const projectIds = val.map(({ id }) => id);
                const newPreviewFilter = {
                  ...previewFilter,
                  projectIds
                };
                await setCurrentData({
                  ...currentData,
                  projectIds: val
                });
                await setPreviewFilter(newPreviewFilter);
              }}
              selectedOptions={currentData.projectIds}
            />
          </div>
          <div>
            <Multiselect
              options={statusOptions}
              label={t("bitrixSearchForm.status")}
              onChange={async (val) => {
                const statusIds = val.map(({ id }) => id);
                const newPreviewFilter = {
                  ...previewFilter,
                  statusIds
                };
                await setCurrentData({
                  ...currentData,
                  statusIds: val
                });
                await setPreviewFilter(newPreviewFilter);
              }}
              selectedOptions={currentData.statusIds}
            />
          </div>
        </div>
      </Formik>
    </>
  );
};
BitrixSearchForm.propTypes = {
  dataSourceId: PropTypes.string.isRequired,
  setPreviewFilter: PropTypes.func.isRequired,
  previewFilter: PropTypes.object.isRequired,
  formclassname: PropTypes.string
};
