import { gql } from "@apollo/client";

const CreateCheckInQuestions = gql`
  mutation createOrganizationHoliday($input: [CreateOrganizationHolidayInput]) {
    createOrganizationHoliday(input: $input) {
      success
    }
  }
`;

export default CreateCheckInQuestions;
