import { gql } from "@apollo/client";

const createCheckInRetrospectiveRoomAction = gql`
  mutation createCheckInRetrospectiveRoomAction(
    $input: CreateCheckInRetrospectiveRoomActionInput!
  ) {
    createCheckInRetrospectiveRoomAction(input: $input) {
      id
      success
      code
    }
  }
`;

export default createCheckInRetrospectiveRoomAction;
