import PropTypes from "prop-types";

const DisabledInput = (props) => {
  const { addonafter, containerclass, label } = props;
  return (
    <div className={containerclass}>
      <div className="text-left	block text-sm font-medium text-gray-700">
        {label}
      </div>
      <input
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm p-2 bg-gray-100 text-gray-500 cursor-not-allowed"
        {...{ ...props }}
      />
      {addonafter}
    </div>
  );
};
DisabledInput.propTypes = {
  addonafter: PropTypes.any,
  containerclass: PropTypes.string,
  label: PropTypes.string
};
DisabledInput.defaultProps = {
  addonafter: "",
  containerclass: ""
};
export default DisabledInput;
