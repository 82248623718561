import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Datepicker from "../../../../layout/Datepicker";
import Select from "../../../../layout/Select";

const DashboardFilter = ({
  usersFilterStub,
  statusFilterStub,
  filter,
  setFilter
}) => {
  const { t } = useTranslation();

  return (
    <Formik initialValues={{}}>
      <Form>
        <Select
          options={usersFilterStub}
          label={t("User")}
          type="text"
          selectedOption={
            filter.userId
              ? usersFilterStub.find(
                  ({ id: userId }) => userId === filter.userId
                )
              : usersFilterStub[0]
          }
          onChange={(val) => setFilter({ ...filter, userId: val.id })}
        />
        <div className="mt-5">
          <Select
            options={statusFilterStub}
            label={t("Status")}
            type="text"
            selectedOption={
              filter.status
                ? statusFilterStub.find(
                    ({ id: status }) => status === filter.status
                  )
                : statusFilterStub[0]
            }
            onChange={(val) => setFilter({ ...filter, status: val.id })}
          />
        </div>
        <div className="mt-5">
          <Datepicker
            type={"range"}
            labelFrom={t("Start Date")}
            labelTo={t("End Date")}
            startDate={filter.dateFrom}
            endDate={filter.dateTo}
            setStartDate={(val) => setFilter({ ...filter, dateFrom: val })}
            setEndDate={(val) => setFilter({ ...filter, dateTo: val })}
          />
        </div>
      </Form>
    </Formik>
  );
};

DashboardFilter.propTypes = {
  usersFilterStub: PropTypes.array,
  statusFilterStub: PropTypes.array,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  )
};
export default DashboardFilter;
