import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { summaryRoomType } from "../../../../../../../graphql/enum";
import Loading from "../../../../../../layout/Loading/Loading";
import RadioGroup from "../../../../../../layout/RadioGroup";
import SummaryRoomSelect from "./SummaryRoomSelect/SummaryRoomSelect";

export const getOrganizationQuery = gql`
  query getAllOrganizations {
    currentUser {
      id
      organizations {
        organization {
          id
          rooms {
            id
            title
            spaceName
          }
        }
      }
    }
  }
`;
export const SummaryRoomRadioGroup = ({
  checkInData,
  setCheckInData,
  errors
}) => {
  const { t } = useTranslation();
  const { loading, data, refetch } = useQuery(getOrganizationQuery, {
    notifyOnNetworkStatusChange: true
  });
  const roomTypes = [
    {
      id: summaryRoomType.personal,
      name: t("summaryRoomRadioGroup.personalMessage"),
      description: t("summaryRoomRadioGroup.personalMessageDescription")
    },
    {
      id: summaryRoomType.space,
      name: t("summaryRoomRadioGroup.space"),
      description: t("summaryRoomRadioGroup.spaceDesctiption")
    }
  ];
  if (loading) return <Loading />;

  return (
    <>
      <RadioGroup
        layout="list"
        options={roomTypes}
        selected={checkInData.summaryType}
        onChange={(val) => {
          setCheckInData({
            ...checkInData,
            summaryType: val
          });
        }}
      />
      <div className="mt-4">
        <SummaryRoomSelect
          type={checkInData.summaryType}
          checkInData={checkInData}
          setCheckInData={setCheckInData}
          rooms={data?.currentUser?.organizations?.organization?.rooms || []}
          errors={errors}
          refetch={refetch}
        />
      </div>
    </>
  );
};
SummaryRoomRadioGroup.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  errors: PropTypes.object
};
