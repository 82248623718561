/* eslint-disable react/prop-types */
import React from "react";

const BitrixIcon = ({ width, height, disabled = false }) => (
  <svg
    id="svg2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200.9 201.2"
    width={width}
    height={height}
  >
    <g id="layer1" transform="translate(-76.221 -152.77)">
      <path
        id="path821"
        className="st0"
        fill={disabled ? "#ccc" : "#B62936"}
        d="M157.3 352.8c-17.6-3.6-34-11.8-46.8-23.2-33-29.3-43.3-75.4-25.8-116.5C89 203 96 192.8 104.6 183.9l7.6-7.8.5 45.8c.5 43.9.6 46.1 2.7 51.6 4.2 11.1 8.6 18.1 16.3 25.7 19.8 19.7 48.8 24.6 73.5 12.5 23.2-11.4 37.8-36.3 36.1-61.5-1.1-16.9-6.8-29.3-19.1-41.6-9.3-9.3-20.2-15.4-32-17.9-12.1-2.5-11.5-2.9-11.5 7.3v8.8l6.6 1.2c25.6 4.7 42.6 29.9 37.3 55.3-1.7 8.1-5.5 15.3-11.6 21.9-9.3 10-22.4 14.8-33.8 14.8-18.9 0-34.6-10.8-42.3-26.6 0 0-2-4.5-2.6-7.3-3.8-16.5-1.4-51.1-1.4-51.1l-.3-50.7 3-1.5c1.6-.9 3.2-1.5 3.5-1.5s.6 23.3.8 51.8c0 0-3.6 37.3.9 51.6.6 2.1 1.9 5.6 1.9 5.6 3.9 8 10.2 15.1 18.4 19 6.2 2.9 9.4 4.3 18.6 4.3 6 0 11.3-1.9 17.5-4.7 7.2-3.3 15.5-12.1 18.6-20.2 8.4-22.2-3.3-46-26-52.7-9.3-2.7-9.1-2.9-9.1 7.2v8.8l4.4 1.2c16 4.3 20.9 25.8 8.3 36.8-4.4 3.9-8.4 5.6-15.4 5.6-7.5 0-14.7-4.5-18.4-11.5-2.2-4.2-2.4-8.1-2.4-9.9 0-16.7-.4-46.9-.4-46.9l-.3-51.7 2.3-.6c1.3-.3 6.4-1.1 11.4-1.7 16.9-2.2 41.9 3.4 58.7 13 22.9 13.1 40.2 35.6 47.4 61.6 2.5 8.9 2.8 11.6 2.8 25.9 0 14.3-.3 17-2.7 25.8-9.8 35.4-34.8 60.6-70.5 71.4-7.5 2.3-11 2.7-24.3 3-10.7.1-17.6-.2-22.3-1.2z"
      />
    </g>
  </svg>
);
export default BitrixIcon;
