import { questionTypesEnum } from "../enum";

export const isStandupQuestionsConfigured = (standup) => {
  if (!standup.questions) return true;
  const userQuestionIds = standup.questions.map(({ question }) => question.id);
  const isNeedConfigurate = standup.standup.questions.map((question) => {
    if (
      !userQuestionIds.includes(question.id) &&
      question.type === questionTypesEnum.tasksList
    ) {
      return true;
    }
    return false;
  });

  if (isNeedConfigurate.includes(true)) return false;

  return true;
};

export const cleanObject = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

export const onBlurChange = (callback) => (e) => {
  if (!e.currentTarget.contains(e.relatedTarget)) {
    callback();
  }
};

export const getColorByName = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  // eslint-disable-next-line no-bitwise
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  const color = "00000".substring(0, 6 - c.length) + c;
  return `#${color}`;
};
export const getOffset = (pageSize, currentPage) => {
  if (currentPage === 1) {
    return 0;
  }
  return pageSize * (currentPage - 1);
};
