import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon, XIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { forwardRef, Fragment, useEffect, useRef, useState } from "react";

import { classNames } from "../../../utility";
import Badge from "../Badge";
import Option from "./Option";

const MultiselectComponent = ({
  onChange,
  selectedOptions,
  options,
  label,
  placeholder,
  error
}) => {
  const node = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const setValue = (e) => {
    const isSelected = selectedOptions.some((selected) => selected.id === e.id);
    if (isSelected) {
      const newArray = selectedOptions.filter(
        (selected) => selected.id !== e.id
      );
      onChange(newArray);
      return;
    }

    onChange([...selectedOptions, e]);
  };

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const Btn = forwardRef((props, ref) => (
    <button
      style={{ minHeight: "40px" }}
      data-testid="myltiselect"
      className="flex justify-start  min-w-full items-center bg-white	relative border border-gray-300 rounded-md shadow-sm pl-1 pr-10  text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      onClick={() => setIsOpen(!isOpen)}
      ref={ref}
    >
      {selectedOptions.length > 0 ? (
        <span className="block truncate mt-1 ml-1">
          {selectedOptions.map((selectedOption, idx) => (
            <div className="mr-1 mb-1  float-left" key={idx}>
              <Badge color="blue" className="border border-blue-300">
                {selectedOption.name}
                <div className="m-1 m-auto">
                  <XIcon
                    className="h-4 w-4 text-sm text-blue-500 font-thin stroke-1 cursor-pointer hover:text-blue-800"
                    aria-hidden="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(selectedOption);
                    }}
                  />
                </div>
              </Badge>
            </div>
          ))}
        </span>
      ) : (
        <span className="text-gray-500 ml-2">{placeholder}</span>
      )}

      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </span>
    </button>
  ));

  return (
    <div ref={node}>
      <Listbox open={isOpen} value={selectedOptions} onChange={setValue}>
        {() => (
          <>
            <Listbox.Label className=" min-h-full  text-left	block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className="relative min-h-fullflex flex-auto flex-wrap">
              <Listbox.Button as={Btn} open={isOpen}></Listbox.Button>
              <Transition
                unmount={false}
                show={isOpen}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "cursor-default hover:bg-indigo-600 hover:text-white select-none relative py-2 pl-8 pr-9 flex"
                        )
                      }
                      value={person}
                    >
                      <Option {...{ selectedOptions, person }} />
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
            {error ? <p className="text-gray-400 text-left">{error}</p> : null}
          </>
        )}
      </Listbox>
    </div>
  );
};

MultiselectComponent.defaultProps = {
  placeholder: ""
};

MultiselectComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.any
};

export default MultiselectComponent;
