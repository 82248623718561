import { gql } from "@apollo/client";

const updateUserTeams = gql`
  mutation updateUserTeams($input: [UpdateUserTeamsInput]!) {
    updateUserTeams(input: $input) {
      code
      success
    }
  }
`;

export default updateUserTeams;
