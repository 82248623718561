/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { userConfirmationCodeEnum } from "../../../../graphql/enum";
import ResetPassword from "../../../../graphql/mutation/resetPassword";
import { validatePassword } from "../../../../utility";
import { UserContext } from "../../../../utility/context/User";
import Button from "../../../layout/Button";
import Input from "../../../layout/Input";
import ErrorResetPassword from "../ErrorResetPassword/ErrorResetPassword";

const ResetPasswordForm = ({ response, code }) => {
  const { t } = useTranslation();
  const [responseCode, setResponseCode] = useState();
  const { login } = useContext(UserContext);
  const [ResetPasswordMutation] = useMutation(ResetPassword);

  const [isFaildValidateOnChange, setIsFaildValidateOnChange] = useState({
    validateOnChange: false
  });

  const validate = ({ newPassword, confirmNewPassword }) => {
    const errors = {};
    const newPasswordError = validatePassword(newPassword, t);
    const confirmNewPasswordError = validatePassword(confirmNewPassword, t);
    if (newPasswordError !== null) errors.newPassword = newPasswordError;
    if (confirmNewPasswordError !== null)
      errors.confirmNewPassword = confirmNewPasswordError;

    if (newPassword !== confirmNewPassword) {
      errors.confirmNewPassword = t("resetPassword.passwordsNotMatch");
    }
    return errors;
  };
  const onResetPassword = async (value, { setErrors }) => {
    setIsFaildValidateOnChange({ validate });
    const errors = validate(value);
    setErrors(errors);

    if (Object.keys(errors).length > 0) return;

    const { data } = await ResetPasswordMutation({
      variables: {
        input: {
          code,
          password: value.confirmNewPassword
        }
      }
    });
    if (!data.resetPassword.success) {
      setResponseCode(data.resetPassword.code);
      return;
    }
    const { jwtToken } = data.resetPassword;
    login(jwtToken);
  };
  if (response.code !== userConfirmationCodeEnum.success) {
    return <ErrorResetPassword responseCode={response.code} />;
  }
  if (responseCode) {
    return <ErrorResetPassword responseCode={responseCode} />;
  }
  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmNewPassword: ""
      }}
      onSubmit={onResetPassword}
      {...{ ...isFaildValidateOnChange }}
    >
      {({ errors }) => (
        <Form id="reset-pasword">
          <div>
            <div>
              <Input
                label={t("resetPassword.emailAddress")}
                type="text"
                disabled={true}
                value={response.user.email}
                className="mb-5 w-full px-3 py-2 text-gray-500 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <div className="mt-1">
              <Input
                label={t("resetPassword.newPassword")}
                name="newPassword"
                type="password"
                id="newPassword"
                error={errors.newPassword}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                errorclassname={{ notError: "m-0", error: "m-0" }}
              />
            </div>
          </div>
          <div>
            <div className="mt-1">
              <Input
                label={t("resetPassword.confirmNewPassword")}
                name="confirmNewPassword"
                type="password"
                id="confirmNewPassword"
                error={errors.confirmNewPassword}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                errorclassname={{ notError: "m-0", error: "m-0" }}
              />
            </div>
          </div>
          <div className="mt-2">
            <Button
              type="submit"
              layout="primary"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium "
            >
              {t("resetPassword.saveAndContinue")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
ResetPasswordForm.propTypes = {
  response: PropTypes.exact({
    user: PropTypes.exact({
      email: PropTypes.string
    }),
    code: PropTypes.string
  }),
  code: PropTypes.string
};
export default ResetPasswordForm;
