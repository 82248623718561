import { gql } from "@apollo/client";

const mergeCheckInRetrospectiveRoom = gql`
  mutation mergeCheckInRetrospectiveRoom(
    $retrospectiveItemsRoomId: ID!
    $retrospectiveItemsRoomParentId: ID!
  ) {
    mergeCheckInRetrospectiveRoom(
      retrospectiveItemsRoomId: $retrospectiveItemsRoomId
      retrospectiveItemsRoomParentId: $retrospectiveItemsRoomParentId
    ) {
      success
      code
    }
  }
`;

export default mergeCheckInRetrospectiveRoom;
