import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { environment, isDevelopment, sentryDsn } from "../config";

export const setCurrentUser = (user) => {
  Sentry.configureScope((scope) => scope.setUser(user));
};

export const captureException = (err, context) =>
  Sentry.captureException(err, context);

export const initErrorTracking = () => {
  if (!isDevelopment) {
    return Sentry.init({
      dsn: sentryDsn,
      autoSessionTracking: true,
      integrations: [new Integrations.BrowserTracing()],
      environment,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0
    });
  }

  return null;
};
