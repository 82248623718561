import PropTypes from "prop-types";
import { withAlert } from "react-alert";

import ActionTeam from "./ActionTeam/ActionTeam";
import TitleTeamEdit from "./TitleTeamEdit/TitleTeamEdit";

const SettingsTeam = ({ team, alert, query }) => (
  <>
    <div className="bg-gray-50 sm:rounded-lg shadow">
      <div className="px-4 py-5 sm:p-6">
        <TitleTeamEdit {...{ team, alert, query }} />
      </div>
    </div>
    <div className="mt-5 bg-gray-50 sm:rounded-lg shadow">
      <div className="px-4 py-5 sm:p-6">
        <ActionTeam {...{ team, alert, query }} />
      </div>
    </div>
  </>
);
SettingsTeam.propTypes = {
  query: PropTypes.any,
  team: PropTypes.exact({
    id: PropTypes.string,
    title: PropTypes.string,
    isActive: PropTypes.bool
  }),
  alert: PropTypes.object
};
export default withAlert()(SettingsTeam);
