import { gql } from "@apollo/client";

const updateCheckInRetrospectiveRoom = gql`
  mutation updateCheckInRetrospectiveRoom(
    $retrospectiveItemsRoomId: ID!
    $input: UpdateCheckInRetrospectiveRoomInput!
  ) {
    updateCheckInRetrospectiveRoom(
      retrospectiveItemsRoomId: $retrospectiveItemsRoomId
      input: $input
    ) {
      success
      code
    }
  }
`;

export default updateCheckInRetrospectiveRoom;
