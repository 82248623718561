import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { standupResponseStatusTypes } from "../../../../../graphql/enum";
import { getDefaultValueForDropdown } from "../../../../../utility/config";
import Datepicker from "../../../../layout/Datepicker";
import Input from "../../../../layout/Input/Input";
import Select from "../../../../layout/Select";

const StandupResponsesFilter = ({ filter, setFilter, users, questions }) => {
  const { t } = useTranslation();
  const [currentSearch, setCurrentSearch] = useState(filter.search);

  const date = new Date();
  const defaultValue = getDefaultValueForDropdown(t("all"));

  const userFilterFields = [defaultValue, ...users];
  const statusFilterFields = [
    defaultValue,
    {
      id: standupResponseStatusTypes.skipped,
      name: t("standupResponsesTab.skipped")
    },
    {
      id: standupResponseStatusTypes.waiting,
      name: t("standupResponsesTab.empty")
    },
    {
      id: standupResponseStatusTypes.answered,
      name: t("standupResponsesTab.available")
    }
  ];
  const questionsFilter = questions.map(({ id, title }) => ({
    id,
    name: title
  }));
  const questionsFilterFields = [defaultValue, ...questionsFilter];

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        const newFilter = {
          ...filter,
          search: currentSearch
        };
        setFilter(newFilter);
      }}
    >
      <Form>
        <Input
          label={t("Search")}
          type="text"
          value={currentSearch}
          name="search"
          onBlur={async (e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              const newFilter = {
                ...filter,
                search: currentSearch
              };
              setFilter(newFilter);
            }
          }}
          onChange={async (e) => {
            setCurrentSearch(e.target.value);
          }}
        />
        <Select
          options={userFilterFields}
          label={t("User")}
          type="text"
          selectedOption={
            filter.userId
              ? userFilterFields.find(
                  ({ id: userId }) => userId === filter.userId
                )
              : userFilterFields[0]
          }
          onChange={(val) => setFilter({ ...filter, userId: val.id })}
        />
        <div className="mt-5">
          <Select
            options={questionsFilterFields}
            label={t("Question")}
            type="text"
            selectedOption={
              filter.checkInQuestionId
                ? questionsFilterFields.find(
                    ({ id }) => id === filter.checkInQuestionId
                  )
                : questionsFilterFields[0]
            }
            onChange={(val) =>
              setFilter({ ...filter, checkInQuestionId: val.id })
            }
          />
        </div>
        <div className="mt-5">
          <Select
            options={statusFilterFields}
            label={t("Response")}
            type="text"
            selectedOption={
              filter.status
                ? statusFilterFields.find(({ id }) => id === filter.status)
                : statusFilterFields[0]
            }
            onChange={(val) => setFilter({ ...filter, status: val.id })}
          />
        </div>
        <div className="mt-5">
          <Datepicker
            type={"range"}
            labelFrom={t("From Date")}
            labelTo={t("To Date")}
            startDate={filter.dateFrom}
            endDate={filter.dateTo}
            maxDate={date}
            setStartDate={(val) => setFilter({ ...filter, dateFrom: val })}
            setEndDate={(val) => setFilter({ ...filter, dateTo: val })}
          />
        </div>
      </Form>
    </Formik>
  );
};

StandupResponsesFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  checkIn: PropTypes.shape({
    questions: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string
      })
    })
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string
    })
  )
};
export default StandupResponsesFilter;
