import "./GoogleLogin.css";

import { gql, useMutation } from "@apollo/client";
import { GoogleLogin } from "@react-oauth/google";
import momentTimeZone from "moment-timezone";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { UserContext } from "../../utility/context/User";

const signInGoogleQuery = gql`
  mutation signInGoogle($credential: String!, $timeZone: String!) {
    signInGoogle(credential: $credential, timeZone: $timeZone) {
      success
      jwtToken
      code
    }
  }
`;

const GoogleLoginButton = () => {
  const { t } = useTranslation();
  const { login } = useContext(UserContext);

  const [signInGoogle] = useMutation(signInGoogleQuery);

  const timeZone = momentTimeZone.tz.guess();

  const successGoogle = async (response) => {
    const { credential } = response;
    const data = await signInGoogle({
      variables: { credential, timeZone }
    });
    const { jwtToken } = data.data.signInGoogle;
    login(jwtToken);
  };

  const responseGoogle = () => {
    // eslint-disable-next-line no-alert
    alert(t("rules.errorLogin"));
  };

  return <GoogleLogin onSuccess={successGoogle} onError={responseGoogle} />;
};

GoogleLoginButton.propTypes = {};

export default GoogleLoginButton;
