/* eslint-disable react/prop-types */
import { Fragment } from "react";
import { Link } from "react-router-dom";

const ProfileMenuPhone = ({ userNavigation }) => (
  <>
    <div className="mt-3 space-y-1 testing-library-menu-profile-phone">
      {userNavigation.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          onClick={item.onClick}
          className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
        >
          {item.name}
        </Link>
      ))}
    </div>
  </>
);
export default ProfileMenuPhone;
