import PropTypes from "prop-types";

import { classNames } from "../../../utility";

const colors = [
  "gray",
  "red",
  "yellow",
  "green",
  "blue",
  "indigo",
  "purple",
  "pink"
];
const getColorByLetter = (letter) => {
  const colorNumber = Math.ceil(
    (letter.toLowerCase().charCodeAt(0) - 96) / (26 / colors.length) - 1
  );

  const color = colors[colorNumber];

  switch (color) {
    case "gray":
      return "bg-gray-500";
    case "red":
      return "bg-red-500";
    case "yellow":
      return "bg-yellow-500";
    case "green":
      return "bg-green-500";
    case "blue":
      return "bg-blue-500";
    case "indigo":
      return "bg-indigo-500";
    case "purple":
      return "bg-purple-500";
    case "pink":
      return "bg-pink-500";
    default:
      return "bg-gray-500";
  }
};
const CapitalLetterAvatar = ({ letter, width, height, className }) =>
  letter ? (
    <span
      style={{
        width,
        height
      }}
      className={classNames(
        `inline-flex items-center justify-center rounded-full w-8 h-8 ${className}`,
        getColorByLetter(letter)
      )}
    >
      <span className="font-medium leading-none text-white text-lg">
        {letter.toUpperCase()}
      </span>
    </span>
  ) : null;
CapitalLetterAvatar.defaultProps = {
  width: "",
  height: "",
  className: ""
};

CapitalLetterAvatar.propTypes = {
  letter: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};
export default CapitalLetterAvatar;
