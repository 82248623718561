import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { statusUserEnum } from "../../../../graphql/enum";
import deleteUserOrganization from "../../../../graphql/mutation/deleteUserOrganization";
import updateUserOrganization from "../../../../graphql/mutation/updateUserOrganization";
import Button from "../../../layout/Button";

const EditPeopleUserModal = ({
  selectedUser,
  onClose,
  refetch,
  organizationId
}) => {
  const { t } = useTranslation();
  const [updateUserOrganizationMutation] = useMutation(updateUserOrganization);
  const [deleteUserOrganizationMutation] = useMutation(deleteUserOrganization);
  const updateStatusUser = async (userId, isDeactivated) => {
    await updateUserOrganizationMutation({
      variables: {
        updateUserOrganizationInput: {
          userId,
          organizationId,
          isDeactivated
        }
      }
    });
    refetch();
  };
  const deleteUser = async (userId) => {
    const { data } = await deleteUserOrganizationMutation({
      variables: {
        userId,
        organizationId
      }
    });
    if (data.deleteUserOrganization.success) {
      refetch();
      onClose();
    }
  };
  return (
    <>
      <div className="bg-white shadow sm:rounded-lg mt-10">
        <div className="px-4 py-5 sm:p-6">
          {selectedUser.status !== statusUserEnum.pendingInvitation ? (
            <div className="sm:flex sm:items-start sm:justify-between">
              <div>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>{t("editPeopleUserModal.deactiveUserDescription")}</p>
                </div>
              </div>
              <div className="mt-5 sm:mt-0 sm:flex-shrink-0 sm:flex sm:items-center">
                <div className="block rounded-lg">
                  <Button
                    layout="white"
                    onClick={async () =>
                      updateStatusUser(
                        selectedUser.id,
                        selectedUser.status === statusUserEnum.active
                      )
                    }
                  >
                    {selectedUser.status !== statusUserEnum.active
                      ? t("editPeopleUserModal.activate")
                      : t("editPeopleUserModal.deactivate")}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  {t("editPeopleUserModal.deleteUserDescription")}{" "}
                  <span className="font-bold">
                    {t("editPeopleUserModal.deleteUserDescriptionStrong")}
                  </span>
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 sm:flex-shrink-0 sm:flex sm:items-center">
              <div className="block rounded-lg">
                <Button
                  layout="red"
                  onClick={() => deleteUser(selectedUser.id)}
                >
                  {t("editPeopleUserModal.deleteUser")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
EditPeopleUserModal.propTypes = {
  selectedUser: PropTypes.object,
  onClose: PropTypes.func,
  refetch: PropTypes.func.isRequired,
  organizationId: PropTypes.string
};
export default EditPeopleUserModal;
