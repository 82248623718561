import PropTypes from "prop-types";

const getClassByColor = (color) => {
  if (color === "green") return "bg-green-100 text-green-800";
  if (color === "yellow") return "bg-yellow-100 text-yellow-800";
  if (color === "red") return "bg-red-100 text-red-800";
  if (color === "blue") return "bg-blue-100 text-blue-800";
  if (color === "purple") return "bg-purple-100 text-purple-800";
  if (color === "pink") return "bg-pink-100 text-pink-800";
  if (color === "gray") return "text-gray-700 border";

  return "";
};
const Badge = ({ children, color, className }) => (
  <span
    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getClassByColor(
      color
    )}  ${className}`}
  >
    {children}
  </span>
);

Badge.propTypes = {
  color: PropTypes.any.isRequired,
  children: PropTypes.any,
  className: PropTypes.string
};

export default Badge;
