import { gql } from "@apollo/client";

const updateUserOrganization = gql`
  mutation signInQuery($input: SignInInput!) {
    signIn(input: $input) {
      code
      success
      jwtToken
    }
  }
`;

export default updateUserOrganization;
