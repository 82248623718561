import { gql } from "@apollo/client";

const CreateCheckInQuestions = gql`
  mutation createCheckInQuestions($input: [CreateCheckInQuestionsInput]) {
    createCheckInQuestions(input: $input) {
      success
    }
  }
`;

export default CreateCheckInQuestions;
