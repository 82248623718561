/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import momentTimeZone from "moment-timezone";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  createOrganizationAccountMutationCode,
  validationErrors
} from "../../../../graphql/enum";
import createOrganizationAccountGql from "../../../../graphql/mutation/createOrganizationAccount";
import { loginUrl } from "../../../../routes";
import { validateEmail, validatePassword } from "../../../../utility";
import { UserContext } from "../../../../utility/context/User";
import Button from "../../../layout/Button";
import Input from "../../../layout/Input";
import Link from "../../../layout/Link";

const CreateOrganizationAccountForm = () => {
  const { t } = useTranslation();
  const { login } = useContext(UserContext);
  const [isFaildValidateOnChange, setIsFaildValidateOnChange] = useState({
    validateOnChange: false
  });
  const [createOrganizationAccountMutation] = useMutation(
    createOrganizationAccountGql
  );

  const validate = ({ name, email, password }) => {
    const errors = {};
    if (!name) {
      errors.name = t(`validationErrors.${validationErrors.required}`);
    }

    if (!validateEmail(email)) {
      errors.email = t(`validationErrors.emailIsNotCorrect`);
    }
    if (email.length === 0) {
      errors.email = t(`validationErrors.${validationErrors.required}`);
    }

    const passwordError = validatePassword(password, t);
    if (passwordError !== null) errors.password = passwordError;

    return errors;
  };
  const onCreateAccount = async (value, { setErrors }) => {
    setIsFaildValidateOnChange({ validate });
    const errors = validate(value);
    setErrors(errors);
    if (Object.keys(errors).length > 0) return;
    const timeZone = momentTimeZone.tz.guess();
    const { data } = await createOrganizationAccountMutation({
      variables: {
        input: {
          name: value.name,
          email: value.email,
          password: value.password,
          timeZone
        }
      }
    });

    if (data.createOrganizationAccount.success) {
      const { jwtToken } = data.createOrganizationAccount;
      login(jwtToken);
    }

    if (
      data.createOrganizationAccount.code ===
      createOrganizationAccountMutationCode.userIsAlreadyInOrganization
    ) {
      setErrors({ email: t("createAccount.userAlreadyInOrganization") });
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        name: ""
      }}
      onSubmit={onCreateAccount}
      {...{ ...isFaildValidateOnChange }}
    >
      {({ errors }) => (
        <Form id="create-organization">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              {t("createAccount.fullName")}
            </label>
            <div className="mt-1">
              <Input
                name="name"
                type="text"
                id="name"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                error={errors.name}
                errorclassname={{ notError: "m-0", error: "m-0" }}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("createAccount.emailAddress")}
            </label>
            <div className="mt-1">
              <Input
                name="email"
                type="email"
                id="email"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                error={errors.email}
                errorclassname={{ notError: "m-0", error: "m-0" }}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              {t("createAccount.password")}
            </label>
            <div className="mt-1">
              <Input
                name="password"
                type="password"
                id="password"
                error={errors.password}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                errorclassname={{ notError: "m-0", error: "m-0" }}
              />
            </div>
          </div>

          <div className="flex items-center justify-end">
            <div>
              <Link to={loginUrl} className="font-medium">
                {t("createAccount.alreadyHaveAnAccount")}
              </Link>
            </div>
          </div>

          <div className="mt-6">
            <Button
              type="submit"
              layout="primary"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium "
            >
              {t("createAccount.signUp")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrganizationAccountForm;
