import { gql } from "@apollo/client";

const resendTeamInvite = gql`
  mutation resendTeamInvite($teamId: ID!, $userId: ID!) {
    resendTeamInvite(teamId: $teamId, userId: $userId) {
      success
      code
    }
  }
`;
export default resendTeamInvite;
