import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  integrationTypes,
  setupStatusEnum
} from "../../../../../../../graphql/enum";
import updateCheckIn from "../../../../../../../graphql/mutation/updateCheckIn";
import ConnectTaskTrackerButton from "../ConnectTaskTrackerButton/ConnectTaskTrackerButton";

const ConnectTaskTrackerBlock = ({
  type,
  bitrixDomain,
  checkInId,
  dataSourceId
}) => {
  const { t } = useTranslation();
  const [updateCheckInMutation] = useMutation(updateCheckIn);

  const onClickTrackerButton = async () => {
    await updateCheckInMutation({
      variables: {
        checkInId,
        input: {
          setupStatus: setupStatusEnum.taskLists,
          bitrixDomain
        }
      }
    });
  };
  return (
    <div className="w-6/12 mx-auto text-center">
      <div className="text-lg leading-6 font-medium text-gray-900 ">
        {t("connectTaskTrackerSection.previewTaskLists")}
      </div>
      <div className="text-gray-500 mt-2">
        {t("connectTaskTrackerSection.previewTaskListsDescription")}
      </div>
      <div className="mt-12">
        <ConnectTaskTrackerButton
          type={type}
          bitrixDomain={bitrixDomain}
          checkInId={checkInId}
          onClick={onClickTrackerButton}
          dataSourceId={dataSourceId}
        />{" "}
      </div>
    </div>
  );
};
ConnectTaskTrackerBlock.propTypes = {
  type: PropTypes.oneOf(Object.values(integrationTypes)),
  bitrixDomain: PropTypes.string,
  checkInId: PropTypes.string.isRequired,
  dataSourceId: PropTypes.string
};
export default ConnectTaskTrackerBlock;
