import PropTypes from "prop-types";

import DisabledInput from "./DisabledInput";
import Input from "./Input";

const InputView = (props) => {
  if (props.disabled) return <DisabledInput {...{ ...props }} />;
  return <Input {...{ ...props }} />;
};

InputView.propTypes = {
  disabled: PropTypes.bool,
  addonafter: PropTypes.object,
  errorclassname: PropTypes.shape({
    error: PropTypes.string,
    notError: PropTypes.string
  })
};

InputView.defaultProps = {
  disabled: false
};
export default InputView;
