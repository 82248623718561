/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  checkInRunSetupStatusEnum,
  mutationCodes
} from "../../../../graphql/enum";
import createCheckInRun from "../../../../graphql/mutation/createCheckInRun";
import { cleanObject } from "../../../../graphql/utils/utils";
import { getCheckInUrl } from "../../../../routes";
import { convertStringToInteger } from "../../../../utility";
import Button from "../../../layout/Button";
import Page from "../../../layout/Page";
import { DashboardFilters } from "./DashboardFilters/DashboardFilters";
import DashboardTable from "./DashboardTable";

const DashboardTab = ({ checkInId, alert }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);

  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const search = parameters.get("search");
  const checkInQuestionId = parameters.get("checkInQuestionId");
  const offset = parameters.get("offset");
  const limit = parameters.get("limit");
  const [page, setPage] = useState({
    offset: !offset ? 0 : convertStringToInteger(offset),
    limit: !limit ? 10 : convertStringToInteger(limit)
  });
  const [filters, setFilters] = useState({
    search: search || "",
    dateFrom: dateFrom ? new Date(dateFrom) : defaultDateFrom,
    dateTo: dateTo ? new Date(dateTo) : defaultDateTo,
    checkInQuestionId
  });
  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: getCheckInUrl(checkInId),
      query: cleanObject({
        activeTab: "dashboard",
        search: filters.search,
        checkInQuestionId: filters.checkInQuestionId,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        offset: page.offset,
        limit: page.limit
      })
    });
    history.push(url);
  }, [page, filters, history, checkInId]);

  const [createCheckInRunMutation] = useMutation(createCheckInRun);

  return (
    <div>
      <div className="flex justify-end">
        <div className="mr-2 mb-5">
          <Button
            onClick={async () => {
              const { data } = await createCheckInRunMutation({
                variables: {
                  checkInId,
                  setupStatus: checkInRunSetupStatusEnum.createItems
                }
              });
              if (data?.createCheckInRun?.code === mutationCodes.success) {
                const url = queryString.stringifyUrl({
                  url: getCheckInUrl(checkInId),
                  query: {
                    checkInRunId: data?.createCheckInRun?.id
                  }
                });
                history.push(url);
                return;
              }

              alert.error(
                <div>
                  <p className="text-black">{t("retrospective.error")}</p>
                  <p className="text-gray-500">
                    {t("retrospective.errorDescriotion")}
                  </p>
                </div>
              );
            }}
          >
            {t("retrospective.startButton")}
          </Button>
        </div>
      </div>
      <Page
        sidebar={
          <div className="w-48 sticky top-4">
            <DashboardFilters
              filters={filters}
              setFilters={setFilters}
              checkInId={checkInId}
            />
          </div>
        }
      >
        <DashboardTable
          filters={filters}
          checkInId={checkInId}
          page={page}
          setPage={setPage}
        />
      </Page>
    </div>
  );
};
DashboardTab.propTypes = {
  checkInId: PropTypes.string.isRequired,
  alert: PropTypes.object
};
export default withAlert()(DashboardTab);
