import { gql } from "@apollo/client";

const sendTeamInvites = gql`
  mutation sendTeamInvites($teamId: ID!, $users: [UserTeamRole]) {
    sendTeamInvites(teamId: $teamId, users: $users) {
      success
      code
    }
  }
`;

export default sendTeamInvites;
