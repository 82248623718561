import PropTypes from "prop-types";
import React from "react";

import Avatar from "../../../../../layout/Avatar";

const calculateHeight = (height, tasks, allTasks) =>
  (height / allTasks) * tasks;

const CustomBar = ({
  x,
  y,
  width,
  height,
  avatar,
  name,
  plannedTasks,
  doneTasks,
  avatarWidth,
  allTasks,
  avatarHeight
}) => (
  <>
    <svg>
      {doneTasks ? (
        <rect
          x={x}
          y={
            y +
            (plannedTasks ? calculateHeight(height, plannedTasks, allTasks) : 0)
          }
          width={width}
          height={calculateHeight(height, doneTasks, allTasks)}
          stroke="none"
          fill={allTasks ? "#34D399" : "silver"}
        />
      ) : null}
      {plannedTasks ? (
        <rect
          x={x}
          y={y}
          width={width}
          height={
            allTasks ? calculateHeight(height, plannedTasks, allTasks) : 0
          }
          stroke="none"
          fill="#38BDF8"
        />
      ) : null}
      {!allTasks ? (
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke="none"
          fill="silver"
        />
      ) : null}
      <foreignObject
        x={x + width / 2 - avatarWidth / 2}
        y={y - avatarHeight - 5}
        width={avatarWidth}
        height={avatarHeight}
        className="d-block"
      >
        <Avatar
          width={avatarWidth}
          height={avatarHeight}
          alt={name}
          src={avatar}
          name={name}
          className="rounded-full object-contain max-w-full"
        />
      </foreignObject>
    </svg>
  </>
);

CustomBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  avatar: PropTypes.string,
  name: PropTypes.string,
  doneTasks: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  plannedTasks: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allTasks: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  avatarWidth: PropTypes.number,
  avatarHeight: PropTypes.number
};

export default CustomBar;
