import { gql } from "@apollo/client";

const createOrganizationAccount = gql`
  mutation createOrganizationAccount($input: CreateOrganizationAccountInput!) {
    createOrganizationAccount(input: $input) {
      success
      code
      jwtToken
    }
  }
`;

export default createOrganizationAccount;
