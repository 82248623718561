/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { validationErrors } from "../../graphql/enum";
import updateUser from "../../graphql/mutation/updateUser";
import { CURRENT_USER, UserContext } from "../../utility/context/User";
import Button from "../layout/Button";
import Loading from "../layout/Loading/Loading";
import AccountSection from "./AccountSection/AccountSection";
import TimeZoneSection from "./TimeZoneSection/TimeZoneSection";

export const getCurrentUser = gql`
  query getCurrentUser {
    currentUser {
      id
      name
      avatar
      email
      organizations {
        role
        organization {
          id
          title
          ownerId
        }
      }
    }
  }
`;
const ProfileView = ({ alert }) => {
  const { loading, data } = useQuery(getCurrentUser);
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  const [updateUserMutation] = useMutation(updateUser);

  const [userData, setUserData] = useState();
  const [timeZoneSelected, setTimeZoneSelected] = useState({
    id: user.timeZone,
    name: `${user.timeZone} (${momentTimeZone().tz(user.timeZone).format("Z")})`
  });

  useEffect(() => {
    if (!data) return;
    setUserData({
      user: data.currentUser,
      organization: data.currentUser.organizations.organization
    });
  }, [data]);

  const onSubmit = async (values) => {
    await updateUserMutation({
      variables: {
        updateUserInput: {
          name: values.userName,
          timeZone: timeZoneSelected.id
        }
      },
      optimisticResponse: true,
      update: (cache) => {
        const { currentUser } = cache.readQuery({
          query: CURRENT_USER
        });
        const newData = {
          currentUser: {
            ...currentUser,
            name: values.userName,
            timeZone: timeZoneSelected.id
          }
        };
        cache.writeQuery({
          query: CURRENT_USER,
          data: newData
        });
      }
    });

    alert.success(
      <div>
        <p className="text-black">{t("settingsTab.successfullySaved")}</p>
      </div>
    );
  };
  const validate = (values) => {
    const errors = {};

    if (!values.userName) {
      errors.userName = t(`validationErrors.${validationErrors.required}`);
    }

    return errors;
  };
  if (loading || !userData) return <Loading />;
  return (
    <>
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate  mb-6">
        {t("account.profile")}
      </h2>
      <div className="mx-auto">
        <Formik
          validate={validate}
          initialValues={{
            userName: userData?.user?.name
          }}
          onSubmit={onSubmit}
        >
          {({ errors }) => (
            <Form>
              <AccountSection {...{ errors, user: userData?.user }} />
              <TimeZoneSection {...{ timeZoneSelected, setTimeZoneSelected }} />
              <div className="flex justify-end mt-8">
                <div>
                  <Button layout="primary" type="submit">
                    {t("Save")}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
ProfileView.propTypes = {
  alert: PropTypes.object
};
export const Profile = withAlert()(ProfileView);
