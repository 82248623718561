import { gql } from "@apollo/client";

const createOrganizationInviteLink = gql`
  mutation createOrganizationInviteLink($organizationId: ID!) {
    createOrganizationInviteLink(organizationId: $organizationId) {
      success
      link
    }
  }
`;
export default createOrganizationInviteLink;
