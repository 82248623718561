import { gql } from "@apollo/client";

const approveTeamInvite = gql`
  mutation approveTeamInvite($code: String!) {
    approveTeamInvite(code: $code) {
      success
      code
      jwtToken
    }
  }
`;

export default approveTeamInvite;
