import PropTypes from "prop-types";

import ListOfDescription from "./ListOfDescription";
import StackedCards from "./StackedCards";

const RadioGroup = (props) => {
  const { layout } = props;

  if (layout === "list") return <ListOfDescription {...{ ...props }} />;
  if (layout === "stacked") return <StackedCards {...{ ...props }} />;

  return null;
};
RadioGroup.defaultProps = {
  layout: "list"
};
RadioGroup.propTypes = {
  layout: PropTypes.oneOf(["list", "stacked"])
};
export default RadioGroup;
