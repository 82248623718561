/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { organizationRoles, statusUserEnum } from "../../graphql/enum";
import { UserContext } from "../../utility/context/User";
import { botIsNotIstalledLink, organizationPeopleUrl } from "..";

export const AuthorizedRouteRedirect = ({ children }) => {
  const { user } = useContext(UserContext);
  if (!user) {
    return <Redirect to="/login" />;
  }

  if (!user.organization) {
    return <Redirect to={organizationPeopleUrl} />;
  }

  if (!user.isBotAdded && user.organization.role !== organizationRoles.owner) {
    return <Redirect to={botIsNotIstalledLink} />;
  }

  return children;
};

export const NonAuthorizedRouteRedirect = ({ children }) => {
  const { user } = useContext(UserContext);
  const query = new URLSearchParams(window.location.search);
  const redirectUrl = query.get("redirect_uri");

  if (redirectUrl && user) {
    window.location = redirectUrl;
    return true;
  }
  return user ? <Redirect to="/" /> : children;
};

export const ApproveInviteRedirect = ({ children }) => {
  const { user } = useContext(UserContext);
  return !user ? (
    <Redirect to={`/login?redirect_uri=${window.location.href}`} />
  ) : (
    children
  );
};

export const NotVerificationEmail = ({ children }) => {
  const { user } = useContext(UserContext);
  if (user.status !== statusUserEnum.emailNotVerified) {
    return <Redirect to="/" />;
  }
  return children;
};

export const ApproveInviteRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <ApproveInviteRedirect>
        <Component {...routeRenderProps} />
      </ApproveInviteRedirect>
    )}
  />
);

export const PublicRouteRedirect = ({ children }) => children;

export const AuthorizedAppRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <AuthorizedRouteRedirect>
        <Component {...routeRenderProps} />
      </AuthorizedRouteRedirect>
    )}
  />
);

export const PublicAppRoute = ({ component: Component, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <PublicRouteRedirect>
        <Component {...routeRenderProps} />
      </PublicRouteRedirect>
    )}
  />
);

export const NotAuthorizedAppRoute = ({
  component: Component,
  ...routeProps
}) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <NonAuthorizedRouteRedirect>
        <Component {...routeRenderProps} />
      </NonAuthorizedRouteRedirect>
    )}
  />
);

export const NotVerificationEmailAppRoute = ({
  component: Component,
  ...routeProps
}) => (
  <Route
    {...routeProps}
    render={(routeRenderProps) => (
      <NotVerificationEmail>
        <Component {...routeRenderProps} />
      </NotVerificationEmail>
    )}
  />
);
