import PropTypes from "prop-types";
import React from "react";
import { Dot } from "recharts";

const CustomScatter = ({ cx, cy, x, fill, index }) => (
  <g>
    <Dot x={x} cx={cx + index * 10} cy={cy} r={5} fill={fill} />
  </g>
);

CustomScatter.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  x: PropTypes.number,
  index: PropTypes.number,
  fill: PropTypes.string
};

export default CustomScatter;
