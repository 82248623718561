import { gql } from "@apollo/client";

const changeIntegrationTypeCheckIn = gql`
  mutation changeIntegrationTypeCheckIn(
    $input: ChangeIntegrationTypeCheckInInput!
  ) {
    changeIntegrationTypeCheckIn(input: $input) {
      success
      code
    }
  }
`;

export default changeIntegrationTypeCheckIn;
