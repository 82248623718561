import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { validationErrors } from "../../../../graphql/enum";
import updateCheckInNotification from "../../../../graphql/mutation/updateCheckInNotification";
import Button from "../../../layout/Button";
import Alerts from "./Alerts/Alerts";
import Reminders from "./Reminders/Reminders";
import ScheduledReports from "./ScheduledReports/ScheduledReports";

const SettingsTab = ({ checkIn, alert, query }) => {
  const [updateCheckInNotificationMutation] = useMutation(
    updateCheckInNotification
  );
  const { t } = useTranslation();
  const reminderTimes = [
    {
      id: "5:00",
      name: "Every 5 min"
    },
    {
      id: "10:00",
      name: "Every 10 min"
    },
    {
      id: "15:00",
      name: "Every 15 min"
    },
    {
      id: "30:00",
      name: "Every 30 min"
    }
  ];

  const [reminderTime, setReminderTime] = useState(
    reminderTimes.find(
      (reminderTimeItem) =>
        reminderTimeItem.id === checkIn.notification.reminderTime
    )
  );
  const [checkInData, setCheckInData] = useState({
    isScheduledReportsWeekly: !!checkIn.notification.isScheduledReportsWeekly,
    isScheduledReportsMonthly: !!checkIn.notification.isScheduledReportsMonthly,
    isAlertDeleteChatBot: !!checkIn.notification.isAlertDeleteChatBot,
    isAlertNoTasksConfiguration:
      !!checkIn.notification.isAlertNoTasksConfiguration,
    isUserNoResponse: !!checkIn.notification.isUserNoResponse,
    userNoResponseDays: checkIn.notification.userNoResponseDays,
    numberReminders: parseInt(checkIn.notification.numberReminders, 10),
    isReminders: !!checkIn.notification.isReminders
  });
  const validate = () => {
    const errors = {};

    if (checkInData.userNoResponseDays < 1) {
      errors.userNoResponseDays = t(
        `validationErrors.${validationErrors.minimumValue}`,
        { value: 1 }
      );
    }

    if (checkInData.numberReminders < 1) {
      errors.numberReminders = t(
        `validationErrors.${validationErrors.minimumValue}`,
        { value: 1 }
      );
    }
    return errors;
  };

  const onSubmit = async () => {
    await updateCheckInNotificationMutation({
      variables: {
        checkInId: checkIn.id,
        input: {
          ...checkInData,
          reminderTime: reminderTime.id
        }
      },
      optimisticResponse: true,
      update: async (cache) => {
        const dataQuery = await cache.readQuery({
          query,
          variables: {
            checkInId: parseInt(checkIn.id, 10)
          }
        });
        const newData = {
          checkIn: {
            ...dataQuery.checkIn,
            notification: {
              ...checkInData,
              reminderTime: reminderTime.id
            }
          }
        };
        cache.writeQuery({
          data: newData,
          query,
          variables: {
            checkInId: parseInt(checkIn.id, 10)
          }
        });
      }
    });

    alert.success(
      <div>
        <p className="text-black">{t("settingsTab.successfullySaved")}</p>
      </div>
    );
  };

  return (
    <>
      <Formik initialValues={{}} onSubmit={onSubmit} validate={validate}>
        {({ errors }) => (
          <Form>
            <ScheduledReports
              setCheckInData={setCheckInData}
              checkInData={checkInData}
            />
            <Alerts
              setCheckInData={setCheckInData}
              checkInData={checkInData}
              teamRole={checkIn?.team?.role}
              errors={errors}
            />
            <Reminders
              usersTeam={checkIn.team.users}
              setCheckInData={setCheckInData}
              checkInData={checkInData}
              reminderTimes={reminderTimes}
              reminderTime={reminderTime}
              setReminderTime={setReminderTime}
              errors={errors}
            />
            <div className="flex justify-end mt-8">
              <div>
                <Button layout="primary" type="submit">
                  {t("Save")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
SettingsTab.propTypes = {
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    notification: PropTypes.shape({
      isScheduledReportsWeekly: PropTypes.bool.isRequired,
      isScheduledReportsMonthly: PropTypes.bool.isRequired,
      isAlertDeleteChatBot: PropTypes.bool.isRequired,
      isAlertNoTasksConfiguration: PropTypes.bool.isRequired,
      isUserNoResponse: PropTypes.bool.isRequired,
      userNoResponseDays: PropTypes.number.isRequired,
      isReminders: PropTypes.bool.isRequired,
      numberReminders: PropTypes.number.isRequired,
      reminderTime: PropTypes.string.isRequired
    }).isRequired,
    team: PropTypes.shape({
      role: PropTypes.string,
      users: PropTypes.array
    })
  }),
  alert: PropTypes.object,
  query: PropTypes.object.isRequired
};

export default withAlert()(SettingsTab);
