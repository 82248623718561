import { gql, useQuery } from "@apollo/client";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import Loading from "../../layout/Loading/Loading";
import ResetPasswordForm from "./ResetPasswordForm/ResetPasswordForm";

export const userConfirmationCode = gql`
  query userConfirmationCode($code: String!) {
    userConfirmationCode(code: $code) {
      user {
        email
      }
      code
    }
  }
`;

const ResetPassword = () => {
  const { t } = useTranslation();
  const { code } = queryString.parse(window.location.search);

  const { data, loading } = useQuery(userConfirmationCode, {
    variables: {
      code
    }
  });
  if (loading) {
    return <Loading />;
  }

  return (
    <div className=" flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src="/assets/img/standupify_icon_256px.png"
          alt="Standupify"
        />
        <h2
          className="mt-6 text-center font-extrabold text-gray-900 mb-0"
          style={{ fontSize: "1.85rem" }}
        >
          {t("resetPassword.setUpNewPassword")}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 mb-0">
          {t("resetPassword.paeaseEnterNewPassword")}
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <ResetPasswordForm response={data.userConfirmationCode} code={code} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
