import { XIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import Input from "../../../../layout/Input";
import Table from "../../../../layout/Table/Table";
import ImportTaskModal from "../ImportTaskModal/ImportTaskModal";

const PlanningPokerStartTable = ({
  questions,
  setQuestions,
  errors,
  alert,
  setFieldValue,
  isImportTaskVisible,
  setIsImportTaskInModalVisible,
  lastId,
  setLastId
}) => {
  const { t } = useTranslation();
  const removeTask = (id) => {
    const newQuestions = questions.filter((question) => question.id !== id);
    setQuestions(newQuestions);
  };

  const dataRender = [
    [
      {
        id: `title-${questions[0].id}`,
        render: () => (
          <div className="-mt-3 -mb-4">
            {questions.map((question, idx) => (
              <div className="pt-3" key={idx}>
                <Input
                  type="text"
                  name={`checkInLink[${question.id}]`}
                  placeholder={t("planningPokerStart.enterStoryLink")}
                  error={
                    errors.checkInLink ? errors.checkInLink[question.id] : null
                  }
                />
              </div>
            ))}
          </div>
        ),
        className: ""
      },
      {
        id: `story-${questions[0].id}`,
        render: () => (
          <div className="-mt-3 -mb-4 -ml-10 -mr-24">
            {questions.map((question, idx) => (
              <div className="pt-3" key={idx}>
                <Input
                  type="text"
                  name={`checkInTitle[${question.id}]`}
                  placeholder={t("planningPokerStart.enterStoryName")}
                  error={
                    errors.checkInTitle
                      ? errors.checkInTitle[question.id]
                      : null
                  }
                />
              </div>
            ))}
          </div>
        ),
        className: ""
      },
      {
        id: `remove-${questions[0].id}`,
        render: () => (
          <div className="-mt-3 -mb-4">
            {questions.map((question, idx) => (
              <div
                className="pt-5 flex justify-end"
                style={{ paddingBottom: "1.68rem" }}
                key={idx}
              >
                <XIcon
                  className="h-6 w-6 text-sm text-gray-400 font-thin stroke-1 cursor-pointer hover:text-gray-500"
                  aria-hidden="true"
                  onClick={() => {
                    if (questions.length > 1) {
                      removeTask(question.id);
                      return;
                    }
                    alert.error(
                      <div>
                        <p className="text-black">
                          {t("planningPokerStart.error")}
                        </p>
                        <p className="text-gray-500">
                          {t("planningPokerStart.thisLastElement")}
                        </p>
                      </div>
                    );
                  }}
                />
              </div>
            ))}
          </div>
        ),
        className: "text-lerft"
      }
    ]
  ];
  const columns = [
    {
      title: t("planningPokerStart.link"),
      isVisible: true,
      className: "min-w-320 text-center"
    },
    {
      title: t("planningPokerStart.title"),
      isVisible: true,
      className: "min-w-180 text-center"
    },
    {
      tilte: t("planningPokerStart.delete"),
      isVisible: false,
      className: ""
    }
  ];
  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:pl-2 lg:pr-2">
            <Table
              key="tabel"
              {...{ columns }}
              data={dataRender}
              typeResultText={t("pagination.users")}
            />
          </div>
        </div>
      </div>
      <ImportTaskModal
        setFieldValue={setFieldValue}
        lastId={lastId}
        setLastId={setLastId}
        visible={isImportTaskVisible}
        questions={questions}
        setQuestions={setQuestions}
        onClose={() => setIsImportTaskInModalVisible(false)}
      />
    </>
  );
};

PlanningPokerStartTable.propTypes = {
  alert: PropTypes.shape({
    success: PropTypes.func.isRequired,
    error: PropTypes.func.isRequired
  }),
  questions: PropTypes.array.isRequired,
  setQuestions: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isImportTaskVisible: PropTypes.bool.isRequired,
  setIsImportTaskInModalVisible: PropTypes.func.isRequired,
  lastId: PropTypes.number.isRequired,
  setLastId: PropTypes.func.isRequired
};
export default withAlert()(PlanningPokerStartTable);
