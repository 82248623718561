import ApproveEmailVerification from "./ApproveEmailVerification";
import ApproveOrganizationInvite from "./ApproveOrganizationInvite";
import ApproveOrganizationInviteLink from "./ApproveOrganizationInviteLink";
import ApprovePeopleInvite from "./ApprovePeopleInvite";
import ApproveTeamInvite from "./ApproveTeamInvite";
import ApproveTeamInviteLink from "./ApproveTeamInviteLink";
import BotNotInstalled from "./BotNotInstalled";
import CheckIns from "./CheckIns";
import CheckInSingle from "./CheckInSingle";
import CreateOrganizationAccount from "./CreateOrganizationAccount";
import GitHubOauthCallback from "./GitHubOauthCallback";
import Home from "./Home";
import JiraOauthCallback from "./JiraOauthCallback";
import Login from "./Login";
import Organisation from "./Organisation";
import People from "./People";
import PrivacyPolicy from "./PrivacyPolicy";
import Profile from "./Profile";
import ResetPassword from "./ResetPassword";
import ResetPasswordRequest from "./ResetPasswordRequest";
import Teams from "./Teams";
import TeamSingleView from "./TeamSingle";
import TermsOfService from "./TermsOfService";
import VerificationEmail from "./VerificationEmail";

export default {
  ApproveOrganizationInvite,
  BotNotInstalled,
  CreateOrganizationAccount,
  Home,
  JiraOauthCallback,
  Login,
  People,
  PrivacyPolicy,
  Profile,
  Teams,
  TeamSingleView,
  TermsOfService,
  GitHubOauthCallback,
  ApproveTeamInvite,
  ApproveTeamInviteLink,
  ApprovePeopleInvite,
  ApproveOrganizationInviteLink,
  ApproveEmailVerification,
  CheckInSingle,
  VerificationEmail,
  ResetPasswordRequest,
  ResetPassword,
  CheckIns,
  Organisation
};
