/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";

import { classNames } from "../../../utility/index";

const Menu = ({ navigation }) => (
  <div className="hidden sm:block sm:ml-6">
    <div className="flex space-x-4 testing-library-menu">
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          className={classNames(
            item.current
              ? "bg-gray-900 text-white"
              : "text-gray-300 hover:bg-gray-700",
            "rounded-md text-sm font-medium  px-3 py-2 "
          )}
          aria-current={item.current ? "page" : undefined}
        >
          {item.name}
        </Link>
      ))}
    </div>
  </div>
);
export default Menu;
