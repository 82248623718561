/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Loading from "../components/layout/Loading/Loading";
import { gitHubAuthorizeEroors, integrationTypes } from "../graphql/enum";
import createGitHubIntegration from "../graphql/mutation/createGitHubIntegration";
import setCheckInQuestion from "../graphql/mutation/setCheckInQuestion";
import { getCheckInUrl, gitHubAuthorizeUrl } from "../routes";
import Error from "./forbidden/Error/Error";

const GithubOauthCallback = ({ location }) => {
  const history = useHistory();
  const { code, state, error } = queryString.parse(location.search);

  const { checkInId } = JSON.parse(state);

  const [createGitHubIntegrationMutation, { data }] = useMutation(
    createGitHubIntegration
  );
  const [setCheckInQuestionMutation] = useMutation(setCheckInQuestion);

  useEffect(() => {
    if (code)
      createGitHubIntegrationMutation({ variables: { code, checkInId } });
  }, [code, createGitHubIntegrationMutation, checkInId]);

  const setCheckIn = useCallback(
    async (integrationId) => {
      await setCheckInQuestionMutation({
        variables: {
          checkInId,
          integrationId,
          integrationType: integrationTypes.gitHub
        }
      });
      history.push(getCheckInUrl(checkInId));
    },
    [setCheckInQuestionMutation, checkInId, history]
  );
  useEffect(() => {
    if (data) {
      if (checkInId) {
        setCheckIn(data.createGitHubIntegration.id);
      }
    }
  }, [data, history, checkInId, setCheckIn]);

  if (error === gitHubAuthorizeEroors.applicationSuspended)
    return (
      <Error
        title={"gitHubOauthCallback.applicationSuspended"}
        isAddButton={true}
        href={gitHubAuthorizeUrl}
        buttonTitle={"gitHubOauthCallback.tryOnceMore"}
      />
    );

  if (error === gitHubAuthorizeEroors.redirectUriMismatch)
    return (
      <Error
        title={"gitHubOauthCallback.redirectUriMismatch"}
        isAddButton={true}
        href={gitHubAuthorizeUrl}
        buttonTitle={"gitHubOauthCallback.tryOnceMore"}
      />
    );

  if (error === gitHubAuthorizeEroors.accessDenied)
    return (
      <Error
        title={"gitHubOauthCallback.accessDinied"}
        isAddButton={true}
        href={gitHubAuthorizeUrl}
        buttonTitle={"gitHubOauthCallback.tryOnceMore"}
      />
    );
  if (error)
    return (
      <Error
        title={"gitHubOauthCallback.somethingWentWrong"}
        isAddButton={true}
        href={gitHubAuthorizeUrl}
        buttonTitle={"gitHubOauthCallback.tryOnceMore"}
      />
    );

  return <Loading />;
};
GithubOauthCallback.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  })
};

export default GithubOauthCallback;
