/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../../../../../layout/Input/Input";
import Loading from "../../../../../../../layout/Loading/Loading";
import Select from "../../../../../../../layout/Select";

export const previewProjects = gql`
  query previewUsers($id: ID!) {
    previewDataSource(id: $id) {
      id
      organizations {
        id
        name
      }
    }
  }
`;

export const GithubSearchForm = ({
  dataSourceId,
  setPreviewFilter,
  previewFilter,
  formclassname
}) => {
  const { data, loading } = useQuery(previewProjects, {
    variables: {
      id: dataSourceId
    }
  });

  const [currentLimit, setCurrentLimit] = useState(null);
  const [currentUpdatedDaysAgo, setCurrentUpdatedDaysAgo] = useState(null);

  useEffect(() => {
    setCurrentLimit(previewFilter.limit || "");
    setCurrentUpdatedDaysAgo(previewFilter.updatedDaysAgo || "");
  }, [previewFilter]);

  const { t } = useTranslation();

  const statuses = [
    {
      id: "all",
      name: t("All")
    },
    {
      id: "open",
      name: t("Open")
    },
    {
      id: "closed",
      name: t("Closed")
    }
  ];
  const filters = [
    {
      id: "all",
      name: t("All")
    },
    {
      id: "assigned",
      name: t("Assigned")
    },
    {
      id: "created",
      name: t("Created")
    },
    {
      id: "mentioned",
      name: t("Mentioned")
    },
    {
      id: "subscribed",
      name: t("Subscribed")
    }
  ];
  if (!dataSourceId) return null;
  if (loading) return <Loading />;
  const { organizations } = data.previewDataSource;

  const selectedStatus =
    statuses.find(({ id }) => previewFilter.statusId === id) || statuses[0];

  const selectedFilter =
    filters.find(({ id }) => previewFilter?.filter === id) || filters[0];
  return (
    <>
      <Formik>
        <div className={formclassname}>
          <div>
            <Select
              options={organizations}
              label={t("githubSerachForm.project")}
              onChange={async (val) => {
                const newPreviewFilter = {
                  ...previewFilter,
                  organizationId: val.name
                };
                await setPreviewFilter(newPreviewFilter);
              }}
              selectedOption={{
                name: previewFilter.organizationId,
                id: previewFilter.organizationId
              }}
              placeholder={t("githubSerachForm.selectProject")}
            />
          </div>
          <div>
            <Select
              options={statuses}
              label={t("githubSerachForm.status")}
              onChange={async (val) => {
                const newPreviewFilter = {
                  ...previewFilter,
                  statusId: val.id
                };
                await setPreviewFilter(newPreviewFilter);
              }}
              selectedOption={selectedStatus}
            />
          </div>
          <div>
            <Select
              options={filters}
              label={t("githubSerachForm.filter")}
              onChange={async (val) => {
                const newPreviewFilter = {
                  ...previewFilter,
                  filter: val.id
                };
                await setPreviewFilter(newPreviewFilter);
              }}
              selectedOption={selectedFilter}
              placeholder={t("githubSerachForm.selectFilter")}
            />
          </div>
          <div className="relative">
            <Input
              label={t("githubSerachForm.updated")}
              type="number"
              data-testid="updatedDaysAgo"
              addonafter={
                <div className="absolute top-10  bottom-1/2 right-5 flex items-center text-gray-500">
                  {t("githubSerachForm.daysAgo")}
                </div>
              }
              errorclassname={{ notError: "hidden" }}
              onChange={async (e) => {
                setCurrentUpdatedDaysAgo(
                  e.target.value === "" ? null : Number(e.target.value)
                );
              }}
              onBlur={async (e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  const newPreviewFilter = {
                    ...previewFilter,
                    updatedDaysAgo: currentUpdatedDaysAgo
                  };
                  await setPreviewFilter(newPreviewFilter);
                }
              }}
              value={currentUpdatedDaysAgo}
            />
          </div>
          <div className=" relative">
            <Input
              label={t("githubSerachForm.limit")}
              type="number"
              data-testid="limit"
              onChange={async (e) => {
                setCurrentLimit(
                  e.target.value === "" ? null : Number(e.target.value)
                );
              }}
              onBlur={async (e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                  const newPreviewFilter = {
                    ...previewFilter,
                    limit: currentLimit
                  };
                  await setPreviewFilter(newPreviewFilter);
                }
              }}
              value={currentLimit}
            />
          </div>
        </div>
      </Formik>
    </>
  );
};
GithubSearchForm.propTypes = {
  dataSourceId: PropTypes.string.isRequired,
  setPreviewFilter: PropTypes.func.isRequired,
  previewFilter: PropTypes.object.isRequired,
  formclassname: PropTypes.string
};
