import { gql, useQuery } from "@apollo/client";
import { UserAddIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  manageOrganizationTeamsResources,
  resourceCategories
} from "../../graphql/enum";
import { ResourceRequired } from "../../utility/hocs";
import Button from "../layout/Button";
import Loading from "../layout/Loading/Loading";
import CreateTeamModal from "./CreateTeamModal";
import CreateTeamView from "./CreateTeamView";
import TeamsTable from "./TeamsTable/TeamsTable";

export const teamsQuery = gql`
  query teamsQuery {
    currentUser {
      id
      teams {
        id
        title
        users {
          id
          avatar
          name
        }
        checkIns {
          id
        }
      }
    }
  }
`;

export const Teams = () => {
  const { loading, data, refetch } = useQuery(teamsQuery);
  const { t } = useTranslation();
  const [isCreateTeamModalVisible, setIsCreateTeamModalVisible] =
    useState(false);

  if (loading) return <Loading />;
  const { teams } = data.currentUser;

  if (teams.length === 0) return <CreateTeamView refetch={refetch} />;

  return (
    <>
      <div className="flex justify-between  mb-6">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {t("teams.teams")}
        </h2>
        <ResourceRequired
          resource={manageOrganizationTeamsResources.createTeams}
          resourceCategory={resourceCategories.manageOrganizationTeams}
        >
          <div>
            <Button
              onClick={() => {
                setIsCreateTeamModalVisible(true);
              }}
            >
              <UserAddIcon className="h-5 w-4 mr-1" aria-hidden="true" />
              {t("teams.createTeam")}
            </Button>
          </div>
        </ResourceRequired>
      </div>
      <TeamsTable teams={teams} />
      <CreateTeamModal
        visible={isCreateTeamModalVisible}
        onClose={() => setIsCreateTeamModalVisible(false)}
        refetch={refetch}
      />
    </>
  );
};
