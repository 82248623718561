import { gql } from "@apollo/client";

const createTeamInviteLink = gql`
  mutation createTeamInviteLink($teamId: ID!) {
    createTeamInviteLink(teamId: $teamId) {
      success
      link
    }
  }
`;
export default createTeamInviteLink;
