import { gql } from "@apollo/client";

const deleteUserCheckIns = gql`
  mutation deleteUserCheckIns($input: [CreateUserCheckInsInput]) {
    deleteUserCheckIns(input: $input) {
      success
    }
  }
`;
export default deleteUserCheckIns;
