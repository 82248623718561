import { gql } from "@apollo/client";

const sendResetPassword = gql`
  mutation sendResetPassword($email: String!) {
    sendResetPassword(email: $email) {
      success
      code
    }
  }
`;

export default sendResetPassword;
