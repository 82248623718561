/* eslint-disable react/prop-types */
import { useMutation } from "@apollo/client/index";
import queryString from "query-string";
import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { integrationTypes } from "../graphql/enum";
import createBitrixIntegration from "../graphql/mutation/createBitrixIntegration";
import setCheckInQuestion from "../graphql/mutation/setCheckInQuestion";
import { getCheckInUrl, teamsUrl } from "../routes";

const HomeView = ({ location }) => {
  const history = useHistory();
  const { code, domain, state = "{}" } = queryString.parse(location.search);
  const { checkInId } = JSON.parse(state);

  const [createBitrixIntegrationMutation] = useMutation(
    createBitrixIntegration
  );
  const [setCheckInQuestionMutation] = useMutation(setCheckInQuestion);

  useEffect(() => {
    const createBitrixIntegrationFunction = async () => {
      const { data } = await createBitrixIntegrationMutation({
        variables: { code, domain }
      });
      await setCheckInQuestionMutation({
        variables: {
          checkInId,
          integrationId: data?.createBitrixIntegration?.id,
          integrationType: integrationTypes.bitrix
        }
      });
      history.push(getCheckInUrl(checkInId));
      return true;
    };
    if (code) {
      createBitrixIntegrationFunction();
    }
  }, [
    code,
    createBitrixIntegrationMutation,
    history,
    domain,
    state,
    setCheckInQuestionMutation,
    checkInId
  ]);

  return <Redirect to={teamsUrl} />;
};

export default HomeView;
