import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import GitHubIcon from "../../../components/layout/icons/GitHubIcon";
import TwitterIcon from "../../../components/layout/icons/TwitterIcon";

const AuthorizationLayout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-between min-h-screen flex-col">
      <div className="layout-content-authorization">
        <div className="mt-32">
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-6">
              <div className="px-4 sm:px-0">
                <div>{children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="max-w-7xl sm:px-12 lg:px-12 py-4 mt-auto mx-auto w-full">
        <div className="flex justify-between">
          <div className="text-gray-400">
            {t("copyringhtSign")}
            {new Date().getFullYear()} {t("aliiRightsReserved")}
          </div>
          <div className="flex">
            <Link className="mr-1" to={"#"}>
              <TwitterIcon height="20" width="20" />
            </Link>
            <Link to={"#"}>
              <GitHubIcon height="20" width="20" disabled={true} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

AuthorizationLayout.propTypes = {
  children: PropTypes.element
};

export default AuthorizationLayout;
