/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { dateFormat } from "../../../../graphql/utils/date";
import { cleanObject } from "../../../../graphql/utils/utils";
import { getCheckInUrl } from "../../../../routes";
import { convertStringToInteger } from "../../../../utility";
import Button from "../../../layout/Button";
import Loading from "../../../layout/Loading/Loading";
import Page from "../../../layout/Page";
import PlanningPokerEstimatesFilter from "./PlanningPokerEstimatesFilter/PlanningPokerEstimatesFilter";
import PlanningPokerEstimatesTable from "./PlanningPokerEstimatesTable/PlanningPokerEstimatesTable";

export const checkInPlanningPokerQuestionsQuery = gql`
  query checkIn(
    $id: ID!
    $filters: CheckInQuestionsListFilterInput
    $pagination: paginationInput
  ) {
    checkIn(checkInId: $id) {
      questions {
        count(filters: $filters)
        data(filters: $filters, pagination: $pagination) {
          id
          title
          createdAt
          finalPoint {
            point
          }
          points {
            point
            user {
              id
              name
              avatar
            }
          }
        }
      }
    }
  }
`;

export const EstimatesTab = ({ checkInId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);

  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const search = parameters.get("search");
  const offset = parameters.get("offset");
  const limit = parameters.get("limit");
  const [filter, setFilter] = useState({
    search: search || "",
    dateFrom: dateFrom ? new Date(dateFrom) : defaultDateFrom,
    dateTo: dateTo ? new Date(dateTo) : defaultDateTo
  });
  const [page, setPage] = useState({
    offset: !offset ? 0 : convertStringToInteger(offset),
    limit: !limit ? 10 : convertStringToInteger(limit)
  });
  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: getCheckInUrl(checkInId),
      query: cleanObject({
        activeTab: "estimates",
        search: filter.search,
        dateTo: filter.dateTo,
        offset: page.offset,
        limit: page.limit
      })
    });
    history.push(url);
  }, [page, filter, history, checkInId]);
  const { loading, data } = useQuery(checkInPlanningPokerQuestionsQuery, {
    variables: {
      id: checkInId,
      filters: {
        search: filter.search,
        dateFrom: moment(filter.dateFrom).format(dateFormat),
        dateTo: moment(filter.dateTo).format(dateFormat)
      },
      pagination: {
        offset: page.offset,
        limit: page.limit
      }
    },
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <Loading />;
  if (!data?.checkIn) return null;

  return (
    <>
      <div className="flex justify-end">
        <div className="mr-2 mb-5">
          <Button
            onClick={() => {
              const url = queryString.stringifyUrl({
                url: getCheckInUrl(checkInId),
                query: {
                  activeTab: "start"
                }
              });
              history.push(url);
            }}
          >
            {t("planningPokerStart.start")}
          </Button>
        </div>
      </div>
      <Page
        sidebar={
          <div className="w-48">
            <PlanningPokerEstimatesFilter
              filter={filter}
              setFilter={setFilter}
            />
          </div>
        }
        modals={[]}
      >
        <PlanningPokerEstimatesTable
          questions={data?.checkIn?.questions}
          page={page}
          setPage={setPage}
        />
      </Page>
    </>
  );
};
EstimatesTab.propTypes = {
  checkInId: PropTypes.string.isRequired
};
