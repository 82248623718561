import { Dialog } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button from "../../layout/Button";
import Modal from "../../layout/Modal";
import AddCheckInForm from "../AddCheckInForm/AddCheckInForm";

const AddCheckInModal = ({ visible, onClose, teamId, refetch }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="p-8">
        <div className="mx-auto flex items-center justify-center">
          <CheckCircleIcon
            className="h-12 w-12 text-sm text-gray-400 stroke-1"
            aria-hidden="true"
          />
        </div>
        <div className="mt-1 text-center sm:mt-1">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {t("addCheckInModal.addTeamCheckIn")}
          </Dialog.Title>
        </div>
        <div className="mt-10  w-9/12 m-auto">
          <AddCheckInForm
            name="AddCheckInForm"
            teamId={teamId}
            refetch={refetch}
            onFormFinish={() => {
              onClose();
            }}
          />
        </div>
        <div className="mt-6"></div>
      </div>
      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end">
          <div className="flex">
            <Button layout="white" className="mr-5" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="submit" form="AddCheckInForm">
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddCheckInModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  refetch: PropTypes.func
};
AddCheckInForm.defaultProps = {
  visible: false
};

export default AddCheckInModal;
