import {
  manageOrganizationUsersResources,
  organizationRoles
} from "../graphql/enum";
import {
  checkInsUrl,
  organisationUrl,
  peopleUrl,
  profileUrl,
  teamsUrl
} from "../routes";

export const typeMenu = {
  user: "user",
  organization: "organization",
  noOrganizationCreated: "noOrganizationCreated"
};

export const getTypeMenu = (user) => {
  if (user.organization) {
    if (
      user.resources.manageOrganizationUsers.includes(
        manageOrganizationUsersResources.inviteNewUsers
      )
    )
      return typeMenu.organization;

    return typeMenu.user;
  }
  return typeMenu.noOrganizationCreated;
};

export const getProfile = ({ name, email, avatar }) => ({
  name,
  email,
  imageUrl: avatar
});

export const getNavigationMenu = (user, pathname) => {
  const type = getTypeMenu(user);

  if (type === typeMenu.organization) {
    return [
      {
        name: "Check-ins",
        href: checkInsUrl,
        current: checkInsUrl === pathname
      },
      { name: "Teams", href: teamsUrl, current: teamsUrl === pathname },
      { name: "People", href: peopleUrl, current: peopleUrl === pathname }
    ];
  }
  if (type === typeMenu.noOrganizationCreated) {
    return [];
  }

  return [
    { name: "Check-ins", href: checkInsUrl, current: checkInsUrl === pathname },
    { name: "Teams", href: teamsUrl, current: teamsUrl === pathname }
  ];
};

export const getProfileMenu = (user, pathname, logout) => {
  if (user.organization) {
    if (
      user.organization.role === organizationRoles.owner ||
      user.organization.role === organizationRoles.admin ||
      user.organization.role === organizationRoles.organizationManager
    ) {
      return [
        {
          name: "Your Profile",
          href: profileUrl,
          current: profileUrl === pathname
        },
        {
          name: "Organisation",
          href: organisationUrl,
          current: organisationUrl === pathname
        },
        { name: "Sign out", href: "#", onClick: logout }
      ];
    }
    return [
      {
        name: "Your Profile",
        href: profileUrl,
        current: profileUrl === pathname
      },
      { name: "Sign out", href: "#", onClick: logout }
    ];
  }
  return [];
};

export const getDefaultValueForDropdown = (name) => ({
  id: "all",
  name,
  value: null
});
