/* eslint-disable max-statements */
import { MailIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  manageOrganizationResources,
  resourceCategories
} from "../../../graphql/enum";
import { ResourceRequired } from "../../../utility/hocs";
import Button from "../../layout/Button";

const ButtonOpenInvitePeopleModal = ({ setIsInviteUserModalVisible }) => {
  const { t } = useTranslation();

  return (
    <ResourceRequired
      resource={manageOrganizationResources.inviteNewUsers}
      resourceCategory={resourceCategories.manageOrganizationUsers}
    >
      <div>
        <Button
          onClick={() => {
            setIsInviteUserModalVisible(true);
          }}
        >
          <MailIcon className="h-4 w-4 mr-1" aria-hidden="true" />
          {t("oganizationPeople.inviteMembers")}
        </Button>
      </div>
    </ResourceRequired>
  );
};

ButtonOpenInvitePeopleModal.propTypes = {
  setIsInviteUserModalVisible: PropTypes.func
};

export default ButtonOpenInvitePeopleModal;
