/* eslint-disable react/prop-types */
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment } from "react";

import { classNames } from "../../../utility";

const Select = ({
  seletedOption,
  onChange,
  roles,
  addonafter,
  error,
  disable,
  classNameButton
}) => (
  <>
    <Listbox value={seletedOption} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`relative cursor-pointer inline-flex items-center p-2 rounded-l-none rounded-r-md  text-sm font-medium outline-none ${
                disable ? "bg-gray-100" : ""
              }`}
            >
              <div
                className={`relative inline-flex items-center justify-between pl-3 rounded-l-md text-gray-500 ${classNameButton}`}
              >
                <span className="block truncate">{seletedOption.name}</span>
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              show={disable ? false : open}
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {roles.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative p-4 text-sm"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <span
                            className={
                              selected ? "font-semibold" : "font-normal"
                            }
                          >
                            {option.name}
                          </span>
                          {selected ? (
                            <span
                              className={
                                active ? "text-white" : "text-indigo-500"
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {addonafter}
          {error}
        </>
      )}
    </Listbox>
  </>
);
export default Select;
