const developmentEnv = "development";

export const API_URL = process.env.REACT_APP_API_URL;

export const environment = process.env.REACT_APP_ENV || developmentEnv;

export const isDevelopment = environment === developmentEnv;

export const bitrixAppInstallUrl = process.env.REACT_APP_BITRIX_APP_INSTALL_URL;
export const botInstallUrl = process.env.REACT_APP_BOT_INSTALL_LINK;

export const GRAPHQL_API_URL = `${API_URL}/graphql`;
export const JIRA_AUTHORIZE_URL = `${API_URL}/jira-authorize`;

export const bitrixAuthorizeUrl = `${API_URL}/bitrix-authorize`;
export const GITHUB_AUTHORIZE_URL = `${API_URL}/github-authorize`;

export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

export const ahaUrl = "https://standupify.ideas.aha.io/ideas/new";
export const documentationJiraQueryLanguage =
  "https://support.atlassian.com/jira-service-management-cloud/docs/use-advanced-search-with-jira-query-language-jql/";
