import { gql } from "@apollo/client";

const updateCheckInRetrospectiveItems = gql`
  mutation updateCheckInRetrospectiveItems(
    $retrospectiveItemsId: ID!
    $input: UpdateCheckInRetrospectiveItemsInput!
  ) {
    updateCheckInRetrospectiveItems(
      retrospectiveItemsId: $retrospectiveItemsId
      input: $input
    ) {
      success
      code
    }
  }
`;

export default updateCheckInRetrospectiveItems;
