import { gql } from "@apollo/client";

const createCheckInRun = gql`
  mutation createCheckInRun($checkInId: ID!, $setupStatus: CheckInRunType) {
    createCheckInRun(checkInId: $checkInId, setupStatus: $setupStatus) {
      id
      success
      code
    }
  }
`;

export default createCheckInRun;
