import { gql } from "@apollo/client";

const approveTeamInviteLink = gql`
  mutation approveTeamInviteLink($code: String!) {
    approveTeamInviteLink(code: $code) {
      success
      code
      jwtToken
    }
  }
`;

export default approveTeamInviteLink;
