/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Loading from "../components/layout/Loading/Loading";
import NotFoundPage from "../components/layout/NotFoundPage/NotFoundPage";
import { userConfirmationCodeEnum } from "../graphql/enum";
import approveVerificationEmail from "../graphql/mutation/approveVerificationEmail";
import { UserContext } from "../utility/context/User";
import ErrorView from "./forbidden/Error/Error";

const ApproveVerificationEmailView = ({ location }) => {
  const { code } = queryString.parse(location.search);

  const [approveVerificationEmailMutation, { loading, data }] = useMutation(
    approveVerificationEmail
  );

  const { login } = useContext(UserContext);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    approveVerificationEmailMutation({ variables: { code } });
  }, [approveVerificationEmailMutation, code]);
  if (loading) return <Loading />;
  if (data) {
    const { success, code: responseCode } = data.approveVerificationEmail;
    if (success) {
      const { jwtToken } = data.approveVerificationEmail;
      login(jwtToken);
      history.push("/");
    }
    if (responseCode === userConfirmationCodeEnum.alreadyUsed) {
      return (
        <ErrorView>{t("userConfirmationCode.thisLinkAlreadyUsed")}</ErrorView>
      );
    }
    if (responseCode === userConfirmationCodeEnum.tokenExpired) {
      return <ErrorView>{t("userConfirmationCode.tokenExpired")}</ErrorView>;
    }
  }
  return <NotFoundPage />;
};

ApproveVerificationEmailView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default ApproveVerificationEmailView;
