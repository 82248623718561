/* eslint-disable react/prop-types */
import { ChevronRightIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, UsersIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import { getTeamUrl } from "../../../routes";
import Link from "../../layout/Link";
import Table from "../../layout/Table/Table";
import Avatars from "../Avatars/Avatars";

const TeamsTable = ({ teams }) => {
  const { t } = useTranslation();
  const columns = [];

  const data = teams.map((team) => [
    {
      id: `title-${team.id}`,
      render: () => (
        <>
          <div>
            <Link
              to={getTeamUrl(team.id)}
              className="font-medium text-indigo-600"
            >
              {team.title}
            </Link>
          </div>
          <div className="text-gray-400 flex mt-2 items-center">
            {team.checkIns.length > 0 ? (
              <>
                <CheckCircleIcon className="h-4 w-4 mr-1" aria-hidden="true" />
                <div className="mr-5">
                  {team.checkIns.length} {t("teamsTable.checkIns")}
                </div>
              </>
            ) : null}
            <UsersIcon className="h-4 w-4 mr-1	" aria-hidden="true" />
            {team.users.length} {t("teamsTable.members")}
          </div>
        </>
      )
    },
    {
      id: `avatars-${team.id}`,
      render: () => (
        <Link to={getTeamUrl(team.id)}>
          <Avatars users={team.users.slice(0, 4)} />
        </Link>
      )
    },
    {
      id: `link-${team.id}`,
      render: () => (
        <Link to={getTeamUrl(team.id)} className="text-right">
          <div className="float-right">
            <ChevronRightIcon className="text-gray-400 h-10 w-6 mr-0 text-right" />
          </div>
        </Link>
      )
    }
  ]);

  return <Table columns={columns} data={data} />;
};

export default TeamsTable;
