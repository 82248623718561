import { RadioGroup } from "@headlessui/react";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { classNames } from "../../../utility";

const StackedCards = ({ options, selected, onChange }) => (
  <RadioGroup value={selected} onChange={onChange}>
    <div className="space-y-4">
      {options.map((option) => (
        <Fragment key={option.name}>
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={classNames(
              option.name === selected.name ? "ring-1 ring-indigo-500" : "",
              "relative block rounded-lg border border-gray-300 bg-white shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between outline-none"
            )}
          >
            <>
              <div className="flex items-center">
                <RadioGroup.Label
                  as="p"
                  className="font-medium text-gray-900 mb-0 text-lg"
                >
                  {option.name}
                </RadioGroup.Label>
              </div>
              <RadioGroup.Description
                as="div"
                className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right"
              >
                <div className="font-medium text-gray-900">
                  {option.rightContent}
                </div>
              </RadioGroup.Description>
              <div
                className={classNames(
                  option.name === selected.name
                    ? "border-indigo-500"
                    : "border-transparent",
                  "absolute -inset-px rounded-lg border-2 pointer-events-none"
                )}
                aria-hidden="true"
              />
            </>
          </RadioGroup.Option>
          <div>
            {option.name === selected.name ? selected.hideContent : null}
          </div>
        </Fragment>
      ))}
    </div>
  </RadioGroup>
);
StackedCards.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      rightContent: PropTypes.any,
      hideContent: PropTypes.any
    })
  ),
  selected: PropTypes.shape({
    name: PropTypes.string,
    rightContent: PropTypes.any,
    hideContent: PropTypes.any
  }),
  onChange: PropTypes.func
};
export default StackedCards;
