import { gql } from "@apollo/client";

const createGitHubIntegration = gql`
  mutation createGitHubIntegration($code: String!, $checkInId: ID) {
    createGitHubIntegration(code: $code, checkInId: $checkInId) {
      success
      code
      id
    }
  }
`;

export default createGitHubIntegration;
