/* eslint-disable max-statements */
import { gql, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  questionTypesEnum,
  setupStatusEnum
} from "../../../../../graphql/enum";
import updateCheckIn from "../../../../../graphql/mutation/updateCheckIn";
import updateDataSource from "../../../../../graphql/mutation/updateDataSource";
import Button from "../../../../layout/Button";
import Loading from "../../../../layout/Loading/Loading";
import Steps from "../../../../layout/Steps";
import PreviewQuestion from "./PreviewQuestion/PreviewQuestion";

export const checkInByIdQuery = gql`
  query checkInById($checkInId: ID!) {
    checkIn(checkInId: $checkInId) {
      id
      integrationType
      bitrixDomain
      questions {
        data {
          id
          title
          type
          dataSource {
            id
            jql
            createdDaysAgo
            asigneeIds
            reporterIds
            statusIds
            projectIds
            closedDaysAgo
            organizationId
            deadlineDaysBefore
            deadlineDaysAfter
            updatedDaysAgo
            limit
            filter
          }
        }
      }
    }
  }
`;
export const TaskLists = ({ checkInId, setCurrentStep }) => {
  const { loading, data, refetch } = useQuery(checkInByIdQuery, {
    variables: { checkInId },
    fetchPolicy: "no-cache"
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [updateCheckInMutation] = useMutation(updateCheckIn);
  const [updateDataSourceMutation] = useMutation(updateDataSource);

  const [questionsFilters, setQuestionsFilters] = useState({});

  const { t } = useTranslation();

  if (loading) return <Loading />;
  if (!data?.checkIn) return null;

  const mappedStepsQuestions = data.checkIn.questions.data.map((q) => ({
    id: q.id,
    name: q.title,
    href: "",
    dataSource: q.dataSource,
    type: q.type
  }));
  const stepsQuestions = mappedStepsQuestions.filter(
    (s) => s.type === questionTypesEnum.tasksList
  );
  const currentStepIndex = stepsQuestions.findIndex(
    ({ id }) => id === stepsQuestions[currentQuestionIndex]?.id
  );
  return (
    <>
      <div className="w-9/12 mx-auto">
        <div className="text-lg leading-6 font-medium text-gray-900 text-center">
          {t("taskLists.previewTaskLists")}
        </div>
        <div className="text-gray-500 text-center mt-5 mb-10">
          {t("taskLists.previewTaskListsDescription")}
        </div>
      </div>
      <Steps
        layout="linear"
        currentStep={stepsQuestions[currentQuestionIndex]}
        steps={stepsQuestions}
      />
      {stepsQuestions[currentQuestionIndex]?.dataSource?.id ? (
        <PreviewQuestion
          question={stepsQuestions[currentQuestionIndex]}
          integrationType={data.checkIn.integrationType}
          checkIn={data.checkIn}
          dataSourceId={stepsQuestions[currentQuestionIndex]?.dataSource?.id}
          dataSource={stepsQuestions[currentQuestionIndex]?.dataSource}
          refetch={refetch}
          previewFilter={questionsFilters}
          setPreviewFilter={setQuestionsFilters}
        />
      ) : null}
      <div className="flex px-10 justify-between mt-20">
        <div>
          <Button
            layout="white"
            onClick={async () => {
              await updateDataSourceMutation({
                variables: {
                  updateDataSourceInput: {
                    dataSourceId:
                      stepsQuestions[currentQuestionIndex]?.dataSource?.id,
                    dataSourceFilter: {
                      ...questionsFilters
                    }
                  }
                }
              });
              await refetch();

              if (!stepsQuestions[currentStepIndex - 1]) {
                setCurrentStep(setupStatusEnum.taskTrackers);
                return;
              }
              const prevQuestion = stepsQuestions[currentStepIndex - 1];
              await refetch();

              if (prevQuestion) {
                setCurrentQuestionIndex(currentStepIndex - 1);
              }
            }}
          >
            {t("Previous")}
          </Button>
        </div>
        <div>
          <Button
            layout="primary"
            onClick={async () => {
              await updateDataSourceMutation({
                variables: {
                  updateDataSourceInput: {
                    dataSourceId:
                      stepsQuestions[currentQuestionIndex]?.dataSource?.id,
                    dataSourceFilter: {
                      ...questionsFilters
                    }
                  }
                }
              });
              const nextQuestion = stepsQuestions[currentStepIndex + 1];

              if (nextQuestion) {
                setCurrentQuestionIndex(currentStepIndex + 1);
                return;
              }

              await updateCheckInMutation({
                variables: {
                  checkInId,
                  input: {
                    setupStatus: setupStatusEnum.review
                  }
                }
              });

              setCurrentStep(setupStatusEnum.review);
            }}
          >
            {t("Next")}
          </Button>
        </div>
      </div>
    </>
  );
};
TaskLists.propTypes = {
  checkInId: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired
};
