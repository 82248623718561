import { useMutation } from "@apollo/client";
import { ExclamationIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import deleteTeam from "../../../../../graphql/mutation/deleteTeam";
import { teamsUrl } from "../../../../../routes";
import Button from "../../../../layout/Button";
import Modal from "../../../../layout/Modal";
import { teamsQuery } from "../../../../Teams/Teams";

const DeleteTeamModal = ({ visible, onClose, team }) => {
  const { t } = useTranslation();
  const [deleteTeamMutation] = useMutation(deleteTeam);

  const history = useHistory();

  const onDelete = async () => {
    const { data } = await deleteTeamMutation({
      variables: {
        teamId: team.id
      },
      optimisticResponse: true,
      update: async (cache) => {
        const dataQuery = await cache.readQuery({
          query: teamsQuery
        });
        if (!dataQuery) return;
        const newTeams = dataQuery.currentUser.teams.filter(
          (i) => i.id !== team.id
        );
        cache.writeQuery({
          query: teamsQuery,
          data: {
            currentUser: {
              ...dataQuery.currentUser,
              teams: newTeams
            }
          }
        });
      }
    });
    if (data.deleteTeam.success) {
      history.push(teamsUrl);
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} size="md">
      <div className="p-8">
        <div className="flex">
          <div>
            <div className="rounded-full p-2 bg-red-100 mr-4">
              <ExclamationIcon
                className="text-red-600 h-6 w-6"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="max-w-md">
            <h3 className="text-lg font-medium">
              {t("deleteTeamModal.deleteTeam")}
            </h3>
            <p className="text-gray-500">
              {t("deleteTeamModal.areYouSure")}
              <span className="font-semibold">{team.title}</span>
              {t("deleteTeamModal.allDataWillDelete")}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end ">
          <div className="flex">
            <Button layout="white" className="mr-5" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              type="submit"
              form="createTeamModal"
              layout="danger"
              onClick={onDelete}
            >
              {t("Delete")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
DeleteTeamModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};
export default DeleteTeamModal;
