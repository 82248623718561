import { useTranslation } from "react-i18next";

import { signupUrl } from "../../../routes";
import Link from "../../layout/Link";
import ResetPasswordRequestForm from "./ResetPasswordRequestForm/ResetPasswordRequestForm";

const ResetPasswordRequest = () => {
  const { t } = useTranslation();

  return (
    <div className=" flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src="/assets/img/standupify_icon_256px.png"
          alt="Standupify"
        />
        <h2
          className="mt-6 text-center font-extrabold text-gray-900 mb-0"
          style={{ fontSize: "1.85rem" }}
        >
          {t("resetPassword.resetPassword")}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 mb-0">
          {t("resetPassword.or")}{" "}
          <Link to={signupUrl} className="font-medium">
            {t("resetPassword.createOrganisationAccount")}
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <ResetPasswordRequestForm />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
