import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";

import { googleClientId } from "./config";
import Router from "./Router";
import { UserContextProvider } from "./utility/context/User";

const App = () => (
  <UserContextProvider>
    <GoogleOAuthProvider clientId={googleClientId}>
      <Router />
    </GoogleOAuthProvider>
  </UserContextProvider>
);

export default App;
