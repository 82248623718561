/* eslint-disable i18next/no-literal-string */

import { XIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

import Avatar from "../Avatar";
import Badge from "../Badge";

const ListOfUsers = ({ users, onClose, isDisplayAvatar }) => (
  <ul className="divide-y divide-gray-200 border-t border-b border-gray-200">
    {users.map(({ user, badges }) => (
      <li key={user.email} className="py-4 flex justify-between">
        <div className="flex items-center">
          {isDisplayAvatar ? (
            <Avatar src={user.avatar} name={user.name} />
          ) : null}
          <div className="ml-3 flex items-center">
            <div className="text-sm font-medium text-gray-900">
              {user.email} {user.addonafter ? user.addonafter : null}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-2">
            {badges.map((element, idx) => (
              <Badge color="blue" className="mr-1" key={`${user.email}-${idx}`}>
                {element}
              </Badge>
            ))}
          </div>
          <div>
            <XIcon
              className="h-6 w-6 text-sm text-gray-400 font-thin stroke-1 cursor-pointer hover:text-gray-500"
              aria-hidden="true"
              onClick={() => {
                onClose(user.id);
              }}
            />
          </div>
        </div>
      </li>
    ))}
  </ul>
);

ListOfUsers.defaultProps = {
  isDisplayAvatar: true
};

ListOfUsers.propTypes = {
  isDisplayAvatar: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      badges: PropTypes.array,
      user: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        avatar: PropTypes.string,
        name: PropTypes.string,
        addonAfter: PropTypes.any
      })
    })
  )
};
export default ListOfUsers;
