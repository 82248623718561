import moment from "moment";
import momentTimeZone from "moment-timezone";

export const timeIsoFormat = "HH:mm:ssZ";
export const dateIsoFormat = "DD-MM-YYYY";
export const milisecondsPerMinute = 60000;
const minutesPerHour = 60;
const hoursPerDay = 24;

export const convertTimeToTimeZone = (time, timeZone) => {
  const [hours, minutes, seconds = "00"] = time.split(":");

  return momentTimeZone
    .utc(`${hours}:${minutes}:${seconds}`, "hh:mm")
    .tz(timeZone);
};

export const subtractDays = (date, days) => {
  date.setTime(
    date.getTime() - days * hoursPerDay * milisecondsPerMinute * minutesPerHour
  );
  return date.getTime();
};
export const convertTimeToUTCTimeZone = (time) => {
  const [hours, minutes, seconds] = time.split(":");

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  const localDate = new Date();
  const localOffset = localDate.getTimezoneOffset() * milisecondsPerMinute;
  date.setTime(date.getTime() + localOffset);
  return moment(date, timeIsoFormat).format("HH:mm:ss");
};
export const convertTimeTo12Hours = (time) => {
  const [hours, minutes, seconds] = time.split(":");
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);

  return moment(date, timeIsoFormat);
};

export const formatDateTime = (date) => {
  const localDate = new Date(date);
  return moment(localDate).format("DD MMM YYYY HH:mm");
};

export const convertDateToUTC = (date) => {
  const dateUTC = new Date(date);
  return moment(dateUTC, dateIsoFormat);
};
