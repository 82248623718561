import PropTypes from "prop-types";

import { questionStateEnum } from "../../../../../../graphql/enum";
import MergeSimilarQuestionItem from "../MergeSimilarQuestionItem/MergeSimilarQuestionItem";

const MergeSimilarQuestions = ({
  checkInRunId,
  questions,
  setNewItem,
  refetch
}) =>
  questions.map(
    ({
      id: checkInQuestionId,
      title,
      retrospectiveItemsRooms,
      questionState
    }) =>
      questionState !== questionStateEnum.actionItems ? (
        <div key={checkInQuestionId}>
          <span className="text-base font-bold text-black ">{title}</span>
          <div className="grid grid-cols-1 gap-4 mt-6">
            {
              <MergeSimilarQuestionItem
                refetch={refetch}
                checkInRunId={checkInRunId}
                setNewItem={setNewItem}
                rooms={retrospectiveItemsRooms}
                checkInQuestionId={checkInQuestionId}
              />
            }
          </div>
        </div>
      ) : null
  );

MergeSimilarQuestions.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  user: PropTypes.object,
  newItem: PropTypes.object,
  questions: PropTypes.array,
  setNewItem: PropTypes.func,
  refetch: PropTypes.func
};
export default MergeSimilarQuestions;
