import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { UserTeamRequired } from "../../../../../utility/hocs";
import Input from "../../../../layout/Input/Input";
import Select from "../../../../layout/Select/Select";
import Toggle from "../../../../layout/Toggle/Toggle";

const Reminders = ({
  checkInData,
  setCheckInData,
  usersTeam,
  reminderTimes,
  reminderTime,
  setReminderTime,
  errors
}) => {
  const { t } = useTranslation();
  return (
    <UserTeamRequired usersTeam={usersTeam}>
      <div className="shadow rounded-lg	p-6 mt-6">
        <div className="flex">
          <div className="w-1/3 mr-10">
            <p className="text-lg font-medium">
              {t("standupNotificationsTab.reminders")}
            </p>
            <p className="text-gray-500 text-sm">
              {t("standupNotificationsTab.remindersDescription")}
            </p>
          </div>
          <div className="w-2/3">
            <div className="flex mt-6">
              <div className="w-1/12">
                <Toggle
                  enabled={checkInData.isReminders}
                  onChange={() =>
                    setCheckInData({
                      ...checkInData,
                      isReminders: !checkInData.isReminders
                    })
                  }
                />
              </div>
              <div className="w-11/12 -ml-1">
                <div>
                  <span className="text-sm text-center font-medium text-gray-700">
                    <span className="">
                      {t("standupNotificationsTab.noResponse")}
                    </span>
                  </span>
                </div>
                <div>
                  <span className="text-sm font-normal text-gray-500">
                    {t("standupNotificationsTab.noResponseDescription", {
                      day: checkInData.userNoResponseDays
                    })}
                  </span>
                </div>
                <div className="pt-2">
                  <span className="text-sm text-center font-medium text-gray-700">
                    <span>{t("standupNotificationsTab.howManyReminders")}</span>
                  </span>
                  <div className="flex w-fit">
                    <div className="relative">
                      <Input
                        type="text"
                        error={errors.numberReminders}
                        value={checkInData.numberReminders}
                        onChange={({ target }) => {
                          const value =
                            target.value.length > 0
                              ? parseInt(target.value, 10)
                              : "";
                          setCheckInData({
                            ...checkInData,
                            numberReminders: value
                          });
                        }}
                        addonafter={
                          <div className="absolute top-0 right-0 flex items-center">
                            <Select
                              seletedOption={reminderTime}
                              onChange={(value) => {
                                setReminderTime(value);
                              }}
                              roles={reminderTimes}
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </UserTeamRequired>
  );
};
Reminders.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  usersTeam: PropTypes.array,
  reminderTimes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  reminderTime: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  setReminderTime: PropTypes.func.isRequired
};
export default Reminders;
