import { XIcon } from "@heroicons/react/solid";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import Badge from "../../../layout/Badge/index";
import Point from "../../../layout/Point";

const ListHoliday = ({ holidays, removeHoliday }) => (
  <div className="mt-5 border-gray-200">
    <dl className="divide-y divide-gray-200">
      {holidays.map(({ id, date, title }, idx) => (
        <div className="py-3 grid grid-cols-12 gap-4" key={idx}>
          <dt className="text-sm font-medium text-gray-500 col-span-3 -mr-5">
            <Badge
              color="pink"
              className="py-1 px-4 
"
            >
              <span>
                <Point layout="pink" />
              </span>
              {moment(new Date(date)).format("D MMM")}
            </Badge>
          </dt>
          <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-9">
            <span className="flex-grow">{title}</span>
            <span className="ml-4 flex-shrink-0">
              <XIcon
                className="h-6 w-6 text-sm text-gray-400 font-thin stroke-1 cursor-pointer hover:text-gray-500"
                aria-hidden="true"
                onClick={() => removeHoliday(id)}
              />
            </span>
          </dd>
        </div>
      ))}
    </dl>
  </div>
);
ListHoliday.propTypes = {
  holidays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string
    })
  ),
  removeHoliday: PropTypes.func
};
export default ListHoliday;
