import { InformationCircleIcon } from "@heroicons/react/outline";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { documentationJiraQueryLanguage } from "../../../../../../../../config";
import Input from "../../../../../../../layout/Input/Input";

const JiraSearchForm = ({
  previewFilter,
  setPreviewFilter,
  isValideJQL,
  setIsValideJQL
}) => {
  const [currentJql, setCurrentJql] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setCurrentJql(previewFilter.jql || "");
  }, [previewFilter]);
  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          const newPreviewFilter = {
            jql: currentJql
          };
          setPreviewFilter(newPreviewFilter);
        }}
      >
        <Input
          placeholder={t("jiraSearchForm.jql")}
          pla={t("jiraSearchForm.jql")}
          label={
            <div className="flex">
              <span className="text-gray-700">{t("forms.query")}</span>
              <InformationCircleIcon
                onClick={() => window.open(documentationJiraQueryLanguage)}
                className="w-5 h-5 ml-1 text-gray-300  cursor-pointer"
              />
            </div>
          }
          type="text"
          name="search"
          onChange={async (e) => {
            setCurrentJql(e.target.value);
          }}
          onBlur={async (e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              const newPreviewFilter = {
                jql: currentJql
              };
              await setPreviewFilter(newPreviewFilter);
              setIsValideJQL(true);
            }
          }}
          value={currentJql}
          error={!isValideJQL ? t("jiraSearchForm.jqlIsNotValid") : ""}
        />
      </Formik>
    </>
  );
};

JiraSearchForm.propTypes = {
  setPreviewFilter: PropTypes.func.isRequired,
  previewFilter: PropTypes.object.isRequired,
  isValideJQL: PropTypes.bool,
  setIsValideJQL: PropTypes.func
};

export default JiraSearchForm;
