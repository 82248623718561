/* eslint-disable max-statements */
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { concatObj } from "../../../../../utility";
import NoChart from "../../../../layout/NoChart";
import CustomScatter from "../CustomElements/CustomScatter";
import CustomTooltip from "../CustomElements/CustomTooltip";

const createObjectFromArray = (obj, idx) =>
  Object.entries(obj).map(([key, val]) => [`${key}_${idx}`, val]);

const generateObject = (arr) => {
  const newDataArr = [];
  const arrayOfObj = arr.points.reduce(
    (prev, cur, idx) => [
      ...prev,
      Object.fromEntries(createObjectFromArray(cur, idx))
    ],
    []
  );
  newDataArr.push(...arrayOfObj, arr);
  return concatObj(newDataArr);
};

function chechIfIntersect(pos, chart) {
  if (pos.x >= chart.current.props.width / 2) {
    return true;
  }
  return false;
}

const formatData = (oldData) =>
  oldData.map((arr, idx) => generateObject(arr, idx));

const getFinalPointData = ({ finalPoint }) =>
  finalPoint === null ? 0 : finalPoint.point;

const LineChart = ({ questionsData, filters }) => {
  const [dotData, setDotData] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const chartEl = useRef(null);
  const [isInRightHalf, setIsInRightHalf] = useState(false);

  const getScatterComponents = (scatterData) => {
    const arrOfComponents = scatterData.points.map((prev, index) => (
      <Scatter
        onMouseEnter={(e) => {
          setDotData(e.tooltipPayload[1]);
          setShowTooltip(true);
          setCoordinates({
            x: e.x + index * 10,
            y: e.y
          });
          setIsInRightHalf(chechIfIntersect(e, chartEl));
        }}
        name={`user_${index}`}
        dataKey={`point_${index}`}
        fill="lightblue"
        shape={<CustomScatter index={index} />}
      />
    ));
    return arrOfComponents;
  };

  if (questionsData.length < 1) {
    return <NoChart />;
  }
  const questionsDataUserFilter = questionsData.map((questionData) => {
    const newPoints = questionData.points.filter((point) => {
      if (!filters?.usersIds || filters?.usersIds?.length < 1)
        return questionData;
      const isFilterUser = filters.usersIds.find(
        (userId) => userId === point.user.id
      );
      return !!isFilterUser;
    });
    return {
      ...questionData,
      points: newPoints
    };
  });
  const formatedData = formatData(questionsDataUserFilter);
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        ref={chartEl}
        width={500}
        height={400}
        data={formatedData}
        margin={{
          top: 20,
          right: 80,
          bottom: 20,
          left: 20
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <Tooltip
          wrapperStyle={{
            pointerEvents: "all"
          }}
          cursor={false}
          position={{
            x: coordinates.x - (isInRightHalf ? 230 : 20),
            y: coordinates.y - 185
          }}
          content={
            <CustomTooltip
              data={dotData}
              id={dotData.name}
              isActive={showTooltip}
              isInRightHalf={isInRightHalf}
              onHoverFunc={setShowTooltip}
            />
          }
        />

        <XAxis dataKey="title" padding={{ left: 50, right: 50 }} />
        <YAxis type="number" domain={[0, "dataMax + 10"]} />
        {formatedData.map((item, idx) => getScatterComponents(item, idx))}
        <Line
          connectNulls={true}
          type="monotone"
          dataKey={getFinalPointData}
          stroke="#D1D5DB"
          dot={false}
          activeDot={false}
          legendType="none"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
LineChart.propTypes = {
  questionsData: PropTypes.array.isRequired,
  filters: PropTypes.shape({
    usersIds: PropTypes.array
  })
};
export default LineChart;
