import React from "react";

import CheckIns from "../components/CheckIns";
import Layout from "./Layout/NewLayout/NewLayout";

const CheckInSingleView = () => (
  <Layout>
    <CheckIns />
  </Layout>
);

export default CheckInSingleView;
