import React from "react";

import CreateOrganizationAccount from "../components/Authorization/CreateOrganizationAccount/CreateOrganizationAccount";
import AuthorizationLayout from "./Layout/AuthorizationLayout/AuthorizationLayout";

const CreateOrganizationAccountView = () => (
  <AuthorizationLayout>
    <CreateOrganizationAccount />
  </AuthorizationLayout>
);

export default CreateOrganizationAccountView;
