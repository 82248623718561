import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import createCheckInRetrospectiveItems from "../../../../../../graphql/mutation/createCheckInRetrospectiveItems";
import updateCheckInRetrospectiveItems from "../../../../../../graphql/mutation/updateCheckInRetrospectiveItems";
import SetupCheckInRetrospectiveItem from "../../SetupCheckInRetrospectiveItem";

const SetupCheckInRetrospectiveQuestionItems = ({
  refetch,
  checkInRunId,
  setNewItem,
  rooms,
  checkInQuestionId
}) => {
  const [updateCheckInRetrospectiveItemsMutation] = useMutation(
    updateCheckInRetrospectiveItems
  );
  const [createCheckInRetrospectiveItemsMutation] = useMutation(
    createCheckInRetrospectiveItems
  );
  const saveItem = async (item) => {
    if (item.id) {
      await updateCheckInRetrospectiveItemsMutation({
        variables: {
          retrospectiveItemsId: item.id,
          input: {
            title: item.title,
            description: item.description
          }
        }
      });
      refetch();
      return;
    }
    await createCheckInRetrospectiveItemsMutation({
      variables: {
        input: {
          title: item.title,
          description: item.description,
          checkInQuestionId: item.checkInQuestionId,
          checkInRunId
        }
      }
    });
    refetch();
    setNewItem(null);
  };
  return rooms.map((item) => {
    if (item?.checkInQuestionId === checkInQuestionId) {
      return item.id ? (
        item.retrospectiveItems.map((retrospectiveItem) => (
          <SetupCheckInRetrospectiveItem
            key={item.id}
            item={{
              ...retrospectiveItem,
              users: [retrospectiveItem.user]
            }}
            saveItem={saveItem}
          />
        ))
      ) : (
        <SetupCheckInRetrospectiveItem
          key={item.title}
          item={{ ...item }}
          saveItem={saveItem}
        />
      );
    }
    return null;
  });
};
SetupCheckInRetrospectiveQuestionItems.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  setNewItem: PropTypes.func,
  rooms: PropTypes.array,
  checkInQuestionId: PropTypes.string,
  refetch: PropTypes.func
};
export default SetupCheckInRetrospectiveQuestionItems;
