/* eslint-disable react/prop-types */
/* eslint-disable max-statements */
import { useMutation } from "@apollo/client/index";
import { Button, Typography } from "antd";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import PageSpinner from "../components/layout/Loading/Loading";
import NotFoundPage from "../components/layout/NotFoundPage/NotFoundPage";
import { approveInviteMutationCode } from "../graphql/enum";
import approveOrganizationInviteLink from "../graphql/mutation/approveOrganizationInviteLink";
import { teamsUrl } from "../routes";
import Layout from "./Layout/NewLayout/NewLayout";

const { Title } = Typography;

const ApproveOrganizationInvite = ({ location }) => {
  const { code } = queryString.parse(location.search);
  const [approveInviteLinkMutation, { loading, data }] = useMutation(
    approveOrganizationInviteLink
  );

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    approveInviteLinkMutation({ variables: { code } });
  }, [code, approveInviteLinkMutation]);
  if (loading) return <PageSpinner />;

  if (data) {
    const { success, code: responseCode } = data.approveOrganizationInviteLink;
    if (success) {
      history.push(teamsUrl);
    }
    if (
      responseCode ===
      approveInviteMutationCode.userIsAlreadyInDifferentOrganization
    )
      return (
        <Layout>
          <Title type="danger">
            {t("approveInvite.userIsAlreadyInDifferentOrganization")}{" "}
          </Title>
        </Layout>
      );

    if (responseCode === approveInviteMutationCode.tokenExpired) {
      return (
        <Layout>
          <Title type="danger">{t("approveInvite.tokenExpired")}</Title>
          <br />
          <Link to={teamsUrl}>
            <Button>{t("goToStandups")}</Button>
          </Link>
        </Layout>
      );
    }
    if (responseCode === approveInviteMutationCode.alreadyApproved) {
      return (
        <Layout>
          <Title type="danger">{t("approveInvite.tokenIsApproved")} </Title>
        </Layout>
      );
    }
  }
  return <NotFoundPage />;
};

export default ApproveOrganizationInvite;
