import {
  ClipboardListIcon,
  ColorSwatchIcon,
  EmojiHappyIcon
} from "@heroicons/react/solid";
import PropTypes from "prop-types";

import { checkInTypes } from "../../../../graphql/enum";

const CheckInIcon = ({ type, className }) => {
  if (type === checkInTypes.standup)
    return <ClipboardListIcon className={className} />;

  if (type === checkInTypes.moodTracker)
    return <EmojiHappyIcon className={className} />;

  if (type === checkInTypes.planningPoker)
    return <ColorSwatchIcon className={className} />;
  return null;
};

CheckInIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(checkInTypes)).isRequired,
  className: PropTypes.string
};
CheckInIcon.defaultProps = {
  className: ""
};

export default CheckInIcon;
