import { gql } from "@apollo/client";

const updateUserOrganization = gql`
  mutation updateUserOrganization(
    $updateUserOrganizationInput: UpdateUserOrganizationInput
  ) {
    updateUserOrganization(input: $updateUserOrganizationInput) {
      code
      success
    }
  }
`;

export default updateUserOrganization;
