import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Avatar from "../../../../layout/Avatar";

const CustomTooltip = ({ data, id, isActive, isInRightHalf, onHoverFunc }) => {
  const { t } = useTranslation();
  const tooltip = useRef();
  const currentTooltip = tooltip.current;

  useEffect(() => {
    const unHoverTooltip = () => {
      onHoverFunc(false);
    };

    if (tooltip && tooltip.current) {
      tooltip.current.addEventListener("mouseleave", unHoverTooltip, false);
      return function cleanup() {
        currentTooltip.removeEventListener("mouseleave", unHoverTooltip, false);
      };
    }
    return null;
  }, [currentTooltip, onHoverFunc]);

  if (!isActive) return null;

  return (
    <div
      ref={tooltip}
      className="bg-white p-2.5 rounded-lg h-44 shadow-lg w-64 relative"
    >
      <div className="flex items-center mb-1">
        <div className="mr-5">
          <Avatar
            src={data.payload[id]?.avatar}
            alt={data.payload[id]?.name}
            width={23}
            height={23}
          />
        </div>
        <div>
          <p className="text-sm leading-5 font-medium text-gray-700">
            {data.payload[id]?.name}
          </p>
          <p className="text-sm leading-5 font-normal text-gray-500">
            {data.payload[id]?.createdAt}
          </p>
        </div>
      </div>

      <div className="h-24 flex justify-evenly flex-col">
        <p className="text-sm leading-5 font-normal text-gray-500">
          {t("Estimated")}{" "}
          <span className="font-bold">
            {data?.value} {t("points")}
          </span>
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-blue-700 underline pointer-events-auto"
          href={data.payload?.taskLink}
        >
          {data.payload?.title}
        </a>
      </div>
      <div
        className={`w-8  rounded-sm overflow-hidden inline-block absolute  -mt-1 top-full
            ${isInRightHalf ? "right-1" : null}`}
      >
        <div className="shadow-lg  h-5 w-4 rounded-sm bg-white -rotate-45 transform origin-top-left"></div>
      </div>
    </div>
  );
};

CustomTooltip.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isInRightHalf: PropTypes.bool,
  onHoverFunc: PropTypes.func
};

export default CustomTooltip;
