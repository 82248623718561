import { gql } from "@apollo/client";

const createCheckInRetrospectiveItems = gql`
  mutation createCheckInRetrospectiveItems(
    $input: CreateCheckInRetrospectiveItemsInput!
  ) {
    createCheckInRetrospectiveItems(input: $input) {
      id
      success
      code
    }
  }
`;

export default createCheckInRetrospectiveItems;
