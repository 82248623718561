import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { onBlurChange } from "../../../../../graphql/utils/utils";
import { checkInsUrl } from "../../../../../routes";
import { getDefaultValueForDropdown } from "../../../../../utility/config";
import DatePicker from "../../../../layout/Datepicker";
import Input from "../../../../layout/Input/Input";
import Loading from "../../../../layout/Loading/Loading";
import Select from "../../../../layout/Select";

export const checkInUsersQuery = gql`
  query checkInStandupResponses($checkInId: ID!) {
    checkIn(checkInId: $checkInId) {
      id
      questions {
        data {
          id
          title
        }
      }
    }
  }
`;

export const DashboardFilters = ({ filters, setFilters, checkInId }) => {
  const { t } = useTranslation();
  const date = new Date();
  const [currentSearch, setCurrentSearch] = useState(filters.search);

  const { loading, data } = useQuery(checkInUsersQuery, {
    variables: {
      checkInId: parseInt(checkInId, 10)
    },
    notifyOnNetworkStatusChange: true
  });
  if (loading) return <Loading />;
  if (!data?.checkIn) return <Redirect to={checkInsUrl} />;
  const defaultValue = getDefaultValueForDropdown(t("all"));
  const questionsFilter = data.checkIn.questions.data.map(({ id, title }) => ({
    id,
    name: title
  }));
  const qusestionsFilterFields = [defaultValue, ...questionsFilter];

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        const newFilter = {
          ...filters,
          search: currentSearch
        };
        setFilters(newFilter);
      }}
    >
      <Form>
        <Input
          label={t("Search")}
          type="text"
          value={currentSearch}
          name="search"
          id="search"
          placeholder={t("retrospective.searchByResponseText")}
          onBlur={onBlurChange(() => {
            const newFilter = {
              ...filters,
              search: currentSearch
            };
            setFilters(newFilter);
          })}
          onChange={async (e) => {
            setCurrentSearch(e.target.value);
          }}
        />
        <Select
          options={qusestionsFilterFields}
          label={t("Question")}
          type="text"
          id="question"
          selectedOption={filters.checkInQuestion || qusestionsFilterFields[0]}
          onChange={(e) => setFilters({ ...filters, checkInQuestion: e })}
        />
        <div className="mt-5">
          <DatePicker
            type={"range"}
            labelFrom={t("From Date")}
            labelTo={t("To Date")}
            startDate={filters.dateFrom}
            endDate={filters.dateTo}
            maxDate={date}
            setStartDate={(val) => setFilters({ ...filters, dateFrom: val })}
            setEndDate={(val) => setFilters({ ...filters, dateTo: val })}
          />
        </div>
      </Form>
    </Formik>
  );
};
DashboardFilters.propTypes = {
  filters: PropTypes.shape({
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    search: PropTypes.string,
    checkInQuestion: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  }),
  checkInId: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired
};
