/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { checkInDayOffStatusEnum } from "../../../../../graphql/enum";
import { dateFormat } from "../../../../../graphql/utils/date";
import { getDefaultValueForDropdown } from "../../../../../utility/config";
import Avatar from "../../../../layout/Avatar";
import Badge from "../../../../layout/Badge";
import Loading from "../../../../layout/Loading/Loading";
import NotFoundPage from "../../../../layout/NotFoundPage/NotFoundPage";
import Table from "../../../../layout/Table/Table";

export const checkInDayOffQuery = gql`
  query checkIn(
    $id: ID!
    $filters: CheckInDayOffFilterInput
    $pagination: paginationInput
  ) {
    checkIn(checkInId: $id) {
      dayOff {
        count(filters: $filters)
        data(filters: $filters, pagination: $pagination) {
          id
          status
          startDate
          endDate
          user {
            id
            avatar
            name
          }
        }
      }
    }
  }
`;
const getColorStatus = (status) => {
  if (status === checkInDayOffStatusEnum.approve) return "green";
  if (status === checkInDayOffStatusEnum.decline) return "red";
  if (status === checkInDayOffStatusEnum.pending) return "yellow";
  return "gray";
};
const getDifferenceBetweenDates = ({ startDate, endDate }) => {
  const dateFrom = moment(startDate);
  const dateTo = moment(endDate);
  const days = dateTo.diff(dateFrom, "days");
  return days + 1;
};
const formtDate = ({ startDate, endDate }) => {
  const dateFrom = new Date(startDate);
  const dateTo = new Date(endDate);

  if (startDate === endDate) return moment(dateFrom).format("DD MMMM");
  if (dateFrom.getMonth() === dateTo.getMonth())
    return `${moment(dateFrom).format("DD")} - ${moment(endDate).format(
      "DD MMMM"
    )}`;
  return `${moment(dateFrom).format("DD MMMM")} - ${moment(endDate).format(
    "DD MMMM"
  )}`;
};

const DayOffTabTable = ({ checkInId, filter, page, setPage }) => {
  const { t } = useTranslation();
  const defaultValue = getDefaultValueForDropdown(t("all"));
  const { loading, data } = useQuery(checkInDayOffQuery, {
    variables: {
      id: checkInId,
      filters: {
        userId: filter.userId === defaultValue.id ? null : filter.userId,
        status: filter.status === defaultValue.id ? null : filter.status,
        dateFrom: moment(filter.dateFrom).format(dateFormat),
        dateTo: moment(filter.dateTo).format(dateFormat)
      },
      pagination: {
        offset: page.offset,
        limit: page.limit
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const columns = [
    {
      title: t("dayOffTable.user"),
      isVisible: true,
      className: "uppercase text-center w-8/12",
      colspan: 1
    },
    {
      title: t("dayOffTable.days"),
      isVisible: true,
      className: "uppercase text-center w-4/12",
      colspan: 1
    },
    {
      title: t("dayOffTable.status"),
      isVisible: true,
      className: "uppercase text-center w-4/12",
      colspan: 1
    },
    {
      title: t("dayOffTable.dates"),
      isVisible: true,
      className: "uppercase text-center w-4/12",
      colspan: 1
    }
  ];
  if (loading) return <Loading />;
  if (!data?.checkIn) return <NotFoundPage />;
  const dataRender = data?.checkIn?.dayOff?.data.map((dayOffItem) => [
    {
      id: `avatar-${dayOffItem.id}`,
      render: () => (
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <Avatar
              src={dayOffItem.user.avatar}
              name={dayOffItem.user.name}
              className="w-10 h-10"
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              {dayOffItem.user.name}
            </div>
          </div>
        </div>
      )
    },
    {
      id: `days-${dayOffItem.id}`,
      render: () => (
        <div className="-mt-3 -mb-4">
          <div>
            <span className="text-blue-500">
              {getDifferenceBetweenDates(dayOffItem)}
            </span>
          </div>
        </div>
      ),
      className: "text-center"
    },
    {
      id: `status-${dayOffItem.id}`,
      render: () => (
        <div className="-mt-3 -mb-4">
          <div>
            <span className="capitalize">
              <Badge color={getColorStatus(dayOffItem.status)}>
                {t(`dayOffTable.${dayOffItem.status}`)}
              </Badge>
            </span>
          </div>
        </div>
      )
    },
    {
      id: `dates-${dayOffItem.id}`,
      render: () => (
        <div className="-mt-3 -mb-4">
          <div>
            <span className="text-blue-500">{formtDate(dayOffItem)}</span>
          </div>
        </div>
      )
    }
  ]);

  return (
    <div className="sm:-mx-6 lg:-mx-8 table-tr-border-none">
      <Table
        data={dataRender}
        columns={columns}
        page={page}
        setPage={setPage}
        typeResultText={t("dayOffTable.stories")}
        countElements={data.checkIn.dayOff.count}
      />
    </div>
  );
};
DayOffTabTable.propTypes = {
  checkInId: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  page: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number
  }),
  setPage: PropTypes.func
};

export default DayOffTabTable;
