import PropTypes from "prop-types";

import Toggle from "../../layout/Toggle/Toggle";

const EditUserCheckInSection = ({ userCheckIns, setUserChekIns }) => {
  if (!userCheckIns) return null;
  return (
    <ul className="divide-y divide-gray-200 border-t border-b border-gray-200">
      {userCheckIns.map((userCheckIn) => (
        <li key={userCheckIn.checkInId} className="py-4 flex justify-between">
          <div className="text-sm font-medium text-gray-900 flex items-center">
            {userCheckIn.title}
          </div>
          <Toggle
            enabled={userCheckIn.isUserInCheckIn}
            onChange={() => {
              const newUserCheckIns = userCheckIns.map((item) => {
                if (item.checkInId === userCheckIn.checkInId) {
                  return {
                    ...item,
                    isUserInCheckIn: !item.isUserInCheckIn
                  };
                }
                return item;
              });
              setUserChekIns(newUserCheckIns);
            }}
          />
        </li>
      ))}
    </ul>
  );
};

EditUserCheckInSection.propTypes = {
  userCheckIns: PropTypes.array,
  setUserChekIns: PropTypes.func
};

export default EditUserCheckInSection;
