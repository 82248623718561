import PropTypes from "prop-types";

import DatePickerRangeComponent from "./DatePickerRangeComponent";
import DatePickerSingleComponent from "./DatePickerSingleComponent";

const Datepicker = (props) => {
  if (props.type === "range")
    return <DatePickerRangeComponent {...{ ...props }} />;

  return <DatePickerSingleComponent {...{ ...props }} />;
};

Datepicker.propTypes = {
  type: PropTypes.oneOf(["range", "single"])
};

Datepicker.defaultProps = {
  type: "single"
};

export default Datepicker;
