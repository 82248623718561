import { gql } from "@apollo/client";

const updateCheckInNotification = gql`
  mutation updateCheckInNotification(
    $checkInId: ID!
    $input: UpdateCheckInNotificationInput!
  ) {
    updateCheckInNotification(input: $input, checkInId: $checkInId) {
      code
      success
    }
  }
`;

export default updateCheckInNotification;
