import {
  BanIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  LightningBoltIcon
} from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { getCheckInUrl } from "../../../../routes";
import Hint from "../../../layout/Hint";
import Link from "../../../layout/Link";
import SelectIcon from "../../../layout/Select/SelectIcon";
import CheckInIcon from "../../../TeamSingle/CheckInTable/CheckInIcon/CheckInIcon";
import CheckInStatusLabel from "../../../TeamSingle/CheckInTable/CheckInStatusLabel/CheckInStatusLabel";

const CheckInBlockDetails = ({ checkIn, nextRun, started, tabs }) => {
  const { t } = useTranslation();
  const {
    id: checkInId,
    title,
    type,
    isSetup,
    isActive,
    isWarning,
    activeRate,
    blockerCount,
    inProgressTasks,
    doneTasks
  } = checkIn;
  return (
    <div className="border border-gray-300 rounded-lg py-5 pl-5 pr-7">
      <div className="flex">
        <div className="w-2/12">
          <Link to={getCheckInUrl(checkInId)}>
            <div className="flex justify-center">
              <CheckInIcon type={type} className="w-9 mr-1 text-gray-400" />
            </div>
            <div className="flex justify-center mt-2">
              <CheckInStatusLabel checkIn={{ type, isSetup, isActive }} />
            </div>
          </Link>
        </div>
        <div className="w-9/12 pl-4 flex">
          <div>
            <Link to={getCheckInUrl(checkInId)}>
              <span className="text-gray-900">{title}</span>

              {started.length > 0 ? (
                <div>
                  <span className="text-gray-500">{started}</span>
                </div>
              ) : null}
              {nextRun.length > 0 ? (
                <div>
                  <span className="text-gray-500">{nextRun}</span>
                </div>
              ) : null}
            </Link>
          </div>
          <div className="ml-4 mt-6 flex">
            {activeRate !== null ? (
              <div>
                <Hint
                  hint={t("checkIns.activityScore")}
                  style={{ bottom: "35px" }}
                  className="w-max"
                >
                  <LightningBoltIcon className="w-4  mt-0.5 text-yellow-400 float-left" />
                  <span className="text-gray-500 ml-2">{activeRate}%</span>
                </Hint>
              </div>
            ) : null}
            {blockerCount !== null ? (
              <div className="ml-3">
                <Hint
                  className="w-max"
                  hint={t("checkIns.blockers")}
                  style={{ bottom: "35px" }}
                >
                  <BanIcon className="w-4 mt-0.5 text-red-400 float-left" />
                  <span className="text-gray-500 ml-2 pb-1">
                    {blockerCount}
                  </span>
                </Hint>{" "}
              </div>
            ) : null}
            {inProgressTasks !== null ? (
              <div className="ml-3">
                <Hint
                  className="w-max"
                  hint={t("checkIns.inProgress")}
                  style={{ bottom: "35px" }}
                >
                  <ClockIcon className="w-4 mt-0.5 text-blue-400 float-left" />
                  <span className="text-gray-500 ml-2">{inProgressTasks}</span>
                </Hint>{" "}
              </div>
            ) : null}
            {doneTasks !== null ? (
              <div className="ml-3">
                <Hint
                  className="w-max"
                  hint={t("checkIns.done")}
                  style={{ bottom: "35px" }}
                >
                  <CheckCircleIcon className="w-4 mt-0.5 text-green-400 float-left" />
                  <span className="text-gray-500 ml-2">{doneTasks}</span>
                </Hint>{" "}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-1/12">
          <div className="text-gray-400">
            <SelectIcon menu={tabs} />
          </div>
          {isWarning ? (
            <ExclamationCircleIcon className="w-6 text-yellow-400 mt-6" />
          ) : null}
        </div>
      </div>
    </div>
  );
};

CheckInBlockDetails.propTypes = {
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isSetup: PropTypes.bool.isRequired,
    isWarning: PropTypes.bool.isRequired,
    activeRate: PropTypes.number,
    blockerCount: PropTypes.number,
    inProgressTasks: PropTypes.number,
    doneTasks: PropTypes.number,
    isActive: PropTypes.bool.isRequired
  }),
  tabs: PropTypes.array,
  nextRun: PropTypes.string,
  started: PropTypes.string
};
export default CheckInBlockDetails;
