import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "../../../../layout/Input/Input";

const QuestionsZone = ({ checkInData, setCheckInData, errors }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg	p-6 mt-6">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">{t("questionsZone.questions")}</p>
          <p className="text-gray-500 text-sm">
            {t("questionsZone.description")}
          </p>
        </div>
        <div className="w-1/3">
          {checkInData.questions.map((question) => (
            <Input
              value={question.title}
              type="text"
              key={question.id}
              error={errors ? errors[question.id] : null}
              onChange={(e) => {
                const newQuestions = checkInData.questions.map((q) => {
                  if (question.id === q.id) {
                    return {
                      id: q.id,
                      title: e.target.value
                    };
                  }
                  return {
                    id: q.id,
                    title: q.title
                  };
                });

                setCheckInData({
                  ...checkInData,
                  questions: newQuestions
                });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
QuestionsZone.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    questions: PropTypes.array
  })
};
export default QuestionsZone;
