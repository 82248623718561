import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Select from "../../layout/Select";

const TimeZoneSection = ({ timeZoneSelected, setTimeZoneSelected }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg	p-6 mt-10">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">
            {" "}
            {t("userTimeZoneSettingSection.timezone")}
          </p>
          <p className="text-gray-500 text-sm">
            {t("userTimeZoneSettingSection.description")}
          </p>
        </div>
        <div className="w-1/3">
          <Select
            selectedOption={timeZoneSelected}
            label={t("userTimeZoneSettingSection.timezone")}
            onChange={setTimeZoneSelected}
            options={momentTimeZone.tz.names().map((timeZone) => ({
              id: timeZone,
              name: `${timeZone} (${momentTimeZone().tz(timeZone).format("Z")})`
            }))}
            classNameButton="flex"
            placeholder={t("planningPokerSetup.selectSpace")}
          />
        </div>
      </div>
    </div>
  );
};
TimeZoneSection.propTypes = {
  timeZoneSelected: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  setTimeZoneSelected: PropTypes.func
};
export default TimeZoneSection;
