/* eslint-disable max-statements */
import { useMutation } from "@apollo/client/index";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Loading from "../components/layout/Loading/Loading";
import { approveInviteMutationCode } from "../graphql/enum";
import approveTeamInviteLink from "../graphql/mutation/approveTeamInviteLink";
import { teamsUrl } from "../routes";
import Error from "./forbidden/Error/Error";

const ApproveTeamInviteLink = ({ location }) => {
  const { code } = queryString.parse(location.search);
  const [approveInviteLinkMutation, { loading, data }] = useMutation(
    approveTeamInviteLink
  );

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    approveInviteLinkMutation({ variables: { code } });
  }, [code, approveInviteLinkMutation]);
  if (loading) return <Loading />;

  if (data) {
    const { success, code: responseCode } = data.approveTeamInviteLink;
    if (success) {
      history.push(teamsUrl);
    }
    if (
      responseCode ===
      approveInviteMutationCode.userIsAlreadyInDifferentOrganization
    )
      return (
        <Error>{t("approveInvite.userIsAlreadyInDifferentOrganization")}</Error>
      );

    if (responseCode === approveInviteMutationCode.userIsAlreadyInTeam) {
      return <Error>{t("approveInvite.userIsAlreadyInTeam")}</Error>;
    }
    if (responseCode === approveInviteMutationCode.alreadyApproved) {
      return <Error>{t("approveInvite.tokenIsApproved")}</Error>;
    }
  }
  return null;
};
ApproveTeamInviteLink.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default ApproveTeamInviteLink;
