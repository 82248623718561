/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Dialog } from "@headlessui/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { organizationRoles } from "../../../../graphql/enum";
import createUsersTeams from "../../../../graphql/mutation/createUsersTeams";
import deleteUserTeam from "../../../../graphql/mutation/deleteUserTeam";
import updateUserOrganization from "../../../../graphql/mutation/updateUserOrganization";
import updateUserTeams from "../../../../graphql/mutation/updateUserTeams";
import Button from "../../../layout/Button";
import ListOfUsers from "../../../layout/ListOfUsers/ListOfUsers";
import Modal from "../../../layout/Modal";
import AddUserToTeamForm from "../../InvitePeopleModal/AddUserToTeamForm/AddUserToTeamForm";
import ActionWithUser from "../ActionWithUser/ActionWithUser";
import NameUserAndRoleOrganization from "../NameUserAndRoleOrganization/NameUserAndRoleOrganization";

const EditPeopleUserModal = ({
  visible,
  onClose,
  selectedUser,
  teams,
  refetch,
  organizationId
}) => {
  const [roleOrganization, setRoleOrganization] = useState();
  const [selectedTeams, setSelectedTeams] = useState(
    selectedUser ? selectedUser.myTeams : []
  );
  const [deleteUserTeamMutation] = useMutation(deleteUserTeam);
  const [updateUserOrganizationMutation] = useMutation(updateUserOrganization);
  const [updateUserTeamsMutation] = useMutation(updateUserTeams);
  const [createUsersTeamsMutation] = useMutation(createUsersTeams);

  const { t } = useTranslation();

  if (!selectedUser) {
    return null;
  }

  const getTeamsAddAndDeleteAndUpdate = (
    selectedTeamsParms,
    changeListTeams
  ) => {
    const userTeamsToCreate = [];
    const deleteTeamFromUser = [];
    const updateTeamFromUser = [];
    changeListTeams.map((element) => {
      selectedTeamsParms.map((selectedTeam) => {
        if (selectedTeam.id === element.id) {
          if (selectedTeam.role !== element.role) {
            updateTeamFromUser.push(element);
          }
        }
        return null;
      });

      const isAlreadyList = selectedTeamsParms.some(
        (selectedTeam) => selectedTeam.id === element.id
      );
      if (!isAlreadyList) {
        userTeamsToCreate.push(element);
      }
      return element;
    });
    selectedTeamsParms.map((element) => {
      const isAlreadyList = changeListTeams.some(
        (selectedTeam) => selectedTeam.id === element.id
      );
      if (!isAlreadyList) {
        deleteTeamFromUser.push(element);
      }
      return element;
    });
    return {
      deleteTeams: deleteTeamFromUser,
      newUserTeamsToCreate: userTeamsToCreate,
      updateTeams: updateTeamFromUser
    };
  };

  const onSave = async () => {
    const { deleteTeams, newUserTeamsToCreate, updateTeams } =
      getTeamsAddAndDeleteAndUpdate(selectedUser.myTeams, selectedTeams);

    if (deleteTeams.length !== 0) {
      await deleteUserTeamMutation({
        variables: {
          deleteUserTeamInput: deleteTeams.map((team) => ({
            userId: selectedUser.id,
            teamId: team.id
          }))
        }
      });
    }
    if (updateTeams.length !== 0) {
      await updateUserTeamsMutation({
        variables: {
          input: updateTeams.map((team) => ({
            role: team.role,
            userId: selectedUser.id,
            teamId: team.id
          }))
        }
      });
    }
    if (newUserTeamsToCreate.length !== 0) {
      await createUsersTeamsMutation({
        variables: {
          input: newUserTeamsToCreate.map((team) => ({
            role: team.role,
            userId: selectedUser.id,
            teamId: team.id
          }))
        }
      });
    }

    if (selectedUser.organizationRole !== roleOrganization.id) {
      await updateUserOrganizationMutation({
        variables: {
          updateUserOrganizationInput: {
            role: roleOrganization.id,
            userId: selectedUser.id,
            organizationId
          }
        }
      });
    }
    refetch();
    onClose();
  };
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className="p-8">
        <div className="mt-1 text-center sm:mt-1">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {t("editPeopleUserModal.editUser")}
          </Dialog.Title>
        </div>
        <NameUserAndRoleOrganization
          selectedUser={selectedUser}
          role={roleOrganization}
          setRole={setRoleOrganization}
        />
        <AddUserToTeamForm
          teams={teams}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
        />
        {selectedTeams.length !== 0 ? (
          <div className="mt-8">
            <div className="text-left uppercase text-gray-500 font-semibold mt-3">{`${t(
              "editPeopleUserModal.listTeamUser"
            )} ${selectedUser.name}`}</div>
            <ListOfUsers
              users={selectedTeams.map((team) => ({
                user: { id: team.id, email: team.title, name: team.title },
                badges: [t(`teamRoles.${team.role}`)]
              }))}
              onClose={(id) => {
                const newSelectedUsers = selectedTeams.filter(
                  (selectedTeam) => selectedTeam.id !== id
                );
                setSelectedTeams(newSelectedUsers);
              }}
              isDisplayAvatar={false}
            />
          </div>
        ) : null}
        {selectedUser.organizationRole !== organizationRoles.owner ? (
          <ActionWithUser
            selectedUser={selectedUser}
            onClose={onClose}
            refetch={refetch}
            organizationId={organizationId}
          />
        ) : null}
      </div>

      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end">
          <div className="flex">
            <Button layout="white" className="mr-5" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button type="submit" form="createTeamModal" onClick={onSave}>
              {t("Save")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

EditPeopleUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.string,
    isBotAdded: PropTypes.bool,
    organizationRole: PropTypes.string,
    organizations: PropTypes.shape({
      organizationId: PropTypes.string
    }),
    status: PropTypes.string,
    myTeams: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        role: PropTypes.string
      })
    )
  }),
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string
    })
  ),
  refetch: PropTypes.func,
  organizationId: PropTypes.string
};
export default EditPeopleUserModal;
