/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  setupStatusEnum,
  summaryRoomType,
  validationErrors
} from "../../../../../graphql/enum";
import updateCheckIn from "../../../../../graphql/mutation/updateCheckIn";
import { UserContext } from "../../../../../utility/context/User";
import Button from "../../../../layout/Button";
import SetupCheckInSuccess from "../../SetupCheckInSuccess/SetupCheckInSuccess";
import GeneralZone from "./GeneralZone/GeneralZone";
import ScheduleZone from "./ScheduleZone/ScheduleZone";
import SummaryZone from "./SummaryZone/SummaryZone";

const Review = ({ checkIn, checkInId, refetch, setCurrentStep }) => {
  const { user } = useContext(UserContext);

  const [checkInData, setCheckInData] = useState({
    membersCanSeeOnlyOwnResponses: true,
    startTime: {
      id: momentTimeZone
        .utc(checkIn.startTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("HH:mm:ss"),
      name: momentTimeZone
        .utc(checkIn.startTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("h:mm a")
    },
    summaryTime: {
      id: momentTimeZone
        .utc(checkIn.summaryTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("HH:mm:ss"),
      name: momentTimeZone
        .utc(checkIn.summaryTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("h:mm a")
    },
    schedule: checkIn.schedule.map((d) => ({
      id: d,
      name: d
    })),
    summaryType: checkIn.summaryType,
    summarySpace: checkIn.summarySpace
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();

  const [updateCheckInMutation] = useMutation(updateCheckIn);

  if (isSuccess)
    return (
      <SetupCheckInSuccess
        title={t("successZone.checkInSuccessfulyyCreated")}
        description={t("successZone.description")}
        onViewCheckIn={async () => {
          await refetch();
        }}
      />
    );

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = t(`validationErrors.${validationErrors.required}`);
    }
    if (
      checkInData.summaryType === summaryRoomType.space &&
      !checkInData.summarySpace
    ) {
      errors.summarySpace = t(`validationErrors.${validationErrors.required}`);
    }
    if (checkInData.schedule.length === 0) {
      errors.schedule = t(`validationErrors.${validationErrors.required}`);
    }

    return errors;
  };

  const onSubmit = (values) => async (isSetup) => {
    await updateCheckInMutation({
      variables: {
        checkInId,
        input: {
          isSetup,
          title: values.title,
          summaryType: checkInData.summaryType,
          summarySpace:
            checkInData.summaryType === summaryRoomType.space
              ? checkInData.summarySpace.spaceName
              : "",
          startTime: momentTimeZone
            .tz(checkInData.startTime.name, "hh:mm Z", user.timeZone)
            .utc()
            .format("HH:mm:ss"),
          summaryTime: momentTimeZone
            .tz(checkInData.summaryTime.name, "hh:mm Z", user.timeZone)
            .utc()
            .format("HH:mm:ss"),
          schedule: checkInData.schedule.map(({ id }) => id)
        }
      }
    });
  };
  return (
    <>
      <Formik
        validate={validate}
        initialValues={{
          title: checkIn.title
        }}
        onSubmit={onSubmit}
      >
        {({ errors, submitForm }) => (
          <Form>
            <GeneralZone
              checkIn={checkIn}
              checkInData={checkInData}
              setCheckInData={setCheckInData}
              errors={errors}
            />
            <ScheduleZone
              usersTeam={checkIn.team.users}
              currentUser={user}
              checkIn={checkIn}
              checkInData={checkInData}
              setCheckInData={setCheckInData}
              errors={errors}
            />
            <SummaryZone
              usersTeam={checkIn.team.users}
              currentUser={user}
              checkIn={checkIn}
              checkInData={checkInData}
              setCheckInData={setCheckInData}
              errors={errors}
            />
            <div className="flex justify-between mt-20">
              <div>
                <Button
                  className="mr-5"
                  layout="white"
                  type="submit"
                  onClick={async () => {
                    const updateCheckInMutationSend = await submitForm();
                    await updateCheckInMutationSend(false);
                    await refetch();
                    await setCurrentStep(setupStatusEnum.taskLists);
                  }}
                >
                  {t("Previous")}
                </Button>
              </div>
              <div>
                <Button
                  layout="primary"
                  type="submit"
                  onClick={async () => {
                    const updateCheckInMutationSend = await submitForm();
                    await updateCheckInMutationSend(true);
                    await setIsSuccess(true);
                  }}
                >
                  {t("Next")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
Review.propTypes = {
  checkIn: PropTypes.shape({
    title: PropTypes.string.isRequired,
    startTime: PropTypes.string,
    summaryTime: PropTypes.string,
    schedule: PropTypes.array,
    summaryType: PropTypes.oneOf(Object.values(summaryRoomType)),
    summarySpace: PropTypes.string,
    team: PropTypes.shape({
      users: PropTypes.array
    })
  }),
  checkInId: PropTypes.string,
  refetch: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired
};
export default Review;
