import { gql } from "@apollo/client";

const CreateCheckInQuestions = gql`
  mutation createCheckInQuestionsPlanningPoker(
    $input: [CreateCheckInQuestionsPlanningPokerInput]
    $checkInId: ID!
  ) {
    createCheckInQuestionsPlanningPoker(input: $input, checkInId: $checkInId) {
      success
    }
  }
`;

export default CreateCheckInQuestions;
