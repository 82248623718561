import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

import createCheckInRetrospectiveRoomAction from "../../../../../../../graphql/mutation/createCheckInRetrospectiveRoomAction";
import deleteRetrospectiveItemsRoomAction from "../../../../../../../graphql/mutation/deleteRetrospectiveItemsRoomAction";
import updateCheckInRetrospectiveRoomAction from "../../../../../../../graphql/mutation/updateCheckInRetrospectiveRoomAction";
import SetupCheckInRetrospectiveItem from "../../../SetupCheckInRetrospectiveItem";

const AssignUserActionRooms = ({
  newAction,
  setNewAction,
  retrospectiveItemsRoomAction,
  refetch,
  roomId
}) => {
  const [createCheckInRetrospectiveRoomActionMutation] = useMutation(
    createCheckInRetrospectiveRoomAction
  );
  const [updateCheckInRetrospectiveRoomActionMutation] = useMutation(
    updateCheckInRetrospectiveRoomAction
  );
  const [deleteRetrospectiveItemsRoomActionMutation] = useMutation(
    deleteRetrospectiveItemsRoomAction
  );
  return (
    <>
      {newAction.roomId === roomId ? (
        <SetupCheckInRetrospectiveItem
          key={roomId}
          item={newAction}
          classnamefield="contents justify-center"
          saveItem={async ({ title }) => {
            await createCheckInRetrospectiveRoomActionMutation({
              variables: {
                input: {
                  retrospectiveItemsRoomId: newAction.roomId,
                  title
                }
              }
            });
            await refetch();
            setNewAction({});
          }}
          isDisplayDescription={false}
          addonAfter={
            <TrashIcon
              className="w-5 cursor-pointer"
              onClick={() => {
                setNewAction({});
              }}
            />
          }
        />
      ) : null}
      {retrospectiveItemsRoomAction ? (
        <SetupCheckInRetrospectiveItem
          key={retrospectiveItemsRoomAction.id}
          item={retrospectiveItemsRoomAction}
          classnamefield="contents justify-center"
          addonAfter={
            <TrashIcon
              className="w-5 cursor-pointer"
              onClick={async () => {
                await deleteRetrospectiveItemsRoomActionMutation({
                  variables: {
                    retrospectiveItemsRoomActionId:
                      retrospectiveItemsRoomAction.id
                  }
                });
                await refetch();
              }}
            />
          }
          saveItem={async ({ id: retrospectiveItemsRoomActionId, title }) => {
            await updateCheckInRetrospectiveRoomActionMutation({
              variables: {
                retrospectiveItemsRoomActionId,
                input: {
                  title
                }
              }
            });
            await refetch();
          }}
          isDisplayDescription={false}
        />
      ) : null}
    </>
  );
};

AssignUserActionRooms.propTypes = {
  newAction: PropTypes.object.isRequired,
  setNewAction: PropTypes.func,
  retrospectiveItemsRoomAction: PropTypes.object,
  roomId: PropTypes.string,
  refetch: PropTypes.func
};
export default AssignUserActionRooms;
