/* eslint-disable max-statements */
import PropTypes from "prop-types";
import queryString from "query-string";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { getCheckInUrl } from "../../../routes";
import Tabs from "../../layout/Tabs";
import DashboardTab from "./DashboardTab";
import ButtonDowloadCSV from "./DashboardTab/ButtonDowloadCSV/ButtonDowloadCSV";

const DayOffSingle = ({ checkInId, checkIn }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const parameters = new URLSearchParams(window.location.search);
  const defaultKeyId = parameters.get("defaultKey");
  const activeTab = parameters.get("activeTab");

  useEffect(() => {
    if (!activeTab && defaultKeyId) {
      const url = queryString.stringifyUrl({
        url: getCheckInUrl(checkInId),
        query: {
          activeTab: defaultKeyId
        }
      });
      history.push(url);
    }
  });
  const tabs = [
    {
      name: "dashboard",
      title: t("Dashboard"),
      render: () => <DashboardTab checkInId={checkInId} checkIn={checkIn} />,
      rightContent: <ButtonDowloadCSV checkInId={checkInId} />
    }
  ];

  return (
    <Tabs
      tabs={tabs}
      defaultKey={activeTab || "dashboard"}
      currentUrl={getCheckInUrl(checkInId)}
    />
  );
};

DayOffSingle.propTypes = {
  checkInId: PropTypes.string.isRequired,
  checkIn: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired
};
export default DayOffSingle;
