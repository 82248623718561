import PropTypes from "prop-types";

import LinearSteps from "./LinearSteps";
import NumericSteps from "./NumericSteps";

const Steps = (props) => {
  const { layout } = props;

  switch (layout) {
    case "numeric":
      return <NumericSteps {...{ ...props }} />;
    case "linear":
      return <LinearSteps {...{ ...props }} />;

    default:
      return null;
  }
};
Steps.defaultProps = {
  layout: "numeric"
};
Steps.propTypes = {
  layout: PropTypes.oneOf(["numeric", "linear"])
};

export default Steps;
