import { CalendarIcon } from "@heroicons/react/solid";
import { t } from "i18next";
import PropTypes from "prop-types";

import { convertTimeTo12Hours } from "../../../utility/date";
import Select from "../Select";

const getTimes = () => {
  const hours = [];
  for (let i = 0; i < 24; i += 1) {
    hours.push(i);
  }
  const minutes = [];
  for (let i = 0; i < 60; i += 15) {
    minutes.push(i);
  }

  const times = [];
  hours.map((h) => {
    minutes.map((m) => {
      const time = `${h}:${m}:00`;
      const name = convertTimeTo12Hours(time).format("h:mm a");
      const id = convertTimeTo12Hours(time).format("HH:mm:ss");

      times.push({
        id,
        name
      });

      return m;
    });
    return h;
  });
  return times;
};
const DatePicker = ({
  currentValue,
  startValue,
  onChange,
  addonafter,
  label,
  error
}) => {
  let times = getTimes();

  if (startValue) {
    times = times.filter((timeObj) => timeObj.id > startValue.id);
  }

  return (
    <>
      <div className="text-left	block text-sm font-medium text-gray-700">
        {label}
      </div>
      <div>
        <Select
          options={times}
          selectedOption={currentValue}
          onChange={onChange}
          icon={
            <CalendarIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          }
        />
        {addonafter}
      </div>
      {error ? (
        <p className="text-gray-400 text-left">{error}</p>
      ) : (
        <p className={`opacity-0 `}>{t("empty")}</p>
      )}
    </>
  );
};
DatePicker.propTypes = {
  currentValue: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.any
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  startValue: PropTypes.shape({
    id: PropTypes.string
  }),
  label: PropTypes.any,
  addonafter: PropTypes.object,
  error: PropTypes.any
};
export default DatePicker;
