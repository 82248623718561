import { XIcon } from "@heroicons/react/outline";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { questionTypesEnum } from "../../../../../graphql/enum";
import { dateFormat } from "../../../../../graphql/utils/date";
import Avatar from "../../../../layout/Avatar";
import LinkView from "../../../../layout/Link";
import Pagination from "../../../../layout/Pagination/Pagination";

const StandupResponsesTable = ({
  checkInStandupResponses,
  questions,
  page,
  setPage
}) => {
  const { t } = useTranslation();

  const pageSizeTable = 10;

  const { data } = checkInStandupResponses;
  const columns = [
    {
      title: t("User"),
      isVisible: true
    },
    {
      title: t("Question"),
      isVisible: true
    },
    {
      title: t("Responses"),
      isVisible: true
    }
  ];
  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:pl-2 lg:pr-2">
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 rounded-lg"></div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr key="col">
                      {columns.map((col) => (
                        <th
                          key={col.title}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {col.title}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {data.length > 0 ? (
                      data.map((dataItem, index) => (
                        <tr
                          key={index}
                          className={
                            data[index + 1] ? "border-b-15 border-gray-50" : ""
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap w-3/12 text-left">
                            <div className="flex flex-col items-center">
                              <Avatar
                                src={dataItem.user.avatar}
                                name={dataItem.user.name}
                              />
                              <p className="text-gray-900 font-medium mt-4">
                                {dataItem.user.name}
                              </p>
                              <p className="text-gray-500">
                                {moment(dataItem.date, dateFormat).format(
                                  "ddd MMM D YYYY"
                                )}
                              </p>
                            </div>
                          </td>
                          <td colSpan="2">
                            <ul className="divide-y divide-gray-200">
                              {questions.map((q) => {
                                const response = dataItem?.responses.find(
                                  (r) => r.question.id === q.id
                                );

                                if (
                                  !response ||
                                  response?.responseData.length === 0
                                )
                                  return (
                                    <li
                                      className="flex items-center py-8"
                                      key={q.id}
                                    >
                                      <div className="w-6/12 text-gray-500">
                                        {q.title}
                                      </div>
                                      <div className="w-6/12">
                                        <XIcon
                                          width="20"
                                          height="20"
                                          className="text-gray-300"
                                        />
                                      </div>
                                    </li>
                                  );

                                return (
                                  <li
                                    className="flex items-center py-8"
                                    key={q.id}
                                  >
                                    <div className="w-6/12 text-gray-500">
                                      {q.title}
                                    </div>
                                    <div className="w-6/12">
                                      {response.question.type ===
                                      questionTypesEnum.tasksList ? (
                                        response.responseData.map(
                                          (responseItem, idx) => (
                                            <div key={idx}>
                                              <LinkView
                                                to={{
                                                  pathname: responseItem.taskUrl
                                                }}
                                                target="_blank"
                                              >
                                                {responseItem.taskTitle}
                                              </LinkView>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <div>
                                          {
                                            response.responseData[0]
                                              ?.textMessage
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key="noData">
                        <td
                          colSpan={
                            columns.length > 1
                              ? columns.reduce((previousValue, col) => ({
                                  countColumns:
                                    (previousValue.countColumns ??
                                      previousValue.colspan ??
                                      1) + (col.colspan ?? 1)
                                })).countColumns
                              : 1
                          }
                          className={`px-6 py-16 whitespace-nowrap text-center text-xs font-medium text-gray-500`}
                        >
                          {t("tablesRows.noData")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <Pagination
                  {...{
                    page,
                    setPage: { setPage },
                    pageSize: pageSizeTable,
                    countElements: checkInStandupResponses.count,
                    typeResultText: "text"
                  }}
                  displayElements={pageSizeTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
StandupResponsesTable.propTypes = {
  checkInStandupResponses: PropTypes.shape({
    data: PropTypes.array.isRequired,
    count: PropTypes.string
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  page: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number
  }),
  setPage: PropTypes.func,
  setCurrentPageTable: PropTypes.func
};

export default StandupResponsesTable;
