import PropTypes from "prop-types";

const GrayButton = ({
  onClick,
  children,
  type = "button",
  form,
  className
}) => (
  <button
    type={type}
    className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${className}`}
    {...{
      onClick,
      form,
      type
    }}
  >
    {children}
  </button>
);
GrayButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  type: PropTypes.string,
  form: PropTypes.func,
  className: PropTypes.string
};
export default GrayButton;
