import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { integrationTypes } from "../../../../../../graphql/enum";
import updateDataSource from "../../../../../../graphql/mutation/updateDataSource";
import Page from "../../../../../layout/Page";
import ConnectTaskTrackerBlock from "../../TaskTrackers/ConnectTaskTrackerSection/ConnectTaskTrackerBlock/ConnectTaskTrackerBlock";
import Filters from "./Filters/Filters";
import { PreviewTasksTable } from "./PreviewTasksTable/PreviewTasksTable";

const PreviewQuestion = ({
  question,
  integrationType,
  dataSourceId,
  dataSource,
  checkIn,
  checkInId,
  isDisplayButtonSave,
  previewFilter,
  setPreviewFilter
}) => {
  const [isConnectTracker, setIsConnectTracker] = useState(true);
  const [isValideJQL, setIsValideJQL] = useState(true);

  const [updateDataSourceMutation] = useMutation(updateDataSource);
  useEffect(() => {
    if (!dataSource) return;
    setPreviewFilter({
      statusId: dataSource.statusIds[0],
      organizationId: dataSource.organizationId,
      filter: dataSource.filter,
      limit: dataSource.limit,
      updatedDaysAgo: dataSource.updatedDaysAgo,
      jql: dataSource.jql,
      asigneeIds: dataSource.asigneeIds || [],
      reporterIds: dataSource.reporterIds || [],
      createdDaysAgo: dataSource.createdDaysAgo,
      closedDaysAgo: dataSource.closedDaysAgo,
      projectIds: dataSource.projectIds || [],
      statusIds: dataSource.statusIds || []
    });
  }, [dataSource, setPreviewFilter]);

  if (!isConnectTracker)
    return (
      <ConnectTaskTrackerBlock
        type={checkIn.integrationType}
        bitrixDomain={checkIn.bitrixDomain}
        checkInId={checkInId}
        dataSourceId={dataSourceId}
      />
    );

  if (!question) return null;

  return (
    <Page
      key="page"
      sidebar={
        <div className="w-48">
          <Filters
            integrationType={integrationType}
            previewFilter={previewFilter}
            setPreviewFilter={setPreviewFilter}
            dataSourceId={question?.dataSource?.id}
            dataSource={dataSource}
            isValideJQL={isValideJQL}
            setIsValideJQL={setIsValideJQL}
          />
        </div>
      }
      modals={[]}
    >
      <PreviewTasksTable
        onSave={async () => {
          await updateDataSourceMutation({
            variables: {
              updateDataSourceInput: {
                dataSourceId,
                dataSourceFilter: {
                  ...previewFilter
                }
              }
            }
          });
        }}
        setIsConnectTracker={setIsConnectTracker}
        dataSourceId={dataSourceId}
        previewFilter={previewFilter}
        setIsValideJQL={setIsValideJQL}
        isDisplayButtonSave={isDisplayButtonSave}
      />
    </Page>
  );
};

PreviewQuestion.defaultProps = {
  setQuestionsFilters: () => {}
};

PreviewQuestion.propTypes = {
  question: PropTypes.object,
  integrationType: PropTypes.oneOf(Object.values(integrationTypes)),
  dataSourceId: PropTypes.string.isRequired,
  dataSource: PropTypes.object,
  refetch: PropTypes.func,
  checkIn: PropTypes.shape({
    id: PropTypes.string,
    integrationType: PropTypes.string,
    bitrixDomain: PropTypes.string
  }),
  checkInId: PropTypes.string,
  isDisplayButtonSave: PropTypes.bool,
  previewFilter: PropTypes.object.isRequired,
  setPreviewFilter: PropTypes.func
};

export default PreviewQuestion;
