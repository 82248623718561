/* eslint-disable react/prop-types */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { validationErrors } from "../../../graphql/enum";
import createTeam from "../../../graphql/mutation/createTeam";
import { getTeamUrl } from "../../../routes";
import Input from "../../layout/Input";

const CreateTeamForm = ({ onFormFinish, name }) => {
  const { t } = useTranslation();
  const [createTeamMutation] = useMutation(createTeam);
  const history = useHistory();

  const onCreateTeam = async ({ teamName }) => {
    const { data } = await createTeamMutation({
      variables: {
        title: teamName
      }
    });
    await onFormFinish();
    if (data.createTeam.success) {
      history.push(getTeamUrl(data.createTeam.id));
    }
  };
  const validate = (values) => {
    const errors = {};

    if (!values.teamName) {
      errors.teamName = t(`validationErrors.${validationErrors.required}`);
    }
    return errors;
  };
  return (
    <Formik
      validate={validate}
      initialValues={{
        teamName: ""
      }}
      onSubmit={onCreateTeam}
    >
      {({ errors }) => (
        <Form id={name}>
          <label
            htmlFor="team"
            className="block text-sm font-medium text-gray-700 text-left"
          >
            <p>{t("createTeamForm.team")}</p>
          </label>
          <div className="mt-1">
            <Input
              type="text"
              name="teamName"
              placeholder={t("createTeamForm.enterYourTeamName")}
              error={errors.teamName}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default CreateTeamForm;
