/* eslint-disable react/prop-types */
import { Dialog } from "@headlessui/react";
import { UserGroupIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../layout/Button";
import Modal from "../../layout/Modal";
import CreateTeamForm from "../CreateTeamForm";

const CreateTeamModal = ({ visible, onClose, refetch }) => {
  const [teamName, setTeamName] = useState("");
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <Modal visible={visible} onClose={onClose}>
      <div>
        <div className="mx-auto flex items-center justify-center">
          <UserGroupIcon
            className="h-12 w-12 text-sm text-gray-400 font-thin stroke-1"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {t("createTeamModal.createTeam")}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500 w-10/12 mx-auto">
              {t("createTeamModal.createTeamDescribe")}
            </p>
          </div>
          <div>
            <div className="mx-auto  w-6/12">
              <CreateTeamForm
                {...{ teamName, setTeamName }}
                onFormFinish={async () => {
                  await onClose();
                  await refetch();
                }}
                name="createTeamModal"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <div className="flex justify-end">
          <div className="flex">
            <button
              type="button"
              className="inline-flex flex-1 justify-center mr-5 w-full rounded-md border shadow-sm px-4 py-2 bg-white-600 text-base font-medium text-gray sm:text-sm"
              onClick={onClose}
            >
              {t("Cancel")}
            </button>
            <Button type="submit" form="createTeamModal">
              {t("createTeamModal.createTeam")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CreateTeamModal;
