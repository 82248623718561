import BotNotInstalled from "../components/BotNotInstalled/BotNotInstalled";
import Layout from "./Layout/NewLayout/NewLayout";

const PeopleView = () => (
  <Layout>
    <BotNotInstalled />
  </Layout>
);

export default PeopleView;
