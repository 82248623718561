export const daysOfTheWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const questionTypesEnum = {
  tasksList: "TASKS_LIST",
  textResponse: "TEXT_RESPONSE"
};
export const questionStateEnum = {
  skipInAnalytic: "SKIP_IN_ANALYTIC",
  planned: "PLANNED",
  done: "DONE",
  wentWell: "WENT_WELL",
  notWentWell: "NOT_WENT_WELL",
  actionItems: "ACTION_ITEMS"
};

export const integrationTypes = {
  jira: "JIRA",
  bitrix: "BITRIX",
  jiraTypes: {
    oauth: "oauth",
    basic: "basic-auth"
  },
  gitHub: "GITHUB"
};

export const mutationCodes = {
  success: "SUCCESS",
  unauthorized: "UNAUTHORIZED",
  accessDenied: "ACCESS_DENIED"
};

export const createOrganizationAccountMutationCode = {
  success: "SUCCESS",
  userIsAlreadyInOrganization: "USER_IS_ALREADY_IN_ORGANIZATION"
};

export const approveInviteMutationCode = {
  success: "SUCCESS",
  userIsAlreadyInStandup: "USER_IS_ALREADY_IN_STANDUP",
  userIsAlreadyInOrganization: "USER_IS_ALREADY_IN_ORGANIZATION",
  tokenExpired: "TOKEN_EXPIRED",
  alreadyApproved: "ALREADY_APPROVED",
  userIsAlreadyInDifferentOrganization:
    "USER_IS_ALREADY_IN_DIFFERENT_ORGANIZATION",
  userIsAlreadyInTeam: "USER_IS_ALREADY_IN_TEAM"
};

export const sendInviteMutationCode = {
  userIsAlreadyInStandup: "USER_IS_ALREADY_IN_STANDUP",
  userIsAlreadyInOrganization: "USER_IS_ALREADY_IN_ORGANIZATION"
};

export const organizationRoles = {
  owner: "OWNER",
  manager: "MANAGER",
  user: "USER",
  admin: "ADMIN"
};

export const manageOrganizationResources = {
  deleteOrganization: "deleteOrganization",
  renameOrganization: "renameOrganization",
  inviteNewUsers: "inviteNewUsers"
};

export const manageOrganizationUsersResources = {
  inviteNewUsers: "inviteNewUsers",
  deleteUsers: "deleteUsers"
};

export const resourceCategories = {
  manageStandup: "manageStandup",
  manageOrganizationStandup: "manageOrganizationStandup",
  manageOrganization: "manageOrganization",
  manageOrganizationUsers: "manageOrganizationUsers",
  manageOrganizationTeams: "manageOrganizationTeams"
};
export const manageOrganizationTeamsResources = {
  createTeams: "createTeams",
  deleteTeams: "deleteTeams"
};

export const messageStatuses = {
  sent: "SENT",
  seen: "SEEN",
  responseSent: "RESPONSE_SENT",
  skiped: "SKIPED"
};
export const aggregationTypes = {
  day: "day",
  week: "week",
  month: "month"
};

export const gitHubAuthorizeEroors = {
  accessDenied: "access_denied",
  applicationSuspended: "application_suspended",
  redirectUriMismatch: "redirect_uri_mismatch"
};

export const statusUserEnum = {
  active: "active",
  pendingInvitation: "pendingInvitation",
  emailNotVerified: "emailNotVerified",
  deactivated: "deactivated"
};

export const validationErrors = {
  required: "required",
  minimumValue: "minimumValue"
};
export const teamRoles = {
  user: "USER",
  manager: "MANAGER"
};

export const checkInTypes = {
  standup: "STANDUP",
  planningPoker: "PLANNING_POKER",
  dayOff: "DAY_OFF",
  retrospective: "RETROSPECTIVE",
  custom: "CUSTOM"
};

export const signInEnum = {
  success: "SUCCESS",
  userPasswordInvalid: "USER_PASSWORD_INVALID"
};

export const sendResetPasswordCode = {
  userPasswordInvalid: "USER_DOES_NOT_EXIST"
};

export const setupStatusEnum = {
  questions: "questions",
  taskTrackers: "taskTrackers",
  taskLists: "taskLists",
  review: "review"
};

export const standupResponseStatusTypes = {
  waiting: "WAITING",
  skipped: "SKIPPED",
  answered: "ANSWERED"
};

export const userConfirmationCodeEnum = {
  success: "SUCCESS",
  tokenExpired: "TOKEN_EXPIRED",
  alreadyUsed: "ALREADY_USED"
};

export const summaryRoomType = {
  personal: "personal",
  space: "space"
};

export const checkInDayOffStatusEnum = {
  approve: "APPROVE",
  pending: "PENDING",
  decline: "DECLINE"
};

export const checkInRunSetupStatusEnum = {
  createItems: "CREATE_ITEMS",
  mergeSimilar: "MERGE_SIMILAR",
  actionItems: "ACTION_ITEMS"
};
