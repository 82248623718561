/* eslint-disable complexity */
/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { dateFormat } from "../../../../graphql/utils/date";
import { cleanObject } from "../../../../graphql/utils/utils";
import { getCheckInUrl } from "../../../../routes";
import { convertStringToInteger } from "../../../../utility";
import { getDefaultValueForDropdown } from "../../../../utility/config";
import Loading from "../../../layout/Loading/Loading";
import Page from "../../../layout/Page";
import StandupResponsesFilter from "./StandupResponsesFilter/StandupResponsesFilter";
import StandupResponsesTable from "./StandupResponseTable/StandupResponsesTable";

export const checkInStandupResponsesQuery = gql`
  query checkInStandupResponses(
    $checkInId: ID!
    $checkInQuestionId: ID
    $filter: CheckInStandupResponsesFilter
    $checkInFilter: CheckInQuestionsListFilterInput
    $pagination: paginationInput
  ) {
    checkInStandupResponses {
      count(checkInId: $checkInId, filter: $filter)
      data(checkInId: $checkInId, filter: $filter, pagination: $pagination) {
        date
        id
        user {
          id
          name
          avatar
        }
        responses(checkInQuestionId: $checkInQuestionId) {
          id
          question {
            id
            title
            type
          }
          responseData {
            taskId
            taskTitle
            taskUrl
            textMessage
          }
        }
      }
    }
    checkIn(checkInId: $checkInId) {
      id
      users {
        id
        name
      }
      questions {
        data(filters: $checkInFilter) {
          id
          title
        }
      }
    }
  }
`;

export const StandupResponsesTab = ({ checkInId, checkIn }) => {
  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);

  const history = useHistory();
  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const userId = parameters.get("userId");
  const status = parameters.get("status");
  const checkInQuestionId = parameters.get("checkInQuestionId");
  const search = parameters.get("search");
  const { t } = useTranslation();
  const defaultValue = getDefaultValueForDropdown(t("all"));
  const offset = parameters.get("offset");
  const limit = parameters.get("limit");
  const [page, setPage] = useState({
    offset: !offset ? 0 : convertStringToInteger(offset),
    limit: !limit ? 10 : convertStringToInteger(limit)
  });
  const [filter, setFilter] = useState({
    search: search || "",
    dateFrom: dateFrom ? new Date(dateFrom) : defaultDateFrom,
    dateTo: dateTo ? new Date(dateTo) : defaultDateTo,
    userId,
    status,
    checkInQuestionId
  });
  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: getCheckInUrl(checkInId),
      query: cleanObject({
        activeTab: "responses",
        search: filter.search,
        userId: filter.userId,
        status: filter.status,
        checkInQuestionId: filter.checkInQuestionId,
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
        offset: page.offset,
        limit: page.limit
      })
    });
    history.push(url);
  }, [page, filter, history, checkInId]);

  const { loading, data } = useQuery(checkInStandupResponsesQuery, {
    variables: {
      checkInId,
      checkInQuestionId:
        filter.checkInQuestionId === defaultValue.id
          ? null
          : filter.checkInQuestionId,
      filter: {
        search: filter.search,
        userId: filter.userId === defaultValue.id ? null : filter.userId,
        status: filter.status === defaultValue.id ? null : filter.status,
        dateFrom: moment(filter.dateFrom).format(dateFormat),
        dateTo: moment(filter.dateTo).format(dateFormat)
      },
      checkInFilter: {
        checkInQuestionId:
          filter.checkInQuestionId === defaultValue.id
            ? null
            : filter.checkInQuestionId
      },
      pagination: {
        offset: page.offset,
        limit: page.limit
      }
    },
    notifyOnNetworkStatusChange: true
  });

  if (loading) return <Loading />;
  if (!data?.checkInStandupResponses) return null;

  return (
    <Page
      sidebar={
        <div className="w-48 sticky top-4">
          <StandupResponsesFilter
            checkInId={checkInId}
            filter={filter}
            setFilter={setFilter}
            users={data?.checkIn?.users}
            questions={checkIn.questions.data}
          />
        </div>
      }
      modals={[]}
    >
      <StandupResponsesTable
        checkInStandupResponses={data?.checkInStandupResponses}
        questions={data?.checkIn?.questions.data}
        page={page}
        setPage={setPage}
      />
    </Page>
  );
};
StandupResponsesTab.propTypes = {
  checkInId: PropTypes.string.isRequired,
  checkIn: PropTypes.shape({
    questions: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string
        })
      )
    })
  })
};
