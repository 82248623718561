/* eslint-disable react/prop-types */
/* eslint-disable i18next/no-literal-string */

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import GitHubIcon from "../../../components/layout/icons/GitHubIcon";
import TwitterIcon from "../../../components/layout/icons/TwitterIcon";
import { UserContext } from "../../../utility/context/User";
import Header from "../Header";

const Layout = ({ children }) => {
  const { user, logout } = useContext(UserContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  return (
    <div className="flex items-between min-h-screen flex-col">
      <div className="bg-white">
        <Header {...{ user, pathname, logout }} />
        <div className="pt-3 pb-10">
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-6">
              <div className="px-4 py-8 sm:px-0 layout-content">
                <div>{children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="max-w-7xl sm:px-12 lg:px-12 mt-auto py-3 mx-auto w-full">
        <div className="flex justify-between">
          <div className="text-gray-400">
            ©{new Date().getFullYear()} {t("aliiRightsReserved")}
          </div>
          <div className="flex">
            <Link className="mr-1" to={"#"}>
              <TwitterIcon height="20" width="20" />
            </Link>
            <Link to={"#"}>
              <GitHubIcon height="20" width="20" disabled={true} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
