import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import MultiselectComponent from "../../../../../layout/Multiselect/Multiselect";
import TimePicker from "../../../../../layout/TimePicker/TimePicker";
import UsersTimeZone from "../../../../../layout/UsersTimeZone/UsersTimeZone";

const ScheduleZone = ({
  checkInData,
  setCheckInData,
  errors,
  usersTeam,
  currentUser
}) => {
  const { t } = useTranslation();

  const days = [
    {
      id: "Mon",
      name: t("Mon")
    },
    {
      id: "Tue",
      name: t("Tue")
    },
    {
      id: "Wed",
      name: t("Wed")
    },
    {
      id: "Thu",
      name: t("Thu")
    },
    {
      id: "Fri",
      name: t("Fri")
    },
    {
      id: "Sat",
      name: t("Sat")
    },
    {
      id: "Sun",
      name: t("Sun")
    }
  ];

  return (
    <div className="shadow rounded-lg	p-6 mt-6">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">{t("scheduleZone.schedule")}</p>
          <p className="text-gray-500 text-sm">
            {t("scheduleZone.description")}
          </p>
        </div>
        <div className="w-2/3">
          <div className="w-1/2">
            <MultiselectComponent
              label={t("scheduleZone.schedule")}
              options={days}
              onChange={(newData) => {
                setCheckInData({
                  ...checkInData,
                  schedule: newData
                });
              }}
              selectedOptions={checkInData.schedule}
              error={errors.schedule}
            />
          </div>
          <div className="flex mt-6">
            <div className="w-1/2">
              <TimePicker
                currentValue={checkInData.startTime}
                onChange={(val) =>
                  setCheckInData({ ...checkInData, startTime: val })
                }
                label={t("scheduleZone.startTime")}
              />
              <span className="text-gray-500">
                {`${t("scheduleZone.yourTimezone")} ${currentUser.timeZone} (
              ${momentTimeZone().tz(currentUser.timeZone).format("Z")})`}
              </span>
            </div>
            <div className="w-1/2 pl-5">
              <UsersTimeZone
                users={usersTeam}
                time={checkInData.startTime}
                currentUser={currentUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ScheduleZone.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    schedule: PropTypes.string
  }),
  usersTeam: PropTypes.array,
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    timeZone: PropTypes.string,
    avatar: PropTypes.string
  })
};
export default ScheduleZone;
