/* eslint-disable max-statements */
import { gql } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { organizationRoles, validationErrors } from "../../../../graphql/enum";
import { validateEmail } from "../../../../utility";
import Button from "../../../layout/Button";
import Input from "../../../layout/Input";
import MultiSelect from "../../../layout/Multiselect";
import UserRole from "../UserRole/UserRole";

export const getUserByEmailQuery = gql`
  query getUserByIdQuery($email: String!) {
    user(email: $email) {
      user {
        id
        name
        avatar
        email
        organizations {
          organizationId
        }
      }
    }
  }
`;

const InvitePeopleForm = ({
  selectedUsers,
  setSelectedUsers,
  teams,
  client
}) => {
  const { t } = useTranslation();

  const roles = [
    {
      id: organizationRoles.user,
      name: t(organizationRoles.user),
      description: t("invitePeopleModal.userRoleDescription")
    },
    {
      id: organizationRoles.manager,
      name: t(organizationRoles.manager),
      description: t("invitePeopleModal.managerRoleDescription")
    },
    {
      id: organizationRoles.admin,
      name: t(organizationRoles.admin),
      description: t("invitePeopleModal.adminRoleDescription")
    }
  ];
  const [role, setRole] = useState(roles[0]);
  const [teamsSelecteds, setTeamsSelecteds] = useState([]);
  const [errorsValidate, setErrorsValidate] = useState();

  const getUser = async (email) => {
    const {
      data: { user }
    } = await client.query({
      query: getUserByEmailQuery,
      variables: {
        email
      }
    });
    return user.user;
  };
  const validate = (values, organizations) => {
    const errors = {};

    if (!validateEmail(values.email))
      errors.email = t("invitePeopleForm.emailIsNotCorrect");

    if (!values.email)
      errors.email = t(`validationErrors.${validationErrors.required}`);

    if (organizations !== null)
      errors.email = t("invitePeopleForm.userAlreadyInOrganization");

    selectedUsers.map(({ user }) => {
      if (user.email === values.email)
        errors.email = t("inviteTeamForm.userAlreadyInInviteList");

      return true;
    });
    return errors;
  };
  const onInvite = async (dataUser, { resetForm }) => {
    const user = await getUser(dataUser.email);
    const errors = validate({ ...dataUser }, user.organizations);

    setErrorsValidate(errors);

    const isErrors = Object.keys(errors).length !== 0;

    if (isErrors) return;

    setSelectedUsers([
      ...selectedUsers,
      {
        user: { ...user, role, teams: teamsSelecteds },
        badges: teamsSelecteds.map((elementTeam) => elementTeam.name)
      }
    ]);
    setRole();
    setTeamsSelecteds([]);
    resetForm({});
  };
  const teamsData = teams.map(({ id, title }) => ({ id, name: title }));

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      onSubmit={onInvite}
    >
      {() => (
        <Form>
          <div className="grid grid-cols-12 mt-8">
            <div className="col-span-7">
              <label
                htmlFor="role"
                className="text-left block text-sm font-medium text-gray-700"
              >
                {t("invitePeopleModal.user")}
              </label>
              <div className="relative">
                <Input
                  type="text"
                  name="email"
                  error={errorsValidate ? errorsValidate.email : null}
                  placeholder={t("invitePeopleModal.enterAnEmail")}
                  addonafter={
                    <div className="absolute top-0 right-0 flex items-center">
                      <UserRole
                        seletedOption={role}
                        onChange={setRole}
                        roles={roles}
                      />
                    </div>
                  }
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="ml-5 -mr-8">
                <MultiSelect
                  selectedOptions={teamsSelecteds}
                  onChange={setTeamsSelecteds}
                  options={teamsData}
                  label={t("invitePeopleModal.team")}
                  placeholder={t("invitePeopleModal.noTeam")}
                />
              </div>
            </div>
            <div className="col-span-1 flex justify-end mt-7 mb-1">
              <Button type="submit" className="w-2/12" layout="plus" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
InvitePeopleForm.propTypes = {
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        teams: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
          })
        ),
        role: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
          description: PropTypes.string
        })
      }),
      badges: PropTypes.array
    })
  ),
  setSelectedUsers: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string
    })
  ),
  client: PropTypes.object
};
export default withApollo(InvitePeopleForm);
