import { useTranslation } from "react-i18next";

import VerificationEmailButton from "./VerificationEmailButton/VerificationEmailButton";

const VerificationEmail = () => {
  const { t } = useTranslation();

  return (
    <div className=" flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src="/assets/img/standupify_icon_256px.png"
          alt="Standupify"
        />
        <h2
          className="mt-6 text-center font-extrabold text-gray-900 mb-0"
          style={{ fontSize: "1.85rem" }}
        >
          {t("notVerificationEmail.welcomeTo")}{" "}
          <span className="text-blue-400">
            {t("notVerificationEmail.standupify")}
          </span>
        </h2>
        <p className="mt-2 text-left text-sm font-normal text-gray-600 mb-0">
          <span className="font-semibold">
            {t("notVerificationEmail.weAlreadySendEmail")}{" "}
          </span>
          {t("notVerificationEmail.pleaseOpenClickLink")}
          <br />
          {t("notVerificationEmail.ifCouldntFindEmail")}
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <VerificationEmailButton />
        </div>
      </div>
    </div>
  );
};

export default VerificationEmail;
