import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Input from "../../../../../layout/Input/Input";
import Toggle from "../../../../../layout/Toggle/Toggle";

const GeneralZone = ({ checkInData, setCheckInData, checkIn, errors }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg	p-6">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">{t("generalZone.general")}</p>
          <p className="text-gray-500 text-sm">
            {t("generalZone.checkInNameUsedEveryWhere")}
          </p>
        </div>
        <div className="w-1/3">
          <Input
            label={t("generalZone.checkInName")}
            placeholder={t("generalZone.typeTheCheckInTitle")}
            type="text"
            name="title"
            error={errors.title}
          />
          <p className="text-gray-700 text-sm font-medium">
            {t("generalZone.checkInType")}
            <span className="font-bold">{checkIn.type}</span>
          </p>
          <p className="mt-6 font-medium flex items-center">
            <Toggle
              enabled={checkInData.membersCanSeeOnlyOwnResponses}
              onChange={() =>
                setCheckInData({
                  ...checkInData,
                  membersCanSeeOnlyOwnResponses:
                    !checkInData.membersCanSeeOnlyOwnResponses
                })
              }
            />

            <span className="ml-6">
              {t("generalZone.checkInMembersCanSeeOnlyOwnResponse")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
GeneralZone.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  checkIn: PropTypes.object.isRequired,
  errors: PropTypes.object
};
export default GeneralZone;
