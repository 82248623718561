import PropTypes from "prop-types";

import { questionStateEnum } from "../../../../../../graphql/enum";
import AssignUserActionRooms from "../AssignUserActionRooms/AssignUserActionRooms";

const AssignActionItemsQuestions = ({ questions, refetch }) => {
  const questionActionItem = questions.find(
    ({ questionState }) => questionState === questionStateEnum.actionItems
  );
  return questions.map(
    ({ id: checkInQuestionId, title, retrospectiveItemsRooms }, index) =>
      checkInQuestionId !== questionActionItem.id ? (
        <>
          <div>
            <span className="text-base font-bold text-black ">{title}</span>
          </div>
          <div>
            <span className="text-base font-bold text-black ">
              {index === 0 ? questionActionItem.title : null}
            </span>
          </div>
          {
            <AssignUserActionRooms
              refetch={refetch}
              rooms={retrospectiveItemsRooms}
              checkInQuestionId={checkInQuestionId}
            />
          }
        </>
      ) : null
  );
};

AssignActionItemsQuestions.propTypes = {
  questions: PropTypes.array,
  refetch: PropTypes.func
};
export default AssignActionItemsQuestions;
