import React from "react";

import ResetPasswordRequest from "../components/Authorization/ResetPasswordRequest/ResetPasswordRequest";
import AuthorizationLayout from "./Layout/AuthorizationLayout/AuthorizationLayout";

const ResetPasswordRequestView = () => (
  <AuthorizationLayout>
    <ResetPasswordRequest />
  </AuthorizationLayout>
);

export default ResetPasswordRequestView;
