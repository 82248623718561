import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Toggle from "../../../../layout/Toggle/Toggle";

const ScheduledReports = ({ checkInData, setCheckInData }) => {
  const { t } = useTranslation();
  return (
    <div className="shadow rounded-lg	p-6 mt-6">
      <div className="flex">
        <div className="w-1/3 mr-10">
          <p className="text-lg font-medium">
            {t("standupNotificationsTab.scheduledReports")}
          </p>
          <p className="text-gray-500 text-sm">
            {t("standupNotificationsTab.scheduledReportsDescription")}
          </p>
        </div>
        <div className="w-2/3">
          <div className="flex">
            <div className="w-1/12">
              <Toggle
                enabled={checkInData.isScheduledReportsWeekly}
                onChange={() =>
                  setCheckInData({
                    ...checkInData,
                    isScheduledReportsWeekly:
                      !checkInData.isScheduledReportsWeekly
                  })
                }
              />
            </div>
            <div className="w-11/12 -ml-1">
              <div>
                <span className=" text-sm  font-medium text-gray-700">
                  {t("standupNotificationsTab.weekly")}
                </span>
              </div>{" "}
              <div>
                <span className="text-sm font-normal text-gray-500">
                  {t("standupNotificationsTab.weeklyDescription")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex mt-6">
            <div className="w-1/12">
              <Toggle
                enabled={checkInData.isScheduledReportsMonthly}
                onChange={() =>
                  setCheckInData({
                    ...checkInData,
                    isScheduledReportsMonthly:
                      !checkInData.isScheduledReportsMonthly
                  })
                }
              />
            </div>
            <div className="w-11/12 -ml-1">
              <div>
                <span className=" text-sm  font-medium text-gray-700">
                  {t("standupNotificationsTab.monthly")}
                </span>
              </div>{" "}
              <div>
                <span className="text-sm font-normal text-gray-500">
                  {t("standupNotificationsTab.monthlyDescription")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ScheduledReports.propTypes = {
  checkInData: PropTypes.object.isRequired,
  setCheckInData: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    questions: PropTypes.array
  })
};
export default ScheduledReports;
