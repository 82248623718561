import PropTypes from "prop-types";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { classNames } from "../../../utility";

const Tabs = ({ defaultKey, tabs, currentUrl }) => {
  const [activeKey, setActiveKey] = useState("");
  const history = useHistory();

  useEffect(() => {
    setActiveKey(defaultKey);
  }, [defaultKey]);
  const onChange = (v) => {
    const url = queryString.stringifyUrl({
      url: currentUrl,
      query: {
        activeTab: v
      }
    });
    history.push(url);
  };
  const activeTab = tabs.find((tab) => tab.name === activeKey);

  return (
    <>
      <div className="p-5 mt-5 mb-10  border border-gray-200 border rounded-lg">
        <div className="sm:hidden">
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            value={activeKey}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value);
                return;
              }
              setActiveKey(e.target.value);
            }}
          >
            {tabs.map((tab) => (
              <option value={tab.name} key={tab.name}>
                {tab.title}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:flex justify-between">
          <nav className="flex justify-between" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.name === activeKey
                    ? "bg-indigo-100 text-indigo-700"
                    : "text-gray-500 hover:text-gray-700",
                  "px-3 py-2 font-medium text-sm rounded-md mr-2"
                )}
                aria-current={tab.name === activeKey ? "page" : undefined}
                onClick={() => {
                  if (onChange) {
                    onChange(tab.name);
                    return;
                  }
                  setActiveKey(tab.name);
                }}
              >
                {tab.title}
              </a>
            ))}
          </nav>
          <div>
            {activeTab ? activeTab.rightContent : activeTab?.rightContent}
          </div>
        </div>
      </div>
      <div>{activeTab ? activeTab.render() : null}</div>
    </>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
      rightContent: PropTypes.any
    }).isRequired
  ).isRequired,
  defaultKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  currentUrl: PropTypes.string
};

export default Tabs;
