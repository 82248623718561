/* eslint-disable max-statements */
import { useMutation } from "@apollo/client";
import { CloudUploadIcon } from "@heroicons/react/solid";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { validationErrors } from "../../../../graphql/enum";
import createCheckInQuestionsPlanningPoker from "../../../../graphql/mutation/createCheckInQuestionsPlanningPoker";
import { teamsUrl } from "../../../../routes";
import Button from "../../../layout/Button/index";
import Page from "../../../layout/Page";
import PlanningPokerStartTable from "./PlanningPokerStartTable/PlanningPokerStartTable";

const PlanningPokerStartTab = ({ checkInId, alert }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isImportTaskVisible, setIsImportTaskInModalVisible] = useState(false);
  const [createCheckInQuestionsPlanningPokerMutation] = useMutation(
    createCheckInQuestionsPlanningPoker
  );
  const [isFaildValidateOnChange, setIsFaildValidateOnChange] = useState({
    validateOnChange: false
  });
  const [lastId, setLastId] = useState(0);
  const [questions, setQuestions] = useState([
    {
      id: 1
    }
  ]);
  const addNewTask = () => {
    const taskId = lastId ? lastId + 1 : 0;
    setQuestions([
      ...questions,
      {
        id: taskId
      }
    ]);
    setLastId(lastId + 1);
    return taskId;
  };

  const initialValues = () => {
    const checkInLink = [];
    const checkInTitle = [];
    questions.map((question) => {
      checkInLink[question.id] = "";
      checkInTitle[question.id] = "";
      return null;
    });
    return {
      checkInLink,
      checkInTitle
    };
  };
  const validate = (fields) => {
    const checkInLinkError = [];
    const checkInTitleError = [];
    const textError = t(`validationErrors.${validationErrors.required}`);

    questions.map(({ id: questionId }) => {
      if (
        !fields.checkInLink[questionId] ||
        fields.checkInLink[questionId] === ""
      ) {
        checkInLinkError[questionId] = textError;
      }
      if (
        !fields.checkInTitle[questionId] ||
        fields.checkInTitle[questionId] === ""
      ) {
        checkInTitleError[questionId] = textError;
      }
      return null;
    });
    if (checkInLinkError.length === 0 && checkInTitleError.length === 0) {
      return {};
    }
    return {
      checkInLink: checkInLinkError,
      checkInTitle: checkInTitleError
    };
  };
  const onStartcheckIn = async (fields, { setErrors }) => {
    setIsFaildValidateOnChange({ validate });
    const errors = validate(fields);

    setErrors(errors);
    const input = questions.map(({ id: questionId }) => ({
      title: fields.checkInTitle[questionId],
      taskLink: fields.checkInLink[questionId]
    }));

    if (Object.keys(errors).length > 0) return;

    const { data } = await createCheckInQuestionsPlanningPokerMutation({
      variables: {
        input,
        checkInId
      }
    });
    if (data.createCheckInQuestionsPlanningPoker.success) {
      alert.success(
        <div>
          <p className="text-black">{t("alert.successfully")}</p>
          <p className="text-gray-500">{t("alert.successfullySaved")}</p>
        </div>
      );
      history.push(teamsUrl);
    }
  };
  return (
    <Page
      key="page"
      pageName={t("planningPokerStart.startPlanningPoker")}
      modals={[]}
    >
      <Formik
        {...{ ...isFaildValidateOnChange }}
        initialValues={initialValues()}
        onSubmit={onStartcheckIn}
      >
        {({ setFieldValue, errors }) => (
          <Form>
            <div className="flex justify-end -mx-6">
              <Button
                layout="primaryOutline"
                className="flex"
                onClick={() => setIsImportTaskInModalVisible(true)}
              >
                <>
                  <CloudUploadIcon className="w-4 text-indigo-500 mt-1" />{" "}
                  <span className="ml-2">
                    {t("planningPokerStart.importTasks")}
                  </span>
                </>
              </Button>
            </div>
            <div className="mt-3">
              <PlanningPokerStartTable
                questions={questions}
                setQuestions={setQuestions}
                errors={errors}
                setFieldValue={setFieldValue}
                lastId={lastId}
                setLastId={setLastId}
                isImportTaskVisible={isImportTaskVisible}
                setIsImportTaskInModalVisible={setIsImportTaskInModalVisible}
              />
            </div>
            <div className="mt-5 flex text-indigo-700 hover:text-indigo-800 cursor-pointer">
              <Button layout="plus" className="flex" onClick={addNewTask} />
              <span className=" ml-2 text-sm mt-1" onClick={addNewTask}>
                {t("planningPokerStart.addNewTask")}
              </span>
            </div>
            <div className="mt-5 flex justify-end">
              <Button
                type="submit"
                layout="primary"
                className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium "
              >
                {t("planningPokerStart.startCheckIn")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

PlanningPokerStartTab.propTypes = {
  alert: PropTypes.shape({
    success: PropTypes.func
  }),
  checkInId: PropTypes.string.isRequired
};
export default withAlert()(PlanningPokerStartTab);
