/* eslint-disable max-statements */
import { DocumentDownloadIcon } from "@heroicons/react/solid";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { dateFormat } from "../../../../../graphql/utils/date";
import { cleanObject } from "../../../../../graphql/utils/utils";
import { getExportPlanningPokerQuestionsCsvQuery } from "../../../../../routes";
import Button from "../../../../layout/Button";

const PlanningPokerButtonDowloadCsv = ({ checkInId }) => {
  const { t } = useTranslation();

  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 1);

  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const search = parameters.get("search");

  const dateFromFilter = dateFrom ? new Date(dateFrom) : defaultDateFrom;
  const dateToFilter = dateTo ? new Date(dateTo) : defaultDateTo;

  const onGeneratedCsv = () => {
    const query = queryString.stringifyUrl({
      url: getExportPlanningPokerQuestionsCsvQuery(checkInId),
      query: cleanObject({
        search,
        dateFrom: moment(dateFromFilter).format(dateFormat),
        dateTo: moment(dateToFilter).format(dateFormat)
      })
    });
    window.open(query);
  };

  return (
    <Button
      layout="primaryOutline"
      className="flex items-center"
      onClick={onGeneratedCsv}
    >
      <>
        <DocumentDownloadIcon className="w-5 h-5 mr-2" />
        {t("planningPokerTab.downloadCsv")}
      </>
    </Button>
  );
};
PlanningPokerButtonDowloadCsv.propTypes = {
  checkInId: PropTypes.string.isRequired
};
export default PlanningPokerButtonDowloadCsv;
