import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CustomTooltip = ({ active, payload, isTooltipActive, isInRightHalf }) => {
  const { t } = useTranslation();
  if (payload === null) return null;

  const dataBar = payload[0]?.payload;

  if (!isTooltipActive || !active) return null;
  return (
    <div className="bg-white p-2.5 rounded-lg shadow-lg min-w-240 relative">
      <p className="text-sm leading-5 font-medium text-gray-700 mb-1">
        {dataBar?.name}
      </p>
      <p className="text-sm leading-5 font-normal text-gray-500">
        {dataBar?.date}
      </p>
      <div className="h-14 flex justify-center flex-col">
        {dataBar.doneTasks || dataBar.plannedTasks ? (
          <div>
            <p className="text-sm leading-5 font-normal text-gray-500">
              <span className="font-bold">
                {dataBar?.plannedTasks} {t("issues")}{" "}
              </span>
              {t("planned")}
            </p>
            <p className="text-sm leading-5 font-normal text-gray-500">
              <span className="font-bold">
                {dataBar?.doneTasks} {t("issues")}{" "}
              </span>
              {t("have finished")}
            </p>
          </div>
        ) : (
          <p className="text-sm leading-5 text-gray-500 font-bold">
            {dataBar.status ? dataBar.status : "No data"}
          </p>
        )}
      </div>
      <div
        className={`w-8  rounded-sm overflow-hidden inline-block absolute  -mt-1 top-full
            ${isInRightHalf ? "right-1" : null}`}
      >
        <div className="shadow-lg  h-5 w-4 rounded-sm bg-white -rotate-45 transform origin-top-left"></div>
      </div>
    </div>
  );
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
  isTooltipActive: PropTypes.bool,
  isInRightHalf: PropTypes.bool
};
export default CustomTooltip;
