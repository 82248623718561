import { gql } from "@apollo/client";
import { withApollo } from "@apollo/client/react/hoc";
import { UserGroupIcon } from "@heroicons/react/outline";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { teamRoles } from "../../../graphql/enum";
import Hint from "../../layout/Hint";
import ListOfUsers from "../../layout/ListOfUsers/ListOfUsers";
import InviteLinkButton from "../InviteLinkButton/InviteLinkButton";
import InviteTeamForm from "../InviteTeamForm/InviteTeamForm";

export const getUserByEmailQuery = gql`
  query getUserByIdQuery($email: String!) {
    user(email: $email) {
      user {
        id
        name
        avatar
        email
      }
      isNewMember
    }
  }
`;
const InviteTeam = ({
  selectedUsers,
  setSelectedUsers,
  client,
  users,
  teamId,
  teamTitle
}) => {
  const { t } = useTranslation();

  const roles = [
    {
      id: teamRoles.user,
      name: t(`teamRoles.${teamRoles.user}`)
    },
    {
      id: teamRoles.manager,
      name: t(`teamRoles.${teamRoles.manager}`)
    }
  ];
  const [role, setRole] = useState(roles[0]);

  const onInvite = async (data, { resetForm }) => {
    const {
      data: { user }
    } = await client.query({
      query: getUserByEmailQuery,
      variables: {
        email: data.email
      }
    });
    setSelectedUsers([
      ...selectedUsers,
      {
        ...user,
        badges: [t(user.isNewMember ? "inviteTeamForm.newMember" : null)],
        role
      }
    ]);
    resetForm({});
  };

  return (
    <div>
      <div className="mx-auto flex items-center justify-center">
        <UserGroupIcon
          className="h-12 w-12 text-sm text-gray-400 stroke-1"
          aria-hidden="true"
        />
      </div>
      <div className="mt-1 text-center sm:mt-1">
        <div as="h3" className="text-lg leading-6 font-medium text-gray-900">
          {t("inviteTeamModal.addTeamMembers")}
        </div>
        <div>
          <div className="mx-auto  w-9/12">
            <InviteTeamForm
              {...{
                onInvite,
                selectedUsers,
                users,
                role,
                setRole,
                roles
              }}
            />
            {selectedUsers.length !== 0 ? (
              <>
                <div className="text-left uppercase text-gray-500 font-semibold mt-3">{`${t(
                  "inviteTeamModal.teamMembersWillBeAdded"
                )} ${teamTitle}`}</div>
                <ListOfUsers
                  users={selectedUsers}
                  onClose={(id) => {
                    const newSelectedUsers = selectedUsers.filter(
                      ({ user }) => user.id !== id
                    );

                    setSelectedUsers(newSelectedUsers);
                  }}
                />
              </>
            ) : null}
            <div className="mt-8 flex justify-between items-center">
              <InviteLinkButton teamId={teamId} />
              <Hint hint={t("inviteTeamModal.hintAboutInviteLink")}>
                <div className="flex justify-end  items-center text-gray-400 p-4">
                  <div>
                    <QuestionMarkCircleIcon
                      className="h-4 w-4 text-sm font-thin stroke-1"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <span className="ml-3 text-sm w-10/12 mx-auto">
                      {t("inviteTeamModal.learnMoreAboutSharing")}
                    </span>
                  </div>
                </div>
              </Hint>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
InviteTeam.propTypes = {
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func,
  client: PropTypes.shape({
    query: PropTypes.func
  }),
  users: PropTypes.array,
  teamId: PropTypes.string,
  teamTitle: PropTypes.string
};
export default withApollo(InviteTeam);
