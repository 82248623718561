import { gql } from "@apollo/client";

const deleteTeam = gql`
  mutation deleteTeam($teamId: ID!) {
    deleteTeam(teamId: $teamId) {
      success
      code
    }
  }
`;
export default deleteTeam;
