/* eslint-disable react/prop-types */
import { UserGroupIcon } from "@heroicons/react/outline";
import { UserAddIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../layout/Button";
import CreateTeamForm from "../CreateTeamForm/CreateTeamForm";

const CreateTeamView = ({ refetch }) => {
  const [teamName, setTeamName] = useState("");
  const { t } = useTranslation();

  return (
    <div className="w-10/12 mx-auto">
      <div className="mx-auto flex items-center justify-center">
        <UserGroupIcon
          className="h-12 w-12 text-sm text-gray-400 font-thin stroke-1"
          aria-hidden="true"
        />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h2 as="h3" className="text-lg leading-6 font-medium text-gray-900">
          {t("createTeamView.createTeamFormHeaderTitle")}
        </h2>
        <div className="mt-2">
          <p className="text-sm text-gray-500 w-10/12 mx-auto">
            {t("createTeamView.createTeamFormHeaderDescription")}
          </p>
        </div>
        <div className="mx-auto  md:w-5/12 sm:w-full mt-8">
          <CreateTeamForm
            {...{ teamName, setTeamName }}
            name="createTeamView"
            onFormFinish={async () => {
              await refetch();
            }}
          />
        </div>
        <div className="flex justify-center mt-8">
          <div>
            <Button type="submit" form="createTeamView">
              <UserAddIcon className="h-4 w-4 mr-1	" aria-hidden="true" />
              {t("createTeamView.createTeam")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateTeamView;
