import React from "react";

import TeamSingle from "../components/TeamSingle";
import Layout from "./Layout/NewLayout/NewLayout";

const TeamSingleView = () => (
  <Layout>
    <TeamSingle />
  </Layout>
);

export default TeamSingleView;
