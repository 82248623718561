import { gql } from "@apollo/client";

const updateCheckInRetrospectiveRoomAction = gql`
  mutation updateCheckInRetrospectiveRoomAction(
    $retrospectiveItemsRoomActionId: ID!
    $input: UpdateCheckInRetrospectiveRoomActionInput!
  ) {
    updateCheckInRetrospectiveRoomAction(
      retrospectiveItemsRoomActionId: $retrospectiveItemsRoomActionId
      input: $input
    ) {
      success
      code
    }
  }
`;

export default updateCheckInRetrospectiveRoomAction;
