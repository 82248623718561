import PropTypes from "prop-types";
import React from "react";

import { integrationTypes } from "../../../../../../../graphql/enum";
import { BitrixSearchForm } from "./BitrixSearchForm/BitrixSearchForm";
import { GithubSearchForm } from "./GithubSearchForm/GithubSearchForm";
import JiraSearchForm from "./JiraSearchForm/JiraSearchForm";

const Filters = ({
  integrationType,
  dataSourceId,
  setPreviewFilter,
  previewFilter,
  isValideJQL,
  setIsValideJQL
}) => {
  if (integrationType === integrationTypes.gitHub) {
    return (
      <GithubSearchForm
        formclassname="grid grid-cols-1 gap-4"
        dataSourceId={dataSourceId}
        setPreviewFilter={setPreviewFilter}
        previewFilter={previewFilter}
      />
    );
  }
  if (integrationType === integrationTypes.jira) {
    return (
      <JiraSearchForm
        setPreviewFilter={setPreviewFilter}
        previewFilter={previewFilter}
        isValideJQL={isValideJQL}
        setIsValideJQL={setIsValideJQL}
      />
    );
  }
  if (integrationType === integrationTypes.bitrix) {
    return (
      <BitrixSearchForm
        formclassname="grid grid-cols-1 gap-4"
        dataSourceId={dataSourceId}
        setPreviewFilter={setPreviewFilter}
        previewFilter={previewFilter}
      />
    );
  }
  return null;
};

Filters.propTypes = {
  integrationType: PropTypes.string,
  dataSourceId: PropTypes.string.isRequired,
  setPreviewFilter: PropTypes.func.isRequired,
  previewFilter: PropTypes.object.isRequired,
  isValideJQL: PropTypes.bool,
  setIsValideJQL: PropTypes.func
};

export default React.memo(Filters);
