import { gql } from "@apollo/client";

const setCheckInQuestion = gql`
  mutation setCheckInQuestion(
    $checkInId: ID!
    $integrationId: ID!
    $integrationType: IntegrationTypeEnum
  ) {
    setCheckInQuestion(
      checkInId: $checkInId
      integrationId: $integrationId
      integrationType: $integrationType
    ) {
      success
      code
    }
  }
`;

export default setCheckInQuestion;
