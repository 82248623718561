import moment from "moment";

import { validationErrors } from "../graphql/enum";
import { convertTimeToTimeZone } from "./date";

export const classNames = (...classes) => classes.filter(Boolean).join(" ");

export const validateEmail = (email) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};
export const validatePassword = (password, t) => {
  if (password.length === 0) {
    return t(`validationErrors.${validationErrors.required}`);
  }
  if (password.length < 8) {
    return `${t(`validationErrors.passwordMustBeAtLeast`)} 8 ${t(
      `validationErrors.characters`
    )}`;
  }
  return null;
};

export const validateUrl = (string) =>
  !!string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

export const groupBy = (arrayToGroup, property) =>
  arrayToGroup.reduce((r, a) => {
    // eslint-disable-next-line no-param-reassign
    r[a[property]] = r[a[property]] || [];
    r[a[property]].push(a);
    return r;
  }, {});

export const concatObj = (objs) => Object.assign({}, ...objs);
export const getIsHoliday = (holidays, date) =>
  !!holidays.find(({ date: holidayDate }) => {
    const d = new Date(holidayDate);
    const isHoliday =
      d.getDate() === date.getDate() && d.getMonth() === date.getMonth();
    return isHoliday;
  })?.date;

export const getNextRunDate = (startTime, schedule, holidays, timeZone) => {
  const currentDate = moment();
  const beginningTime = convertTimeToTimeZone(startTime, timeZone);
  const isHoliday = getIsHoliday(holidays, new Date(currentDate.format()));
  if (schedule.includes(currentDate.format("ddd")) && !isHoliday) {
    return beginningTime.format("ddd, hh:mm A");
  }
  const startDate = convertTimeToTimeZone(startTime, timeZone).add(1, "days");

  while (
    !schedule.includes(startDate.format("ddd")) &&
    getIsHoliday(holidays, new Date(currentDate.format()))
  ) {
    startDate.add(1, "days");
  }
  return startDate.format("D MMM H:mm");
};

export const convertStringToInteger = (string) => parseInt(string, 10);
