import { useTranslation } from "react-i18next";

import { signupUrl } from "../../../routes";
import GoogleLogin from "../../GoogleLogin/GoogleLogin";
import Link from "../../layout/Link";
import SignInForm from "./SignInForm/SignInForm";

const SignIn = () => {
  const { t } = useTranslation();

  return (
    <div className=" flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-16 w-auto"
          src="/assets/img/standupify_icon_256px.png"
          alt="Standupify"
        />
        <h2
          className="mt-6 text-center font-extrabold text-gray-900 mb-0"
          style={{ fontSize: "1.85rem" }}
        >
          {t("signIn.signInToYourAccount")}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 mb-0">
          {t("signIn.or")}{" "}
          <Link
            to={signupUrl}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {t("signIn.createOrganisationAccount")}
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <SignInForm />
          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  {t("signIn.orContinueWith")}
                </span>
              </div>
            </div>

            <div className="mt-6 flex justify-center">
              <div>
                <GoogleLogin />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
