import { ExternalLinkIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import queryString from "query-string";
import React from "react";
import { useTranslation } from "react-i18next";

import { integrationTypes } from "../../../../../graphql/enum";
import Button from "../../../../layout/Button";
import { BitrixSearchForm } from "../../../SetupCheckIn/SetupCheckInStandup/TaskLists/PreviewQuestion/Filters/BitrixSearchForm/BitrixSearchForm";
import { GithubSearchForm } from "../../../SetupCheckIn/SetupCheckInStandup/TaskLists/PreviewQuestion/Filters/GithubSearchForm/GithubSearchForm";
import JiraSearchForm from "../../../SetupCheckIn/SetupCheckInStandup/TaskLists/PreviewQuestion/Filters/JiraSearchForm/JiraSearchForm";

const QuestionTabFilter = ({
  integrationType,
  previewFilter,
  setPreviewFilter,
  dataSourceId,
  isValideJQL,
  setIsValideJQL,
  firstQuestionUrl
}) => {
  const { t } = useTranslation();
  if (integrationType === integrationTypes.gitHub) {
    return (
      <GithubSearchForm
        formclassname="grid grid-cols-4 gap-3"
        setPreviewFilter={setPreviewFilter}
        previewFilter={previewFilter}
        dataSourceId={dataSourceId}
      />
    );
  }
  if (integrationType === integrationTypes.jira) {
    return firstQuestionUrl && isValideJQL ? (
      <div className="flex">
        <div className="w-10/12">
          <JiraSearchForm
            setPreviewFilter={setPreviewFilter}
            previewFilter={previewFilter}
            isValideJQL={isValideJQL}
            setIsValideJQL={setIsValideJQL}
          />
        </div>
        <Button
          layout="primaryOutline"
          className="flex items-center mb-5 ml-3 mt-4 h-11"
          onClick={() => {
            const url = queryString.stringifyUrl({
              url: firstQuestionUrl,
              query: {
                jql: previewFilter.jql
              }
            });
            if (firstQuestionUrl && isValideJQL) {
              window.open(url);
            }
          }}
        >
          <>
            <ExternalLinkIcon className="w-5 h-5 mr-2" />
            {t("forms.goToJIRA")}
          </>
        </Button>{" "}
      </div>
    ) : (
      <JiraSearchForm
        setPreviewFilter={setPreviewFilter}
        previewFilter={previewFilter}
        isValideJQL={isValideJQL}
        setIsValideJQL={setIsValideJQL}
      />
    );
  }
  if (integrationType === integrationTypes.bitrix) {
    return (
      <BitrixSearchForm
        formclassname="grid grid-cols-4 gap-3 mb-5"
        dataSourceId={dataSourceId}
        setPreviewFilter={setPreviewFilter}
        previewFilter={previewFilter}
      />
    );
  }
  return null;
};

QuestionTabFilter.propTypes = {
  integrationType: PropTypes.string,
  dataSourceId: PropTypes.string.isRequired,
  previewFilter: PropTypes.object,
  setPreviewFilter: PropTypes.func,
  isValideJQL: PropTypes.bool,
  setIsValideJQL: PropTypes.func,
  firstQuestionUrl: PropTypes.string
};

export default QuestionTabFilter;
