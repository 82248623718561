import moment from "moment";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const DatepickerCustomInput = forwardRef(
  ({ value, format, borderStyle, onClick }, ref) => (
    <button
      onClick={onClick}
      ref={ref}
      className={`bg-white relative w-full rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none sm:text-sm ${borderStyle}`}
      id="headlessui-listbox-button-23"
      type="button"
      aria-haspopup="true"
      aria-expanded="false"
      aria-labelledby="headlessui-listbox-label-22 headlessui-listbox-button-23"
    >
      <span className="block truncate">
        {moment(new Date(value)).format(format)}
      </span>
      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
          className="h-5 w-5 text-gray-400"
        >
          <path
            fillRule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clipRule="evenodd"
          ></path>
        </svg>
      </span>
    </button>
  )
);

DatepickerCustomInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  onClick: PropTypes.func,
  classesForInput: PropTypes.string,
  format: PropTypes.string,
  borderStyle: PropTypes.string
};
DatepickerCustomInput.defaultProps = {
  format: "ddd MMM D yyyy",
  borderStyle:
    "border border-gray-300 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
};
export default DatepickerCustomInput;
