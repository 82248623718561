/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { dateFormat } from "../../../../graphql/utils/date";
import { cleanObject } from "../../../../graphql/utils/utils";
import { getCheckInUrl } from "../../../../routes";
import { subtractDays } from "../../../../utility/date";
import Loading from "../../../layout/Loading/Loading";
import DashBoardFilters from "./DashboardFilters/DashboardFilters";
import LineChart from "./LineChart/LineChart";

export const checkInPlanningPokerQuestionsQuery = gql`
  query checkIn($checkInId: ID!, $filters: CheckInQuestionsListFilterInput) {
    checkIn(checkInId: $checkInId) {
      questions {
        count(filters: $filters)
        data(filters: $filters) {
          id
          title
          createdAt
          taskLink
          finalPoint {
            point
          }
          points {
            point
            createdAt
            user {
              id
              avatar
              name
            }
          }
        }
      }
    }
  }
`;

const DashboardTab = ({ checkInId }) => {
  const history = useHistory();
  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateFrom.setTime(subtractDays(defaultDateFrom, 7));
  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const usersIds = parameters.getAll("usersIds");
  const [filters, setFilters] = useState({
    dateFrom: dateFrom ? new Date(dateFrom) : defaultDateFrom,
    dateTo: dateTo ? new Date(dateTo) : defaultDateTo,
    usersIds
  });
  const { loading, data } = useQuery(checkInPlanningPokerQuestionsQuery, {
    variables: {
      checkInId,
      filters: {
        dateFrom: moment(filters.dateFrom).format(dateFormat),
        dateTo: moment(filters.dateTo).format(dateFormat)
      }
    },
    notifyOnNetworkStatusChange: true
  });
  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: getCheckInUrl(checkInId),
      query: cleanObject({
        usersIds: filters.usersIds,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo
      })
    });
    history.push(url);
  }, [filters, history, checkInId]);
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full">
      <DashBoardFilters
        filters={filters}
        setFilters={setFilters}
        questions={data.checkIn.questions.data}
      />
      <LineChart
        filters={filters}
        questionsData={data.checkIn.questions.data}
      />
    </div>
  );
};
DashboardTab.propTypes = {
  checkInId: PropTypes.string.isRequired
};
export default DashboardTab;
