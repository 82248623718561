/* eslint-disable react/prop-types */
/* eslint-disable i18next/no-literal-string */

import { useState } from "react";

import Button from "../../layout/Button";
import InviteTeamModal from "../InviteTeamModal/InviteTeamModal";

const InviteTeamButton = ({ users, teamId, refetch, team }) => {
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setIsInviteModalVisible(true)}>Add</Button>
      <InviteTeamModal
        visible={isInviteModalVisible}
        onClose={() => setIsInviteModalVisible(false)}
        users={users}
        teamId={teamId}
        refetch={refetch}
        team={team}
      />
    </>
  );
};
export default InviteTeamButton;
