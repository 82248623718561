/* eslint-disable react/prop-types */
/* eslint-disable i18next/no-literal-string */

import { useTranslation } from "react-i18next";

import Button from "../Button";

const Pagination = ({
  displayElements,
  countElements,
  page = null,
  typeResultText,
  setPage
}) => {
  const { t } = useTranslation();

  if (page === null) {
    return null;
  }
  const { offset, limit } = page;

  const clickPreviousPage = () => {
    setPage({ offset: offset - limit, limit });
  };

  const clickNextPage = () => {
    setPage({ offset: offset + limit, limit });
  };
  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block pt-2">
        <span className="text-sm text-gray-700 text-center">
          {displayElements !== 0 ? (
            <span>
              <span> {t("pagination.showing")} </span>{" "}
              <span className="font-medium">{offset + 1}</span>{" "}
              {t("pagination.to")}{" "}
              <span className="font-medium">{offset + displayElements}</span>{" "}
              {t("pagination.of")}{" "}
            </span>
          ) : (
            ""
          )}
          <span className="font-medium">{countElements}</span> {typeResultText}
        </span>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {offset !== 0 ? (
          <Button layout="gray" onClick={clickPreviousPage}>
            {t("teamUserTable.previous")}
          </Button>
        ) : null}

        {offset + limit <= countElements ? (
          <Button layout="gray" onClick={clickNextPage} className="ml-3">
            {t("teamUserTable.next")}
          </Button>
        ) : null}
      </div>
    </nav>
  );
};
export default Pagination;
