import { useMutation } from "@apollo/client";
import { CheckIcon, LinkIcon } from "@heroicons/react/solid";
import copyToCliboard from "copy-to-clipboard";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import createOrganizationInviteLink from "../../../../graphql/mutation/createOrganizationInviteLink";

const InviteLinkButton = ({ organizationId }) => {
  const [createOrganizationInviteLinkMutation] = useMutation(
    createOrganizationInviteLink
  );
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const onCopyLink = async () => {
    const { data } = await createOrganizationInviteLinkMutation({
      variables: {
        organizationId
      }
    });
    copyToCliboard(data.createOrganizationInviteLink.link);
    setDisabled(true);
  };
  if (disabled)
    return (
      <div className="text-indigo-600 flex items-center justify-start">
        <CheckIcon className="h-4 w-4 float-left mr-3" />
        {t("inviteLinkButton.copied")}
      </div>
    );
  return (
    <div
      className="cursor-pointer text-indigo-600 flex items-center justify-start"
      onClick={onCopyLink}
    >
      <LinkIcon className="h-4 w-4 float-left mr-3" />
      {t("inviteLinkButton.coptyInvitationLink")}
    </div>
  );
};

InviteLinkButton.propTypes = {
  organizationId: PropTypes.string
};

export default InviteLinkButton;
