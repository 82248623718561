import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { classNames } from "../../../../utility";

const UserRole = ({ seletedOption, onChange, roles }) => (
  <Listbox value={seletedOption} onChange={onChange}>
    {({ open }) => (
      <div className="outline-none">
        <div className="relative">
          <div className="inline-flex rounded-md">
            <div className="relative z-0 inline-flex rounded-md">
              <div className="relative inline-flex items-center pl-3 rounded-l-md text-gray-500">
                <span className="ml-1 text-sm font-medium text-center h-100">
                  {seletedOption ? seletedOption.name : ""}
                </span>
              </div>
              <Listbox.Button
                data-testid="userRole"
                className="relative inline-flex items-center p-2 rounded-l-none rounded-r-md  text-sm font-medium outline-none"
              >
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </Listbox.Button>
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {roles.map((option) => (
                <Listbox.Option
                  key={option.name}
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-indigo-500" : "text-gray-900",
                      "cursor-default select-none relative p-4 text-sm"
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <span
                          className={selected ? "font-semibold" : "font-normal"}
                        >
                          {option.name}
                        </span>
                        {selected ? (
                          <span
                            className={
                              active ? "text-white" : "text-indigo-500"
                            }
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                      <span
                        className={classNames(
                          active ? "text-indigo-200" : "text-gray-500",
                          "mt-2"
                        )}
                      >
                        {option.description}
                      </span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </div>
    )}
  </Listbox>
);
UserRole.propTypes = {
  seletedOption: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired
};

export default UserRole;
