import { gql } from "@apollo/client";

const createUserCheckIns = gql`
  mutation createUserCheckIns($input: [CreateUserCheckInsInput]) {
    createUserCheckIns(input: $input) {
      success
    }
  }
`;
export default createUserCheckIns;
