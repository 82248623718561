/* eslint-disable max-lines */
/* eslint-disable i18next/no-literal-string */

import React from "react";

const PrivacyPolicy = () => (
  <div style={{ padding: "20px" }}>
    <h1>PRIVACY NOTICE</h1>
    <p>
      <b>Last updated May 25, 2020 </b>
    </p>
    <p>
      Thank you for choosing to be part of our community at standupify
      (“company”, “we”, “us”, or “our”). We are committed to protecting your
      personal information and your right to privacy. If you have any questions
      or concerns about our policy, or our practices with regards to your
      personal information, please contact us at contact@standupify.com.
    </p>
    <p>
      When you visit our website https://standupify.com, and use our services,
      you trust us with your personal information. We take your privacy very
      seriously. In this privacy notice, we describe our privacy policy. We seek
      to explain to you in the clearest way possible what information we
      collect, how we use it and what rights you have in relation to it. We hope
      you take some time to read through it carefully, as it is important. If
      there are any terms in this privacy policy that you do not agree with,
      please discontinue use of our Sites and our services.
    </p>
    <p>
      This privacy policy applies to all information collected through our
      website (such as https://standupify.com), and/or any related services,
      sales, marketing or events (we refer to them collectively in this privacy
      policy as the “Sites“).
    </p>
    <p>Personal information you disclose to us</p>
    <p>
      In Short: We collect personal information that you provide to us such as
      name, address, contact information, passwords and security data, and
      payment information.
    </p>
    <p>
      We collect personal information that you voluntarily provide to us when
      registering at the Sites expressing an interest in obtaining information
      about us or our products and services, when participating in activities on
      the Sites or otherwise contacting us.
    </p>
    <p>
      The personal information that we collect depends on the context of your
      interactions with us and the Sites, the choices you make and the products
      and features you use. The personal information we collect can include the
      following:
    </p>
    <p>
      Name and Contact Data. We collect your first and last name, email address,
      postal address, phone number, and other similar contact data.
    </p>
    <p>
      Credentials. We collect passwords, password hints, and similar security
      information used for authentication and account access.
    </p>
    <p>
      Payment Data. We collect data necessary to process your payment if you
      make purchases, such as your payment instrument number (such as a credit
      card number), and the security code associated with your payment
      instrument. All payment data is stored by our payment processor and you
      should review its privacy policies and contact the payment processor
      directly to respond to your questions.
    </p>
    <p>
      All personal information that you provide to us must be true, complete and
      accurate, and you must notify us of any changes to such personal
      information.
    </p>
    <p>Information automatically collected</p>
    <p>
      In Short: Some information – such as IP address and/or browser and device
      characteristics – is collected automatically when you visit our Sites.
    </p>
    <p>
      We automatically collect certain information when you visit, use or
      navigate the Sites. This information does not reveal your specific
      identity (like your name or contact information) but may include device
      and usage information, such as your IP address, browser and device
      characteristics, operating system, language preferences, referring URLs,
      device name, country, location, information about how and when you use our
      Sites and other technical information. This information is primarily
      needed to maintain the security and operation of our Sites, and for our
      internal analytics and reporting purposes.
    </p>
    <p>
      Like many businesses, we also collect information through cookies and
      similar technologies.
    </p>
    <p>Information collected from other sources</p>
    <p>
      In Short: We may collect limited data from public databases, marketing
      partners, and other outside sources.
    </p>
    <p>
      We may obtain information about you from other sources, such as public
      databases, joint marketing partners, as well as from other third parties.
      Examples of the information we receive from other sources include: social
      media profile information; marketing leads and search results and links,
      including paid listings (such as sponsored links).
    </p>
    <h3>2. HOW DO WE USE YOUR INFORMATION?</h3>
    <p>
      In Short: We process your information for purposes based on legitimate
      business interests, the fulfillment of our contract with you, compliance
      with our legal obligations, and/or your consent.
    </p>
    <p>
      We use personal information collected via our Sites for a variety of
      business purposes described below. We process your personal information
      for these purposes in reliance on our legitimate business interests
      (“Business Purposes”), in order to enter into or perform a contract with
      you (“Contractual”), with your consent (“Consent”), and/or for compliance
      with our legal obligations (“Legal Reasons”). We indicate the specific
      processing grounds we rely on next to each purpose listed below.
    </p>
    <p>We use the information we collect or receive:</p>
    <p>
      To facilitate account creation and logon process.If you choose to link
      your account with us to a third party account *(such as your Google or
      Facebook account), we use the information you allowed us to collect from
      those third parties to facilitate account creation and logon process.
    </p>
    <p>
      Deliver targeted advertising to you. We may use your information to
      develop and display content and advertising (and work with third parties
      who do so) tailored to your interests and/or location and to measure its
      effectiveness.
    </p>
    <p>
      Request Feedback. We may use your information to request feedback and to
      contact you about your use of our Sites.
    </p>
    <p>
      To enable user-to-user communications. We may use your information in
      order to enable user-to-user communications with each user’s consent.
    </p>
    <p>
      For other Business Purposes. We may use your information for other
      Business Purposes, such as data analysis, identifying usage trends,
      determining the effectiveness of our promotional campaigns and to evaluate
      and improve our Sites, products, services, marketing and your experience.
    </p>
    <h3>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
    <p>
      In Short: We only share information with your consent, to comply with
      laws, to protect your rights, or to fulfill business obligations.
    </p>
    <p>
      We may process or share data based on the following legal basis: Consent:
      We may process your data if you have given us specific consent to use your
      personal information in a specific purpose.
    </p>
    <p>
      Legitimate Interests: We may process your data when it is reasonably
      necessary to achieve our legitimate business interests.
    </p>
    <p>
      Performance of a Contract: Where we have entered into a contract with you,
      we may process your personal information to fulfill the terms of our
      contract.
    </p>
    <p>
      Legal Obligations: We may disclose your information where we are legally
      required to do so in order to comply with applicable law, governmental
      requests, a judicial proceeding, court order, or legal process, such as in
      response to a court order or a subpoena (including in response to public
      authorities to meet national security or law enforcement requirements).
    </p>
    <p>
      Vital Interests: We may disclose your information where we believe it is
      necessary to investigate, prevent, or take action regarding potential
      violations of our policies, suspected fraud, situations involving
      potential threats to the safety of any person and illegal activities, or
      as evidence in litigation in which we are involved. More specifically, we
      may need to process your data or share your personal information in the
      following situations:
    </p>
    <p>
      Business Transfers. We may share or transfer your information in
      connection with, or during negotiations of, any merger, sale of company
      assets, financing, or acquisition of all or a portion of our business to
      another company.
    </p>
    <p>
      Third-Party Advertisers. We may use third-party advertising companies to
      serve ads when you visit the Sites. These companies may use information
      about your visits to our Website(s) and other websites that are contained
      in web cookies and other tracking technologies in order to provide
      advertisements about goods and services of interest to you.
    </p>
    <h3>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
    <p>
      In Short: We may use cookies and other tracking technologies to collect
      and store your information.
    </p>
    <p>
      We may use cookies and similar tracking technologies (like web beacons and
      pixels) to access or store information. Specific information about how we
      use such technologies and how you can refuse certain cookies is set out in
      our Cookie Policy.{" "}
    </p>
    <h3>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
    <p>
      In Short: We keep your information for as long as necessary to fulfill the
      purposes outlined in this privacy policy unless otherwise required by law.
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy policy, unless a longer retention
      period is required or permitted by law (such as tax, accounting or other
      legal requirements). No purpose in this policy will require us keeping
      your personal information for longer than the period of time in which
      users have an account with us.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or anonymize it, or, if this is not
      possible (for example, because your personal information has been stored
      in backup archives), then we will securely store your personal information
      and isolate it from any further processing until deletion is possible.
    </p>
    <h3> 6. HOW DO WE KEEP YOUR INFORMATION SAFE? </h3>
    <p>
      In Short: We aim to protect your personal information through a system of
      organisational and technical security measures.
    </p>
    <p>
      We have implemented appropriate technical and organisational security
      measures designed to protect the security of any personal information we
      process. However, please also remember that we cannot guarantee that the
      internet itself is 100% secure. Although we will do our best to protect
      your personal information, transmission of personal information to and
      from our Sites is at your own risk. You should only access the services
      within a secure environment.
    </p>
    <h3> 7. DO WE COLLECT INFORMATION FROM MINORS? </h3>
    <p>
      In Short: We do not knowingly collect data from or market to children
      under 18 years of age.
    </p>
    <p>
      We do not knowingly solicit data from or market to children under 18 years
      of age. By using the Sites, you represent that you are at least 18 or that
      you are the parent or guardian of such a minor and consent to such minor
      dependent’s use of the Sites. If we learn that personal information from
      users less than 18 years of age has been collected, we will deactivate the
      account and take reasonable measures to promptly delete such data from our
      records. If you become aware of any data we have collected from children
      under age 18, please contact us at contact@standupify.com.
    </p>
    <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
    <p>
      In Short: In some regions, such as the European Economic Area, you have
      rights that allow you greater access to and control over your personal
      information. You may review, change, or terminate your account at any
      time.
    </p>
    <p>
      In some regions (like the European Economic Area), you have certain rights
      under applicable data protection laws. These may include the right (i) to
      request access and obtain a copy of your personal information, (ii) to
      request rectification or erasure; (iii) to restrict the processing of your
      personal information; and (iv) if applicable, to data portability. In
      certain circumstances, you may also have the right to object to the
      processing of your personal information. To make such a request, please
      use the contact details provided below. We will consider and act upon any
      request in accordance with applicable data protection laws.
    </p>
    <p>
      If we are relying on your consent to process your personal information,
      you have the right to withdraw your consent at any time. Please note
      however that this will not affect the lawfulness of the processing before
      its withdrawal.
    </p>
    <p>
      If you are resident in the European Economic Area and you believe we are
      unlawfully processing your personal information, you also have the right
      to complain to your local data protection supervisory authority. You can
      find their contact details here:
      http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
      Account Information
    </p>
    <p>
      If you would at any time like to review or change the information in your
      account or terminate your account, you can:
      <li>Log into your account settings and update your user account.</li>
      <li> Contact us using the contact information provided.</li>
    </p>
    <p>
      Upon your request to terminate your account, we will deactivate or delete
      your account and information from our active databases. However, some
      information may be retained in our files to prevent fraud, troubleshoot
      problems, assist with any investigations, enforce our Terms of Use and/or
      comply with legal requirements.
    </p>
    <p>
      Cookies and similar technologies: Most Web browsers are set to accept
      cookies by default. If you prefer, you can usually choose to set your
      browser to remove cookies and to reject cookies. If you choose to remove
      cookies or reject cookies, this could affect certain features or services
      of our Sites. To opt-out of interest-based advertising by advertisers on
      our Sites visit http://www.aboutads.info/choices/.
    </p>
    <p>
      Opting out of email marketing: You can unsubscribe from our marketing
      email list at any time by clicking on the unsubscribe link in the emails
      that we send or by contacting us using the details provided below. You
      will then be removed from the marketing email list – however, we will
      still need to send you service-related emails that are necessary for the
      administration and use of your account. To otherwise opt-out, you may: -
      Access your account settings and update preferences.
    </p>
    <h3>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
    <p>
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track (“DNT”) feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. No uniform technology
      standard for recognizing and implementing DNT signals has been finalized.
      As such, we do not currently respond to DNT browser signals or any other
      mechanism that automatically communicates your choice not to be tracked
      online. If a standard for online tracking is adopted that we must follow
      in the future, we will inform you about that practice in a revised version
      of this Privacy Policy.
    </p>
    <h3>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
    <p>
      In Short: Yes, if you are a resident of California, you are granted
      specific rights regarding access to your personal information.
    </p>
    <p>
      California Civil Code Section 1798.83, also known as the “Shine The Light”
      law, permits our users who are California residents to request and obtain
      from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with
      which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a
      request, please submit your request in writing to us using the contact
      information provided below.
    </p>
    <p>
      If you are under 18 years of age, reside in California, and have a
      registered account with the Sites, you have the right to request removal
      of unwanted data that you publicly post on the Sites. To request removal
      of such data, please contact us using the contact information provided
      below, and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Sites, but please be aware that the data may not
      be completely or comprehensively removed from our systems.
    </p>
    <h3>11. DO WE MAKE UPDATES TO THIS POLICY?</h3>
    <p>
      In Short: Yes, we will update this policy as necessary to stay compliant
      with relevant laws.
    </p>
    <p>
      We may update this privacy policy from time to time. The updated version
      will be indicated by an updated “Revised” date and the updated version
      will be effective as soon as it is accessible. If we make material changes
      to this privacy policy, we may notify you either by prominently posting a
      notice of such changes or by directly sending you a notification. We
      encourage you to review this privacy policy frequently to be informed of
      how we are protecting your information.
    </p>
  </div>
);

export default PrivacyPolicy;
