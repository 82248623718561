import React from "react";

import ResetPassword from "../components/Authorization/ResetPassword/ResetPassword";
import AuthorizationLayout from "./Layout/AuthorizationLayout/AuthorizationLayout";

const ResetPasswordView = () => (
  <AuthorizationLayout>
    <ResetPassword />
  </AuthorizationLayout>
);

export default ResetPasswordView;
