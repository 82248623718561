import { PlusSmIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

import { botInstallUrl } from "../../config";
import Button from "../layout/Button";

const BotNotInstalled = () => {
  const { t } = useTranslation();
  return (
    <div className="max-w-2xl -mt-10 mx-auto px-4 sm:px-6 lg:px-0 text-center">
      <img
        className="mx-auto w-auto"
        src="/assets/img/icon/not-install-bot.png"
        alt="Standupify"
      />
      <h1 className="mt-5 text-gray-900 text-sm font-medium mb-2">
        {t("botNotInstalled.botNotInstalled")}
      </h1>
      <p className="text-gray-500 text-sm font-normal	 mb-5">
        {t("botNotInstalled.continueUsingStandupifyPleaseInstalledBot")}
      </p>
      <a href={botInstallUrl} target="_blank" rel="noreferrer">
        <Button layout="primary">
          <PlusSmIcon className="w-5 mr-1" />
          {t("botNotInstalled.installBot")}
        </Button>
      </a>
      <img
        className="mx-auto w-72 mt-5"
        src="/assets/img/icon/example-post.jpg"
        alt="Standupify"
      />
    </div>
  );
};
export default BotNotInstalled;
