/* eslint-disable max-statements */
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { cleanObject } from "../../../../graphql/utils/utils";
import { getCheckInUrl } from "../../../../routes";
import { subtractDays } from "../../../../utility/date";
import Barchart from "./BarChart";
import { Blockers } from "./Blockers/Blockers";
import { DashBoardFilters } from "./DashboardFilters/DashboardFilters";

const Dashboard = ({ checkInId }) => {
  const history = useHistory();

  const defaultDateTo = new Date();
  const defaultDateFrom = new Date();
  defaultDateFrom.setTime(subtractDays(defaultDateFrom, 7));
  const parameters = new URLSearchParams(window.location.search);
  const dateTo = parameters.get("dateTo");
  const dateFrom = parameters.get("dateFrom");
  const userId = parameters.get("userId");
  const [filters, setFilters] = useState({
    dateFrom: dateFrom ? new Date(dateFrom) : defaultDateFrom,
    dateTo: dateTo ? new Date(dateTo) : defaultDateTo,
    userId
  });

  useEffect(() => {
    const url = queryString.stringifyUrl({
      url: getCheckInUrl(checkInId),
      query: cleanObject({
        userId: filters.userId,
        dateTo: filters.dateTo,
        dateFrom: filters.dateFrom
      })
    });
    history.push(url);
  }, [filters, history, checkInId]);
  return (
    <div className="w-full">
      <DashBoardFilters
        filters={filters}
        setFilters={setFilters}
        checkInId={checkInId}
      />
      <Barchart checkInId={checkInId} filters={filters} />
      <Blockers checkInId={checkInId} filters={filters} />
    </div>
  );
};
Dashboard.propTypes = {
  checkInId: PropTypes.string.isRequired
};
export default Dashboard;
