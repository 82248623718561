import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  bitrixAuthorizeUrl,
  GITHUB_AUTHORIZE_URL,
  JIRA_AUTHORIZE_URL
} from "../../../../../../../config";
import { integrationTypes } from "../../../../../../../graphql/enum";
import Button from "../../../../../../layout/Button";
import BitrixIcon from "../../../../../../layout/icons/BitrixIcon";
import GitHubIcon from "../../../../../../layout/icons/GitHubIcon";
import JiraIcon from "../../../../../../layout/icons/JiraIcon";

const getIconGetType = (type) => {
  switch (type) {
    case integrationTypes.bitrix:
      return <BitrixIcon width="30" height="30" />;
    case integrationTypes.gitHub:
      return <GitHubIcon width="30" height="30" />;
    case integrationTypes.jira:
      return <JiraIcon width="30" height="30" />;
    default:
      <></>;
  }
  return null;
};
const getUrlByType = (type, checkInId, bitrixDomain, dataSourceId) => {
  switch (type) {
    case integrationTypes.bitrix:
      return `${bitrixAuthorizeUrl}/?domain=https://${bitrixDomain}&checkInId=${checkInId}`;
    case integrationTypes.gitHub:
      return `${GITHUB_AUTHORIZE_URL}/?checkInId=${checkInId}`;
    case integrationTypes.jira:
      return `${JIRA_AUTHORIZE_URL}/?checkInId=${checkInId}${
        dataSourceId ? `&dataSourceId=${dataSourceId}` : ""
      }`;
    default:
      return "";
  }
};
const ConnectTaskTrackerButton = ({
  type,
  bitrixDomain,
  checkInId,
  onClick = async () => {},
  dataSourceId
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        layout="white"
        className="mr-5"
        data-testid="submit"
        onClick={async () => {
          await onClick();
          const url = getUrlByType(type, checkInId, bitrixDomain, dataSourceId);
          window.location = url;
        }}
      >
        <div className="text-lg flex items-center p-2">
          <div className="mr-3">{getIconGetType(type)}</div>
          <div> {t("connectTaskTrackerSection.connectTaskTracker")}</div>
        </div>
      </Button>
    </>
  );
};
ConnectTaskTrackerButton.propTypes = {
  type: PropTypes.oneOf(Object.values(integrationTypes)),
  onClick: PropTypes.func,
  bitrixDomain: PropTypes.string,
  checkInId: PropTypes.string.isRequired,
  dataSourceId: PropTypes.string
};
export default ConnectTaskTrackerButton;
