import { gql, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { checkInRunSetupStatusEnum } from "../../../../graphql/enum";
import Loading from "../../../layout/Loading/Loading";
import NotFoundPage from "../../../layout/NotFoundPage/NotFoundPage";
import Page from "../../../layout/Page";
import Steps from "../../../layout/Steps";
import AssignActionItems from "./AssignActionItems";
import CreateItems from "./CreateItems/CreateItems";
import MergeSimilar from "./MergeSimilar/MergeSimilar";

export const checkInRunByIdQuery = gql`
  query checkInRunById($checkInRunId: ID!) {
    checkInRun(checkInRunId: $checkInRunId) {
      id
      setupStatus
    }
  }
`;
const SetupCheckInStandup = ({ checkIn, checkInRunId }) => {
  const [currentStep, setCurrentStep] = useState();

  const { t } = useTranslation();
  const { loading, data } = useQuery(checkInRunByIdQuery, {
    variables: { checkInRunId: parseInt(checkInRunId, 10) }
  });
  useEffect(() => {
    if (data?.checkInRun) {
      setCurrentStep(data?.checkInRun?.setupStatus);
    }
  }, [data?.checkInRun]);

  const steps = [
    {
      id: checkInRunSetupStatusEnum.createItems,
      name: t("checkInRetrospectiveSetup.createItems"),
      render: (
        <CreateItems
          checkInRunId={checkInRunId}
          checkInId={checkIn.id}
          query={checkInRunByIdQuery}
        />
      )
    },
    {
      id: checkInRunSetupStatusEnum.mergeSimilar,
      name: t("checkInRetrospectiveSetup.mergeSimilar"),
      render: (
        <MergeSimilar
          checkInRunId={checkInRunId}
          checkInId={checkIn.id}
          query={checkInRunByIdQuery}
        />
      )
    },
    {
      id: checkInRunSetupStatusEnum.actionItems,
      name: t("checkInRetrospectiveSetup.assignActionItems"),
      render: (
        <AssignActionItems checkInRunId={checkInRunId} checkInId={checkIn.id} />
      )
    }
  ];

  if (loading) return <Loading />;
  if (!data?.checkInRun || !currentStep) return <NotFoundPage />;
  return (
    <Page key="page" pageName={checkIn.title}>
      <div className="sm:-mx-6 lg:-mx-8">
        <Steps steps={steps} currentStepId={currentStep} />
      </div>
    </Page>
  );
};

SetupCheckInStandup.propTypes = {
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  checkInRunId: PropTypes.string.isRequired
};
export default SetupCheckInStandup;
