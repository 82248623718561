import { gql } from "@apollo/client";

const updateTeam = gql`
  mutation updateTeam($teamId: ID!, $input: UpdateTeamInput!) {
    updateTeam(teamId: $teamId, input: $input) {
      success
      code
    }
  }
`;
export default updateTeam;
