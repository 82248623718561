import PropTypes from "prop-types";
import { useState } from "react";

import Button from "../../../../../layout/Button";
import SetupCheckInRetrospectiveItem from "../../SetupCheckInRetrospectiveItem";
import AssingUserRoomsActionsItem from "./AssingUserRoomsActionsItem";

const AssignUserActionRooms = ({ refetch, rooms, checkInQuestionId }) => {
  const [newAction, setNewAction] = useState({});
  return rooms.map((room) => {
    if (room?.checkInQuestionId === checkInQuestionId) {
      return (
        <>
          <div className="grid grid-cols-1 gap-4">
            <SetupCheckInRetrospectiveItem
              key={room.id}
              item={{
                ...room,
                users: room.retrospectiveItems.map(({ user }) => user)
              }}
              fieldDisabled={true}
              saveItem={() => {}}
              classnameavatar={room.retrospectiveItems.length > 1 ? "mr-9" : ""}
              addonAfter={
                room.retrospectiveItemsRoomAction ||
                newAction.roomId === room.id ? null : (
                  <Button
                    layout="plus"
                    className="mt-2"
                    onClick={() => {
                      setNewAction({ roomId: room.id });
                    }}
                  />
                )
              }
            />
          </div>
          <div className="grid grid-cols-1 gap-4">
            <AssingUserRoomsActionsItem
              {...{
                newAction,
                setNewAction,
                retrospectiveItemsRoomAction: room.retrospectiveItemsRoomAction,
                refetch,
                roomId: room.id
              }}
            />
          </div>
        </>
      );
    }
    return null;
  });
};
AssignUserActionRooms.propTypes = {
  rooms: PropTypes.array,
  checkInQuestionId: PropTypes.string,
  refetch: PropTypes.func
};
export default AssignUserActionRooms;
