import PropTypes from "prop-types";

const Content = ({ content, sidebar }) => (
  <div className="flex">
    {sidebar !== null ? <div className="py-2 pr-8">{sidebar}</div> : null}
    <div className="flex flex-col w-0 flex-1 overflow-hidden">
      <main className="overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 ">
          {content}
        </div>
      </main>
    </div>
  </div>
);
Content.propTypes = {
  content: PropTypes.object,
  sidebar: PropTypes.object
};

Content.defaultProps = {
  sidebar: null
};
export default Content;
