import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import deleteUserTeam from "../../../graphql/mutation/deleteUserTeam";
import Button from "../../layout/Button";

const DeleteTeamUserButton = ({ teamId, userId, onDelete, query }) => {
  const [deleteUserTeamMutation] = useMutation(deleteUserTeam);
  const { t } = useTranslation();

  return (
    <Button
      layout="danger"
      onClick={async () => {
        await deleteUserTeamMutation({
          variables: {
            deleteUserTeamInput: [{ userId, teamId }]
          },
          optimisticResponse: true,
          update: async (cache) => {
            const data = await cache.readQuery({
              query,
              variables: {
                teamId
              }
            });
            const newUsers = data.team.users.filter(
              (user) => user.id !== userId
            );
            const newData = {
              team: {
                ...data.team,
                users: newUsers
              }
            };
            cache.writeQuery({
              data: newData,
              query,
              variables: {
                teamId
              }
            });
          }
        });

        onDelete();
      }}
    >
      {t("deleteTeamUserButton.deleteFromTeam")}
    </Button>
  );
};

DeleteTeamUserButton.propTypes = {
  teamId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  onDelete: PropTypes.func
};
DeleteTeamUserButton.defaultProps = {
  onDelete: () => {}
};
export default DeleteTeamUserButton;
