/* eslint-disable react/prop-types */
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { classNames } from "../../../utility/index";

const MenuPhone = ({ navigation }) => (
  <>
    <div className="px-2 pt-2 pb-3 space-y-1 testing-library-menu-phone">
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          className={classNames(
            item.current
              ? "bg-gray-900 text-white"
              : "text-gray-300 hover:bg-gray-700 hover:text-white",
            "block px-3 py-2 rounded-md text-base font-medium"
          )}
          aria-current={item.current ? "page" : undefined}
        >
          {item.name}
        </Link>
      ))}
    </div>
  </>
);
export default MenuPhone;
