/* eslint-disable max-statements */
import PropTypes from "prop-types";

import DangerButton from "./DangerButton";
import DisabledButton from "./DisabledButton";
import GrayButton from "./GrayButton";
import PlusButton from "./PlusButton";
import PrimaryButton from "./PrimaryButton";
import PrimaryButtonOutline from "./PrimaryButtonOutline";
import RedButton from "./RedButton";
import RefreshButton from "./RefreshButton";
import WhiteButton from "./WhiteButton";

const Button = (props) => {
  const { layout, disabled } = props;

  if (disabled) return <DisabledButton {...{ ...props }} />;
  if (layout === "white") return <WhiteButton {...{ ...props }} />;
  if (layout === "primary") return <PrimaryButton {...{ ...props }} />;
  if (layout === "gray") return <GrayButton {...{ ...props }} />;
  if (layout === "red") return <RedButton {...{ ...props }} />;
  if (layout === "plus") return <PlusButton {...{ ...props }} />;
  if (layout === "danger") return <DangerButton {...{ ...props }} />;
  if (layout === "refresh") return <RefreshButton {...{ ...props }} />;
  if (layout === "primaryOutline")
    return <PrimaryButtonOutline {...{ ...props }} />;

  return null;
};
Button.propTypes = {
  layout: PropTypes.oneOf([
    "white",
    "primary",
    "gray",
    "red",
    "plus",
    "danger",
    "refresh",
    "primaryOutline"
  ]),
  disabled: PropTypes.bool
};
Button.defaultProps = {
  layout: "primary",
  disabled: false
};
export default Button;
