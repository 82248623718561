/* eslint-disable max-statements */
/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useTranslation } from "react-i18next";

import { summaryRoomType, validationErrors } from "../../../../graphql/enum";
import createUserCheckIns from "../../../../graphql/mutation/createUserCheckIns";
import deleteUserCheckIns from "../../../../graphql/mutation/deleteUserCheckIns";
import updateCheckIn from "../../../../graphql/mutation/updateCheckIn";
import { UserContext } from "../../../../utility/context/User";
import { convertTimeToUTCTimeZone } from "../../../../utility/date";
import Button from "../../../layout/Button";
import ManageCheckInZone from "../../ManageCheckInZone/ManageCheckInZone";
import GeneralZone from "../../SetupCheckIn/SetupCheckInStandup/Review/GeneralZone/GeneralZone";
import ScheduleZone from "../../SetupCheckIn/SetupCheckInStandup/Review/ScheduleZone/ScheduleZone";
import SummaryZone from "../../SetupCheckIn/SetupCheckInStandup/Review/SummaryZone/SummaryZone";
import ManageCheckInUsersZone from "./ManageCheckInUsersZone/ManageCheckInUsersZone";
import QuestionsZone from "./QuestionsZone/QuestionsZone";

const SettingsTab = ({ checkIn, alert, query, refetch }) => {
  const [updateCheckInMutation] = useMutation(updateCheckIn);
  const [createUserCheckInsMutation] = useMutation(createUserCheckIns);
  const [deleteUserCheckInsMutation] = useMutation(deleteUserCheckIns);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const userCheckIns = checkIn.team.users.map((u) => ({
    userId: u.id,
    checkInId: checkIn.id,
    isUserInCheckIn: !!u.checkIn,
    user: u
  }));

  const [checkInData, setCheckInData] = useState({
    membersCanSeeOnlyOwnResponses: true,
    startTime: {
      id: momentTimeZone
        .utc(checkIn.startTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("HH:mm:ss"),
      name: momentTimeZone
        .utc(checkIn.startTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("h:mm a")
    },
    schedule: checkIn.schedule.map((d) => ({
      id: d,
      name: d
    })),
    summaryTime: {
      id: momentTimeZone
        .utc(checkIn.summaryTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("HH:mm:ss"),
      name: momentTimeZone
        .utc(checkIn.summaryTime, "HH:mm:ss")
        .tz(user.timeZone)
        .format("h:mm a")
    },
    questions: checkIn.questions.data,
    summaryType: checkIn.summaryType,
    summarySpace: checkIn.summarySpace,
    userCheckIns
  });

  const [isNextDay, setNextDay] = useState(false);

  useEffect(() => {
    if (checkInData.startTime.id > checkInData.summaryTime.id && !isNextDay) {
      if (checkInData.startTime.id >= "23:00:00") setNextDay(true);

      setCheckInData((data) => ({
        ...data,
        summaryTime: {
          id: moment(checkInData.startTime.id, "HH:mm:ss")
            .add(1, "hour")
            .format("HH:mm:ss"),
          name: moment(checkInData.startTime.name, "h:mm a")
            .add(1, "hour")
            .format("h:mm a")
        }
      }));
    } else {
      setNextDay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInData.startTime]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = t(`validationErrors.${validationErrors.required}`);
    }
    if (
      checkInData.summaryType === summaryRoomType.space &&
      !checkInData.summarySpace
    ) {
      errors.summarySpace = t(`validationErrors.${validationErrors.required}`);
    }
    if (checkInData.schedule.length === 0) {
      errors.schedule = t(`validationErrors.${validationErrors.required}`);
    }

    const startTime = checkInData.startTime.id;
    const summaryTime = checkInData.summaryTime.id;
    if (startTime >= summaryTime) {
      errors.summaryTime = t(`summaryZone.summaryTimeError`);
    }

    const questionErrors = {};

    checkInData.questions.map((q) => {
      if (!q.title) {
        questionErrors[q.id] = t(
          `validationErrors.${validationErrors.required}`
        );
      }
      return q;
    });
    if (Object.values(questionErrors).length > 0) {
      errors.questions = questionErrors;
    }

    return errors;
  };

  const onSubmit = async (values) => {
    await updateCheckInMutation({
      variables: {
        checkInId: checkIn.id,
        input: {
          isSetup: true,
          title: values.title,
          summaryType: checkInData.summaryType,
          summarySpace:
            checkInData.summaryType === summaryRoomType.space
              ? checkInData.summarySpace.spaceName
              : "",
          startTime: momentTimeZone
            .tz(checkInData.startTime.id, "HH:mm:ss", user.timeZone)
            .utc()
            .format("HH:mm:ss"),
          summaryTime: momentTimeZone
            .tz(checkInData.summaryTime.id, "HH:mm:ss", user.timeZone)
            .utc()
            .format("HH:mm:ss"),
          schedule: checkInData.schedule.map(({ id }) => id),
          questions: checkInData.questions.map(({ id, title }) => ({
            id,
            title
          }))
        }
      }
    });
    const userCheckInInsert = checkIn.team.users.filter((userInset) => {
      if (userInset.checkIn) return false;
      const userCheckIn = checkInData.userCheckIns.find(
        (userCheckInData) => userCheckInData.userId === userInset.id
      );
      return !!userCheckIn.isUserInCheckIn;
    });
    const userCheckInDelete = checkIn.team.users.filter((userDelete) => {
      if (!userDelete.checkIn) return false;
      const userCheckIn = checkInData.userCheckIns.find(
        (userCheckInData) => userCheckInData.userId === userDelete.id
      );
      return !userCheckIn.isUserInCheckIn;
    });

    const userCheckInsToInsertInput = userCheckInInsert.map(
      ({ id: userId }) => ({
        userId,
        checkInId: checkIn.id
      })
    );
    const userInsToDeleteInput = userCheckInDelete.map(({ id: userId }) => ({
      userId,
      checkInId: checkIn.id
    }));
    await createUserCheckInsMutation({
      variables: {
        input: userCheckInsToInsertInput
      }
    });
    await deleteUserCheckInsMutation({
      variables: {
        input: userInsToDeleteInput
      }
    });
    refetch();
    alert.success(
      <div>
        <p className="text-black">{t("settingsTab.successfullySaved")}</p>
      </div>
    );
  };

  return (
    <>
      <Formik
        validate={validate}
        initialValues={{
          title: checkIn.title
        }}
        onSubmit={onSubmit}
      >
        {({ errors }) => (
          <Form>
            <GeneralZone
              checkInData={checkInData}
              setCheckInData={setCheckInData}
              checkIn={checkIn}
              errors={errors}
            />
            <QuestionsZone
              setCheckInData={setCheckInData}
              checkInData={checkInData}
              errors={errors.questions}
            />
            <ScheduleZone
              currentUser={user}
              usersTeam={checkIn.team.users}
              checkInData={checkInData}
              setCheckInData={setCheckInData}
              errors={errors}
            />
            <SummaryZone
              usersTeam={checkIn.team.users}
              currentUser={user}
              checkInData={checkInData}
              setCheckInData={setCheckInData}
              errors={errors}
            />
            <ManageCheckInUsersZone
              checkInData={checkInData}
              setCheckInData={setCheckInData}
            />
            <div className="flex justify-end mt-8">
              <div>
                <Button layout="primary" type="submit">
                  {t("Save")}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ManageCheckInZone checkIn={checkIn} query={query} />
    </>
  );
};
SettingsTab.propTypes = {
  checkIn: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    startTime: PropTypes.string,
    summaryTime: PropTypes.string,
    schedule: PropTypes.array,
    questions: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired
        })
      )
    }),
    team: PropTypes.shape({
      users: PropTypes.array.isRequired
    }).isRequired,
    summaryType: PropTypes.oneOf(Object.values(summaryRoomType)),
    summarySpace: PropTypes.string
  }),
  alert: PropTypes.object,
  query: PropTypes.object.isRequired,
  refetch: PropTypes.func
};

export default withAlert()(SettingsTab);
