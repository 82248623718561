/* eslint-disable max-statements */
import { gql, useQuery } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useContext } from "react";
import { Redirect } from "react-router-dom";

import { getCheckInUrl } from "../../../../../routes";
import { UserContext } from "../../../../../utility/context/User";
import Loading from "../../../../layout/Loading/Loading";
import AssignActionItemsQuestions from "./AssignActionItemsQuestions/AssignActionItemsQuestions";

export const checkInRunByIdQuery = gql`
  query checkInRunById($checkInRunId: ID!) {
    checkInRun(checkInRunId: $checkInRunId) {
      id
      checkIn {
        id
        teamRole
        questions {
          data {
            id
            title
            questionState
            retrospectiveItemsRooms(checkInRunId: $checkInRunId) {
              id
              title
              description
              checkInQuestionId
              retrospectiveItems {
                id
                title
                description
                user {
                  id
                  name
                  avatar
                }
              }
              retrospectiveItemsRoomAction {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

const AssignActionItems = ({ checkInRunId, checkInId }) => {
  const { user } = useContext(UserContext);

  const { loading, data, refetch } = useQuery(checkInRunByIdQuery, {
    variables: {
      checkInRunId
    }
  });

  if (loading) return <Loading />;
  if (!data?.checkInRun) return <Redirect to={getCheckInUrl(checkInId)} />;

  return (
    <Formik initialValues={{}}>
      {() => (
        <Form>
          <div className="-mt-8">
            <div className="grid grid-cols-2 gap-5 text-center">
              <AssignActionItemsQuestions
                refetch={refetch}
                checkInRunId={checkInRunId}
                questions={data.checkInRun.checkIn?.questions?.data}
                user={user}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AssignActionItems.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  query: PropTypes.object,
  checkInId: PropTypes.string
};
export default AssignActionItems;
