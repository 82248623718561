import { ChartPieIcon } from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";

const Breadcrumbs = () => {
  const { t } = useTranslation();
  return (
    <div className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg px-12 py-36 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <ChartPieIcon className="mx-auto h-14 w-14 text-gray-400" />
      <span className="mt-2 block text-sm font-medium text-gray-900">
        {t("layout.noDataAvailable")}
      </span>
    </div>
  );
};

export default Breadcrumbs;
