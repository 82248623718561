import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "../../../components/layout/Button";
import OopsIcon from "../../../components/layout/icons/Oops/Oops";
import { teamsUrl } from "../../../routes";
import Layout from "../../Layout/NewLayout/NewLayout";

const ErrorView = ({ children, displayButton }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="flex flex-col items-center">
        <OopsIcon height="394" width="556" />
        <div className="text-black text-2xl mt-11">{children}</div>
        {displayButton ? (
          <Button className="mt-11 py-4 px-11">
            <Link to={teamsUrl} className="text-white hover:text-white">
              {t("error.backToHomePage")}
            </Link>
          </Button>
        ) : null}
      </div>
    </Layout>
  );
};
ErrorView.defaultProps = {
  displayButton: true
};
ErrorView.propTypes = {
  children: PropTypes.any,
  displayButton: PropTypes.bool
};
export default ErrorView;
