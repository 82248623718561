/* eslint-disable max-statements */
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { teamRoles } from "../../../../graphql/enum";
import Button from "../../../layout/Button";
import Select from "../../../layout/Select/Select";

const AddUserToTeamForm = ({ selectedTeams, setSelectedTeams, teams }) => {
  const { t } = useTranslation();
  const roles = useMemo(
    () => [
      {
        id: teamRoles.user,
        name: t(`teamRoles.${teamRoles.user}`)
      },
      {
        id: teamRoles.manager,
        name: t(`teamRoles.${teamRoles.manager}`)
      }
    ],
    [t]
  );

  const [newTeam, setNewTeam] = useState({});
  const [role, setRole] = useState(roles[0]);
  const [errorsValidate, setErrorsValidate] = useState({});

  const validate = (selectedTeamsValidate, newTeamValidate) => {
    const errors = {};

    if (Object.keys(newTeamValidate).length === 0) {
      errors.team = t(`validationErrors.teamNotSelected`);
    }
    selectedTeamsValidate.map((selectedTeam) => {
      if (selectedTeam.id === newTeamValidate.id)
        errors.team = t("editPeopleUserModal.userIsalreadyTeam");
      return true;
    });
    return errors;
  };
  const onInvite = () => {
    const errors = validate(selectedTeams, newTeam);

    setErrorsValidate(errors);
    const isValidate = Object.keys(errors).length === 0;
    if (isValidate) {
      setSelectedTeams([
        ...selectedTeams,
        {
          id: newTeam.id,
          title: newTeam.name,
          role: role.id
        }
      ]);
      setNewTeam({});
      setRole(roles[0]);
    }
  };
  const teamsAvailable = teams.filter((team) => {
    const isBelong = !!selectedTeams.find(
      (selectedTeam) => selectedTeam.id === team.id
    );
    return !isBelong;
  });
  if (teamsAvailable.length === 0) return null;

  return (
    <div className="mt-10">
      <h3 className="mb-0 text-lg leading-6">
        {t("editPeopleUserModal.teams")}
      </h3>
      <p> {t("editPeopleUserModal.userPartTeams")}</p>
      <div className="mt-6 ">
        <div className="flex">
          <div
            className={` border-solid border-2 border-light-blue-500  focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm ${
              errorsValidate.team ? "border-red-300" : "border-gray-300"
            } rounded-md`}
          >
            <div className="flex">
              <Select
                seletedOption={newTeam}
                onChange={setNewTeam}
                roles={teamsAvailable.map((element) => ({
                  id: element.id,
                  name: element.title
                }))}
                classNameButton="min-w-240"
              />
              <Select
                seletedOption={role}
                onChange={setRole}
                roles={roles}
                classNameButton="min-w-120"
              />
            </div>
          </div>
          <div className="col-span-1 ml-3 flex justify-end mt-1 mb-1">
            <Button
              type="button"
              onClick={onInvite}
              className="w-2/12"
              layout="plus"
            />
          </div>
        </div>
        {errorsValidate.team}
      </div>
    </div>
  );
};

AddUserToTeamForm.propTypes = {
  selectedTeams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      role: PropTypes.string
    })
  ),
  setSelectedTeams: PropTypes.func,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      role: PropTypes.string
    })
  )
};
export default AddUserToTeamForm;
