/* eslint-disable react/prop-types */
import { Disclosure, Menu } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Fragment } from "react";

import {
  getNavigationMenu,
  getProfile,
  getProfileMenu
} from "../../utility/config";
import MenuHeader from "./Menus/NewMenu";
import MenuPhone from "./Menus/NewMenuPhone";
import ProfileMenu from "./Menus/ProfileMenu";
import ProfileMenuPhone from "./Menus/ProfileMenuPhone";

const Header = ({ user, pathname, logout }) => {
  const profile = getProfile(user);
  const navigationMenu = getNavigationMenu(user, pathname);
  const profileMenu = getProfileMenu(user, pathname, logout);

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src="/assets/img/standupify_logo_full_701x200.png"
                      alt="Standupify"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src="/assets/img/standupify_logo_full_701x200.png"
                      alt="Standupify"
                    />
                  </div>
                  <MenuHeader navigation={navigationMenu} />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <Menu as="div" className="ml-3 relative">
                    {({ openMenu }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <img
                              className="h-8 w-8 rounded-full"
                              src={profile.imageUrl}
                              alt=""
                            />
                          </Menu.Button>
                        </div>
                        <ProfileMenu
                          userNavigation={profileMenu}
                          openMenu={openMenu}
                        />
                      </>
                    )}
                  </Menu>
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <MenuPhone navigation={navigationMenu} />
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {user.email}
                    </div>
                  </div>
                  <button className="ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <ProfileMenuPhone userNavigation={profileMenu} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};
export default Header;
