import { useMutation } from "@apollo/client";
import { CheckIcon } from "@heroicons/react/outline";
import { t } from "i18next";
import PropTypes from "prop-types";

import updateCheckInRun from "../../../../../graphql/mutation/updateCheckInRun";
import { UserTeamManagerRequired } from "../../../../../utility/hocs";
import Button from "../../../../layout/Button";

const CheckInRetrospectiveSetupNextSetupButton = ({
  checkInRunId,
  query,
  userTeamRole,
  checkInRunSetupStatus
}) => {
  const [updateCheckInRunMutation] = useMutation(updateCheckInRun);

  return (
    <UserTeamManagerRequired userTeamRole={userTeamRole}>
      <Button
        layout="primary"
        onClick={() => {
          updateCheckInRunMutation({
            variables: {
              checkInRunId,
              setupStatus: checkInRunSetupStatus
            },
            optimisticResponse: true,
            update: async (cache) => {
              const dataQuery = await cache.readQuery({
                query,
                variables: {
                  checkInRunId: parseInt(checkInRunId, 10)
                }
              });

              const newData = {
                checkInRun: {
                  ...dataQuery.checkInRun,
                  setupStatus: checkInRunSetupStatus
                }
              };
              cache.writeQuery({
                data: newData,
                query,
                variables: {
                  checkInRunId: parseInt(checkInRunId, 10)
                }
              });
            }
          });
        }}
      >
        <CheckIcon className="h-5 w-5" aria-hidden="true" />
        <span className="ml-2">{t("checkInRetrospectiveSetup.nextStep")}</span>
      </Button>
    </UserTeamManagerRequired>
  );
};

CheckInRetrospectiveSetupNextSetupButton.propTypes = {
  checkInRunId: PropTypes.string.isRequired,
  query: PropTypes.object,
  userTeamRole: PropTypes.string,
  checkInRunSetupStatus: PropTypes.string.isRequired
};
export default CheckInRetrospectiveSetupNextSetupButton;
