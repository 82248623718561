import PropTypes from "prop-types";

import Content from "./Content/Content";

const Page = ({ children, pageName, headerRight, sidebar, modals }) => (
  <div>
    {pageName || headerRight ? (
      <div className="flex justify-between mb-6">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {pageName}
        </h2>
        {headerRight}
      </div>
    ) : null}
    <Content sidebar={sidebar} content={children} />
    {modals}
  </div>
);
Page.propTypes = {
  children: PropTypes.object,
  pageName: PropTypes.string,
  headerRight: PropTypes.object,
  sidebar: PropTypes.object,
  modals: PropTypes.array
};

export default Page;
