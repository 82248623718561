import { gql } from "@apollo/client";

const createTeam = gql`
  mutation createTeam($title: String!) {
    createTeam(title: $title) {
      id
      success
      code
    }
  }
`;

export default createTeam;
