import { gql } from "@apollo/client";

const separateCheckInRetrospectiveRoom = gql`
  mutation separateCheckInRetrospectiveRoom($retrospectiveItemsRoomId: ID!) {
    separateCheckInRetrospectiveRoom(
      retrospectiveItemsRoomId: $retrospectiveItemsRoomId
    ) {
      success
      code
    }
  }
`;

export default separateCheckInRetrospectiveRoom;
