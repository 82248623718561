import { RefreshIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import { useState } from "react";

const RefreshButton = ({ onClick, type, form, className }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <button
      type={type}
      className={`flex justify-center align-center rounded-full border border-transparent  text-lg font-medium sm:text-lg ${
        isDisabled ? "text-gray-900" : "text-gray-300 cursor-auto"
      } ${className}`}
      {...{
        form,
        type
      }}
      onClick={() => {
        if (isDisabled) {
          onClick();
          setIsDisabled(false);
        }
      }}
    >
      <RefreshIcon title="refresh" style={{ width: "10px", height: "10px" }} />
    </button>
  );
};

RefreshButton.defaultProps = {
  type: "button"
};

RefreshButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  form: PropTypes.func,
  className: PropTypes.string
};

export default RefreshButton;
