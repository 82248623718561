import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { userConfirmationCodeEnum } from "../../../../graphql/enum";
import Badge from "../../../layout/Badge";

const ErrorResetPassword = ({ responseCode }) => {
  const { t } = useTranslation();
  if (responseCode === userConfirmationCodeEnum.tokenExpired) {
    return (
      <div className="flex justify-center">
        <Badge color="red" className="p-2">
          {t("resetPassword.passwordResetTimeIsOver")}{" "}
        </Badge>
      </div>
    );
  }
  if (responseCode === userConfirmationCodeEnum.alreadyUsed) {
    return (
      <div className="flex justify-center">
        <Badge color="red" className="p-2">
          {t("resetPassword.youHaveAlreadyTakenOpportunity")}{" "}
        </Badge>
      </div>
    );
  }

  return null;
};

ErrorResetPassword.propTypes = {
  responseCode: PropTypes.string
};

export default ErrorResetPassword;
