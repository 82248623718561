import { gql } from "@apollo/client";

const approveOrganizationInviteMutation = gql`
  mutation approveOrganizationInvite($code: String!) {
    approveOrganizationInvite(code: $code) {
      success
      code
      jwtToken
    }
  }
`;

export default approveOrganizationInviteMutation;
