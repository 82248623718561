import PropTypes from "prop-types";

import { classNames } from "../../../utility";
import Avatar from "../../layout/Avatar";
import Hint from "../../layout/Hint";

const Avatars = ({ users, isHint, width, height, className }) => (
  <div className={classNames("flex -space-x-2 relative z-0", className)}>
    {users.map((user) =>
      isHint ? (
        <Hint
          hint={
            <>
              <span className="font-semibold"> {user.name} </span>
              {user.email}
            </>
          }
          className="mb-5"
          key={user.id}
        >
          <Avatar
            src={user.avatar}
            name={user.name}
            key={user.id}
            width={width}
            height={height}
          />
        </Hint>
      ) : (
        <Avatar
          src={user.avatar}
          name={user.name}
          key={user.id}
          width={width}
          height={height}
        />
      )
    )}
  </div>
);
Avatars.propTypes = {
  users: PropTypes.array,
  isHint: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string
};
Avatars.defaultProps = {
  isHint: false,
  className: ""
};
export default Avatars;
