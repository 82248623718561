import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DatePicker from "../../../../layout/Datepicker";
import Multiselect from "../../../../layout/Multiselect";

const DashBoardFilters = ({ filters, setFilters, questions }) => {
  const { t } = useTranslation();
  const users = [];

  questions.map((question) => {
    question.points.map((point) => {
      const isUser = users.find((user) => user.id === point.user.id);
      if (!isUser && point.point !== null) {
        users.push(point.user);
      }
      return null;
    });
    return null;
  });
  return (
    <div className="flex ml-14  mb-10">
      <div className="mr-5 w-60">
        <Multiselect
          options={users}
          label={t("User")}
          placeholder={t("All")}
          selectedOptions={
            filters.usersIds
              ? filters.usersIds.map((userId) =>
                  users.find(({ id: userIdStub }) => userIdStub === userId)
                )
              : []
          }
          onChange={(val) => {
            setFilters({ ...filters, usersIds: val.map(({ id }) => id) });
          }}
        />
      </div>
      <DatePicker
        type={"range"}
        labelFrom={t("From Date")}
        labelTo={t("To Date")}
        startDate={filters.dateFrom}
        endDate={filters.dateTo}
        setStartDate={(val) => setFilters({ ...filters, dateFrom: val })}
        setEndDate={(val) => setFilters({ ...filters, dateTo: val })}
        isHorizontal={true}
      />
    </div>
  );
};
DashBoardFilters.propTypes = {
  filters: PropTypes.shape({
    dateFrom: PropTypes.object.isRequired,
    dateTo: PropTypes.object.isRequired,
    usersIds: PropTypes.array
  }),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  checkInId: PropTypes.string,
  setFilters: PropTypes.func.isRequired
};
export default DashBoardFilters;
