import "./index.css";

import PropTypes from "prop-types";

const Hint = ({ children, hint, style, className }) => (
  <div className="relative hover-trigger">
    <div
      className={`absolute  hover-target bottom-full flex justify-center w-full ${className}`}
      style={style}
    >
      <div className="bg-white border border-gray-100 p-2.5 text-gray-500 rounded-lg text-sm text-left relative">
        {hint}
      </div>
    </div>
    <div> {children}</div>
  </div>
);
Hint.propTypes = {
  children: PropTypes.any,
  hint: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string
};
Hint.defaultProps = {
  className: ""
};

export default Hint;
