/* eslint-disable react/prop-types */
/* eslint-disable max-statements */
import { useMutation } from "@apollo/client/index";
import { Button, Typography } from "antd";
import queryString from "query-string";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import PageSpinner from "../components/layout/Loading/Loading";
import { approveInviteMutationCode } from "../graphql/enum";
import approveOrganizationInviteMutation from "../graphql/mutation/approveOrganizationInviteMutation";
import { teamsUrl } from "../routes";
import { UserContext } from "../utility/context/User";
import Layout from "./Layout";

const { Title } = Typography;

const ApproveOrganisationInvite = ({ location }) => {
  const { code } = queryString.parse(location.search);
  const [approveInviteLinkMutation, { loading, data }] = useMutation(
    approveOrganizationInviteMutation
  );
  const { login } = useContext(UserContext);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    approveInviteLinkMutation({ variables: { code } });
  }, [code, approveInviteLinkMutation]);
  if (loading) return <PageSpinner />;

  if (data) {
    const { success, code: responseCode } = data.approveOrganizationInvite;
    if (success) {
      const { jwtToken } = data.approveOrganizationInvite;
      login(jwtToken);
      history.push(teamsUrl);
    }
    if (
      responseCode === approveInviteMutationCode.userIsAlreadyInOrganization
    ) {
      return (
        <Layout>
          <Title type="danger">
            {t("approveInvite.userIsAlreadyInOrganization")}
          </Title>
          <br />
          <Link to={teamsUrl}>
            <Button>{t("goToStandups")}</Button>
          </Link>
        </Layout>
      );
    }
    if (
      responseCode ===
      approveInviteMutationCode.userIsAlreadyInDifferentOrganization
    ) {
      return (
        <Layout>
          <Title type="danger">
            {t("approveInvite.userIsAlreadyInDifferentOrganization")}
          </Title>
          <br />
          <Link to={teamsUrl}>
            <Button>{t("goToStandups")}</Button>
          </Link>
        </Layout>
      );
    }
    if (responseCode === approveInviteMutationCode.alreadyApproved) {
      return (
        <Layout>
          <Title type="danger">{t("approveInvite.tokenIsApproved")} </Title>
        </Layout>
      );
    }
  }
  return null;
};

export default ApproveOrganisationInvite;
