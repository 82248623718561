import { gql } from "@apollo/client";

const deleteUserTeam = gql`
  mutation deleteUserTeam($deleteUserTeamInput: [DeleteUserTeamInput]) {
    deleteUserTeam(deleteUserTeamInput: $deleteUserTeamInput) {
      success
    }
  }
`;
export default deleteUserTeam;
