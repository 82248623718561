import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { checkInTypes } from "../../../../graphql/enum";
import Badge from "../../../layout/Badge";

const CheckInStatusLabel = ({ checkIn }) => {
  const { t } = useTranslation();

  if (!checkIn.isSetup) {
    return <Badge color="yellow">{t("checkInStatusLabel.settingUp")}</Badge>;
  }
  if (!checkIn.isActive) {
    return <Badge color="red">{t("checkInStatusLabel.deactivated")}</Badge>;
  }

  return <Badge color="green">{t("checkInStatusLabel.active")}</Badge>;
};

CheckInStatusLabel.propTypes = {
  checkIn: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(checkInTypes)).isRequired,
    isSetup: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired
  })
};
export default CheckInStatusLabel;
