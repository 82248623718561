/* eslint-disable max-statements */
import PropTypes from "prop-types";
import queryString from "query-string";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { getCheckInUrl } from "../../../routes";
import { UserContext } from "../../../utility/context/User";
import {
  UserTeamManagerRequired,
  UserTeamNotManagerRequired
} from "../../../utility/hocs";
import Tabs from "../../layout/Tabs";
import DashboardTab from "./DashboardTab/DashboardTab";
import EstimatesTab from "./EstimatesTab";
import PlanningPokerButtonDowloadCsv from "./EstimatesTab/PlanningPokerButtonDowloadCsv/PlanningPokerButtonDowloadCsv";
import PlanningPokerStartTab from "./PlanningPokerStartTab/PlanningPokerStartTab";
import SettingsTab from "./SettingsTab";

const PlanningPokerSingle = ({ checkIn, checkInId, query }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const parameters = new URLSearchParams(window.location.search);
  const defaultKeyId = parameters.get("defaultKey");
  const activeTab = parameters.get("activeTab");

  useEffect(() => {
    if (!activeTab && defaultKeyId) {
      const url = queryString.stringifyUrl({
        url: getCheckInUrl(checkInId),
        query: {
          activeTab: defaultKeyId
        }
      });
      history.push(url);
    }
  });
  if (activeTab === "start") {
    return <PlanningPokerStartTab checkInId={checkInId} />;
  }

  const currentUser = checkIn.team.users.find(
    (userTeam) => userTeam.id === user.id
  );

  const tabsUser = [
    {
      name: "dashboard",
      title: t("Dashboard"),
      render: () => <DashboardTab checkInId={checkInId} />
    }
  ];
  const tabsManager = [
    {
      name: "dashboard",
      title: t("Dashboard"),
      render: () => <DashboardTab checkInId={checkInId} />
    },
    {
      name: "estimates",
      title: t("Estimates"),
      rightContent: <PlanningPokerButtonDowloadCsv checkInId={checkInId} />,
      render: () => <EstimatesTab checkInId={checkInId} />
    },
    {
      name: "settings",
      title: t("Settings"),
      render: () => <SettingsTab checkIn={checkIn} query={query} />
    }
  ];

  return (
    <>
      <UserTeamNotManagerRequired userTeamRole={currentUser?.teamRole}>
        <Tabs
          tabs={tabsUser}
          defaultKey={"dashboard"}
          currentUrl={getCheckInUrl(checkInId)}
        />
      </UserTeamNotManagerRequired>
      <UserTeamManagerRequired userTeamRole={currentUser?.teamRole}>
        <Tabs
          tabs={tabsManager}
          defaultKey={activeTab || "dashboard"}
          currentUrl={getCheckInUrl(checkInId)}
        />
      </UserTeamManagerRequired>
    </>
  );
};

PlanningPokerSingle.propTypes = {
  checkInId: PropTypes.string.isRequired,
  checkIn: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired
};
export default PlanningPokerSingle;
