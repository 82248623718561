import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { checkInTypes, validationErrors } from "../../../graphql/enum";
import createCheckIn from "../../../graphql/mutation/createCheckIn";
import { getCheckInUrl } from "../../../routes";
import Input from "../../layout/Input/Input";
import RadioGroup from "../../layout/RadioGroup";

const AddCheckInForm = ({ name, teamId, onFormFinish, refetch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createCheckInMutation] = useMutation(createCheckIn);

  const checkinOptions = [
    {
      id: checkInTypes.standup,
      name: t("checkInTypes.standup"),
      description: t("addCheckInForm.standupDesctiption")
    },
    {
      id: checkInTypes.planningPoker,
      name: t("checkInTypes.planningPoker"),
      description: t("addCheckInForm.moodTrackerDesctiption")
    },
    {
      id: checkInTypes.custom,
      name: t("checkInTypes.custom"),
      description: t("addCheckInForm.customDesctiption")
    }
  ];
  const [type, setType] = useState(checkinOptions[0].id);

  const validate = ({ checkInTitle }) => {
    const errors = {};
    if (!checkInTitle) {
      errors.checkInTitle = t(`validationErrors.${validationErrors.required}`);
    }
    return errors;
  };

  const onCreateCheckIn = async ({ checkInTitle }) => {
    const { data } = await createCheckInMutation({
      variables: {
        type,
        title: checkInTitle,
        teamId
      }
    });

    if (data.createCheckIn.success) {
      refetch();
      onFormFinish();
      history.push(getCheckInUrl(data.createCheckIn.id));
    }
  };
  return (
    <Formik
      validate={validate}
      initialValues={{
        teamName: "",
        checkInTitle: ""
      }}
      onSubmit={onCreateCheckIn}
    >
      {({ errors }) => (
        <Form id={name}>
          <div className="mt-1 mb-8">
            <Input
              type="text"
              name="checkInTitle"
              placeholder="Type check in title"
              error={errors.checkInTitle}
            />
          </div>
          <div className="text-left uppercase text-gray-500 font-semibold mb-4">
            {t("addCheckInForm.chooseTypeCheckIn")}
          </div>
          <RadioGroup
            options={checkinOptions}
            selected={type}
            onChange={setType}
          />
        </Form>
      )}
    </Formik>
  );
};

AddCheckInForm.propTypes = {
  onFormFinish: PropTypes.func,
  teamId: PropTypes.string.isRequired,
  name: PropTypes.string,
  refetch: PropTypes.func
};
AddCheckInForm.defaultProps = {
  onFormFinish: () => {},
  name: ""
};

export default AddCheckInForm;
